import {actionTypes} from '../constants/action-types';

const initialState = {
    loading: false,
    success: "",
    error: "",
    isSuccess: false,
    isError: false,
    localLoader : false
};

export const highlights = (state = initialState, {type, payload={}}) => {
    switch(type){
        case actionTypes.LOADING:
            return { ...state, loading: payload};
        case actionTypes.LOCAL_LOADER:
            return { ...state,localLoader : payload }
        case actionTypes.NEUTRAL:
            return { ...state, isSuccess: false, isError: false};
        case actionTypes.SUCCESS:
            return { ...state, success: payload, isError: false, isSuccess: true};
            case actionTypes.ERROR:
                return { ...state, error: payload, isError: true, isSuccess: false};
        default:
            return state;
    }
}