import { actionTypes } from "../constants/action-types";

const initialState = {
    logoUrl: '',
};

export const logoReducer =(state=initialState, {type, payload={}})=>{
 
    switch(type){
        case actionTypes.SET_LOGO_URL :
            return {
                logoUrl : payload
            }
        
            default :
            return state

    }
    

}