import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { numberFormater } from '../../helpers/common';
import { colors, graphColors } from '../../helpers/meta';
import PieCore from '../charts/PieCore';
import ColorDot from '../ColorDot';
import '../../App.css'
import './SummaryCard.css'
import { adlyticFormatter, convertMinutesIntoHour, numberFixed } from '../../helpers/utils';

const GraphCard = ({ parent, childrenLabel, allData, overFlowFlag, time = false, edit = false, percentage=false }) => {

    console.log('parent', parent);
    console.log('childrenLabel', childrenLabel);
    console.log('allData', allData);
    console.log('overFlowFlag', overFlowFlag);







    const [children, setChildren] = useState([]);
    const [data, setData] = useState([]);
    const [peiCoreObject, setPieCoreObject] = useState()
    const [showGraph, setShowGraph] = useState(true);
    console.log(peiCoreObject, 'peiCoreObject');
    useEffect(() => {
        setShowGraph(false);
        setTimeout(() => {
            setShowGraph(true)
        }, 10)

    }, [peiCoreObject]);


    useEffect(() => {
        setChildren(childrenLabel)
        setData(allData)
        let pieCoreObject = [];
        childrenLabel.forEach((item) => {

            let obj = {
                name: item.label,
                value: Object.keys(allData ? allData : {}).length ? item.key in allData ? item.key + 'impt' in allData ? allData[item.key + "impt"] + allData[item.key] : allData[item.key] : 0 : 0,
            }
            pieCoreObject.push(obj)
        })
        setPieCoreObject(pieCoreObject);

    }, [parent, childrenLabel, allData])

    console.log('peiCoreObject',peiCoreObject);

    return (
        <Fragment>
            <Card className='graphCard' style={{ height: "100%", cursor: 'pointer', }}  >
                <CardBody>


                    {!edit &&
                        <>


                            <h4 className='mb-0' style={{ fontWeight: 400, fontSize: "14px" }}>{parent.label}</h4>


                            {
                                peiCoreObject &&
                                <PieCore data={peiCoreObject} time={time} percentage={percentage}/>
                            }
                        </>
                    }

                    <div style={{ maxHeight: '12.5rem', backgroundColor: '' }}>
                        <div
                            className={overFlowFlag && 'scrollbar'} id="style-3" style={{ width: "100%", overflowY: 'auto', overflowX: "hidden" }}
                        >
                            {children &&
                                children?.map((item, index) => {
                                    return (
                                        <div className={'py-2 px-2'} style={{ paddingLeft: "10px", fontSize: "12px", color: colors.lightDark, }} key={"visitors-chart-" + index}>
                                            <ColorDot marginRight={'0.4rem'} color={graphColors[index]} display={'inline-block'} />
                                            {item.label} {''}
                                            <span style={{ float: "right" }}>
                                                {time ? convertMinutesIntoHour(Object.keys(data ? data : {})?.length ? item.key in data ? data[item.key] : 0 : 0) :
                                                    adlyticFormatter(Object.keys(data ? data : {})?.length ? item.key in data ? item.key + "impt" in data ? data[item?.key + "impt"] + data[item?.key] : data[item.key] : 0 : 0)}
                                            </span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default GraphCard;