export const actionTypes = {
    LOADING : "LOADING",
    SUCCESS : "SUCCESS",
    LOCAL_LOADER : "LOCAL_LOADER",
    NEUTRAL : "NEUTRAL",
    ERROR : "ERROR",
    LOGIN : "LOGIN",
    ACCESS: "ACCESS",
    CLIENTS: "CLIENTS",
    LOGOUT: "LOGOUT",
    SUMMARY: "SUMMARY",
    EMPLOYEE_SUMMARY:'EMPLOYEE_SUMMARY',
    LIST_FILTER: "LIST_FILTER",
    CREATE_FILTER: "CREATE_FILTER",
    DELETE_FILTER: "DELETE_FILTER",
    GET_USER:"GET_USER",
    GET_SPACES:"GET_SPACES",
    SET_LOGO_URL:"SET_LOGO_URL",
    GET_NEW_USER : "GET_NEW_USER",
    GET_SYSTEM_FEATURE : 'GET_SYSTEM_FEATURE',
    FORGOT_EMAIL_FIND :'FORGOT_EMAIL_FIND',
    SET_HEATMAP: "SET_HEATMAP",
    SET_HEATMAP_SUMMARY: "SET_HEATMAP_SUMMARY",
    SET_HEATMAP_FILTER: "SET_HEATMAP_FILTER",
    SET_HEATMAP_LOADER: "SET_HEATMAP_LOADER",
    GET_HEATMAP_FILTER: "GET_HEATMAP_FILTER",
    SET_FOOTFALL: "SET_FOOTFALL",
    SET_FOOTFALL_IMPUTE : "SET_FOOTFALL_IMPUTE",
    SET_MARKET_AVG_VALUE : "SET_MARKET_AVG_VALUE",
    SET_FOOTFALL_GRAPH: "SET_FOOTFALL_GRAPH",
    SET_FOOTFALL_GRAPH2 : 'SET_FOOTFALL_GRAPH2',
    SET_FOOTFALL_GRAPH_IMPUTE : "SET_FOOTFALL_GRAPH_IMPUTE",
    SET_FOOTFALL_FILTER: "SET_FOOTFALL_FILTER",
    SET_FOOTFALL_LOADER: "SET_FOOTFALL_LOADER",
    GET_FOOTFALL_FILTER: "GET_FOOTFALL_FILTER",
    SET_COMPARISON_LOADER: "SET_COMPARISON_LOADER",
    SET_COMPARISON_FILTER: "SET_COMPARISON_FILTER",
    SET_COMPARISON: "SET_COMPARISON",
    SET_COMPARISON_GROUP: "SET_COMPARISON_GROUP",
    SET_CATEGORY : 'SET_CATEGORY',
    SET_CATEGORY_ENTRY : "SET_CATEGORY_ENTRY",
    SET_CATEGORY_GRAPH : 'SET_CATEGORY_GRAPH',
    SET_CATEGORY_FILTER : "SET_CATEGORY_FILTER",
    SET_CATEGORY_LOADER : "SET_CATEGORY_LOADER",
    SET_FLOOR : "SET_FLOOR",
    SET_GRAPGH_DATA : "SET_GRAPGH_DATA",
    SET_FOOTFALL_GRAPH_TYPE: "SET_FOOTFALL_GRAPH_TYPE",
    SET_CONVERSION_LOADER: "SET_CONVERSION_LOADER",
    SET_CONVERSION_FILTER: "SET_CONVERSION_FILTER",
    SET_CONVERSION_ERROR: "SET_CONVERSION_ERROR",
    SET_CONVERSION_SUCCESS: "SET_CONVERSION_SUCCESS",
    SET_CONVERSION_DATA: "SET_CONVERSION_DATA",
    SET_CONVERSION_DAILY_DATA: "SET_CONVERSION_DAILY_DATA",
    SET_CONVERSION_TOTAL: "SET_CONVERSION_TOTAL",
    SET_INSIGHT_FILTER: "SET_INSIGHT_FILTER",
    SET_INSIGHT_PROGRESS: "SET_INSIGHT_PROGRESS",
    SET_EMPLOYEE:"SET_EMPLOYEE",
    GET_SECTIONS_EMPLOYEE : "GET_SECTIONS_EMPLOYEE",
    SET_FLOORS : "SET_FLOORS",
    GET_GRAPH_OF_EMPLOYEE_ACTIVITY : "GET_GRAPH_OF_EMPLOYEE_ACTIVITY",
    EMP_ACT_GRAPH_LOADER : "EMP_ACT_GRAPH_LOADER",
    SET_EMPLOYEE_SALE_UNITS : 'SET_EMPLOYEE_SALE_UNITS',
    GET_BASIC_INSIGHTS : "GET_BASIC_INSIGHTS",
    GET_BASIC_TRENDS : 'GET_BASIC_TRENDS',
    SET_INSIGHT: "SET_INSIGHT",
    SET_INSIGHT_DATA: "SET_INSIGHT_DATA",
    GET_INSIGHTS: "GET_INSIGHTS",
    SET_SHOW_FILTER : 'SET_SHOW_FILTER',
    SET_JOURNEY : "SET_JOURNEY",
    SET_STORES_ACTIVITY : 'SET_STORES_ACTIVITY',
    SET_PRIMARY_INSIGHTS_QUESTION: "SET_PRIMARY_INSIGHTS_QUESTION",
    SET_CUSTOM_QUESTION : 'SET_CUSTOM_QUESTION',
    SET_SALE_ITEM : "SET_SALE_ITEM",
    SET_INSIGHT_PERFORMANCE: 'SET_INSIGHT_PERFORMANCE',
    GET_USER_ACTIVITY : "GET_USER_ACTIVITY",
    EMP_ACT_TIMELINE : "EMP_ACT_TIMELINE",
    CUS_TIMELINE : "CUS_TIMELINE",
    TIMELINE_LOADER : "TIMELINE_LOADER",
    SET_CHATGPT_TABLE_QUERIES:"SET_CHATGPT_TABLE_QUERIES",
    SET_CHATGPT_QUESTION : "SET_CHATGPT_QUESTION",
    SLOT_COUNTS:"SLOT_COUNTS",
    SET_SPACE : "SET_SPACE"
    
}