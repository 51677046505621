import React, { useState, useEffect, useRef } from 'react';
import ReactECharts from 'echarts-for-react';
import { useSelector } from 'react-redux';
import { graphColors } from '../../helpers/meta';
import { Card, CardBody, Col, Row } from 'reactstrap';
import html2canvas from 'html2canvas';

const getSpiderOptions = (lengendsData, maxIndicators, seriesData) => {
  return {
    title: {
      text: ''
    },
    tooltip: {
      // position : 'absolute',
      // padding: [10, 10],
      // height: '100px',
      // borderColor: "#d5d5d5",
      confine: true

    },
    legend: {
      data: lengendsData
    },
    color: graphColors,
    radar: {
      // shape: 'circle',
      indicator: maxIndicators
    },
    series: [{
      name: 'Comparison',
      type: 'radar',
      // areaStyle: {normal: {}},
      data: seriesData,
    }]
  };
}

const DynamicSpider = ({ dataOptions, items, setSpiderImageData, chartRef }) => {


  const dataPoints = {};
  dataOptions.forEach(val => {
    dataPoints[val.value] = val.label;
  });

  const summary = items;
  const access = useSelector(state => state.auth.access);
  const [option, setOption] = useState(null);
  const loadingOption = {
    text: 'Loading...',
    color: '#4413c2',
    textColor: '#270240',
    maskColor: 'rgba(255, 255, 255, 0.3)',
    zlevel: 0
  };

  const onChartReady = (echarts) => {
    echarts.hideLoading();
  }

  useEffect(() => {
    let current = summary;
    let lengendsData = current.map((store => store.label));
    let maxIndicators = [];
    let seriesData = [];
    let maxValues = {};
    Object.keys(dataPoints).forEach((key) => {
      maxValues[key] = 0;
    });

    if (current.length > 0) {
      current.map((row, index) => {
        var _seriesData = { name: row.label, value: [] };
        Object.keys(dataPoints).forEach((key) => {
          if (typeof row[key] === "string") {

          }
          if (maxValues[key] < row[key]) {
            maxValues[key] = row[key]
          }
          _seriesData.value.push(row[key]);
        });
        seriesData.push(_seriesData);
      });

      Object.keys(maxValues).forEach(key => {
        maxIndicators.push({ name: dataPoints[key], max: maxValues[key] });
      })
      let _option = getSpiderOptions(lengendsData, maxIndicators, seriesData);
      if (JSON.stringify(_option) != JSON.stringify(option)) {
        setOption(getSpiderOptions(lengendsData, maxIndicators, seriesData));
      }
    }

  }, [summary, dataPoints])

  const spiderPage = useRef(null);
  let spiderBody = document.getElementsByClassName("spiderPage");


  useEffect(() => {

    if (typeof spiderBody !== undefined && spiderBody) {
      setTimeout(async () => {
        const canvas = await html2canvas(spiderBody[0]);
        const dataURI = canvas.toDataURL('image/png');
        let array = dataURI.split(",")
        setSpiderImageData(dataURI)
      }, 100);
    }

  }, [spiderBody])

  


  return (
    option !== null &&
    <Row className='p-0'>
      <Col className='p-0'>
        <Card className='p-0'>
          <CardBody className='p-0'>
            <ReactECharts
              option={option}
              style={{ height: 500 }}
              onChartReady={onChartReady}
              className='spiderPage'
              id="spider"
              ref={chartRef}

            />
          </CardBody>
        </Card>
      </Col>
    </Row>

  )
}
export default DynamicSpider;