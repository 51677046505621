import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Button, Form, Input } from 'reactstrap';
import { chatgptTableRequest } from '../helpers/requests';
import { setChatpgptQuestion } from '../redux/actions/summary';
import { Crosshair } from 'react-feather';
import { ImCross } from 'react-icons/im';

export default function TableSearch({ dataOptions, summary, features }) {

    const dispatch = useDispatch();
    const [searchString, setSearchString] = useState("");

    console.log('searchString', searchString);
    const [csv, setCsv] = useState();

    console.log('csvcsv', csv);


    const handleSearch = async (event) => {
        event.preventDefault()
        dispatch(setChatpgptQuestion(searchString))
        let obj = {
            data: searchString + "on given data" + "\n" + csv,
            dispatch
        }
        let response = await chatgptTableRequest(obj)
        console.log('response', response);

    }

    const handlekeypress = (event) => {
        if (event.key === "Enter") {
            handleSearch(event)
        }
    }


    useEffect(() => {
        let _csv = "Store,Floor"


        features.footfall.forEach((item, ind) => {
            // if(ind === dataOptions.length-1 ){
            //     _csv = _csv + "," +item.label+ "\n"
            // }
            // else 
            {
                _csv = _csv + "," + item.label
            }
        })
        features.sales.forEach((item, index) => {
            if (index === features.sales.length - 1) {
                _csv = _csv + "," + item.label + "\n"
            }
            else {
                _csv = _csv + "," + item.label
            }
        })





        summary.current?.forEach((store) => {

            store.floors?.forEach((floor, index) => {
                _csv = _csv + "," + store.label + "," + floor.floorLabel

                features.footfall.forEach((fFal) => {
                    _csv = _csv + "," + floor[fFal.value]
                })

                features.sales.forEach((sale, index) => {

                    if (index === features.sales.length - 1) {
                        _csv = _csv + "," + store[sale.value] + "\n"

                    }
                    else {
                        _csv = _csv + "," + store[sale.value]

                    }
                })
            })
        })

        console.log('_csv', _csv);
        setCsv(_csv)



    }, [summary.current])





    return (
        <>
            <div style={{ width: '600px' }}>
                <Input type='text' className='search-input mx-2' style={{ width: "100%", height: "2rem", fontSize: "12px" }} onChange={(e) => { setSearchString(e.target.value) }} onKeyDown={searchString.length && handlekeypress} />
               
            </div>

            {<Button className="mx-3 btn btn-primary" onClick={handleSearch} disabled={!searchString.length} >Search</Button>}



        </>

    )
}
