import React from 'react';
import ReactDOM from "react-dom/client";
import './index.css';
import App from './App';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import { storage } from './helpers/common';

// Global error handler
window.onerror = function (message, source, lineno, colno, error) {
  console.log('message----', message, "error", error);
  // Check if the error message contains "Malformed UTF-8 data"
  if (message && message.includes("Malformed UTF-8 data")) {
    // Clear local storage and refresh the page
    console.log('going to logout', message);
    storage.clear();
    window.location.reload();
  }

  if (message && message.includes("Uncaught TypeError: Cannot read properties of null (reading 'removeEventListener')")) {
    console.log('Uncaught is coming');
    return true
  }
  return true;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

  <Provider store={store}>
    <App />

  </Provider>

);


// ReactDOM.render(

//   <Provider store={store}>
//     <App />
//   </Provider>

//   , document.getElementById('root')
// );

serviceWorker.unregister();
