import React, { useState, useEffect } from 'react';
import { ArrowDown, ArrowUp, ChevronDown, ChevronUp } from 'react-feather';
import { Card, CardBody } from 'reactstrap';
import { numberFormater } from '../../helpers/common';
import { adlyticFormatter, numberFixed } from '../../helpers/utils';
import './SummaryCard.css'


const SummaryCardCompact = (props) => {
    console.log('props.current', props);
    const tag = "CARD";
    const [percentage, setPercentage] = useState(0);
    useEffect(() => {
        if ((typeof props.previous === undefined && !props.previous) || props.previous == null || props.previous == 0) {
            setPercentage(0);
        } else {
            if (typeof props.current !== undefined && props.current) {
                setPercentage((((props.current - props.previous) / props.previous) * 100).toFixed(1))
            }
            else {
                setPercentage(0)
            }
        }
    }, [props])
    return (
        <Card className="w-100 summaryCard"
        // style={{display: "inline-block", float: "none", /*maxWidth: "270px",*/ marginRight: "10px", minWidth: "150px" }} 
        >
            {/* <Card className='mx-2 mb-3' style={{maxWidth: "250px", float: "left"}}> */}
            <CardBody className=''>
                <h6 className='m-0 pb-2 secondary fz-12' style={{ fontSize: "", fontWeight: "400", }} >
                    {props.title === 'DWELLTIME' ? props.title + ' (Min)' : props.title}
                    {props.showPercentage && <span className={percentage < 0 ? "m-0 p-0 danger" : percentage == 0 ? "m-0 p-0 secondary" : "m-0 p-0 success"} style={{ fontSize: "12px", float: "right" }}>
                        {
                            percentage < 0 &&
                            <ChevronDown style={{ marginTop: "-3px" }} className="danger" size={16} />
                        }
                        {
                            percentage > 0 &&
                            <ChevronUp style={{ marginTop: "-3px" }} className="success" size={16} />
                        }
                        <span>{props.previous == 0 ? "0%" : Math.abs(percentage) + "%"}</span>
                    </span>}
                </h6>
                <h3 className='m-0 p-0 mb-2 fz-20' style={{ fontSize: " ", fontWeight: "500" }}> {props.title === ("Avg DT").toUpperCase() ? props.current : adlyticFormatter(props.current)}</h3>

                <p className='m-0 p-0 secondary fz-10'>
                    {props.showPrevious ?

                        <div>
                            Previous {`  `}
                            <span className='primary'>{adlyticFormatter(props.previous)}</span>
                        </div>
                        :
                        <div>
                            At {`  `}
                            <span className='primary'>{props.occupancy}</span>
                        </div>
                        // <span>&nbsp;</span>
                    }

                    {/* {
                        props.showTime &&
                        <div>
                            Previous {`  `}
                            <span className='primary'>{adlyticFormatter(props.previous)}</span>
                        </div>
                    } */}


                </p>
            </CardBody>
        </Card>
    )
}

export default SummaryCardCompact;