import React, { Fragment, useEffect, useState } from "react";
import { BarChart, Hash, Layout, PieChart, Star, ChevronLeft, ChevronRight, Info } from "react-feather";
import { Button, ButtonGroup, Col, Row, UncontrolledTooltip } from "reactstrap";
import MainFilter from "../../components/filters/MainFilter";
import * as echarts from "echarts/core";
import { GridComponent, TooltipComponent, TitleComponent } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { ScatterChart } from "echarts/charts";
import Spider from "../../components/charts/Spider";
import Scatter from "../../components/charts/Scatter";
import { summaryRequest, storesActivityRequest, user, newSummaryRequest } from "../../helpers/requests";
import { useDispatch, useSelector } from "react-redux";
import SummaryTable from "../../components/tables/SummaryTable";
import LeaderBoard from "../../components/LeaderBoard";
import Properties from "../../components/modals/Properties";
import SummaryCardCompact from "../../components/cards/SummaryCardCompact";
import BarGraph from "../../components/tables/BarGraph";
import NewMainFiltrer from "../../components/filters/newMainFilter";
import { getSelectText } from "../../components/filters/components/FilterCommon";
import { numberFormater } from "../../helpers/common";
import { numberFixed } from "../../helpers/utils";
import SummaryImputeCardCpmpact from "../../components/cards/SummaryImputeCardCpmpact";

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    ScatterChart,
    CanvasRenderer,
]);

const NewHome = () => {
    const tag = "HOME";
    let tooltip;
    const [currentView, setCurrentView] = useState("table");
    const [filter, setFilter] = useState();
    const [selectedStores, setSelectedStores] = useState([]);
    const [categories, setCategories] = useState([])
    const [excludedCategories, setExcludedCategories] = useState(categories)
    const dispatch = useDispatch();
    const summary = useSelector((state) => state.summary);
    const [newSummary, setNewSummary] = useState();
    const [raw, setRaw] = useState();



    useEffect(() => {
        if (typeof summary !== undefined && summary) {
            let _newSummary = summary;
            let newSummary = {};
            Object.keys(_newSummary ? _newSummary : {}).forEach((object) => {
                let currentArray = [];
                if (typeof _newSummary[object] !== undefined && _newSummary[object] && _newSummary[object].length > 0) {
                    _newSummary[object].forEach((item) => {

                        let currentObject = {};
                        Object.keys(item ? item : {}).forEach((childKey) => {

                            if (childKey === "floors") {
                                let _floors = [];
                                item[childKey].forEach((floorItem) => {
                                    let childFloorObject = {};

                                    Object.keys(floorItem ? floorItem : {}).forEach((floorChildKey) => {

                                        let floorImputeKey = floorChildKey + "impt";
                                        if (typeof floorImputeKey !== undefined && floorImputeKey && floorImputeKey in floorItem) {
                                            childFloorObject[floorChildKey] = floorItem[floorChildKey] + floorItem[floorImputeKey];

                                        }
                                        else {
                                            childFloorObject[floorChildKey] = floorItem[floorChildKey]
                                        }
                                    })
                                    _floors.push(childFloorObject);
                                })
                                currentObject[childKey] = _floors
                            }
                            else {
                                let imputeKey = childKey + "impt";
                                if (typeof imputeKey !== undefined && imputeKey && imputeKey in item) {
                                    currentObject[childKey] = item[childKey] + item[imputeKey];
                                }
                                else {
                                    currentObject[childKey] = item[childKey]
                                }


                            }

                        })
                        currentArray.push(currentObject)
                    })

                    newSummary[object] = currentArray;


                }
                else {
                    newSummary[object] = _newSummary[object];
                }

            })
            setNewSummary(newSummary);
            setRaw(newSummary.current);






        }

    }, [summary])


    const features = useSelector((state) => state.auth.features);
    const [selected, setSelected] = useState();


    const payload = {
        start: '',
        start1: '',
        end: '',
        fid: [],
        sid: [],
        type: 'd',
        ex_fid: [],
        excluded: [],
        included: [],
        end1: "",
      }

    const updateFilter = async (e) => {

        console.log('filter===',e);


        let _selectedStores = user.access.filter((item) => e.sid.includes(item._id));
        setSelectedStores(_selectedStores);
        let _categories = [];
        _selectedStores.forEach((store) => {
            store.floors.forEach((floor) => {
                floor.sections.forEach((section) => {
                    _categories.push({ label: section.label, value: section._id })
                })
            })
        })
        let staticCategories = [
            { label: "M Kids", value: "M Kids" },
            { label: "Un-Stitched", value: "Un-Stitched" },
            { label: "Stitched", value: "Stitched" },
            { label: "Jewelry", value: "Jewelry" },
            { label: "M Basic", value: "M Basic" },
            { label: "Perfume", value: "Perfume" },
            { label: "M Couture", value: "M Couture" },
            { label: "M Luxe", value: "M Luxe" },
            { label: "Left-Over", value: "Left-Over" },
        ]



        // setCategories(_categories);
        setCategories(staticCategories);

        e['excluded'] = excludedCategories;
        e["included"] = [];

        let _payload = {};
        Object.keys((payload ? payload : {})).forEach((key) => {
            console.log('current key',key);
            if (key !== undefined && key && key in e ) {
            console.log('current key inside',key);

                _payload[key] = e[key]
            }
        })

        var requestData = {
            params: _payload,
            dispatch,
        };
        console.log('requestData==',requestData);
        setFilter(e)

        storesActivityRequest({ client_ids: [user.client_id[0]._id], dispatch })
        newSummaryRequest(requestData);

    };


    const [option, setOption] = useState();
    const [options, setOptions] = useState();
    const [footfallFeatures, setFootfallFeatures] = useState([]);
    const [properties, setProperties] = useState([]);
    // const raw = useSelector(state => state.summary.current);

    const summaryMenu = [
        ["table", <Layout size={16} />, "Tabular View"],
        ["radar", <PieChart size={16} />, "Radar Chart"],
        ["scatter", <Hash size={16} />, "Scatter Plot"],
        ["bar", <BarChart size={16} />, "Stacked Chart"],
        ["leader", <Star size={16} />, "Leader Board"],
    ];





    useEffect(() => {
        setFootfallFeatures(features.footfall);
        let allOptions = [...features.footfall, ...features.sales, ...[{ value: 'totalimpt', label: 'Imp' }]];
        setOption(allOptions);
        setOptions(allOptions);
    }, [features]);

    useEffect(() => {
        var findMedianSortedArrays = function (nums1, nums2) {
            let concatArray = [...nums1, ...nums2];
            let sorted = concatArray.sort(function (a, b) {
                return a - b;
            });
            let lengthOfArray = concatArray.length;
            if (lengthOfArray % 2 !== 0) {
                let index = Math.floor(lengthOfArray / 2);
                return sorted[index];
            } else {
                let index1 = Math.floor(lengthOfArray / 2);
                let median = (sorted[index1] + sorted[index1 + 1]) / 2;
                return median;
            }
        };
        findMedianSortedArrays([1, 3], [2, 2]);
    });


    useEffect(() => {
        setProperties(option == null ? [] : typeof option !== undefined && option && option.map((opt) => opt.value));
    }, [option]);

    useEffect(() => {
        if (excludedCategories) {

            if (filter !== undefined && filter) {
                let _excluded = excludedCategories.map((item) => { return item.label })

                filter['excluded'] = _excluded;
                filter['included'] = [];
                let requestData = {
                    params: filter,
                    dispatch
                }

                // storesActivityRequest({ client_ids: [user.client_id[0]._id], dispatch })
                // summaryRequest(requestData);
            }
        }

    }, [excludedCategories])


    return (
        <Fragment>
            <MainFilter
                pageTitle="Summary"
                updateFilter={(e) => updateFilter(e)}
                type="default"
            />
            {/* <NewMainFiltrer
        pageTitle="Summary"
        updateFilter={(e) => updateFilter(e)}
        payload={payload}
        selected={{storeName : true, floorName : false}}
        type="default"
        multipleStores={true}
        multipleFloors={true}
        showAllStoresOnce={false}


      /> */}

            <h5 className="p-0 mb-3 fw-normal">Traffic</h5>
            <Row className="mb-4 g-2">
                {typeof newSummary !== undefined && newSummary && 'totalCurrent' in newSummary &&
                    newSummary.totalCurrent.length > 0 &&
                    footfallFeatures &&
                    footfallFeatures.map((item, index) => {
                        let check = isFinite(newSummary.totalCurrent[0][item.value]);
                        let imputeKey = item.value + "impt";
                        let imputedValue = 0;
                        if (typeof imputeKey !== undefined && imputeKey && imputeKey in newSummary.totalCurrent[0]) {
                            imputedValue = numberFormater(numberFixed((newSummary.totalCurrent[0][imputeKey] / newSummary.totalCurrent[0][item.value]) * 100)) + "%"

                        }

                        return (
                            <Col md={2} className="" key={"traffic-card-" + index}>
                                <SummaryImputeCardCpmpact
                                    title={item?.label.toUpperCase()}
                                    current={check ? newSummary.totalCurrent[0][item.value] : 0}
                                    previous={newSummary.totalPrevious[0][item.value]}
                                    imputedValue={imputedValue}
                                    imputeFlag={true}
                                    index={index}
                                />
                            </Col>
                        );
                    })}
                {!footfallFeatures.length && (
                    <span className="d-block small opacity-50">
                        <i>You dont have any feature please contact admin</i>
                    </span>
                )}
            </Row>
            {<h5 className="p-0 mb-3 fw-normal">Sales</h5>}
            <Row className="mb-4 g-2">
                {typeof newSummary !== undefined && newSummary && "totalCurrent" in newSummary &&
                    newSummary.totalCurrent.length > 0 &&
                    features &&
                    features.sales &&
                    features.sales.map((item, index) => {


                        return (
                            <Col md={2} className="" key={"sales-card-" + index}>
                                <SummaryImputeCardCpmpact
                                    title={item?.label.toUpperCase()}
                                    current={newSummary.totalCurrent[0][item.value]}
                                    previous={newSummary.totalPrevious[0][item.value]}
                                    imputedValue={0}
                                    imputeFlag={false}
                                    index={index}


                                />
                            </Col>
                        );
                    })}
                {!features?.sales.length > 0 && (
                    <span className='className="d-block small opacity-50"'>
                        <i>You dont have any feature please contact admin</i>
                    </span>
                )}
            </Row>
            {(footfallFeatures.length || features?.sales) && (
                <Row className="mb-4">
                    {(
                        <Col className="text-start px-3">
                            {option && options &&
                                <Properties
                                    option={option}
                                    options={options}
                                    setOption={(e) => setOption(e)}
                                    flag={'feature'}

                                />}

                            {/* this categories feature is just for mariab for now  */}
                            {/* {
              categories &&

                <Properties
                  option={excludedCategories}
                  options={categories}
                  setOption={(e) => setExcludedCategories(e)}
                  flag={'category'}
                />
              } */}

                        </Col>
                    )}
                    {/* {
            categories &&
            <Col md={2} className="text-start">
              <Properties
                option={excludedCategories}
                options={categories}
                setOption={(e) => setExcludedCategories(e)}
                flag={'category'}
              />
            </Col>
          } */}
                    <Col className="text-end">
                        <ButtonGroup>
                            {summaryMenu.map((val, index) => {
                                return (
                                    <Button
                                        href={"#toggle-tab-" + val[0]}
                                        color={
                                            currentView === val[0]
                                                ? "outline-primary"
                                                : "outline-light"
                                        }
                                        className="section-tab"
                                        size="sm"
                                        onClick={function noRefCheck() {
                                            setCurrentView(val[0]);
                                        }}
                                        active={currentView === val[0] ? true : null}
                                        key={"compare-tab-" + index}
                                        id={"toggle-tooltip-" + val[0]}
                                    >
                                        {val[1]}
                                    </Button>
                                );
                            })}
                        </ButtonGroup>
                        {summaryMenu.map((val, index) => {
                            return (
                                <UncontrolledTooltip
                                    flip
                                    isOpen={val[0] === tooltip}
                                    autohide={false}
                                    placement="top"
                                    target={"toggle-tooltip-" + val[0]}
                                    key={"compare-tab-tooltip-" + index}
                                >
                                    {val[2]}
                                </UncontrolledTooltip>
                            );
                        })}
                    </Col>
                </Row>
            )}
            <div style={{ minHeight: "500px" }}>
                {currentView === "table" && option && typeof newSummary !== undefined && newSummary && (
                    <Row className="mb-4" id={"#toggle-tab-" + currentView}>
                        <Col>
                            <SummaryTable dataOptions={option}
                                summary={newSummary}

                            />
                        </Col>
                    </Row>
                )}
                {currentView === "radar" && option && typeof newSummary !== undefined && newSummary && (
                    <Row className="mb-4" id={"#toggle-tab-" + currentView}>
                        <Col>
                            <Spider dataOptions={option}
                                summary={newSummary}
                            />
                        </Col>
                    </Row>
                )}
                {currentView === "scatter" && option && typeof newSummary !== undefined && newSummary && (
                    <Row className="mb-4" id={"#toggle-tab-" + currentView}>
                        <Col>
                            <Scatter dataOptions={option}
                                summary={newSummary}
                            />
                        </Col>
                    </Row>
                )}

                {currentView === "bar" && option && typeof raw !== undefined && raw && (

                    <Row className="mb-4" id={"#toggle-tab-" + currentView}>
                        <Col>
                            {/* <Line type="bar" stack dataOptions={option} /> */}
                            <BarGraph
                                dataOptions={option}
                                title={options}
                                payload={raw}
                                properties={properties}
                                sortFlag={true}
                            />

                        </Col>
                    </Row>
                )}
                {currentView === "leader" && option && (
                    <Row className="mb-4" id={"#toggle-tab-" + currentView}>
                        <Col>
                            <LeaderBoard dataOptions={option} />
                        </Col>
                    </Row>
                )}
            </div>
        </Fragment>
    );
};

export default NewHome;
