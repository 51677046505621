import {actionTypes} from '../constants/action-types'

export const setFloor = (payload) => {
    return {
        type: actionTypes.SET_FLOOR,
        payload: payload
    }
}

export const setFloors = (payload) => {
    return {
        type: actionTypes.SET_FLOORS,
        payload: payload
    }
}
export const setEmploye = (payload) => {
    return {
        type: actionTypes.SET_EMPLOYEE,
        payload: payload
    }
}


