import { actionTypes } from "../constants/action-types"


export const setSpaces = (payload) => {
    return {
        type : actionTypes.GET_SPACES,
        payload : payload
    }
}

export const setNewUser = (payload) => {
    return {
        type : actionTypes.GET_NEW_USER,
        payload : payload
    }
}

export const setSpace = (payload) => {
    console.log('setSpace is running',payload);
    return {
        type : actionTypes.SET_SPACE,
        payload : payload
        
    }
}