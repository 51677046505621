import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { numberFormater } from '../../helpers/common';
import { colors, graphColors } from '../../helpers/meta';
import PieCore from '../charts/PieCore';
import ColorDot from '../ColorDot';
import '../../App.css'
import { adlyticFormatter, convertMinutesIntoHour, numberFixed } from '../../helpers/utils';

const GraphCardTwo = ({ feature, value, index, showPercentage = false, percentage, active = false }) => {

    console.log('activeactive',active);


   


    return (
        <Fragment>
            <Card className={ `graphCardTwo ${index > 0 ? "graphcardTwoBorder" : "zeroIndexBorder"} ${active && "graphCardTwoActive"}`} style={{textAlign: "center", cursor : "pointer"}}>
                <CardBody style={{ textAlign: "center", borderRadius: 'none' }} className='px-3 py-1'>
                    <div className={'graphCardTwoCardBody'}>
                        {feature.label}
                        {showPercentage && <span className={percentage < 0 ? "m-0 p-0 danger" : percentage == 0 ? "m-0 p-0 secondary" : "m-0 p-0 primary"} style={{ fontSize: "8px", float: "right", marginLeft: "5px", position: "absolute" }}>
                            <span>{adlyticFormatter(Math.abs(percentage)) + "%"}</span>
                        </span>}
                    </div>
                    <div className='graphCardTwoNumber'>
                        {adlyticFormatter(value)}
                    </div>
                </CardBody>
            </Card>
        </Fragment >
    )
}

export default GraphCardTwo;