import { actionTypes } from "../constants/action-types";
import moment from "moment";
import { colors, graphColors } from "../../helpers/meta";
import { numberFormater, storage } from "../../helpers/common";
import { getLabelsBySid, getUserFeatureV_2, numberFixed, padTo2Digits } from "../../helpers/utils";


const tempSeries = (

  series = [],
  color = colors.primary,
  title = "",
  type = "line",
  smooth = false,
  predictedFlag = false,
  predictedIndex = [0],
  barMaxWidth = 20,

) => {
  return {

    name: title,
    type: type,
    color: color,
    data: series,
    predictedFlag: predictedFlag,
    predictedIndex: predictedIndex,
    barMaxWidth: 20,


    // lineStyle: { color: color, width: 4 },
    // symbol: "circle",
    // symbolSize: 5,
    // smooth: smooth,
    // hoverAnimation: true,
    // barMaxWidth: 20,
    // stack: 'Total',

  };
  // return {
  //   title: "Title_" + title,
  //   label: "Label_" + title,
  //   name: title,
  //   type: type,
  //   color: color,
  //   data: series,
  //   lineStyle: { color: color, width: 4 },
  //   symbol: "circle",
  //   symbolSize: 5,
  //   smooth: smooth,
  //   hoverAnimation: true,
  //   barMaxWidth: 20,
  //   stack
  // };
};

const getLabels = (startDate, endDate) => {
  var labels = [];
  var start = moment(startDate);
  var end = moment(endDate);
  if (end.diff(start, "day") <= 1) {
    while (end.diff(start, "hour") > 0) {
      labels.push(start.format("hh:mm a"));
      start = start.add(1, "hour");
    }
  } else {
    while (end.diff(start, "day") >= 0) {
      labels.push(start.format("MMM D"));
      start = start.add(1, "day");
    }
  }
  return labels;

};

const getGraphDataVersionTwo = (data, usersFeatures, requestData, type, imputeeKey, user, impute) => {





  let graphData = {};
  let _typee = type;
  usersFeatures.forEach((feature, index) => {
    let currentFeature = {};

    let _type = (Object.keys(type ? type : {}).length > 0 && typeof feature?.key !== undefined && feature?.key && feature?.key in type) ? type[feature.key] : "line";

    let _data = Object.keys(data ? data : {}).map((store, storeIndex) => {
      let currentStore = data[store];
      let isCloud = false;
      if (typeof requestData !== undefined && requestData && typeof requestData.stores && requestData.stores && requestData.stores.length > 0) {
        let currentStoreChunk = requestData.stores?.filter((item) => {
          return item._id === store

        })
        let isCloud = currentStoreChunk.length > 0 ? "isCloud" in currentStoreChunk[0] ? currentStoreChunk[0].isCloud : false : false;
      }



      return compileDataForMultiStores(
        requestData.start,
        requestData.end,
        currentStore,
        feature.key,
        getLabelsBySid(store),
        storeIndex,
        _type,
        imputeeKey,
        "line",
        isCloud,
        user,
        impute
      )

    })
    currentFeature[feature.key] = { data: _data }

    graphData[feature.key] = currentFeature;
  })

  return graphData


}


const getGraphData = (data, usersFeatures, requestData, type, imputeeKey) => {

  console.log('typetype', type);
  let graphData = {};
  let _typee = type;
  usersFeatures.forEach((feature, index) => {
    let currentFeature = {};
    feature.children.map((childFeature, ind) => {
      let _type = (Object.keys(type ? type : {}).length > 0 && typeof childFeature?.key !== undefined && childFeature?.key && childFeature?.key in type) ? type[childFeature.key] : "line";

      let _data = Object.keys(data ? data : {}).map((store, storeIndex) => {
        let currentStore = data[store];
        return compileDataForMultiStores(
          requestData.start,
          requestData.end,
          currentStore,
          childFeature.key,
          getLabelsBySid(store),
          storeIndex,
          _type,
          imputeeKey,
          "line",
          false
        )

      })
      currentFeature[childFeature.key] = { data: _data }
    })
    graphData[feature.key] = currentFeature;
  })

  return graphData


}

const getMarketAvgData = (data, usersFeatures, requestData, type, imputeeKey, user) => {

  let graphData = {};
  let _typee = type;
  usersFeatures.forEach((feature, index) => {
    let currentFeature = {};
    feature.children.map((childFeature, ind) => {

      let _data = compileDataForMultiStores(
        requestData.start,
        requestData.end,
        data,
        childFeature.key,
        "Industry average",
        0,
        _typee,
        imputeeKey,
        "industry",
        false,
        user,
        false

      )

      currentFeature[childFeature.key] = { data: _data }
    })
    graphData[feature.key] = currentFeature;
  })
  return graphData


}




const getItemStyle = ({ value = 0, color = graphColors[0], borderColor = "", imputed = 0, type = 'line', predictedFlag = 0, name = '' }) => {
  let obj = {
    value: value,
    itemStyle: {
      color: predictedFlag ? "silver" : name === 'industry' ? 'grey' : type === 'line' ? "red" : graphColors[1],
      borderColor: 'red',
      imputed: imputed
    }
  }
  return obj

}



const compileDataForMultiStores = (startDate, endDate, rawdata, entity, title, ind, type = "line", imputeeKey, name = "line", isCloud, user, impute) => {




  var data = [];
  var index = 0;
  let start = moment(startDate);
  let end = moment(endDate);
  let predictedFlag = false;
  let predictedIndex = [];




  if (end.diff(start, "day") < 1) {


    let isUserHaveTiming = false;


    let timing = { start: "00", end: "23" }

    if (typeof user !== undefined && user && "client_id" in user && user.client_id.length > 0 && user.client_id[0].timing.length > 0) {
      let dayOfweek = moment(start).weekday();
      isUserHaveTiming = true;
      let dayTiming = user.client_id[0].timing.filter((item) => {
        return item.key === dayOfweek
      })
      if (dayTiming.length > 0) {
        timing = { start: padTo2Digits(dayTiming[0].start), end: padTo2Digits(dayTiming[0].end) }
      }
    }



    rawdata = rawdata.sort((a, b) => {
      return parseInt(a.time) - parseInt(b.time)
    })

    // rawdata = rawdata.map((item) => {return {...item, impt : true}})




    if (timing.end > timing.start) {

      for (let i = timing.start; i <= timing.end; i++) {
        let currentTime = new Date().getTime();
        let objectTime = new Date(new Date(start).setHours(i)).getTime();
        let timeFlag = false;
        if (objectTime > currentTime) {
          timeFlag = true
          predictedFlag = true
        }

        let currentRawIndex = rawdata.filter((item) => {
          return +item.time === +i
        });


        if (currentRawIndex.length > 0) {



          if (entity in currentRawIndex[0]) {

            let entityImpute = entity + imputeeKey;
            let obj;

            if (entityImpute in currentRawIndex[0]) {
              let imputedData = currentRawIndex[0][entityImpute];
              let value = currentRawIndex[0][entity] + imputedData;
              if (imputedData > 0) {
                let imputedPer = numberFormater(numberFixed((currentRawIndex[0][entityImpute] / value) * 100)) + "%";
                let color = name === 'industry' ? 'grey' : type === 'line' ? graphColors[ind] : timeFlag ? "silver" : name === "industry" ? "grey" : graphColors[ind];
                let borderColor = timeFlag ? graphColors[ind] : type === 'bar' ? 'red' : graphColors[ind];
                obj = {
                  value: value,
                  itemStyle: {
                    color: color,
                    borderColor: borderColor,
                    imputed: imputedPer,
                  }
                }
                if (timeFlag) {
                  obj.itemStyle['predicted'] = true;
                  delete obj.itemStyle.imputed
                }

              }
              else {

                obj = {
                  value: value,
                  itemStyle: {
                    color: name === 'industry' ? 'grey' : timeFlag ? "silver" : graphColors[ind],
                    borderColor: 'none',
                    imputed: 0
                  }
                }

                if (timeFlag) {
                  obj.itemStyle['predicted'] = true;
                  delete obj.itemStyle.imputed
                }





              }
            }
            else if (impute && "impt" in currentRawIndex[0] && currentRawIndex[0].impt) {

              let color = type === 'line' ? 'red' : graphColors[ind];
              let borderColor = timeFlag ? graphColors[ind] : type === 'bar' ? 'red' : graphColors[ind];

              obj = {
                value: currentRawIndex[0][entity],
                itemStyle: {
                  color: color,
                  borderColor: borderColor,
                  imputed: true,
                }
              }

            }
            else {

              let value = currentRawIndex[0][entity]

              obj = {
                value: value,
                itemStyle: {
                  color: name === 'industry' ? 'grey' : timeFlag ? "silver" : graphColors[ind],
                  borderColor: 'none',
                }
              }



              if (timeFlag) {
                obj.itemStyle['predicted'] = true;

                delete obj.itemStyle.imputed
              }



            }

            data.push(obj);


          }
          else {

            let obj =
            {
              value: 0,
              itemStyle: {
                color: name === 'industry' ? 'grey' : timeFlag ? "silver" : graphColors[ind],
                borderColor: 'none',
              }
            }


            if (timeFlag) {
              obj.itemStyle['predicted'] = true;

              delete obj.itemStyle.imputed
            }

            data.push(obj)
          }
        }
        else {
          let obj = {
            value: 0,
            itemStyle: {
              color: name === 'industry' ? 'grey' : timeFlag ? "silver" : graphColors[ind],
              borderColor: 'none',
            }

          }
          if (timeFlag) {
            obj.itemStyle['predicted'] = true;
            delete obj.itemStyle.imputed
          }

          data.push(obj)
        }
      }
    }
    else if (timing.end < timing.start) {

      for (let i = timing.start; i <= 23; i++) {
        let currentTime = new Date().getTime();
        let objectTime = new Date(new Date(start).setHours(i)).getTime();
        let timeFlag = false;
        if (objectTime > currentTime) {
          timeFlag = true
          predictedFlag = true
        }

        let currentRawIndex = rawdata.filter((item) => {
          return +item.time === +i
        });


        if (currentRawIndex.length > 0) {



          if (entity in currentRawIndex[0]) {

            let entityImpute = entity + imputeeKey;
            let obj;

            if (entityImpute in currentRawIndex[0]) {
              let imputedData = currentRawIndex[0][entityImpute];
              let value = currentRawIndex[0][entity] + imputedData;
              if (imputedData > 0) {
                let imputedPer = numberFormater(numberFixed((currentRawIndex[0][entityImpute] / value) * 100)) + "%";
                let color = name === 'industry' ? 'grey' : type === 'line' ? graphColors[ind] : timeFlag ? "silver" : name === "industry" ? "grey" : graphColors[ind];
                let borderColor = timeFlag ? graphColors[ind] : type === 'bar' ? 'red' : graphColors[ind];
                obj = {
                  value: value,
                  itemStyle: {
                    color: color,
                    borderColor: borderColor,
                    imputed: imputedPer,
                  }
                }
                if (timeFlag) {
                  obj.itemStyle['predicted'] = true;
                  delete obj.itemStyle.imputed
                }

              }
              else {

                obj = {
                  value: value,
                  itemStyle: {
                    color: name === 'industry' ? 'grey' : timeFlag ? "silver" : graphColors[ind],
                    borderColor: 'none',
                    imputed: 0
                  }
                }

                if (timeFlag) {
                  obj.itemStyle['predicted'] = true;
                  delete obj.itemStyle.imputed
                }





              }
            }
            else {

              let value = currentRawIndex[0][entity]

              obj = {
                value: value,
                itemStyle: {
                  color: name === 'industry' ? 'grey' : timeFlag ? "silver" : graphColors[ind],
                  borderColor: 'none',
                }
              }



              if (timeFlag) {
                obj.itemStyle['predicted'] = true;

                delete obj.itemStyle.imputed
              }



            }

            data.push(obj);


          }
          else {

            let obj =
            {
              value: 0,
              itemStyle: {
                color: name === 'industry' ? 'grey' : timeFlag ? "silver" : graphColors[ind],
                borderColor: 'none',
              }
            }


            if (timeFlag) {
              obj.itemStyle['predicted'] = true;

              delete obj.itemStyle.imputed
            }

            data.push(obj)
          }
        }
        else {
          let obj = {
            value: 0,
            itemStyle: {
              color: name === 'industry' ? 'grey' : timeFlag ? "silver" : graphColors[ind],
              borderColor: 'none',
            }

          }
          if (timeFlag) {
            obj.itemStyle['predicted'] = true;
            delete obj.itemStyle.imputed
          }

          data.push(obj)
        }
      }

      for (let i = 0; i <= timing.end; i++) {
        i = padTo2Digits(i);
        let currentTime = new Date().getTime();
        let objectTime = new Date(new Date(start).setHours(i)).getTime();
        let timeFlag = false;
        if (objectTime > currentTime) {
          timeFlag = true
          predictedFlag = true
        }

        let currentRawIndex = rawdata.filter((item) => {
          return +item.time === +i
        });


        if (currentRawIndex.length > 0) {



          if (entity in currentRawIndex[0]) {

            let entityImpute = entity + imputeeKey;
            let obj;

            if (entityImpute in currentRawIndex[0]) {
              let imputedData = currentRawIndex[0][entityImpute];
              let value = currentRawIndex[0][entity] + imputedData;
              if (imputedData > 0) {
                let imputedPer = numberFormater(numberFixed((currentRawIndex[0][entityImpute] / value) * 100)) + "%";
                let color = name === 'industry' ? 'grey' : type === 'line' ? graphColors[ind] : timeFlag ? "silver" : name === "industry" ? "grey" : graphColors[ind];
                let borderColor = timeFlag ? graphColors[ind] : type === 'bar' ? 'red' : graphColors[ind];
                obj = {
                  value: value,
                  itemStyle: {
                    color: color,
                    borderColor: borderColor,
                    imputed: imputedPer,
                  }
                }
                if (timeFlag) {
                  obj.itemStyle['predicted'] = true;
                  delete obj.itemStyle.imputed
                }

              }
              else {

                obj = {
                  value: value,
                  itemStyle: {
                    color: name === 'industry' ? 'grey' : timeFlag ? "silver" : graphColors[ind],
                    borderColor: 'none',
                    imputed: 0
                  }
                }

                if (timeFlag) {
                  obj.itemStyle['predicted'] = true;
                  delete obj.itemStyle.imputed
                }





              }
            }
            else {

              let value = currentRawIndex[0][entity]

              obj = {
                value: value,
                itemStyle: {
                  color: name === 'industry' ? 'grey' : timeFlag ? "silver" : graphColors[ind],
                  borderColor: 'none',
                }
              }



              if (timeFlag) {
                obj.itemStyle['predicted'] = true;

                delete obj.itemStyle.imputed
              }



            }

            data.push(obj);


          }
          else {

            let obj =
            {
              value: 0,
              itemStyle: {
                color: name === 'industry' ? 'grey' : timeFlag ? "silver" : graphColors[ind],
                borderColor: 'none',
              }
            }


            if (timeFlag) {
              obj.itemStyle['predicted'] = true;

              delete obj.itemStyle.imputed
            }

            data.push(obj)
          }
        }
        else {
          let obj = {
            value: 0,
            itemStyle: {
              color: name === 'industry' ? 'grey' : timeFlag ? "silver" : graphColors[ind],
              borderColor: 'none',
            }

          }
          if (timeFlag) {
            obj.itemStyle['predicted'] = true;
            delete obj.itemStyle.imputed
          }

          data.push(obj)
        }
      }



    }


  }
  else {
    rawdata = rawdata.sort((a, b) => {
      return new Date(a.time) - new Date(b.time);
    });
    while (end.diff(start, "day") >= 0) {
      if (rawdata.length && index < rawdata.length) {
        if (start.format("YYYY-MM-DD") === rawdata[index].time) {
          if (entity in rawdata[index]) {
            let entityImpute = entity + imputeeKey;
            let featureData;
            let obj
            if (entityImpute in rawdata[index]) {
              let imputedData = rawdata[index][entityImpute];
              let value = rawdata[index][entity] + imputedData;
              if (imputedData > 0) {
                let imputedPer = numberFormater(numberFixed((rawdata[index][entityImpute] / value) * 100)) + "%";
                let color = name === "industry" ? "grey" : graphColors[ind];
                let borderColor = type === 'bar' ? 'red' : graphColors[ind];

                obj = {
                  value: value,
                  itemStyle: {
                    color: color,
                    borderColor: borderColor,
                    imputed: imputedPer
                  }
                }


              }
              else {
                obj = {
                  value: value,
                  itemStyle: {
                    color: name === 'industry' ? 'grey' : graphColors[ind],
                    borderColor: 'none',
                    imputed: 0
                  }
                }

              }
            }
            else {
              let value = rawdata[index][entity]
              obj = {
                value: value,
                itemStyle: {
                  color: name === 'industry' ? 'grey' : graphColors[ind],
                  borderColor: 'none',
                }
              }
            }

            data.push(obj);
          }
          else {
            data.push(
              {
                value: 0,
                itemStyle: {
                  color: name === 'industry' ? 'grey' : graphColors[ind],
                  borderColor: 'none',
                }
              }
            )
          }
          index++;
        } else {
          data.push({
            value: 0,
            itemStyle: {
              color: name === 'industry' ? 'grey' : graphColors[ind],
              borderColor: 'none',
            }
          }
          );
        }
      } else {
        data.push({
          value: 0,
          itemStyle: {
            color: name === 'industry' ? 'grey' : graphColors[ind],
            borderColor: 'none',
          }
        }
        );
      }
      start = start.add(1, "day");
    }
  }

  // data.pop();
  return tempSeries(
    data, name === "industry" ? "grey" : graphColors[ind], title, type, false, predictedFlag, predictedIndex
  );
};


const compileData = (startDate, endDate, rawdata, entity, title, ind, type = "line") => {
  var data = [];
  var index = 0;
  let start = moment(startDate);
  let end = moment(endDate);
  if (end.diff(start, "day") < 1) {
    let stores = {};




    rawdata = rawdata.sort((a, b) => {
      return a._id - b._id;
    });
    while (end.diff(start, "hour") >= 0) {
      if (rawdata.length && index < rawdata.length) {
        if (start.format("HH") === rawdata[index]._id) {
          data.push(rawdata[index][entity]);
          index++;
        } else {
          data.push(0);
        }
      } else {
        data.push(0);
      }
      start = start.add(1, "hour");
    }
  }
  else {
    rawdata = rawdata.sort((a, b) => {
      return new Date(a._id) - new Date(b._id);
    });
    while (end.diff(start, "day") >= 0) {
      if (rawdata.length && index < rawdata.length) {
        if (start.format("YYYY-MM-DD") === rawdata[index]._id) {
          data.push(rawdata[index][entity]);
          index++;
        } else {
          data.push(0);
        }
      } else {
        data.push(0);
      }
      start = start.add(1, "day");
    }
  }
  data.pop();
  return tempSeries(
    data, graphColors[ind], title, type
  );
};



const getUserFeature = (user) => {

  if (user !== undefined && user) {



    let _footfall = user.features.footfall;
    let _sales = user.features.sales;

    let sales = [];
    Object.keys(_sales ? _sales : {}).forEach((item) => {
      let obj = {
        key: item,
        label: _sales[item],
        children: [{
          key: item,
          label: _sales[item],
        }]
      }
      sales.push(obj);
    })

    let totalFeature = [..._footfall, ...sales]
    return totalFeature
  }
}

const getFeaturesWithImpute = (features) => {
  let obj = features;
  return obj

}



const initialState = {
  all_data: null,
  all_data_impute: null,
  imputeKey: 'impt',
  startDate: moment(new Date()).startOf("day"),
  endDate: moment(new Date()).endOf("day"),
  labels: [],
  marketAvg: [],
  grapGhRawData: [],
  grapGhRawData2: [],
  graphRawDataImpute: [],
  graphRequestDataImpute: [],
  data: {
    total: 0,
    footfall: [
      {},
    ],
  },
  graphs: [],
  type: "line",
  filter: {
    type: "today",
    store: null,
    stores: [],
    floor: null,
    floors: [],
    start: moment(new Date()).startOf("day"),
    end: moment(new Date()).endOf("day"),
  },
  loader: false,
};

export const footfallReducer = (
  state = initialState,
  { type, payload = {} }
) => {
  switch (type) {
    case actionTypes.SET_FOOTFALL:
      var data = JSON.parse(JSON.stringify(initialState.data));
      var obj = payload[0];
      return { ...state, data: data, all_data: obj };


    case actionTypes.SET_FOOTFALL_IMPUTE:
      var data = JSON.parse(JSON.stringify(initialState.data));
      var obj = getFeaturesWithImpute(payload[0]);
      return { ...state, data: data, all_data_impute: obj };


    case actionTypes.SET_MARKET_AVG_VALUE:
      let features = getUserFeature(payload.user);
      return { ...state, marketAvg: getMarketAvgData(payload.data, features, payload.requestData, "line", state.imputeKey, payload.user) }
    case actionTypes.SET_FOOTFALL_GRAPH2:
      let usersFeatures1 = getUserFeatureV_2(payload.user.features);
      return { ...state, grapGhRawData: getGraphDataVersionTwo(payload.data, usersFeatures1, payload.requestData, payload.requestData.graphType, state.imputeKey, payload.user, true), graphRequestData: payload.data, }


    case actionTypes.SET_FOOTFALL_GRAPH:
      let usersFeatures = getUserFeatureV_2(payload.user.features);
      return { ...state, grapGhRawData: getGraphDataVersionTwo(payload.data, usersFeatures, payload.requestData, payload.requestData.graphType, state.imputeKey, payload.user, false), graphRequestData: payload.data, }
    case actionTypes.SET_FOOTFALL_GRAPH_IMPUTE:
      let usersFeature = getUserFeatureV_2(payload.user.features);
      return { ...state, graphRawDataImpute: getGraphDataVersionTwo(payload.data, usersFeature, payload.requestData, payload.requestData.graphType, state.imputeKey, payload.user, false), graphRequestDataImpute: payload.data, }

    case actionTypes.SET_FOOTFALL_GRAPH_TYPE:
      var graphs = state.graphs;
      Object.keys(graphs).forEach(key => {
        graphs[key].data.forEach((row) => {
          row.type = payload;
        })
      });
      return { ...state, type: payload, graphs: graphs }

    case actionTypes.SET_FOOTFALL_FILTER:
      storage.set("filter", payload);
      var labels = getLabels({ startDate: payload.start, endDate: payload.end });
      return { ...state, startDate: payload._startDate, endDate: payload._endDate, labels: labels };

    case actionTypes.SET_FOOTFALL_LOADER:
      return { ...state, loader: payload };

    case actionTypes.SET_GRAPGH_DATA:
      let _type = state.type;
      let grapghPayload = payload.grapghRawData.map((val) => {
        return {
          ...val,
        };
      });


      let graphDataa = {};
      payload.footfall.forEach((item, index) => {
        graphDataa[item.key] = {
          data: item.children.map((val, ind) => {
            return compileData(
              payload?.startDate,
              payload?.endDate,
              grapghPayload,
              val.key,
              val.label,
              ind,
              _type
            );
          }),
        };
      });
      return { ...state, graphs: graphDataa };

    default:
      return state;
  }
};
