import React from 'react'
import { Calendar } from "react-multi-date-picker"
import arabic from "react-date-object/calendars/arabic"
import arabic_ar from "react-date-object/locales/arabic_ar"
import "react-multi-date-picker/styles/colors/red.css";
import "react-multi-date-picker/styles/colors/teal.css";
import './Hijricalendar.css'
import { useState } from 'react'
import moment from 'moment'
import hijiriMoment from 'moment-hijri'
import { useEffect } from 'react';


export default function HijriCalendar(props) {

    const [value, setValue] = useState(() => {

    })

    useEffect(() => {
        if (props.selectedDayRange) {
            if (props.selectedDayRange) {
                let _from = props.selectedDayRange.from;
                let _to = props.selectedDayRange.to;
                let from = (new Date(_from.year, _from.month - 1, _from.day));
                let fromoption = new Date(from);
                let to = new Date(_to.year, _to.month - 1, _to.day)
                let _date = [from, to];
                setValue(_date)
            }

        }

    }, [props.selectedDayRange])



    const arabicToEngDate = (date) => {
        if (typeof date !== undefined && date) {


            let _date = moment(new Date(date.year, date.month.number - 1, date.day + 1)).format("YYYY-MM-DD");
            let momentt = hijiriMoment(_date, 'iYYYY/iM/iD');
            let englishdate = new Date(momentt.format("YYYY-MM-DD"));
            let obj = { year: englishdate.getFullYear(), month: englishdate.getMonth() + 1, day: englishdate.getDate() }
            return obj
        }

    }

    const handle = (event) => {

        if (event !== undefined && event && event.length > 1) {

            let _from = arabicToEngDate(event[0]);
            let _to = arabicToEngDate(event[1]);
            let obj = {
                from: _from,
                to: _to
            }
            props.setSelectedDayRange(obj);
        }


    }

    return (
        <div >
            <Calendar
                className='hijiri teal'
                calendar={arabic}
                locale={arabic_ar}
                range={true}
                value={value}
                onChange={(event) => handle(event)}
                maxDate={(new Date())}
            />
        </div>
    )
}
