import { enums, storage } from '../../helpers/common';
import { dataPoints } from '../../helpers/meta';
import { actionTypes } from '../constants/action-types';
const startingData = [{}];
Object.keys(dataPoints).forEach((key) => {
    startingData[0][key] = 0;
})
var defaultUser = { login: false, token: "", access: [] };
var user = storage.getParsed(enums.USER, defaultUser);

const startingTable = (user, store) => {
    if (store !== undefined && store && store.length > 0) {
        let stores = [];
        let currentStores = user.access.filter((item) => store.includes(item._id));
        currentStores.forEach((branch) => {
            var temp = {
                label: branch.label,
                _id: branch._id
            }
            Object.keys(dataPoints).forEach((key) => {
                temp[key] = 0;
            });
            stores.push(temp)

        })
        return stores

    }
    else {


        let stores = [];
        user.access.forEach((branch) => {
            var temp = {
                label: branch.label,
                _id: branch._id
            }
            Object.keys(dataPoints).forEach((key) => {
                temp[key] = 0;
            });
            stores.push(temp)

        })
        return stores
    }
}

const getNotIncludedStores = ({ payload, key }) => {
    let currentSids = payload.current.map((item) => { return item._id });
    let sidNotIncludedInPayload = payload.stores.filter((item) => !currentSids.includes(item));
    let stores = payload.user.access.filter((item) => sidNotIncludedInPayload.includes(item._id));


    if (stores.length > 0) {
        if (payload.current.length > 0) {
            let objectKeys = Object.keys(payload.current[0]).map((object) => {
                return object
            })
            let floorObjectKeys = Object.keys(payload.current[0].floors[0]).map(key => { return key })
            let storesWithObject = [];
            for (let i = 0; i < stores.length; i++) {
                let obj = {};
                for (let j = 0; j < objectKeys.length; j++) {
                    if (objectKeys[j] === 'sid') {
                        obj[objectKeys[j]] = stores[i]._id

                    }
                    else if (objectKeys[j] === 'floors') {
                        let floors = [];
                        for (let k = 0; k < stores[i].floors.length; k++) {
                            let floorObject = {};
                            for (let y = 0; y < floorObjectKeys.length; y++) {
                                if (floorObjectKeys[y] === "floorLabel") {
                                    floorObject[floorObjectKeys[y]] = stores[i].floors[k].floor_label
                                }
                                else {

                                    if (floorObjectKeys[y] in stores[i].floors[k]) {
                                        floorObject[floorObjectKeys[y]] = stores[i].floors[k][floorObjectKeys[y]];
                                    }

                                    else {
                                        floorObject[floorObjectKeys[y]] = 0
                                    }
                                }
                            }
                            floors.push(floorObject);
                        }
                        obj[objectKeys[j]] = floors;

                    }
                    else {
                        if (objectKeys[j] in stores[i]) {
                            obj[objectKeys[j]] = stores[i][objectKeys[j]]
                        }
                        else {
                            obj[objectKeys[j]] = 0
                        }
                    }
                }
                storesWithObject.push(obj);
            }
            return storesWithObject

        }
        else {
            let storesWithObject = startingTable(payload.user, payload.stores);

            return storesWithObject
        }
    }
    else {
        return []
    }

}

const getStoresActivity = (payload) => {
    let activity = payload[0].stores;

    let stores = {};
    if (payload.length > 0) {


        // if(stores in payload[0]) 
        {

            let activity = payload[0].stores;
            activity.forEach((item) => {
                stores[item._id] = item
            })
        }
    }
    return stores
}

const initialState = {
    totalCurrent: startingData,
    totalPrevious: startingData,
    empTotalCurrent: startingData,
    empTotalPrevious: startingData,
    current: startingTable(user),
    previous: startingTable(user),
    storesActivity: [],
    chatgptResponse: { data: "" },
    chatGptQuery: ""

};

export const summary = (state = initialState, { type, payload = {} }) => {
    switch (type) {
        case actionTypes.SUMMARY:

            const storesNotIncludedInCurrent = getNotIncludedStores({ payload, key: 'current' });
            const storesNotIncludedInPrevious = getNotIncludedStores({ payload, key: 'prev' })
            console.log('payloadInHome', payload);
            console.log('storesNotIncludedInCurrent', storesNotIncludedInCurrent);
            console.log('storesNotIncludedInPrevious', storesNotIncludedInPrevious);


            let allCurrent = [...payload.current, ...storesNotIncludedInCurrent];
            let allPrev = 'prev' in payload ? [...payload.prev, ...storesNotIncludedInPrevious] : [...storesNotIncludedInPrevious];


            payload.current = allCurrent.map(val => {
                return { ...val }
            });
            payload.prev = allPrev.map(val => {
                return { ...val }
            });
            payload.total_current = payload.total_current.map(val => {
                return { ...val }
            });
            payload.total_prev = payload.total_prev.map(val => {
                return { ...val }
            });
            var _current = payload.current.length ? payload.current : startingTable(payload.user);
            var _previous = "prev" in payload && (payload.prev.length ? payload.prev : startingTable(payload.user));
            var totalCurrent = payload.total_current.length ? payload.total_current : startingData;
            var totalPrevious = payload.total_prev.length ? payload.total_prev : startingData;
            var current = _current.map((row) => {
                return { ...row, label: row.label }
            })

            var previous = _previous.map((row) => {
                return { ...row, label: row.label }
            });
            return { ...state, current: current, previous: previous, totalCurrent: totalCurrent, totalPrevious: totalPrevious };
        case actionTypes.EMPLOYEE_SUMMARY:





            payload.total_current = payload.total_current.map(val => {
                return { ...val }
            });
            payload.total_prev = payload.total_prev.map(val => {
                return { ...val }
            });

            var _totalCurrent = payload.total_current.length ? payload.total_current : startingData;
            var _totalPrevious = payload.total_prev.length ? payload.total_prev : startingData;

            return { ...state, empTotalCurrent: _totalCurrent, empTotalPrevious: _totalPrevious };

        case actionTypes.SET_CHATGPT_QUESTION:
            console.log('chatGptQueryReucer', payload);
            return { ...state, chatGptQuery: payload }
        case actionTypes.SET_CHATGPT_TABLE_QUERIES:
            return { ...state, chatgptResponse: { data: payload } }
        case actionTypes.SET_STORES_ACTIVITY:
            return { ...state, storesActivity: getStoresActivity(payload) }
        default:
            return state;
    }
}



