import React, { Fragment, useState, useEffect } from "react";
import MainFilter from "../components/filters/MainFilter";
import { Card, CardBody, Row, Col, Progress, Table, CardHeader } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { categoryRequest, categoryGraphRequest, singleFloorFetch, singleSpaceFetch, employeSalesUnitRequest, getSaleItemRequest, getZoneSalesRequest } from "../helpers/requests";
import Sections from "./Templates/Sections";
import { setCategoryLoader, setCategoryFilter } from "../redux/actions/categoryActions";
import LineGraph from "../components/charts/LineGraph";
import SectionWithScale from "./Templates/SectionWithScale";
import '../App.css'
import '../pages/css/categories.css'
import NewMainFiltrer from "../components/filters/newMainFilter";
import { adlyticFormatter } from "../helpers/utils";


const InStoreTraffic = () => {
    const [filters, setFilters] = useState();
    const [products, setProducts] = useState([]);
    const dispatch = useDispatch();
    const labels = useSelector((state) => state.categoryReducer.labels);
    const graphLabels = useSelector((state) => state.categoryReducer.graphLabelsEntry);
    const space = useSelector((state) => state.spaces.space);
    console.log('current space', space);
    const floors = useSelector((state) => state.floorReducer.floors);
    const data = useSelector((state) => state.categoryReducer.dataEntry);
    const graph = useSelector((state) => state.categoryReducer.graph);
    console.log('datadata', data);
    const currentFloor = useSelector((state) => state.floorReducer.floor);
    console.log('currentFloor', currentFloor);
    const [sections, setSections] = useState([]);
    const [sectionSaleData, setSectionSaleData] = useState([]);
    const employeSalesUnits = useSelector((state) => state.categoryReducer.employeSalesUnits);
    const salesItem = useSelector((state) => state.categoryReducer.salesItem)
    const totalTraffic = useSelector((state) => state.categoryReducer.totalTrafficEntry);
    const [saleItem, setSalesItem] = useState([])
    const [section, setSection] = useState();
    const [productModalFlag, setProductModalFlag] = useState(false);
    const [zoneSales, setZoneSales] = useState([])
    console.log('productModal==', productModalFlag);
    const [selectedSection, setSelectedSection] = useState();
    var filter = useSelector((state) => state.categoryReducer.filter);
    var loader = useSelector((state) => state.categoryReducer.loader);
    const updateFilter = (e) => {
        console.log('eeeeeeeeeeee', e);
        setFilters(e);
        e["dispatch"] = dispatch;

        let requestdata = e;
        let floorRequestdata = {
            _id: e.fid,
            dispatch,
        };
        let _filter = {
            _startDate: e.start,
            _endDate: e.end,
            _fid: e.fid,
            _sid: e.sid,
            EMP: e.EMP
        };
        let employeSaleObject = {
            sid: requestdata.sid,
            start: requestdata.start,
            end: requestdata.end,
            dispatch,
        };
        let saleItemObject = {
            sid: [requestdata.sid],
            start: requestdata.start,
            end: requestdata.end,
            dispatch,
        }
        singleSpaceFetch({ _id: e.sid, dispatch })
        getSaleItemRequest(saleItemObject);
        employeSalesUnitRequest(employeSaleObject);
        dispatch(setCategoryFilter(_filter));

        singleFloorFetch(floorRequestdata);
        setTimeout(() => {
            let obj = {
                end: e.end,
                start: e.start,
                fid: e.fid,
                sid: e.sid,
                dispatch,
                EMP: e.EMP,
                section : 'entry'

            }
            categoryRequest(obj);
            // categoryGraphRequest(requestdata);
        }, 200);
    };

    const getBarLabels = () => {

        if (graphLabels.length > 0) {
            return graphLabels;
        }
        else {
            return currentFloor?.sections.map((val, ind) => {
                return val.label;
            });
        }
    };


    const getZoneSales = async (floor) => {

        let payload = {
            data: {
                start: filters.start,
                end: filters.end,
                sid: filters.sid,
                fid: filters.fid,
                section: floor.sections.map((item) => { return { label: item.label, productCategories: item.productCategories, _id: item._id } })
            },
            dispatch
        }

        let _zoneSales = await getZoneSalesRequest(payload);
        if (_zoneSales.status === 200 && Array.isArray(_zoneSales.data)) {
            setZoneSales(_zoneSales.data)

        }
        else {
            setZoneSales([])

        }
        console.log('_zoneSales', _zoneSales);
    }

    console.log('currentFloor', currentFloor);
    useEffect(() => {
        if (currentFloor) {
            setSection(currentFloor?.entrySections[0]);
            setSelectedSection(currentFloor?.entrySections[0]?.label);
            // getZoneSales(currentFloor)
        }
    }, [currentFloor]);

    useEffect(() => {

        if (section && typeof filters !== undefined && filters) {
            let obj = filters;
            if (typeof obj === "object") {
                obj["sec_id"] = section?._id;
                obj['dispatch'] = dispatch;
                obj['EMP'] = filters.EMP
                categoryGraphRequest(obj);
            }
        }
    }, [section, filters]);

    useEffect(() => {
        if (floors) {
            let sections = [];
            floors.map((item) => {
                item.sections.map((section) => {
                    sections.push({
                        value: section._id,
                        label: section.label,
                    });
                });
            });
            setSections(sections);
        }
    }, [floors]);

    useEffect(() => {
        if (employeSalesUnits.length && totalTraffic.length) {
            let salesTrafficData = [];
            employeSalesUnits.filter((sales) => {
                return totalTraffic.find((traffic) => {
                    if (traffic._id === sales.sec_id) {
                        salesTrafficData.push({
                            ...sales,
                            traffic: traffic.footfall,
                            label: traffic.label,
                        });
                    }
                });
            });
            setSectionSaleData(salesTrafficData);
        }
    }, [totalTraffic, employeSalesUnits]);

    function getSelectedValueBack(data) {
        setSelectedSection(data.label);
    }

    useEffect(() => {
        if (salesItem) {
            let size = 6;
            let items = salesItem
            items = items.sort((a, b) => {
                return b.sale - a.sale;
            })
            setSalesItem(items);
        }

    }, [salesItem])

    return (
        <Fragment>
            <MainFilter
                pageTitle="Categories"
                updateFilter={(e) => updateFilter(e)}
                type="gold"
                floorToShow={true}

            ></MainFilter>

            <Row>
                <Col>
                    <h4 className="my-3">Sectionwise Traffic & Dwelltime</h4>
                </Col>
            </Row>

            <Row>
                <Col style={{ height: "20px" }}>
                    {loader && (
                        <Progress
                            animated
                            className="mb-2"
                            color="warning"
                            value="100"
                            style={{ height: "5px" }}
                        />
                    )}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md={6}>
                    <Card>
                        <CardBody>
                            { typeof currentFloor !== undefined && currentFloor && currentFloor && filters && (
                                <SectionWithScale
                                    setSection={(section) => setSection(section)}
                                    dye={currentFloor ? currentFloor.image : ""}
                                    map={currentFloor ? currentFloor.image : ""}
                                    sections={currentFloor.entrySections ? currentFloor.entrySections : []}
                                    section={section ? section : ""}
                                    selectedSection={getSelectedValueBack}
                                    heatmap={false}
                                    heatmapData={[]}
                                    sectionEditFlag={true}
                                    // setProductModal= {(e) => setProductModalFlag(e)}
                                    filters={filters}
                                    setProducts={(e) => setProducts(e)}

                                />

                            )}


                        </CardBody>
                    </Card>

                    {
                        products.length > 0 &&
                        <Card className="mt-3">
                            <CardHeader>
                                <h4 className="m-0" style={{ fontSize: '14px' }}>Zone sales</h4>


                            </CardHeader>
                            <CardBody className="m-0 p-0">
                                <Table className="m-0 p-0" responsive bordered style={{ fontSize: "", textAlign: "center", overflowX: 'scroll', width: '', borderRadius: "10px" }}>
                                    <thead>
                                        <tr>
                                            <th scope="row" className='pointer '>Label</th>
                                            <th scope="row" className='pointer ' >Traffic</th>
                                            <th scope="row" className='pointer ' >Sale</th>
                                            <th scope="row" className='pointer '>Transaction</th>
                                            <th scope="row" className='pointer '>Unit</th>
                                            <th scope="row" className='pointer '>Conversion</th>
                                        </tr>

                                    </thead>
                                    <tbody style={{ borderTop: "none", height: "100%" }}>
                                        {zoneSales?.map((item, index) => {
                                            return <tr key={index}>
                                                <td>{item.label}</td>
                                                <td>{adlyticFormatter(item.footfall)}</td>
                                                <td>{adlyticFormatter(item.sale)}</td>
                                                <td>{adlyticFormatter(item.transactions)}</td>
                                                <td>{adlyticFormatter(item.units)}</td>
                                                <td>{adlyticFormatter(item.conversion)}</td>
                                            </tr>
                                        })}
                                    </tbody>


                                </Table>
                            </CardBody>
                        </Card>
                    }







                </Col>
                <Col md={6}>
                    
                    {filters && filters.sid != "6234c4d7115aa9163039ad5a" && (

                        <>
                            <Row className="mb-3">
                                <Col>
                                    {data && space && ("dwellVisitors" in space ? space.dwellVisitors : true) && (
                                        <LineGraph
                                            seriesData={{
                                                data: data
                                                    ? data[0]
                                                    : new Array(getBarLabels().length).fill(0),
                                                labels: getBarLabels(),
                                            }}
                                            hideControls={true}
                                            title={"Visitors For All Sections"}
                                            space={40}
                                            rotation={45}
                                            height={"19rem"}
                                        />
                                    )}
                                </Col>
                            </Row>
                          
                        </>
                    )}

                    {filters && filters.sid === "6234c4d7115aa9163039ad5a" && (
                        <Row className="mb-3">
                            <Col>
                                <Table
                                    bordered
                                    style={{ fontSize: "40px", textAlign: "center" }}
                                >
                                    <thead>
                                        <tr>
                                            <th>Sections</th>
                                            <th>Sale (in PKR)</th>
                                            <th>Units</th>
                                            <th>Transaction</th>
                                            <th>Traffic</th>
                                            <th>Conversion per Units% </th>
                                            <th>Conversion Per Transaction</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sectionSaleData?.length > 0 &&
                                            sectionSaleData.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.label}</td>
                                                    <td>{item.sale?.toFixed()}</td>
                                                    <td>{item.units}</td>
                                                    <td>{item.transaction}</td>
                                                    <td>{item.traffic}</td>
                                                    <td>
                                                        {((item.units / item.traffic) * 100)?.toFixed(2)}
                                                    </td>
                                                    <td>
                                                        {((item.transaction / item.traffic) * 100)?.toFixed(
                                                            2
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>



        </Fragment>
    );
};
export default InStoreTraffic;
