import React, { Fragment, useState } from 'react';
import { Tool } from 'react-feather';
import Select from 'react-select';
import { Button, Modal, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { colourStyles } from '../../helpers/common';

const Properties = ({option, options, setOption,flag}) => {
    const [showModal, setShowModal] = useState(false);
    const toggleModal = () => {
        setShowModal(!showModal);
    }
    return (
        <Fragment>
            { flag === "category" && <Button id="category-modal" className='section-tab' color="outline-light" size='sm' onClick={()=>toggleModal()}>Exclude</Button>}
            { flag === "feature"&&<Button id="settings-modal" className='section-tab' color="outline-light" size='sm' onClick={()=>toggleModal()}><Tool size={16}/></Button>}

            <UncontrolledTooltip
                autohide={true}
                placement='top'
                target={ flag === "feature" ? "settings-modal" : "category-modal"}
                trigger="hover"
            >
                {flag === "feature" && "Toggle Data Properties" }
                {flag === "category" && "Exclude categories" }

            </UncontrolledTooltip>
            <Modal
                size='lg'
                isOpen={showModal}
                toggle={toggleModal}
            >
                <ModalBody>
                    <h4 className='filter-heading p-0 m-0 mb-3'>{ flag === "feature" ?  "Arrange Properties" : "Exclude categories"}</h4>
                    <Select
                        options={options} 
                        value={option} 
                        onChange={setOption} 
                        isMulti 
                        styles={colourStyles}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="outline-primary" onClick={()=> toggleModal()}>Close</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}

export default Properties;