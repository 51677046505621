import React, { Fragment, useState, useEffect, useRef } from "react";
import MainFilter from "../components/filters/MainFilter";
import { setComparisonFilter } from "../redux/actions/comparisonActions";
import { useSelector, useDispatch } from "react-redux";
import { comparisonRequest, conversionDailyRequest, dailySales } from "../helpers/requests";
import moment from "moment";
import html2canvas from 'html2canvas';


// import { Page, Text, View, Document, StyleSheet, Image, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { getWeeks } from "../redux/reducers/comparisonReducer";
import CompareHeader from './Templates/CompareHeader';
import CompareSingle from "./Templates/CompareSingle";
import { Card, CardBody, Row, Col, Progress, Table, ButtonGroup, Button, UncontrolledTooltip } from "reactstrap";
import Select from 'react-select';
import { adlyticFormatter, capitalize, capitalizeFirstLetter, captialLetterOfEachWord, getDifference, numberFixed,
  //  styles 
  } from "../helpers/utils";
import { BarChart, Download, Layout, Star } from "react-feather";
import CalendarHeatmap from "./Templates/CalendarHeatmap";
import DynamicSpider from "../components/charts/dynamicSpiderChart";
import { logo } from "../helpers/meta";



const Comparison = () => {
  const chartRef = useRef(null);

  const [filters, setFilters] = useState();
  const [compareTypes, setCompareTypes] = useState("single");
  var comparison = useSelector(state => state.compare.groupComparison);
  const [currentView, setCurrentView] = useState('table');
  const [storeToBeCompare1, setStoreToBeCompare1] = useState()
  const [storeToBeCompare2, setStoreToBeCompare2] = useState()
  const features = useSelector((state) => state.auth.features);
  const [spiderData, setSpiderData] = useState([])
  const [hmFlag, setHMFlag] = useState(false);
  const mainLoader = useSelector((state) => state.highlights.loading)
  const [imageData, setImageData] = useState();
  console.log('imageData', imageData);
  const [spiderImageData, setSpiderImageData] = useState();
  const [options, setOptions] = useState();
  const [option, setOption] = useState([
    { value: "sale", label: "Sale (PKR)" },
  ]);

  const [data1, setData1] = useState([])
  const [data2, setData2] = useState([])



  let tooltip;


  const dispatch = useDispatch();

  const updateFilter = (e) => {

    console.log('updateFilterconpa', e);
    e.EMP = ['0', '-1']


    if (e.type === "single") {
      let firstDate;
      let secondDate;
      if (getDifference(e.selected.from1, e.selected.to1) == 0) {
        firstDate = e.selected.from1;
      }
      else {
        firstDate = e.selected.from1 + "-" + e.selected.to1;
      }
      if (getDifference(e.selected.from2, e.selected.to2) == 0) {
        secondDate = e.selected.from2;
      }
      else {
        secondDate = e.selected.from2 + "-" + e.selected.to2;
      }
      setStoreToBeCompare1(firstDate);
      setStoreToBeCompare2(secondDate);
    }
    else {


      setStoreToBeCompare1(e.selected.store1);
      setStoreToBeCompare2(e.selected.store2);
    }


    var requestData = {
      params: e,
      dispatch,
    };

    if (e !== undefined && e && e.sd.length > 1 && e.ed.length > 1 && (getDifference(e.sd[0], e.ed[0]) < 6 || getDifference(e.sd[1], e.ed[1]) < 6)) {
      setHMFlag(false)
    }
    else {
      setHMFlag(true)
    }


    comparisonRequest(requestData);
    setFilters(e);
  };

  const language = "english";
  const user = useSelector((state) => state.auth.user);
  console.log('user', user);
  const stores = user.access.map((val, index) => {
    return { ...val, value: val._id, label: val.label };
  });

  const [comparisonFormatData, setComparisonFormatData] = useState([]);


  const [index, setIndex] = useState(-1)

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? "#fd7e14" : '',
      boxShadow: "0px 4px 37px 9px rgb(0 0 0 / 3%)",
      borderRadius: "0px",
    }),
    control: (provided) => ({
      ...provided,
      height: "40px",
      background: "#FFFFFF",
      boxShadow: "0px 4px 37px 9px rgb(0 0 0 / 3%)",
      borderRadius: "4px",
      border: "1px solid  #ced4da",
      width: 200,

    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 10
    })
  }



  useEffect(() => {
    let allOptions = [...features.footfall, ...features.sales];
    setOption(allOptions);
    setOptions(allOptions);
  }, [features]);

  useEffect(() => {
    if (comparison) {
      let data = { 0: [], 1: [] };

      if (filters && filters.sid1 && filters.sid2.length) {
        data[0] = comparison[0]?.length ? comparison[0][0] : [];
        data[1] = comparison[1]?.length ? comparison[1][0] : [];



        if (data[0]?.length === 0) {
          setIndex(1)
        }
        else { setIndex(0) }
      }

      setComparisonFormatData(data)

    }

  }, [comparison])

  useEffect(() => {
    if (comparisonFormatData !== undefined && comparisonFormatData) {
      let spiderData = [];

      if (comparisonFormatData[0] !== undefined && comparisonFormatData[0]) {
        let one = comparisonFormatData[0];
        one['label'] = storeToBeCompare1;
        one['_id'] = storeToBeCompare1
        spiderData.push(one)
      }

      if (comparisonFormatData[1] !== undefined && comparisonFormatData[1]) {
        let two = comparisonFormatData[1];
        two['label'] = storeToBeCompare2;
        two['_id'] = storeToBeCompare2
        spiderData.push(two)
      }
      setSpiderData(spiderData)
    }

  }, [comparisonFormatData])

  const summaryMenu = [
    ["table", <Layout size={16} />, "Tabular View"],
    // ["calendarHeatmap", <BarChart size={16} />, "Bar Board"],
    ["spider", <Star size={16} />, "Leader Board"],

  ];

  const [properties, setProperties] = useState([]);

  useEffect(() => {
    setProperties(option == null ? [] : option.map((opt) => opt.value));
  }, [option]);



  useEffect(() => {
    setTimeout(() => {
      // getComparison();
    }, 200);
  }, []);

  const dailySale = async (obj) => {
    let res = await dailySales(obj);
    return res
  }


  useEffect(() => {
    if (filters !== undefined && filters) {
      if (filters.fid1 !== undefined && filters.fid1 && filters.fid2 !== undefined && filters.fid2) {

        let obj1 = {
          end1: 22,
          start1: 10,
          sid: filters.sid1,
          fid: filters.fid1,
          type: 'daily',
          end: filters.ed[0],
          start: filters.sd[0],
          dispatch
        }

        let obj2 = {
          end1: 22,
          start1: 10,
          sid: filters.sid2,
          fid: filters.fid2,
          type: 'daily',
          end: filters.ed[1],
          start: filters.sd[1],
          dispatch

        }

        dailySale(obj1).then(data => {
          let totalData1 = getTotaldata({ raw: data.data.data, start: filters.sd[0], end: filters.ed[0] })
          setData1(totalData1)
        });

        dailySale(obj2).then(data => {
          let totalData2 = getTotaldata({ raw: data.data.data, start: filters.sd[1], end: filters.ed[1] })

          setData2(totalData2)
        })
      }
    }

  }, [filters])

  const getTotaldata = ({ raw, start, end }) => {
    if (raw) {
      let object = {};
      let ids = Object.keys(raw).map((key) => { return key });
      let tableByTotal = [];


      // if (filters !== undefined && filters && "ed" in filters)
      {
        var endDate = moment(end).format('YYYY-MM-DD');
        var startDate = moment(start).format('YYYY-MM-DD');
        for (let i = new Date(endDate); i >= new Date(startDate); i.setDate(i.getDate() - 1)) {
          var currentDate = moment(i).format('YYYY-MM-DD');
          let dataOfSameDate = [];
          ids.forEach((id) => {
            let _data = raw[id];
            let dataWithDate = _data.filter((row) => row.date === currentDate)[0];
            if (dataWithDate !== undefined && dataWithDate) {
              dataOfSameDate.push(dataWithDate);
            }
          })



          const sumOfData = dataOfSameDate.reduce((acc, curr) => {
            if (acc !== undefined && acc) {
              for (const [key, value] of Object.entries(curr)) {

                if (key === "date") {
                  acc[key] = value;
                  acc['id'] = value;
                  acc['label'] = value
                }
                else if (key !== 'date' && key !== 'label') {
                  acc[key] ??= 0;
                  acc[key] += value;
                }
              }
            }
            return acc;
          }, {});
          if (Object.keys(sumOfData ? sumOfData : {}).length > 0) {
            tableByTotal.push(sumOfData);
          }
        }
      }
      const dataByTotalSort = tableByTotal.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      })
      return dataByTotalSort
    }
  }

  let body = document.getElementById("domEl");



  const domEl = useRef(null);

  const set = (body) => {
    setTimeout(async () => {
      const canvas = await html2canvas(body);
      const dataURI = canvas.toDataURL('image/png');
      let array = dataURI.split(",")
      setImageData(dataURI);
    }, 100);
  }



  useEffect(() => {
    if (currentView === "table") {
      if (typeof body !== undefined && body) {
        set(body)
      }
    }

  }, [comparisonFormatData, body, currentView])


  const [screenshotUrl, setScreenshotUrl] = useState(null);
  console.log('screenshotUrl', screenshotUrl);
  const handleEchartDownload = (ref) => {


    html2canvas(ref.current.getEchartsInstance().getDom(), {
      allowTaint: true,
      useCORS: true,
    }).then((canvas) => {
      setScreenshotUrl(canvas.toDataURL('image/png'));
    });


  }

  useEffect(() => {
    if (typeof chartRef !== undefined && chartRef && chartRef.current && chartRef.current.getEchartsInstance && currentView === "spider") {

      let _spiderImageData;
      const echartsInstance = chartRef.current.getEchartsInstance();
      if (echartsInstance) {
        html2canvas(echartsInstance.getDom(), {
          allowTaint: true,
          useCORS: true,
        }).then((canvas) => {
          _spiderImageData = canvas.toDataURL('image/png');
          console.log('_spiderImageData', _spiderImageData);
          setScreenshotUrl(_spiderImageData);
        });
      }



    }

  }, [chartRef, currentView, chartRef.current])






  // const Quixote = () => {


  //   return (
  //     <Document>

  //       <Page style={styles.body} wrap={false}>
  //         <View style={[styles.descriptionPage, { position: "absolute", right: "3%", left: "86%", top: `5%`, width: "100px", paddingLeft: "3px", backgroundColor: "", height: "20px" }]}
  //         >
  //           {user.client_id[0].logo &&
  //             <Image
  //               style={{ width: "100%" }}
  //               src={user.client_id[0].logo}
  //             >

  //             </Image>}
  //         </View>
  //         <Text style={[styles.header, { marginTop: "25px", fontWeight: "bold" }]} fixed>
  //           Comparison Report
  //         </Text>
  //         {typeof filters !== undefined && filters && <Text
  //           style={{ fontSize: "10px", marginTop: "15px", marginLeft: "2px", fontWeight: 400, fontFamily: "Helvetica", letterSpacing: "", color: "#999999", }}           >

  //           {`Comparison of ${filters.selected.store1} from ${filters.selected.from1} to ${filters.selected.to1} with ${filters.selected.store2} from ${filters.selected.from2} to ${filters.selected.to2}`}
  //           <br></br>
  //         </Text>}

  //         <View style={{ width: "100%", marginTop: "10px" }}>

  //           {
  //             currentView === "table" &&
  //             typeof imageData !== undefined && imageData &&
  //             <Image
  //               style={{ width: "100%" }}
  //               src={imageData}
  //             >

  //             </Image>
  //           }

  //           {
  //             currentView === "spider" &&
  //             typeof screenshotUrl !== undefined && screenshotUrl &&
  //             <Image
  //               style={{ width: "100%" }}
  //               src={screenshotUrl}
  //             >

  //             </Image>
  //           }
  //         </View>

  //         <Text style={{ fontSize: "10px", marginTop: "35px", marginLeft: "2px", fontWeight: 400, fontFamily: "Helvetica", letterSpacing: "", color: "#999999", }}>
  //           Generated at {moment(new Date()).format("DD MMM YYYY   hh:mmA")}
  //         </Text>
  //       </Page>
  //     </Document>

  //   )
  // }




  return (
    <Fragment>


      <Row>
        <Col>
          <MainFilter
            pageTitle="Comparisons"
            updateFilter={(e) => updateFilter(e)}
            compareType={"single"}
            type='compareMultiple'
          ></MainFilter>
        </Col>
      </Row>
      <Row className="my-3">
        <Col md={12} className='p-2 d-flex justify-content-between'>

          {/* <Col md={6} className='p-0'>
            {
              // currentView === "table" &&
              <PDFDownloadLink document={<Quixote />} fileName={`${'Report'}.pdf`} className='btn btn-primary'>
                {({ blob, url, loading, error }) =>
                  loading ? 'loading...!' : <Download size={16} />
                }
              </PDFDownloadLink>}
          </Col> */}


          <Col md={6} className="text-end p-0" >
            <ButtonGroup>
              {summaryMenu.map((val, index) => {
                return (
                  <Button
                    href={"#toggle-tab-" + val[0]}
                    color={
                      currentView === val[0] ? "outline-primary" : "outline-light"
                    }
                    className="section-tab"
                    size="sm"
                    onClick={function noRefCheck() {
                      setCurrentView(val[0]);
                    }}
                    active={currentView === val[0] ? true : null}
                    key={"compare-tab-" + index}
                    id={"toggle-tooltip-" + val[0]}
                  >
                    {val[1]}
                  </Button>
                );
              })}
            </ButtonGroup>
            {summaryMenu.map((val, index) => {
              return (
                <UncontrolledTooltip
                  flip
                  isOpen={val[0] === tooltip}
                  autohide={false}
                  placement="top"
                  target={"toggle-tooltip-" + val[0]}
                  key={"compare-tab-tooltip-" + index}
                >
                  {val[2]}
                </UncontrolledTooltip>
              );
            })}
          </Col>


        </Col>
      </Row>
      <Row className="p-2">
        {/* <Card> */}
        {currentView === "table" &&
          <>

            <Table className="table-bordered" id="domEl" ref={domEl}>
              <thead>
                <tr>
                  <td className='text-center ' >
                    <div className='p-2'>
                      Keys
                    </div></td>
                  <td className="">
                    <Row >
                      <Col md={1}>
                      </Col>
                      <Col md={3} className='text-center' >
                        <div className='p-2' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <span className="" title={filters?.selected?.group1?.map((item) => { return `${item.label}` })}>{storeToBeCompare1}</span>
                        </div>
                      </Col>
                      <Col md={4} className='text-center p-2' >
                        {"Vs"}
                      </Col>
                      <Col md={3}>
                        <div className='p-2' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <span title={filters?.selected?.group2?.map((item) => { return item.label })}>{storeToBeCompare2}</span>


                        </div>
                      </Col>
                      <Col md={1} className='text-center' >
                      </Col>
                    </Row>
                  </td>
                </tr>
              </thead>
              <tbody>
                {
                  Object.keys(comparisonFormatData).length > 0 && index >= 0 &&
                  Object.keys(comparisonFormatData[index] ? comparisonFormatData[index] : {})?.map((item, index) => {
                    let item1 = comparisonFormatData[0][item] ? comparisonFormatData[0][item] : 0;
                    let item2 = comparisonFormatData[1][item] ? comparisonFormatData[1][item] : 0;
                    if (item != "_id" && item !== 'label') {
                      return (
                        <tr key={index}>
                          <td className='text-center'>
                            {item === "grp" ? "Families" : (item === "ipt" || item === "atv" || item === "asp" || item === "rpv") ? (item !== undefined && item && item.toLocaleUpperCase()) : capitalizeFirstLetter(item)}
                          </td>
                          <td className='text-center'>
                            <Row>
                              <Col md={1}>
                              </Col>
                              <Col md={3}>
                                {adlyticFormatter(comparisonFormatData[0][item] ? comparisonFormatData[0][item] : 0)}
                              </Col>
                              <Col md={4}>

                                <Row>
                                  <Col md={1} className='mx-2'>
                                    {(item1 / (item1 + item2) * 100).toFixed() + "%"}
                                  </Col>
                                  <Col md={9} className='p-2 ml-2'>
                                    <div className="progress " style={{ height: "0.5rem" }}>
                                      <div className="primaryColor progress-bar  " role="progressbar" style={{ width: (item1 / (item1 + item2) * 100).toFixed() + "%", ariaValuenow: (item1 / (item2 + item2) * 100).toFixed(), ariaValuemin: "0", ariaValuemax: "100" }}  ></div>
                                      <div className="primaryLight progress-bar " role="progressbar" style={{ width: (item2 / (item1 + item2) * 100).toFixed() + "%", ariaValuenow: (item2 / (item1 + item2) * 100).toFixed(), ariaValuemin: "0", ariaValuemax: "100" }}  ></div>
                                    </div>
                                  </Col>
                                  <Col md={1} className=''>
                                    {(item2 / (item1 + item2) * 100).toFixed() + "%"}

                                  </Col>


                                </Row>

                              </Col>
                              <Col md={3}>
                                {adlyticFormatter(comparisonFormatData[1][item] ? comparisonFormatData[1][item] : 0)}

                              </Col>
                            </Row>
                          </td>
                        </tr>
                      )
                    }
                  }
                  )}


              </tbody>
            </Table>
            <Card style={{ border: 'none' }}>
              <CardBody
                style={{ minHeight: '6.75rem', height: 'auto', }}
              >
                {!mainLoader && Object.keys(comparisonFormatData[0] ? comparisonFormatData[0] : {}).length < 3 &&
                  Object.keys(comparisonFormatData[1] ? comparisonFormatData[1] : {}).length < 3 &&
                  <span className='className="d-block small opacity-50"'><i>No data on selected date. Please select another date</i></span>}
              </CardBody>
            </Card>
          </>
        }


      </Row>

      <Row>
        <Col md={12}>
          {currentView === "calendarHeatmap" && option && data2 &&

            <>
              {
                !hmFlag &&
                <Card>
                  <CardBody>
                    <span className="d-block small opacity-50 text-center align-middle "><i>Please select 7 days or more to view this feature</i></span>
                  </CardBody>
                </Card>}

              {
                hmFlag && typeof filters !== undefined && filters &&
                <CalendarHeatmap
                  dataOptions={option}
                  payload={data1}
                  data={data1}
                  data2={data2}
                  properties={properties}
                  filters={{ range: new Date(filters.sd[0])?.getFullYear(), range1: [moment(new Date(filters.sd[0])).format("YYYY-MM-DD"), moment(new Date(filters.ed[0])).format("YYYY-MM-DD")], range2: [moment(new Date(filters.sd[1])).format("YYYY-MM-DD"), moment(new Date(filters.ed[1])).format("YYYY-MM-DD")] }}
                  anually={true}
                  monthly={false}
                  weekly={false}
                  view={'double'}
                  heatmapView={'vertical'}
                  storeToBeCompare1={storeToBeCompare1}
                  storeToBeCompare2={storeToBeCompare2}
                />}
            </>
          }
        </Col>
        {/* <Col md={6}>
        {currentView === "calendarHeatmap" && option && data1 &&
          <CalendarHeatmap
            dataOptions={option}
            payload={data2}
            data={data2}
            properties={properties}
            filters = {{range: new Date(filters.sd[0])?.getFullYear()}}
            anually={true}
            monthly={false}
            weekly={false}
            heatmapView={'vertical'}
          />
        }
        </Col> */}

      </Row>

      {currentView === "spider" &&

        <div >



          <DynamicSpider
            dataOptions={option}
            items={spiderData}
            setSpiderImageData={(e) => setSpiderImageData(e)}
            chartRef={chartRef}


          >
          </DynamicSpider>

        </div>

      }







    </Fragment>
  );
};

export default Comparison;
