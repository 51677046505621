import React, { useState } from 'react'
import { Row, Col, Table, Button, ButtonGroup, CardHeader, Card, CardBody } from "reactstrap";
import MainFilter from '../components/filters/MainFilter';
import { getStoresActivityRequest, getStoresHistoryRequest, stores__activity } from '../helpers/requests';
import { json, Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import moment from 'moment';
import { adlyticFormatter, colors, convertUTCDateToLocalDate, getLabelsInSeconds, globalTime, tabStyle } from '../helpers/utils';
import ProfileChart from '../components/charts/ProfileChart';
import { BarChart2 } from 'react-feather'
import { setLocalLoader } from '../redux/actions/highlights';


const Storeactivity = () => {
    const [response, setResponse] = useState();
    const [payload, setpayload] = useState();
    const dispatch = useDispatch()
    const [labels, setLabels] = useState([])
    const [historyResponse, setHistoryResponse] = useState([])
    const [internetSeriesData, setInternetSeriesData] = useState({ data: [], yAxisLabels: [] });
    const [machineSeriesData, setMachineSeriesData] = useState({ data: [], yAxisLabels: [] });
    const [currentView, setCurrentView] = useState("overview");
    const mainLoader = useSelector((state) => state.highlights.loading);
    const localLoader = useSelector((state) => state.highlights.localLoader);



    let format = "YYYY-MM-DD HH:mm:ss"




    console.log('payload', payload, moment(new Date(payload?.end)).format(format));



    const request = async (obj) => {
        dispatch(setLocalLoader(true));
        const res = await getStoresActivityRequest(obj)
        setResponse(res?.data)
        const history = await getStoresHistoryRequest(obj);
        if (history?.status) {
            setHistoryResponse(history.data)
            dispatch(setLocalLoader(false));
        } else {
            dispatch(setLocalLoader(false));
            setHistoryResponse([])
        }
    }

    const updateFilter = async (e) => {
        let obj = {
            dispatch,
            ...e
        }
        request(obj);
        setpayload(e)
        let labels = await getLabelsInSeconds(e.start, e.end, format);
        setLabels(labels)
    };


    function time(data) {
        var machine_time = formatTime(data !== undefined && data);
        const cut_machine_time = machine_time;
        const final_machine_time = cut_machine_time.substring(3);
        return final_machine_time;
    }
    function time_days(data) {
        var machine_day = formatTime(data !== undefined && data);
        const cut_machine_day = machine_day;
        const final_machine_day = cut_machine_day.substring(0, 2);
        return final_machine_day;
    }


    function formatTime(milliseconds) {
        const days = Math.floor(milliseconds / (24 * 60 * 60 * 1000));
        const hours = Math.floor(
            (milliseconds % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)
        );

        const minutes = Math.floor((milliseconds % (60 * 60 * 1000)) / (60 * 1000));
        const seconds = Math.floor((milliseconds % (60 * 1000)) / 1000);

        return [

            days.toString().padStart(2, "0"),
            hours.toString().padStart(2, "0"),
            minutes.toString().padStart(2, "0"),
            seconds.toString().padStart(2, "0")
        ].join(":");

    }






    const getSeriesData = (stores, key) => {

        // Stores consists of data object in which machine , internet is array, "Key" define either it is mahchine or internet
        // for example "key" is "internet"  data will be stores.internet
        // one object of stores.internet[0] would be {dateTime : "time", status : true/false, id}



        // This function return data and labels









        console.log('stores, key', stores, key);
        let data = [];
        let yAxisLabels = [];
        stores.map((emp, index) => {


            /***********************************************************
            
               1 - sortout keydata on dataTime 
               2 - if firstIndex.status = true then { create an dummy object and put its dateTime = payload.start and status = "false" } and push it into first index of keyData so we have pair of status false then true
               3 - iterate keyData and add a key "time" = item.dateTime in each object by passing through globalTime for offset
               4 -  run while loop until i < keyData.length 
                   i : at first we have array = keyData.slice(i); 
                   ii : falseIndex =find firstFalseindex whose status value is false, trueIndex =  firstTrueIndex whose status value is true
                   iii : if falseindex and trueIndex > 0 then take falseIndex.dateTime as startData and trueIndex.dateTime as end and put i = array[trueIndex].index so next iteartion start from next pair of false and true status
                   iv : if there is falseIndex but not trueIndex its mean we are missing pair we have to create dummy object whose status is true and dateTime of dummyObject will be payload.end and put i = i+1
             ************************************************************/


            yAxisLabels.push(emp.storeName);

            /********step 1*******/

            let keyData = JSON.parse(JSON.stringify(emp[key])).sort((a, b) => { return moment(a.datetime) - moment(b.datetime) });
            let deepCopy = JSON.parse(JSON.stringify(keyData)).map((item) => {
                return {
                    ...item, datetime: convertUTCDateToLocalDate(item.datetime, format)
                }
            });

            console.log('keyData', keyData, deepCopy);
            console.log('deepCopy',);




            let i = 0;
            let firstIndex = keyData.length > 0 && keyData[i];


            /********step 2*******/

            if (keyData.length > 0 && firstIndex.status === "true") {
                let object = {
                    datetime: payload.start,
                    status: "false",
                    name: emp.storeName
                }
                keyData.unshift(object)


            }

            /********step 3*******/

            keyData = keyData.map((item, ind) => {
                let obj = { ...item, index: ind, time: convertUTCDateToLocalDate(item.datetime, format), name: emp.storeName, }
                return obj
            })


            /********step 4*******/
            while (i < keyData.length) {
                let array = keyData.slice(i);
                console.log('arrayarray', array);
                let falseIndex = array.findIndex((item) => item.status === "false");
                let trueIndex = array.findIndex((item) => item.status === "true");
                if (falseIndex > -1 && trueIndex > -1) {

                    if (falseIndex < trueIndex) {

                        let _start = array[falseIndex].time;
                        let _end = array[trueIndex].time;
                        // let st = moment(new Date(_start)).format(format);
                        // let et = moment(new Date(_end)).format(format);
                        let st = _start;
                        let et = _end;
                        console.log('st,etst,et', st, et, moment(new Date(array[falseIndex].datetime)));
                        let duration = adlyticFormatter((Math.floor(Math.floor(new Date(array[trueIndex].datetime).getTime()) - Math.floor(new Date(array[falseIndex].datetime).getTime())) / (1000)));
                        let obj = {
                            value: [index, st, et, duration],
                            name: emp.storeName,
                            itemStyle: {
                                normal: {
                                    color: colors[index]
                                }
                            }
                        }
                        data.push(obj);
                        let num = array[trueIndex].index + 1;
                        i = num
                    }
                    else {
                        i = i + 1
                    }
                }
                else if (falseIndex > -1 && trueIndex < 0) {



                    let _start = array[falseIndex].time;
                    let _end = payload.end;
                    let st = moment(new Date(_start)).format(format);
                    let et = moment(new Date(_end)).format(format);
                    console.log('_end_end et', _end, et);
                    let duration = adlyticFormatter((Math.floor(Math.floor(new Date(_end).getTime()) - Math.floor(new Date(_start).getTime())) / (1000)));
                    let obj = {
                        value: [index, st, et, duration],
                        name: emp.storeName,
                        itemStyle: {
                            normal: {
                                color: colors[index]
                            }
                        }
                    }
                    data.push(obj);
                    i = i + 1

                }
                else {
                    i = keyData.length;
                }
            }



        })

        let _data = {
            data: data,
            yAxisLabels: yAxisLabels
        }

        return _data

    }

    const set = async (historyResponse) => {

        let _response = JSON.parse(JSON.stringify(historyResponse))
        console.log('_response', _response);
        let _internetSeries = await getSeriesData(historyResponse, "internet");
        let _machineSeries = await getSeriesData(historyResponse, "machine");
        console.log('_machineSeries', _machineSeries);
        setInternetSeriesData(_internetSeries);
        setMachineSeriesData(_machineSeries)
    }


    useEffect( () => {
        if (typeof historyResponse !== undefined && historyResponse) {
            set(historyResponse)
        }

    }, [historyResponse])





    return (
        <div>
            <MainFilter
                pageTitle="Store Activity"
                updateFilter={(e) => updateFilter(e)}
                type="store_activity"
                currentView={null}
            ></MainFilter>


            <Card className="my-4" style={{ backgroundColor: "" }}>
                <Row>
                    <Col
                        className="h-100 align-middle px-4 text-right"
                        style={{ height: "64px" }}
                    >
                        <ButtonGroup size="sm" style={{ borderRadius: "0px" }}>

                            <Button
                                style={tabStyle("overview", currentView)}
                                onClick={() => setCurrentView("overview")}
                            >
                                Overview
                            </Button>
                            <Button
                                style={tabStyle("internet", currentView)}
                                onClick={() => setCurrentView("internet")}
                            >
                                Internet History
                            </Button>
                            <Button
                                style={tabStyle("machine", currentView)}
                                onClick={() => setCurrentView("machine")}
                            >
                                Machine History
                            </Button>

                        </ButtonGroup>
                    </Col>
                </Row>
            </Card>

            {
                currentView === "internet" &&
                <Card className="" style={{ backgroundColor: '', overflow: '', marginTop: '2rem' }}>
                    {

                        // <CardHeader>
                        //     <Row>
                        //         <Col md={4}>
                        //             <h4 className="m-0 p-2" style={{ fontSize: '14px' }}> Internet Disconnection </h4>
                        //         </Col>
                        //         <Col classNmdame={8} className="text-end">
                        //             <ButtonGroup>
                        //                 <Button
                        //                     href={"#toggle-tab-"}
                        //                     color={"outline-primary"}
                        //                     className='section-tab'
                        //                     size='sm'
                        //                     // onClick={() => setType(val[0])}
                        //                     active={true}
                        //                     key={"compare-tab-"}
                        //                 >
                        //                     <BarChart2 size={16} />
                        //                 </Button>

                        //             </ButtonGroup>
                        //         </Col>
                        //     </Row>
                        // </CardHeader>
                    }
                    <CardBody
                        style={{ minHeight: '6.75rem', height: 'auto' }}
                        className='p-0'
                    >
                        {labels.length > 0 && typeof internetSeriesData !== undefined && internetSeriesData.data.length > 0 && !localLoader &&
                            <ProfileChart
                                labels={labels}
                                seriesData={internetSeriesData}

                            />
                        }
                        {!localLoader && !mainLoader && <span className='className="d-block small opacity-50"'>
                            <i>no data on selected date please select another date for linegraph</i>
                        </span>}
                        <div className="container h-100">
                            <div className="row align-items-center h-100">
                                <div className="col-6 mx-auto">
                                    <div className="jumbotron">
                                        {localLoader && <span className="d-block small opacity-30 text-center align-middle "><i>Data Fetching...</i></span>}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </CardBody>
                </Card>
            }

            {
                currentView === "machine" &&
                <Card className="" style={{ backgroundColor: '', overflow: '', marginTop: '2rem' }}>
                    {

                        // <CardHeader>
                        //     <Row>
                        //         <Col md={4}>
                        //             <h4 className="m-0 p-2" style={{ fontSize: '14px' }}> Mahine Disconnection </h4>
                        //         </Col>
                        //         <Col classNmdame={8} className="text-end">
                        //             <ButtonGroup>
                        //                 <Button
                        //                     href={"#toggle-tab-"}
                        //                     color={"outline-primary"}
                        //                     className='section-tab'
                        //                     size='sm'
                        //                     // onClick={() => setType(val[0])}
                        //                     active={true}
                        //                     key={"compare-tab-"}
                        //                 >
                        //                     <BarChart2 size={16} />
                        //                 </Button>

                        //             </ButtonGroup>
                        //         </Col>

                        //     </Row>
                        // </CardHeader>
                    }
                    <CardBody
                        style={{ minHeight: '6.75rem', height: 'auto', }}
                        className='p-0'

                    >
                        {
                            labels.length > 0 && typeof machineSeriesData !== undefined && machineSeriesData.data.length > 0 && !localLoader &&
                            <ProfileChart
                                labels={labels}
                                seriesData={machineSeriesData}

                            />
                        }

                        {!localLoader && !mainLoader && <span className='className="d-block small opacity-50"'>
                            <i>no data on selected date please select another date for linegraph</i>
                        </span>}
                        <div className="container h-100">
                            <div className="row align-items-center h-100">
                                <div className="col-6 mx-auto">
                                    <div className="jumbotron">
                                        {localLoader && <span className="d-block small opacity-30 text-center align-middle "><i>Data Fetching...</i></span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>

            }


            {
                currentView === "overview" &&
                <Row className="mb-3">
                    <Col>
                        <Table
                            bordered
                            responsive
                            style={{ fontSize: "", textAlign: "center" }}
                        >
                            <thead>
                                <tr style={{ topBorder: '' }}>
                                    <th>SR #</th>
                                    <th>Label</th>
                                    <th>Machine Disconnection Time</th>
                                    <th>Internet Disconnection Time</th>
                                    <th>Current Status</th>
                                </tr>
                            </thead>
                            <tbody>

                                {response !== undefined && response.length > 0 && response.map((item, index) => {

                                    const machine_time = time(item.machineDisconnectionTime);
                                    const machine_days = time_days(item.machineDisconnectionTime);
                                    const internet_time = time(item.internetDisconnectionTime);
                                    const internet_days = time_days(item.internetDisconnectionTime);
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td style={{ cursor: 'pointer' }}><Link to={"/store-history/" + `{"label":${JSON.stringify(item.label)},"sid":${JSON.stringify(item.sid)},"start":${JSON.stringify(payload.start)},"end":${JSON.stringify(payload.end)}}`} >{item.label}</Link></td>
                                            <td>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '.5rem' }}>
                                                    <span style={{ display: machine_days == '00' ? 'none' : 'flex' }}>{`${machine_days} Days `}</span>
                                                    <span>{machine_time}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '.5rem' }}>
                                                    <span style={{ display: internet_days == '00' ? 'none' : 'flex' }}>{`${internet_days} Days `}</span>
                                                    <span>{internet_time}</span>
                                                </div>

                                            </td>
                                            <td> <div style={{ width: '10px', height: '10px', background: item.status == 'Not Connected' ? 'red' : 'rgb(87, 231, 119)', borderRadius: '50%', margin: 'auto' }}></div></td>
                                        </tr>
                                    )
                                })}
                            </tbody>

                        </Table>
                    </Col>
                </Row>
            }

        </div>
    )
}

export default Storeactivity
