import React from 'react'
import { useEffect } from 'react';
import { ListGroupItem } from 'reactstrap';
import { captialLetterOfEachWord } from '../../../helpers/utils';
import { getFidsInObject, getSidsInObject } from './FilterCommon';

export default function FilterTwo({
    options,
    selectedSids,
    setSelectedSids,
    selectedFids,
    setSelectedFids,
    storesType,
    classifyFlag,
    setCategory,
    category

}) {

    console.log('selectedFids',selectedFids);

    console.log('classifyFlag', classifyFlag,category);


    const selectAllStoresByClickingType = async (storesType) => {
        console.log('storesType', storesType);
        setCategory(storesType)

        let _selectedSids = { ...selectedSids };
        let _selectedFids = { ...selectedFids }

        var _currentStores = options.filter(store => store[classifyFlag].toLowerCase() === storesType.toLowerCase());
        let _currentStoreSids = await getSidsInObject(_currentStores);
        let _currentStoresFids = await getFidsInObject(_currentStores);
        if (classifyFlag === 'grade') {

            setSelectedSids(_currentStoreSids);
            setSelectedFids(_currentStoresFids)


        } else {
            let allSids = { ..._selectedSids, ..._currentStoreSids };
            let allFids = { ..._selectedFids, ..._currentStoresFids };
            console.log('allSids', allSids, allFids);
            setSelectedSids(allSids);
            setSelectedFids(allFids)


        }


        // setSelectedStores(_currentStores);

    }

    const selectFirstStoreByClickingType = async (storesType) => {
        console.log('storesType', storesType);
        setCategory(storesType)

        let _selectedSids = { ...selectedSids };
        let _selectedFids = { ...selectedFids };

        var _currentStores = options.filter(store => store[classifyFlag].toLowerCase() === storesType.toLowerCase());
        let _currentStoreSids = await getSidsInObject([_currentStores[0]]);
        let _currentStoresFids = {};
        if (_currentStores.length > 0 && typeof _currentStores[0].floors !== undefined && _currentStores[0].floors && _currentStores[0].floors.length > 0) {
            _currentStoresFids[_currentStores[0].floors[0]._id] = _currentStores[0].floors[0]._id;
        }
        else {

        }
        // let _currentStoresFids = await getFidsInObject([_currentStores[0]]);
        console.log('_currentStoresFids', _currentStoresFids);
        // if (classifyFlag === 'grade') {

        setSelectedSids(_currentStoreSids);
        setSelectedFids(_currentStoresFids)


        // } else {
        //     let allSids = { ..._selectedSids, ..._currentStoreSids };
        //     let allFids = { ..._selectedFids, ..._currentStoresFids };
        //     console.log('allSids', allSids, allFids);
        //     setSelectedSids(allSids);
        //     setSelectedFids(allFids)


        // }


        // setSelectedStores(_currentStores);

    }

    const selectMultipleStores = async (store) => {
        let _currentStore = store;
        let _selectedSids = { ...selectedSids };
        let _selectedFids = { ...selectedFids };

        if (_currentStore._id in _selectedSids) {
            delete _selectedSids[_currentStore._id];
            if ('floors' in _currentStore) {
                _currentStore.floors.forEach((flr) => {
                    delete _selectedFids[flr._id]
                })
            }
            setSelectedSids(_selectedSids);
            setSelectedFids(_selectedFids)
        }
        else {
            let allSids = { ...selectedSids, ...{ [_currentStore._id]: _currentStore._id } };
            let currentStorefids = await getFidsInObject([_currentStore]);
            let allFids = { ..._selectedFids, ...currentStorefids };
            setSelectedSids(allSids)
            setSelectedFids(allFids)
        }

    }

    const selectFirstFloorOfStoreByDefault = async (store) => {
        let _currentStore = store;
        let _selectedSids = { ...selectedSids };
        let _selectedFids = { ...selectedFids };

        if (_currentStore._id in _selectedSids) {
            delete _selectedSids[_currentStore._id];
            if ('floors' in _currentStore) {
                _currentStore.floors.forEach((flr) => {
                    delete _selectedFids[flr._id]
                })
            }
            setSelectedSids(_selectedSids);
            setSelectedFids(_selectedFids)
        }
        else {
            let allSids = { ...selectedSids, ...{ [_currentStore._id]: _currentStore._id } };
            let currentStorefids = {};
            console.log('_currentStore',_currentStore);
            if (typeof _currentStore !== undefined && _currentStore  && typeof _currentStore.floors !== undefined && _currentStore.floors && _currentStore.floors.length > 0) {
                currentStorefids[_currentStore.floors[0]._id] = _currentStore.floors[0]._id;
            }
            console.log('currentStorefids',currentStorefids);
            // let currentStorefids = await getFidsInObject([_currentStore]);
            let allFids = { ..._selectedFids, ...currentStorefids };
            setSelectedSids(allSids)
            setSelectedFids(allFids)
        }

    }

    const selectMultipleFloors = async ({ floor, store }) => {

        console.log('storestore,', store);
        let _selectedFids = { ...selectedFids };
        let _selectedSids = { ...selectedSids }

        if (floor._id in _selectedFids) {

            delete _selectedFids[floor._id];

            let alsoDeletSidFlag = true;

            for (let i = 0; i < store.floors.length; i++) {
                if (store.floors[i]._id in _selectedFids) {
                    alsoDeletSidFlag = false;
                    break
                }

            }
            if (alsoDeletSidFlag) {
                delete _selectedSids[store._id]
            }

            console.log('alsoDeletSidFlag', alsoDeletSidFlag);


            setSelectedFids(_selectedFids);
            setSelectedSids(_selectedSids);
        }
        else {
            let allSids = { ...selectedSids, ...{ [store._id]: store._id } }
            let allFids = { ..._selectedFids, ...{ [floor._id]: floor._id } }
            setSelectedFids(allFids)
            setSelectedSids(allSids);

        }
    }

    useEffect(() => {
        if (classifyFlag === "grade") 
        {
            console.log('categorycategory',category,classifyFlag);
            selectFirstStoreByClickingType(category)
        }

    }, [classifyFlag])

    


    // useEffect(() => {
    //     if(typeof storesType !== undefined && storesType){
    //         let type = Object.keys(storesType ? storesType : {}).map((item) =>{return item})[0]

    //         selectAllStoresByClickingType(type);
    //         console.log('em running',type);

    //     }
    //    }, [1])





    return (
        <div>
            {Object.keys(storesType ? storesType : {}).map((key) => {
                if (typeof key !== undefined && key) {

                    let currentStoresToShow = options.filter((store) => store[classifyFlag] === captialLetterOfEachWord(key));

                    return (
                        <ListGroupItem key={'city-' + key} tag="a" href="#" className='white'>
                            <div
                                onClick={(e) => { selectFirstStoreByClickingType(key) }}
                            >
                                {<span key={'city-' + key} tag="a" href="#"  >
                                    {storesType[key]}
                                </span>
                                }

                            </div>

                            {((classifyFlag === 'grade' && captialLetterOfEachWord(key) === captialLetterOfEachWord(category)) || classifyFlag === 'city') && currentStoresToShow.length > 0 &&
                                currentStoresToShow.map((store, storeIndex) => {
                                    let currentStore = store;
                                    let storeIsActive = (typeof selectedSids !== undefined && selectedSids && store._id in selectedSids) ? true : false
                                    console.log('storeIsActive', storeIsActive);
                                    return (
                                        <>
                                            <div className="px-3 pb-1 " style={{ cursor: '' }}
                                                onClick={() => { selectFirstFloorOfStoreByDefault(store) }}
                                            >
                                                <div className={storeIsActive ? 'storeItemActive p-1' : 'storeItem p-1 '}>
                                                    {currentStore.label}
                                                </div>
                                            </div>


                                            {storeIsActive && "floors" in currentStore && currentStore.floors.length > 1 &&
                                                currentStore.floors.map((floor, floorIndex) => {
                                                    let currentFloor = floor;
                                                    let floorIsActive = (typeof selectedFids !== undefined && selectedFids && floor._id in selectedFids) ? true : false

                                                    return (
                                                        <div className="mx-5 my-1"
                                                            onClick={() => selectMultipleFloors({ floor, store })}
                                                        >
                                                            <div className={floorIsActive ? 'storeItemActive p-1' : 'p-1 storeItem'}>
                                                                {currentFloor.floor_label}
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </>
                                    )
                                })
                            }


                        </ListGroupItem>
                    )
                }

            })}

        </div>

    )
}
