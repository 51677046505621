import React, { useState, useRef } from 'react'
import "../MainFilter.css"
import "../../../App.css"
import { Calendar } from '@hassanmojab/react-modern-calendar-datepicker';
import { Button, Col, Input, InputGroup, Row } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { setError } from '../../../redux/actions/highlights';
import { createFilterRequest, updateFilterRequest } from '../../../helpers/requests';
import { Bookmark, Plus, XCircle } from 'react-feather';
import { colors } from '../../../helpers/meta';
import { useEffect } from 'react';
import Slider from '../../Slider/Slider';


export default function CalendarWithCreateDate(
    { selectedDayRange,
        setSelectedDayRange,
        maximumDate,
        dateFilters,
        setDeleteFilters,
        setCustomSelection,
        isEdit,
        editDateLabel,
        setCurrentDate,
        currentDate,
        deleteFilters,
        setEdit,
        showFooter
    }) {

    const dateRef = useRef(null);
    const dispatch = useDispatch();
    const [dateLoader, setDateLoader] = useState(false);
    const [dateLabel, setDateLabel] = useState();
    const [isCreateDate, setIsCreateDate] = useState();

    const createDateFilter = async () => {
        if (!dateLabel || dateLabel.trim() == 0) {
            dispatch(setError("Label for date filter cannot be empty"));
            return;
        }
        setDateLoader(true);
        let requestData = {
            params: {
                filter: {
                    from: selectedDayRange.from,
                    to: selectedDayRange.to,
                    range: "single"
                },
                label: dateLabel,
                type: "date"
            },
            dispatch
        };
        await createFilterRequest(requestData);
        setDateLabel("");
        setIsCreateDate(false);
        setDateLoader(false);

    }

    const updateDateFilter = async () => {
        if (!dateLabel || dateLabel.trim() == 0) {
            dispatch(setError("Label for date filter cannot be empty"));
            return;
        }
        setDateLoader(true);
        let requestData = {
            params: {
                ...currentDate,
                label: dateLabel,
                type: "date",
                filter: {
                    from: selectedDayRange.from,
                    to: selectedDayRange.to,
                    range: "single"
                },

            },
            dispatch
        };
        await updateFilterRequest(requestData);
        setDateLabel("");
        setDateLoader(false);
        // setEditDateFilterFlag(false)
        setCurrentDate({ _id: "" })
        setIsCreateDate(false)

    }

    useEffect(() => {

        if (isEdit) {
            setDateLabel(editDateLabel);
            setIsCreateDate(true);
        }

    }, [isEdit, editDateLabel])

    return (
        <div>
            <Calendar
                calendarClassName='Calendar'
                value={selectedDayRange}
                onChange={setSelectedDayRange}
                colorPrimary={colors.primary}
                colorPrimaryLight={colors.highlight}
                maximumDate={maximumDate}
            />


            { showFooter &&  <Row className='mb-2' >
                {
                    isCreateDate &&
                    <Col>
                        <InputGroup>
                            <Input ref={dateRef} size="sm" type='text' value={dateLabel} onChange={(e) => setDateLabel(e.target.value)} placeholder='Ex. Ramadan 2022' />
                            <Button size="sm" color="outline-danger" onClick={() => { setIsCreateDate(false); setEdit(false) }} disabled={dateLoader}><XCircle size={16} /></Button>
                            {
                                !isEdit &&
                                <Button size="sm" color="outline-primary" onClick={() => createDateFilter()} disabled={dateLoader}><Bookmark size={16} /> {"Save"} </Button>
                            }
                            {
                                isEdit &&
                                <Button size="sm" color="outline-primary" onClick={() => updateDateFilter()} disabled={dateLoader}><Bookmark size={16} /> {"Update"} </Button>
                            }



                        </InputGroup>
                    </Col>
                }
                {
                    !isCreateDate &&
                    <Col className='text-end'>
                        {(dateFilters.length > 0) && <Button size="sm" color="light mx-2" style={{ color: colors.primary }}
                            onClick={() => {
                                setDeleteFilters(true);
                                setCustomSelection(false);
                                setIsCreateDate(false)
                            }}
                        >View Filters</Button>}
                        {!deleteFilters && <Button size="sm" color="outline-primary" className='btn-outline-primary' onClick={() => setIsCreateDate(true)}><Plus size={16} /> Create Date Filter</Button>}
                        {/* {isEdit && <Button size="sm" color="outline-primary" className='btn-outline-primary' onClick={() => setIsCreateDate(true)}><Plus size={16} /> Update Date Filter</Button>} */}

                    </Col>
                }
            </Row>}



        </div>
    )
}
