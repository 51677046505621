import {actionTypes} from '../constants/action-types'

export const setComparison = (payload) => {
    return {
        type: actionTypes.SET_COMPARISON,
        payload: payload
    }
}

export const setGroupComparison = (payload) => {
    return {
        type: actionTypes.SET_COMPARISON_GROUP,
        payload: payload
    }
}

export const setComparisonLoader = (payload) => {
    return {
        type: actionTypes.SET_CATEGORY_LOADER,
        payload: payload
    }
}

export const setComparisonFilter = (payload) => {
    return {
        type: actionTypes.SET_COMPARISON_FILTER,
        payload: payload
    }
}

