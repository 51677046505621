import { actionTypes } from "../constants/action-types";
import moment from "moment";

export const getWeeks = (year) => {
  var temp = [];
  var week = 52;
  if (year === new Date().getFullYear()) {
    week = moment(new Date()).week();
  } else {
    week = moment(year + "/12/31").week();
    if (week === 1) {
      week = 52;
    }
  }
  for (var i = week; i > 0; i--) {
    var start = moment(year, "YYYY").week(i).startOf("week");
    var end = moment(year, "YYYY").week(i).endOf("week");
    var obj = {
      value: i,
      start: start,
      end: end,
      label: start.format("MMM Do") + "-" + end.format("MMM Do"),
    };
    temp.push(obj);
  }
  return temp;
};
const weeks = getWeeks(new Date().getFullYear());

const months = [
  { value: 1, label: "January", short: "Jan" },
  { value: 2, label: "Februaru", short: "Feb" },
  { value: 3, label: "March", short: "Mar" },
  { value: 4, label: "April", short: "Apr" },
  { value: 5, label: "May", short: "May" },
  { value: 6, label: "June", short: "Jun" },
  { value: 7, label: "July", short: "Jul" },
  { value: 8, label: "August", short: "Aug" },
  { value: 9, label: "September", short: "Sep" },
  { value: 10, label: "October", short: "Oct" },
  { value: 11, label: "November", short: "Nov" },
  { value: 12, label: "December", short: "Dec" },
];

const hours = new Array(24).fill(0).map((val, index) => {
  return {
    value: ("0" + index).slice(-2) + ":00",
    label: ("0" + index).slice(-2) + ":00",
  };
});

const ehours = new Array(24).fill(0).map((val, index) => {
  return {
    value: ("0" + index).slice(-2) + ":59",
    label: ("0" + (index + 1)).slice(-2) + ":00",
  };
});

const initialState = {
  comparison: null,
  groupComparison: null,
  filter: {
    compare: { value: "single", label: "With Self" },
    compareTypes: [
      { value: "single", label: "With Self" },
      { value: "multiple", label: "With Others" },
    ],
    type: { value: "daily", label: "Daily" },
    types: [
      { value: "daily", label: "Daily" },
      { value: "weekly", label: "Weekly" },
      { value: "monthly", label: "Monthly" },
      { value: "yearly", label: "Yearly" },
    ],
    hours: hours,
    ehours: ehours,
    hour: hours[0],
    chour: ehours[ehours.length - 1],
    week: weeks[0],
    cweek: weeks[0],
    weeks: weeks,
    cweeks: weeks,
    months: months,
    month: months[new Date().getMonth()],
    cmonth: months[new Date().getMonth()],
    year: { value: new Date().getFullYear(), label: new Date().getFullYear() },
    cyear: { value: new Date().getFullYear(), label: new Date().getFullYear() },
    years: new Array(5).fill(0).map((val, index) => {
      return {
        value: new Date().getFullYear() - index,
        label: new Date().getFullYear() - index,
      };
    }),
    stores: [],
    store: null,
    cstore: null,
    date: moment(new Date()),
    cdate: moment(new Date()),
    sd: [new Date(), new Date()],
    ed: [new Date(), new Date()],
    st: ["00:00", "00:00"],
    et: ["23:59", "23:59"],
  },
  loader: false,
};

export const comparisonReducer = (
  state = initialState,
  { type, payload = {} }
) => {
  switch (type) {
    case actionTypes.SET_COMPARISON:
      return { ...state, comparison: payload, loader: false };
    case actionTypes.SET_COMPARISON_GROUP:
      return { ...state, groupComparison: payload };
    case actionTypes.SET_COMPARISON_FILTER:
      return { ...state, filter: payload };
    case actionTypes.SET_COMPARISON_LOADER:
      return { ...state, loader: payload };
    default:
      return state;
  }
};
