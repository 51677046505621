import React, { Fragment, useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import echarts from 'echarts/lib/echarts';
import * as chart from 'echarts';
import moment from 'moment';


const getValue = (labels, seriesData) => {





    return {
        tooltip: {
            formatter: function (p) {
                var format = `<div style="padding : 0.125rem ">
                                <p>${p.name}</p>
                                <span style="display : block; opacity:50">${p.value[1]} - ${p.value[2]}</span>
                                <span>Seconds : ${p.value[3]}</span>
                              </div>`
                return format
            }




        },
        dataZoom: [
            {
                type: 'slider',
                filterMode: 'weakFilter',
                showDataShadow: false,
                top: 500,
                labelFormatter: ''
            },
            {
                type: 'inside',
                filterMode: 'weakFilter'
            }
        ],
        grid: {
            left: '12%',
            height: 400
        },
        xAxis: {
            data: labels,
            scale: true,

        },
        yAxis: {
            data: seriesData.yAxisLabels,
            axisLabel: {
                margin: "10",
                // rotate : 25
            },

            // min : 600,
            // max : 750
        },
        series: [
            {
                type: 'custom',
                renderItem: renderItem,
                itemStyle: {
                    opacity: 0.8,
                    paddingLeft: 30,
                },
                encode: {
                    x: [1, 2],
                    y: 0
                },
                data: seriesData.data
            }
        ]
    };

}

const renderItem = (params, api) => {
    var categoryIndex = api.value(0);
    var start = api.coord([api.value(1), categoryIndex]);
    var end = api.coord([api.value(2), categoryIndex]);
    var height = api.size([0, 1])[1] * 0.5;
    var rectShape = chart.graphic.clipRectByRect(
        {
            x: start[0],
            y: start[1] - height / 2,
            width: end[0] - start[0],
            height: height
        },
        {
            x: params.coordSys.x,
            y: params.coordSys.y,
            width: params.coordSys.width,
            height: params.coordSys.height
        }
    );
    return (
        rectShape && {
            type: 'rect',
            transition: ['shape'],
            shape: rectShape,
            style: api.style()
        }
    );
}

export default function ProfileChart({ labels, seriesData }) {

    const [option, setOption] = useState(null);
    const onChartReady = (echarts) => {
        echarts.hideLoading();
    }


    useEffect(() => {
        setOption(getValue(labels, seriesData));
    }, [seriesData, labels])


    return (
        <div className='p-0'>


            {option != undefined &&

                <ReactECharts
                    option={option}
                    onChartReady={onChartReady}
                    style={{ minHeight: '6.75rem', height: '35rem',padding :0, paddingLeft: '1rem',  }}
                />
            }


        </div>
    )
}

