import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Collapse, DropdownMenu, DropdownToggle, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, UncontrolledDropdown } from 'reactstrap';
import logo from '../assets/img/logo.png';
import ProfileMenu from './ProfileMenu';
import axios from 'axios';
import { setLogout } from '../redux/actions/auth';


const TopHeader = () => {
    const menu = useSelector(state => state.auth.menu);
    const user = useSelector(state => state.auth.user);
    const dispatch = useDispatch()
    const [clientLogo, setClientLogo] = useState(user.client_id[0].logo);
    const [clienttitle, setClientTitle] = useState(user.client_id[0].title);

    axios.interceptors.request.use((request) => {
        return request
    })
    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (error?.response?.data?.message === "jwt expired") {
            dispatch(setLogout(false))
            //   authenticationRequest({ dispatch });
        }
        return (error);
    });
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };



    return (
        <Fragment>
            <Navbar color="white" expand="md" light className='py-3 border-bottom border-2' container>
                <NavbarBrand href="/">
                    <img src={logo} width="150px" />
                </NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse navbar isOpen={isOpen} >
                    <Nav className='me-auto' navbar style={{ backgroundColor: '' }}>
                        {menu && <ParseMenu menu={menu} />}
                        {!menu.length &&
                            <span className='className="d-block small opacity-50"'><i>You dont have any Menu please contact admin</i></span>

                        }
                    </Nav>
                    {<ProfileMenu logo={clientLogo} title={clienttitle} />}
                </Collapse>
            </Navbar>
        </Fragment>
    )
}

export default TopHeader;



const ParseMenu = ({ menu }) => {



    const _menu = (
        <>


            {menu?.map((item, index) => {
                if (item.children.length) {
                    return (
                        <UncontrolledDropdown inNavbar nav key={"menu-item-" + index}>
                            <DropdownToggle caret nav>
                                {item.label}
                            </DropdownToggle>
                            <DropdownMenu end>
                                {item?.children.map((child, ind) => {
                                    return (
                                        <NavLink to={child.to} className={"dropdown-item"} key={"sub-menu-item-" + ind}>
                                            {child.label}
                                        </NavLink>
                                    );
                                })}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    );
                } else {
                    return (
                        <NavItem key={"menu-item-" + index}>
                            <NavLink to={item.to} className={"nav-link"}>
                                {item.label}
                            </NavLink>
                        </NavItem>
                    );
                }
            })}

        </>
    );
    return _menu;
} 