import React, { Fragment, useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/legend';
import { adlyticFormatter } from '../../helpers/utils';

const getOption = ({ legends, labels, data }) => {

    console.log('data==',data);



    let _visualObject = data.map((item, index) => {

        let pieces = [];

        item.data.forEach((dataPiece, dataIndex) => {
            if ('predicted' in dataPiece.itemStyle) {
                let piece = {
                    gt: dataIndex - 1,
                    lte: dataIndex,
                    color: 'silver'
                }
                pieces.push(piece)
            }
            else if ('imputed' in dataPiece.itemStyle) {
                let _imputed = dataPiece.itemStyle.imputed;
                console.log('redColorCheck', adlyticFormatter(_imputed));

                // let _imputed = parseFloat( dataPiece.itemStyle.imputed.replace("%", ""));
                if (_imputed !== 0 && adlyticFormatter(_imputed) >= 50 ) {
                    console.log('dataPiece',dataPiece);
                    let piece = {
                        gt: dataIndex - 1,
                        lte: dataIndex,
                        color: 'red'
                    }
                    pieces.push(piece)
                }
                else {
                    let piece = {
                        gt: dataIndex - 1,
                        lte: dataIndex,
                        color: dataPiece.itemStyle.color
                    }
                    pieces.push(piece)

                }

            }
            else {
                let piece = {
                    gt: dataIndex - 1,
                    lte: dataIndex,
                    color: dataPiece.itemStyle.color
                }
                pieces.push(piece)


            }


        })



        let obj = {
            seriesIndex: index,
            show: false,
            dimension: 0,
            pieces: pieces
        }

        return obj




        // if(item.predictedFlag){
        //     return  {
        //         seriesIndex : index,
        //         show: false,
        //         dimension: 0,
        //         pieces: [
        //           {
        //             lte: item.predictedIndex[0],
        //             color: item.color
        //           },
        //           {
        //             gt: item.predictedIndex[0],
        //             lte: item.predictedIndex[item.predictedIndex.length-1],
        //             color: 'silver'
        //           },
        //          ]
        //     }    
        // }
    })

    return {
        title: {
            text: ''
        },

        tooltip: {
            trigger: 'axis',
            // confine: true,
            showDelay: 0,
            formatter: function (params) {
                let chunk;
                if ('predicted' in params[0].data.itemStyle) {
                    chunk = `<span style="margin-left:10px; margin-right : 10px"><i>Projection by Adlytic AI</i></span>` + params[0].axisValueLabel + "<br/>"
                }
                else {
                    chunk = params[0].axisValueLabel + "<br/>"

                }
                params.forEach(param => {
                    if ("imputed" in param.data.itemStyle) {
                        chunk = chunk + ` <p><span style="color:${data[param.componentIndex].color}; margin-right:10px;">\u2B24</span> <span style="font-weight:">${param.seriesName}</span>  : <span style="font-weight:bold">${param.data.value} </span> <span style="margin-left:10px" >Imp : ${param.data.itemStyle?.imputed}</span>  </p>  
                        `+ "<br/>"
                    }
                    else {
                        chunk = chunk + ` <p><span style="color:${data[param.componentIndex].color}; margin-right:10px;">\u2B24</span> <span style="font-weight:">${param.seriesName}</span>  : <span style="font-weight:bold">${param.data.value} </span>  </p>  
                        `+ "<br/>"

                    }
                })
                return (chunk)
            },
        },


        toolbox: {
            show: true,
            feature: {
                saveAsImage: { show: true }
            }
        },
        legend: {
            data: data.map((item) => { return item.name })
        },
        grid: {
            left: '1%',
            right: '2%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: labels,
            // axisLabel : {
            //     margin : -5
            // }
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                margin: 60,
            }
        },
        visualMap: _visualObject,

        series: data


    };
}
const ImputedLineCore = (props) => {

    const [options, setOptions] = useState(null);
    const onChartReady = (echarts) => {
        echarts.hideLoading();
    }

    useEffect(() => {
        setOptions(getOption({ legends: props.legend, labels: props.labels, data: props.data }));
    }, [props])

    return (
        <Fragment>
            {options != undefined && options !== null && options &&
                <ReactECharts
                    echarts={echarts}
                    option={options}
                    style={{ height: 300 }}
                    onChartReady={onChartReady}
                    notMerge={true}

                />
            }
        </Fragment>
    )
}

export default ImputedLineCore;