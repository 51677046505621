import React, { Fragment, useState, useEffect } from "react";
import MainFilter from "../components/filters/MainFilter";
import { Card, CardBody, Row, Col, Progress, Table, CardHeader, UncontrolledTooltip, Button, ButtonGroup } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { categoryRequest, categoryGraphRequest, singleFloorFetch, employeSalesUnitRequest, getSaleItemRequest, getSlotTrafficRequest, categoryRequestTwo } from "../helpers/requests";
import Sections from "./Templates/Sections";
import { setCategoryLoader, setCategoryFilter } from "../redux/actions/categoryActions";
import LineGraph from "../components/charts/LineGraph";
import SectionWithScale from "./Templates/SectionWithScale";
import '../App.css'
import '../pages/css/categories.css'
import NewMainFiltrer from "../components/filters/newMainFilter";
import PaginationTable from "../components/pagination-table/PaginationTable";
import Column from "antd/es/table/Column";
import { adlyticFormatter } from "../helpers/utils";
import { BarChart, Layout } from "react-feather";
import SimpleBarGraph from "../components/charts/SimpleBarGraph";


const SlotsTraffic = () => {
  const [filters, setFilters] = useState();
  const dispatch = useDispatch();
  const labels = useSelector((state) => state.categoryReducer.labels);
  const graphLabels = useSelector((state) => state.categoryReducer.graphLabels);
  const floors = useSelector((state) => state.floorReducer.floors);
  const data = useSelector((state) => state.categoryReducer.data);
  const graph = useSelector((state) => state.categoryReducer.graph);
  const currentFloor = useSelector((state) => state.floorReducer.floor);
  const [sections, setSections] = useState([]);
  const [sectionSaleData, setSectionSaleData] = useState([]);
  const employeSalesUnits = useSelector((state) => state.categoryReducer.employeSalesUnits);
  const salesItem = useSelector((state) => state.categoryReducer.salesItem)
  const totalTraffic = useSelector((state) => state.categoryReducer.totalTraffic);
  const [saleItem, setSalesItem] = useState([])
  const [section, setSection] = useState();
  const [currentView, setCurrentView] = useState("table")
  const [selectedSection, setSelectedSection] = useState();
  const [slotsCount, setSlotsCount] = useState([]);
  const [detailsSlots, setDetailsSlots] = useState({ max: "", min: "", cdt: [] });
  console.log('slotsCount', slotsCount);
  console.log('selectedSection', selectedSection, sections);
  var filter = useSelector((state) => state.categoryReducer.filter);
  var loader = useSelector((state) => state.categoryReducer.loader);
  const updateFilter = (e) => {
    console.log('eeeeeeeeeeee', e);
    setFilters(e);
    e["dispatch"] = dispatch;

    let slotRequest = JSON.parse(JSON.stringify(e));
    console.log('slotRequest', slotRequest);



    let requestdata = e;


    let floorRequestdata = {
      _id: e.fid,
      dispatch,
    };


    let _filter = {
      _startDate: e.start,
      _endDate: e.end,
      _fid: e.fid,
      _sid: e.sid,
    };


    let employeSaleObject = {
      sid: requestdata.sid,
      start: requestdata.start,
      end: requestdata.end,
      dispatch,
    };


    let saleItemObject = {
      sid: [requestdata.sid],
      start: requestdata.start,
      end: requestdata.end,
      dispatch,
    }



    // console.log('slotTrafficRequest',slotTrafficRequest);
    getSaleItemRequest(saleItemObject);
    employeSalesUnitRequest(employeSaleObject);
    dispatch(setCategoryFilter(_filter));
    singleFloorFetch(floorRequestdata);
    setTimeout(() => {
      let obj = {
        end: e.end,
        start: e.start,
        fid: e.fid,
        sid: e.sid,
        dispatch
      }
      categoryRequestTwo(obj);
      // categoryGraphRequest(requestdata);
    }, 200);
  };

  const getBarLabels = () => {

    if (graphLabels.length > 0) {
      return graphLabels;
    }
    else {
      return currentFloor?.sections.map((val, ind) => {
        return val.label;
      });
    }
  };
  console.log('sectionsection', section);

  useEffect(() => {
    if (currentFloor) {
      setSection(currentFloor?.sections[0]);
      setSelectedSection(currentFloor?.sections[0]);
    }
  }, [currentFloor]);


  const set = async (section, filters) => {

    if (section && typeof filters !== undefined && filters) {
      let obj = filters;
      if (typeof obj === "object") {
        obj["sec_id"] = section?._id;
        obj['dispatch'] = dispatch;
        categoryGraphRequest(obj);
        let payload = {
          ...obj,
          sec: obj.sec_id
        }
        delete payload.sec_id
        var slots = await getSlotTrafficRequest(payload);
        if (slots.status) {
          setSlotsCount(slots.data)
          setDetailsSlots({ min: "", max: "", cdt: [] })
          const dummyData = [
            {
              "sec": "60c75d079b58c84e353c03f6",
              "slotDetail":
                [
                  {
                    "min": 1,
                    "max": 10,
                    "cdt": [
                      5
                    ]
                  },
                  {
                    "min": 11,
                    "max": 20,
                    "cdt": [
                      15,
                      15,
                      15,
                      15,
                      15
                    ]
                  }
                ]

            }
          ]
          // setSlotsCount(dummyData)

        }
        else {
          setSlotsCount([]);
          setDetailsSlots({ min: "", max: "", cdt: [] })

        }

      }
    }
  }


  useEffect(() => {

    set(section, filters)
  }, [section, filters]);

  useEffect(() => {
    if (floors) {
      let sections = [];
      floors.map((item) => {
        item.sections.map((section) => {
          sections.push({
            value: section._id,
            label: section.label,
          });
        });
      });
      setSections(sections);
    }
  }, [floors]);

  useEffect(() => {
    if (employeSalesUnits.length && totalTraffic.length) {
      let salesTrafficData = [];
      employeSalesUnits.filter((sales) => {
        return totalTraffic.find((traffic) => {
          if (traffic._id === sales.sec_id) {
            salesTrafficData.push({
              ...sales,
              traffic: traffic.footfall,
              label: traffic.label,
            });
          }
        });
      });
      setSectionSaleData(salesTrafficData);
    }
  }, [totalTraffic, employeSalesUnits]);

  function getSelectedValueBack(data) {
    setSelectedSection(data);
  }

  useEffect(() => {
    if (salesItem) {
      let size = 6;
      let items = salesItem
      items = items.sort((a, b) => {
        return b.sale - a.sale;
      })
      setSalesItem(items);
    }

  }, [salesItem])


  const summaryMenu = [
    ["table", <Layout size={16} />, "Tabular View"],
    ["bar", <BarChart size={16} />, "Stacked Chart"],
  ];


  let tooltip;


  return (
    <Fragment>
      <MainFilter
        pageTitle="Categories"
        updateFilter={(e) => updateFilter(e)}
        type="gold"
        floorToShow={true}

      ></MainFilter>


      <Row>
        <Col>
          <h4 className="my-3">Sectionwise Traffic & Dwelltime</h4>
        </Col>
      </Row>

      <Row>
        <Col style={{ height: "20px" }}>
          {loader && (
            <Progress
              animated
              className="mb-2"
              color="warning"
              value="100"
              style={{ height: "5px" }}
            />
          )}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={6}>
          <Card>
            <CardBody>
              {currentFloor && (
                <SectionWithScale
                  setSection={(section) => setSection(section)}
                  dye={currentFloor ? currentFloor.dye_image : ""}
                  map={currentFloor ? currentFloor.image : ""}
                  sections={currentFloor.sections ? currentFloor.sections : []}
                  section={section ? section : ""}
                  selectedSection={getSelectedValueBack}
                  heatmap={false}
                  heatmapData={[]}
                  sectionEditFlag={true}

                />
              )}
            </CardBody>
          </Card>


        </Col>
        <Col md={6}>

          {filters && filters.sid != "6234c4d7115aa9163039ad5a" && (

            <>
              <Row className="mb-3">
                <Col>
                  {data && (
                    <LineGraph
                      seriesData={{
                        data: data
                          ? data[0]
                          : new Array(getBarLabels().length).fill(0),
                        labels: getBarLabels(),
                      }}
                      hideControls={true}
                      title={"Visitors For All Sections"}
                      space={40}
                      rotation={45}
                      height={"19rem"}
                    />
                  )}
                </Col>
              </Row>

            </>
          )}

        </Col>
      </Row>

      <Row className="mb-3">
        {typeof selectedSection !== undefined && selectedSection && <Col md={6}>

          <Card className="mt-2">
            <CardHeader style={{ backgroundColor: 'none' }} >
              <h4 className="m-0" style={{ fontSize: '14px' }}>{selectedSection.label}</h4>
            </CardHeader>
            <CardBody className="py-0">
              <Table>
                <thead>
                  <tr>
                    <th style={{ fontWeight: "600" }}>Time Slot (Minutes)</th>
                    <th style={{ fontWeight: "600" }}>Total Traffic</th>
                  </tr>
                </thead>
                <tbody>
                  {slotsCount.filter((item) => {

                    return item.sec === selectedSection._id
                  }).map((obj, index) => {

                    let currentSlots = obj.slotDetail;
                    console.log('currentSlots', currentSlots);

                    return (
                      obj.slotDetail.map((slots, ind) => {
                        return (
                          <tr>
                            <td onClick={() => setDetailsSlots(slots)} style={{ cursor: "pointer" }}>{slots.min + "-" + slots.max}</td>
                            <td>{slots.cdt?.length}</td>
                          </tr>
                        )
                      })
                    )


                  })}

                </tbody>
              </Table>
            </CardBody>
          </Card>







        </Col>}

        {typeof selectedSection !== undefined && selectedSection && detailsSlots?.cdt?.length > 0 &&
          <Col md={6}>

            <Card className="mt-2">
              <CardHeader style={{ backgroundColor: 'none' }} >


                <Row style={{ position: 'relative' }}>
                  <Col style={{ backgroundColor: '', position: "absolute", top: "50%", margin: 0, transform: "translateY(-50%)" }}>
                    {


                      <h4 className="m-0" style={{ fontSize: '14px' }}> {selectedSection.label + " " + detailsSlots.min + "-" + detailsSlots.max + " Slot"}</h4>
                    }
                  </Col>
                  <Col className='text-end'>
                    <ButtonGroup>
                      {summaryMenu.map((val, index) => {
                        return (
                          <Button
                            href={"#toggle-tab-" + val[0]}
                            color={
                              currentView === val[0]
                                ? "outline-primary"
                                : "outline-light"
                            }
                            className="section-tab"
                            size="sm"
                            onClick={function noRefCheck() {
                              setCurrentView(val[0]);
                            }}
                            active={currentView === val[0] ? true : null}
                            key={"compare-tab-" + index}
                            id={"toggle-tooltip-" + val[0]}
                          >
                            {val[1]}
                          </Button>
                        );
                      })}
                    </ButtonGroup>
                    {summaryMenu.map((val, index) => {
                      return (
                        <UncontrolledTooltip
                          flip
                          isOpen={val[0] === tooltip}
                          autohide={false}
                          placement="top"
                          target={"toggle-tooltip-" + val[0]}
                          key={"compare-tab-tooltip-" + index}
                        >
                          {val[2]}
                        </UncontrolledTooltip>
                      );
                    })}

                  </Col>
                </Row>


              </CardHeader>
              <CardBody className="py-0">


                {currentView === "bar" && <SimpleBarGraph

                  seriesData={detailsSlots?.cdt?.map((item) => {
                    return adlyticFormatter(item / 60)
                  })}


                  labels={detailsSlots?.cdt?.map((item, index) => {
                    return index + 1
                  })

                  }



                />}


                {currentView === "table" && <PaginationTable
                  columns={
                    [<Column title="Customer" key="#" dataIndex="#" />,

                    <Column title="Dwell Time (Minutes)" key="dwelltime" dataIndex="dwelltime" />,

                    ]}
                  data={detailsSlots?.cdt?.map((item) => {
                    let obj = { dwelltime: adlyticFormatter(item / 60) }
                    return obj
                  })}
                />}

              </CardBody>

            </Card>






          </Col>}

      </Row>

    </Fragment>
  );
};
export default SlotsTraffic;
