import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, ButtonGroup, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import GraphCard from "../components/cards/GraphCard";
import LineCore from "../components/charts/LineCore";
import MainFilter from "../components/filters/MainFilter";
import moment from "moment";
import { imputeFootfallGraphRequest, user, marketAvgRequest, imputeFootfallRequest } from "../helpers/requests";
import { setCategoryFilter } from "../redux/actions/categoryAction";
import { setFootfallFilter, setFootfallGraph, setNewFootfallGraph, setFootfallGraphType, setGrapghData, setMarketAvgValue } from "../redux/actions/footfallActions";
import { BarChart2, ChevronLeft, ChevronRight, TrendingUp } from "react-feather";
import { capitalizeFirstLetter, tabStyle, toLowerCase } from "../helpers/utils";
import ImputedLineCore from "../components/charts/ImputedLineCore";
import NewMainFiltrer from "../components/filters/newMainFilter";
import GraphCardTwo from "../components/cards/GraphCardTwo";
import { useRef } from "react";

const NewTraffic = () => {
  const [type, setType] = useState({ total: "line" })
  const [apiFlag, setApiFlag] = useState(false)
  const [currentView, setCurrentView] = useState('footfall')
  let enabledFeatures = user?.client_id[0]?.feature;
  const [labels, setLabels] = useState([]);
  const footFall = enabledFeatures?.footfall;
  const [salesFeature, setSalesFeature] = useState([])
  const [title, setTitle] = useState();
  const [filter, setFilter] = useState();
  const [store, setStore] = useState(user.access[0].label);
  const [category, setCategory] = useState('')
  const [allFeatures, setAllFeatures] = useState([]);

  const linegraphLoader = useSelector((state) => state.employeActivity.timelineLoader);

  const dispatch = useDispatch();
  const marketAvg = useSelector((state) => state?.footfallReducer?.marketAvg);


  const all_data = useSelector((state) => state?.footfallReducer?.all_data_impute);
  const grapghRawDataImpute = useSelector((state) => state.footfallReducer.graphRawDataImpute)
  const graphRequestDataImpute = useSelector((state) => state.footfallReducer.graphRequestDataImpute);

  const updateFilter = (e) => {

    let object = {
      end: e.end,
      start: e.start,
      fid: e.fid,
      sid: e.sid,
      type: 'd',
      graphType: type,
      dispatch
    }
    setLabels(getLabels(object.start, object.end))
    setFilter(object);
    dispatch(setFootfallFilter({ start: e.start, end: e.end }));
    e["dispatch"] = dispatch;
    let _filter = {
      _startDate: e.start,
      _endDate: e.end,
    }

    dispatch(setCategoryFilter(_filter));
    if (e.classify === 'grade') {
      marketAvgRequest({ end: e.end, start: e.start, category: category, dispatch })
    }
    else {
      let obj = {
        user: user,
        data: [],
        requestData: e
      }

      dispatch(setMarketAvgValue(obj))
    }
    imputeFootfallRequest(object);
    imputeFootfallGraphRequest(object);
  };
  const getLabels = (startDate, endDate) => {
    var labels = [];
    var start = moment(startDate);
    var end = moment(endDate);
    if (end.diff(start, "day") < 1) {
      while (end.diff(start, "hour") >= 0) {
        labels.push(start.format("hh:mm a"));
        start = start.add(1, "hour");
      }
    } else {
      end = end.startOf("day");
      while (end.diff(start, "day") >= 0) {
        labels.push(start.format("D MMM  YYYY"));
        start = start.add(1, "day");
      }
    }
    // labels.pop()
    return labels;

  };

  const elementRef = useRef(null);
  const [arrowDisable, setArrowDisable] = useState(true);

  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true);
      } else {
        setArrowDisable(false);
      }
    }, speed);
  };








  useEffect(() => {

    setApiFlag(true);
    if (apiFlag) {
      let requestData = { ...filter, graphType: type }

      let obj = {
        user: user,
        data: graphRequestDataImpute,
        requestData: requestData,
        impute : false,
      }
      dispatch(setNewFootfallGraph(obj));
    }
  }, [type])

  useEffect(() => {
    if (apiFlag) {
      let requestData = { ...filter, graphType: type }
      let obj = {
        user: user,
        data: graphRequestDataImpute,
        requestData: requestData,
        impute : false,
      }
      dispatch(setNewFootfallGraph(obj));
    }

  }, [apiFlag])




  useEffect(() => {
    if (typeof enabledFeatures !== undefined && enabledFeatures) {

      let _allFeatures = [];
      enabledFeatures.footfall.forEach((item) => {
        item.children.forEach((children) => {
          let obj = { ...children, feature: "footfall", percentage: children.key === "total" ? false : true }
          _allFeatures.push(obj)
        })
      })
      Object.keys(enabledFeatures.sales ? enabledFeatures.sales : {}).forEach((saleFeature) => {
        let obj = {
          label: enabledFeatures.sales[saleFeature],
          key: saleFeature,
          feature: "sale",
          percentage: false
        }
        _allFeatures.push(obj)
      })


      setAllFeatures(_allFeatures);
    }

  }, [])

  useEffect(() => {
    if (typeof allFeatures !== undefined && allFeatures && allFeatures.length > 0) {
      console.log('allFeatures', allFeatures);

      setTitle(allFeatures[0].key);
    }
  }, [allFeatures])

  const summaryMenu = [
    ["line", <TrendingUp size={16} />],
    ["bar", <BarChart2 size={16} />],
  ];


  useEffect(() => {
    if (typeof title !== undefined && title && typeof grapghRawDataImpute !== undefined && grapghRawDataImpute) {
      let current = grapghRawDataImpute[title];
      console.log('currentcurrentcurrent', current, grapghRawDataImpute, title);
      let _type = {}
      Object.keys(current ? current : {}).forEach((item) => _type[item] = "line");
      setType(_type);
    }

  }, [title])


  const payload = {
    end: '',
    start: '',
    fid: [],
    sid: [],
    type: 'd',
    graphType: '',


  }


  return (
    <Fragment>
      {/* <MainFilter
        pageTitle="Traffic"
        Title={store}
        updateStoreNameURL={store}
        updateFilter={(e) => updateFilter(e)}
        type='brown'>
      </MainFilter> */}

      <NewMainFiltrer
        pageTitle="Trends"
        Title={store}
        updateFilter={(e) => updateFilter(e)}
        selected={{ storeName: true, floorName: true }}
        payload={payload}
        type='brown'
        setCategory={(output) => setCategory(output)}
        category={category}

      />

      {!all_data && <span className="d-block small opacity-50 m-2"><i>No data on selected date. Please select another date</i></span>}

      {console.log('allFeatures', allFeatures, all_data, title)}
      <Row className="mb-4">
        <Col>
          {
            allFeatures.length > 0 &&
            <>
              <div className="button-contianer p-0 m-0"
                style={{ textAlign: "center" }}
              >

                {/* <button style={{ textAlign: 'center', borderRadius: "50%", backgroundColor: "#fff", border: "1px solid #fff" }}> */}
                <ChevronLeft size={27}
                  onClick={() => {
                    handleHorizantalScroll(elementRef.current, 25, 100, -10);
                  }}
                  style={{ marginTop: '0.8rem', cursor: "pointer" }}
                  color={'rgba(170, 170, 170)'}
                  disabled={arrowDisable}
                />
                <div className="img-container" ref={elementRef}>
                  {
                    allFeatures.map((feature, index) => {

                      let value = 0;
                      let total = 0;
                      let _featurePercent = 0;

                      if (typeof all_data !== undefined && all_data) {
                        value = (feature.key in all_data) ? feature.key + 'impt' in all_data ? all_data[feature.key] + all_data[feature.key + 'impt'] : all_data[feature.key] : 0;
                        if (feature.percentage) {
                          total = ("total" in all_data) ? 'totalimpt' in all_data ? all_data.total + all_data['totalimpt'] : all_data.total : 0;
                          _featurePercent = value > 0 ? (value / total) * 100 : 0;
                        }
                      }


                      return (
                        <div onClick={() => setTitle(feature.key)}>
                          <GraphCardTwo
                            feature={feature}
                            value={value}
                            percentage={_featurePercent}
                            index={index}
                            active={title === feature.key ? true : false}
                            showPercentage={feature.percentage}
                          />
                        </div>
                      )
                    }
                    )}
                </div>

                <ChevronRight size={27}
                  onClick={() => {
                    handleHorizantalScroll(elementRef.current, 25, 100, 10);
                  }}
                  color={'rgba(170, 170, 170)'}
                  style={{ marginTop: '0.8rem', cursor: "pointer" }}
                />
              </div>
            </>

          }
        </Col>
      </Row>






      <Row>
        <Col>
          {
            Object.keys((grapghRawDataImpute !== undefined && grapghRawDataImpute) && grapghRawDataImpute[title] ? grapghRawDataImpute[title] : {}).map((feature, index) => {
              let currentFeature;
              let graphdata = grapghRawDataImpute[title][feature]
              let _marketAvg;

              if (marketAvg !== undefined && marketAvg && title in marketAvg && feature in marketAvg[title]) {
                // currentFeature.push(marketAvg[title][feature]);
                _marketAvg = marketAvg[title][feature];
                let _data = graphdata.data.slice();
                _data.push(_marketAvg.data);
                currentFeature = { data: _data }
              } else {
                currentFeature = graphdata

              }

              return (
                <Card className="mt-2" key={index}>
                  <CardHeader>
                    <Row>
                      <Col style={{ backgroundColor: '' }}>
                        {


                          <h4 className="m-0" style={{ fontSize: '14px' }}>{capitalizeFirstLetter(feature)}</h4>
                        }
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="text-end">
                        <ButtonGroup>
                          {
                            summaryMenu.map((val, index) => {
                              return (
                                <Button
                                  href={"#toggle-tab-" + val[0]}
                                  color={(typeof type !== undefined && type && feature in type && type[feature] === val[0]) ? "outline-primary" : "outline-light"}
                                  className='section-tab'
                                  size='sm'
                                  onClick={() => setType({ ...type, [feature]: val[0] })}

                                  active={!Object.keys(type ? type : {}).length ? index === 0 && true : (typeof type !== undefined && type && feature in type && type[feature] === val[0]) ? true : false}
                                  key={"compare-tab-" + index}
                                >
                                  {val[1]}
                                </Button>
                              );
                            })
                          }
                        </ButtonGroup>
                      </Col>
                    </Row>
                    {currentFeature.data !== undefined && currentFeature.data !== null && currentFeature.data && labels &&
                      <>
                        {
                          !linegraphLoader &&
                          <ImputedLineCore legends={''} labels={labels} data={currentFeature.data} />
                        }

                        {
                          linegraphLoader && <div className="container" style={{ height: "17.75rem" }}>
                            <div className="row align-items-center h-100">
                              <div className="col-6 mx-auto">
                                <div className="jumbotron">
                                  {linegraphLoader && <span className="d-block small opacity-30 text-center align-middle "><i>Data Fetching...</i></span>}
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      </>
                    }
                  </CardBody>
                </Card>
              )

            })
          }
          {
            !footFall &&
            <span className='className="d-block small opacity-50"'><i>You dont have any enabled features to plot on graph </i></span>
          }
        </Col>
      </Row>
    </Fragment>
  );
};

export default NewTraffic;
