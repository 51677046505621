import React, { useEffect, useState } from 'react'
import { Row, Col, Table, Button } from 'reactstrap'
import { useDispatch } from 'react-redux';
import { Modal } from 'antd';
import moment from 'moment';
import { getLabelsInSeconds } from '../helpers/utils';
import MainFilter from '../components/filters/MainFilter';
import './Slotsetting.css';
import { Delete } from 'react-feather';

import { addSlotRequest, getSlotRequest, updateSlotRequest, user } from '../helpers/requests';
import DeleteModal from '../components/modals/DeleteModal';
import { setSuccess } from '../redux/actions/highlights';

const Slotsetting = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch();
    const [slots, setSlots] = useState([]);
    const [max, setMax] = useState(-1);
    const [min, setMin] = useState(-1);
    const [deleteModal, setDeleteModal] = useState();
    const [currentSlot, setCurrentSlot] = useState();




    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = async () => {


        setIsModalOpen(false);
        let _slot = { min: min, max: max }

        if (!slots.length) {
            let payload = {
                client_id: user.client_id[0]._id,
                slots: [_slot],
                dispatch
            }
            addSlotRequest(payload);
        }
        else {
            let _slots = [...slots[0].slots, ...[_slot]];
            let payload = {
                client_id: user.client_id[0]._id,
                slots: _slots,
            };
            console.log('_slotsUpdate', _slots);
            const response = await updateSlotRequest({ data: payload, _id: slots[0]._id, dispatch });
            if (response.status) {
                fetchSlots()
            }
        }

    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const fetchSlots = async () => {
        let payload = {
            client_id: user.client_id[0]._id,
            dispatch
        }
        console.log('payload', payload);
        let _slots = await getSlotRequest(payload);
        if (_slots.length > 0) {
            setSlots(_slots)

        }
    }

    const deleteSlot = async (slot) => {

        let _slots = [...slots[0].slots];
        let updatedSlots = _slots.filter((item) => {return item._id !== slot._id});
        let payload = {
            data : {
                ...slots[0], slots : updatedSlots
            },
            _id : slots[0]._id,
            dispatch
        }
        const response = await updateSlotRequest(payload);
        if(response.status){
            dispatch(setSuccess("Slot Deleted"))
            fetchSlots();
        }


    }

    useEffect(() => {
        fetchSlots()

    }, [1])


    return (

        <>
            <div>
                <div className='main_slotset'>
                    <div className="top_secSlot">
                        <div className="slot_title">Instore slots configuration</div>
                    </div>
                    <div className="bottom_slot">
                        <div className="bottom_top">
                            <div className="slot_title">Slots</div>
                            <Button className='btn-primary' onClick={showModal}>+ Add Slot</Button>
                        </div>
                        <div className="slot_table">
                            <Row className="mb-3">
                                <Col>
                                    <Table
                                        bordered
                                        responsive
                                        style={{ fontSize: "", textAlign: "center" }}
                                    >
                                        <thead>
                                            <tr style={{ topBorder: '' }}>
                                                <th>SR #</th>
                                                <th>Min</th>
                                                <th>Max</th>
                                                <th>Action</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {slots.length > 0 && slots[0].slots.map((item, index) => {
                                                return (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{item.min}</td>
                                                        <td>{item.max}</td>
                                                        <td>{<Delete style={{cursor : "pointer"}} size={20} onClick={() => { setDeleteModal(true); setCurrentSlot(item) }}  />}</td>
                                                    </tr>
                                                )

                                            })}

                                        </tbody>

                                    </Table>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <Modal title="Add Slot Detail" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                        <label className='slot_group'> <span>Min:-</span><input className='slot_input' type='number' placeholder='Insert Minutes' onChange={(e) => setMin(e.target.value)} /> </label>
                        <label className='slot_group'> <span>Max:-</span><input className='slot_input' type='number' placeholder='Insert Minutes' onChange={(e) => setMax(e.target.value)} /> </label><br />
                      
                        <div className="slot_btn_sec">
                            <Button className='btn-primary' onClick={handleCancel}>Cancel</Button>
                            <Button className='btn-primary' onClick={handleOk}>ADD</Button>
                        </div>
                    </Modal>
                </div>
            </div>


            {deleteModal &&
                <DeleteModal modal={deleteModal} setModal={(output) => setDeleteModal(output)} delete={() => deleteSlot(currentSlot)} />
            }
        </>

    )
}

export default Slotsetting
