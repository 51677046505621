import React, { Fragment } from 'react';

const Sales = () => {
    const tag = "SALES";
    return (
        <Fragment>
            {tag}
        </Fragment>
    )
}

export default Sales;