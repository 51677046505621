import React, { useState, useEffect } from 'react';
import { ArrowDown, ArrowUp, ChevronDown, ChevronUp } from 'react-feather';
import { Card, CardBody } from 'reactstrap';
import { numberFormater } from '../../helpers/common';
import { Delete } from 'react-feather';
import DeleteModal from '../modals/DeleteModal';

const InsightQuestionCard = (props) => {
    const tag = "CARD";
    const [modal, setModal] = useState(false)

    const [percentage, setPercentage] = useState(0);
    useEffect(() => {
        if (props.previous == null || props.previous == 0) {
            setPercentage(0);
        } else {
            setPercentage((((props.current - props.previous) / props.previous) * 100).toFixed(1))
        }
    }, [props])
    return (
        <Card  className={` w-100 hoverr  align-items-center  ${props.active ? 'activeDiv' : ''}  `} style={{ display: "inline-block", float: "none", /*maxWidth: "270px",*/ marginRight: "30px", minWidth: "150px" }}>
            <CardBody>
                <div
                    className='d-flex' style={{ width: "100%" }}
                    
                >
                    <div style={{ width: "95%" }}>
                        <h6 className='m-0 pb-2 secondary' style={{ fontSize: "12px", fontWeight: "400" }}>
                            {props.title}
                        </h6>
                    </div>
                    {
                        props.deleteFlag && <div className='px-3 m-0 pb-2 secondary' style={{ width: '5%', cursor: 'pointer', fontSize: "12px", fontWeight: "400" }}
                        onClick={() => setModal(true)}
                        >
                            <Delete size={20} />
                        </div>
                    }


                </div>

                <h3 className='m-0 p-0 mb-2' style={{ fontSize: "24px", fontWeight: "500" }}>{numberFormater(props.current)}</h3>
                <DeleteModal modal={modal} setModal={(output) => setModal(output)} delete={() => props.deleteCustom(props.index_id)()} />

            </CardBody>
        </Card>
    )
}

export default InsightQuestionCard;