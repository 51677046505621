import { ArrowLeft, ArrowRight, Bookmark, Filter, Plus, Trash2, XCircle, ChevronLeft, ChevronsLeft, ChevronsRight, ChevronRight, Edit2 } from 'react-feather';
import { capitalize, capitalizeFirstLetter, captialLetterOfEachWord, charCheck, convertItemIntoArray, getLengthFlag, isGreaterThanToday, isToday, q, removeSpaceAndCamelCase, removeSpaces, timeFormat, toLowerCase } from '../../helpers/utils';
import { getDateString, getDifference, getEndDate, getFidsFormat2, getFloorFidAndSids, getSids, getStartDate, getStoresObject } from './components/FilterCommon';
import { Dropdown, DropdownMenu, DropdownToggle, Row, Col, ListGroup, ListGroupItem, Button, Input, InputGroup, Label, ButtonGroup, CardBody, CardHeader, Card } from 'reactstrap';
import { createFilterRequest, deleteFilterRequest, listFilterRequest, stores__activity, updateFilterRequest } from '../../helpers/requests';
import CalendarWithCreateDate from './components/CalendarWithCreateDate';
// import 'react-modern-calendar-datepicker/lib/CustomDatePicker.css';
// import 'react-modern-calendar-datepicker/lib/CustomStyle.css';
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { deleteFilter } from '../../redux/actions/savedFilters';
import FilterDescription from './components/FilterDescription';
import CustomeFilterList from './components/CustomeFilterList';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';

// import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import CreateDateFilter from './components/CreateDateFilter';
import StoresTypeHeader from './components/StoresTypeHeader';
// import { Calendar } from 'react-modern-calendar-datepicker';
import { Calendar } from '@hassanmojab/react-modern-calendar-datepicker';

import CreateBranchFilter from './components/BranchFilter';
import DynamicCalendar from './components/DynamicCalendar';
import TimeRangeSlider from './components/TimeRangeSlider';
import { useLocalStorage } from '../Hooks/useLocalStorage';
import { setError } from '../../redux/actions/highlights';
import { useDispatch, useSelector } from 'react-redux';
import ListOfStores from './components/ListOfStores';
import FilterFooter from './components/FilterFooter';
import BranchFilter from './components/BranchFilter';
import { colourStyles } from '../../helpers/common';

import DateToggle from './components/DateToggle';
import { useParams } from 'react-router-dom';
import { BsCircleFill } from "react-icons/bs";
import { colors } from '../../helpers/meta';
import Slider from '../Slider/Slider';
import Select from 'react-select';
import moment from 'moment';
import './MainFilter.css'
import '../../App.css'
import CustomCircleIcon from '../svg/CustomeCircleIcon';
import FilterDropDown from '../Mobile/FilterDropDown';
import DateRangeDropdown from '../Mobile/DateRangeDropdown';


const MainFilter = (props) => {


    // useParams
    const { id, title } = useParams();


    // useRef
    const dateRef = useRef(null);
    const branchRef = useRef(null);
    const isFirstRender = useRef(true);



    // redux states
    const dispatch = useDispatch();
    const branchFilters = useSelector(state => state.savedFilters.branchFilters);
    const showFilterByRedux = useSelector((state) => state.insights.showFilter);
    const dateFilters = useSelector(state => state.savedFilters.dateFilters);
    const loaded = useSelector(state => state.savedFilters.loaded);
    const feature = useSelector(state => state.auth.features);
    const user = useSelector(state => state.auth.user);


    // useStates
    const [storesForComparisonTwo, setStoresForComparisonTwo] = useState();
    const [dateSelectionTwo, setDateSelectionTwo] = useState("yesterday");
    const [editDateFilterFlag, setEditDateFilterFlag] = useState(false);
    const [dateFilterFlagTwo, setDateFilterFlagTwo] = useState(false);
    const [storesForComparison, setStoresForComparison] = useState();
    const [selectAllStoreFlaf, selectAllStoreFlag] = useState(false);
    const [dateSelection, setDateSelection] = useState("yesterday");
    const [insightsYearlyDate, setInsightsYearlyDate] = useState();
    const [customSelection, setCustomSelection] = useState(false);
    const [saveInsightFlag, setSaveInsightFlag] = useState(true);
    const [dateFilterFlag, setDateFilterFlag] = useState(false);
    const [currentDate, setCurrentDate] = useState({ _id: '' });
    const [isFloorActive, setIsFloorActive] = useState(false);
    const [deleteFilters, setDeleteFilters] = useState(false);
    const [selectedFeature, setSelectedFeature] = useState();
    const [comparisonFids1, setComparisonFids1] = useState();
    const [singleCity2, setSingleCity2] = useState("lahore");
    const [comparisonFids2, setComparisonFids2] = useState();
    const [branchLoader, setBranchLoader] = useState(false);
    const [sidForInsights, setSidsForInsights] = useState();
    console.log('sidForInsights', sidForInsights);
    const [isEditBranch, setIsEditBranch] = useState(false);
    const [branchFilter, setBranchFilter] = useState(false);
    const [optionLength, setSingleLength] = useState(true);
    const [singleCity, setSingleCity] = useState("lahore");
    const [showFilters, setShowFilters] = useState(false);
    const [selectType, setSelectType] = useState('daily');
    const [summaryStores, setSummaryStores] = useState();
    const [selection2, setSelection2] = useState("all");
    const [dateFilter, setDateFilter] = useState(false);
    const [dateLoader, setDateLoader] = useState(false);
    const [branchLabel, setBranchLabel] = useState("");
    const [storeTwoName, setStoreNametwo] = useState();
    const [userFeature, setUserFeature] = useState([]);
    const [storeOneName, setStoreName1] = useState();
    const [dateLabel, setDateLabel] = useState("");
    const [storeName2, setStoreName2] = useState();
    const [yearRnage, setYearRange] = useState(0);
    const [floorId2, setFloor2] = useState();
    const [store2, setStore2] = useState();
    const [checkall, setCheckall] = useState();
    const [genderKeys, setGenderKeys] = useState('all');
    const [staffKeys, setStaffKeys] = useState('all');
    const [trafficType, setTrafficType] = useState('0');


    // this is to handle the width of screen
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    console.log('windowWidth===', windowWidth);

    useEffect(() => {
        // Update the windowWidth state when the window is resized
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    const defaultDate = {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate() - 1
    }


    //  
    // define how to show stores. Two types one is by city name second is by grade name. Grade and city is manage by classifyflag

    const [classifyFlag, setClassifyFlag] = useState('city')
    let classifyByClasses = {};
    let classifyByCity = {};
    const options = user.access.map((val) => {
        let _city = val.city.toLowerCase();
        classifyByCity[_city] = val.city;
        let grade = charCheck(val.grade)
        if (grade !== undefined && grade) {
            classifyByClasses[grade] = grade;
        }

        return { ...val, value: val._id }
    });

    const [cities, setCities] = useState(classifyByCity);
    console.log('cities in mainfilter',cities);
    let _option = options[0];



    // useLocalStorage states
    const [selectedDayRange, setSelectedDayRange] = useLocalStorage('selectedDayRange',
        {
            from: defaultDate,
            to: defaultDate
        }
    );
    const [selectedDayRangeTwo, setSelectedDayRangeTwo] = useLocalStorage('selectedDayRangeTwo', selectedDayRange);
    const [selection, setSelection] = useLocalStorage("selection", "all");
    const [storeName, setStoreName] = useLocalStorage("storeName", null);
    const [timeFrom, setTimeFrom] = useLocalStorage('timeFrom', 0);
    const [option, setOption] = useLocalStorage("option", options);
    const [floorId, setFloor] = useLocalStorage('floor', null);
    const [timeTo, setTimeTo] = useLocalStorage('timeTo', 24);




    // functions

    const toggle = () => {
        setShowFilters(!showFilters);
    }


    const selectAll = () => {
        setSelection("all");
        setOption(options);
    }


    const setSelectedDayRangeFromCalendar = (obj) => {
        setSelectedDayRange(obj);
        setDateSelection("");
    }


    const setDateForEmployeeActivity = (obj) => {
        let object = {
            from: obj.from,
            to: obj.from
        }
        setSelectedDayRange(object);
        setDateSelection("");

    }


    const selectByCity = (city) => {
        setSelection(city);
        var locations = options.filter(store => store[classifyFlag].toLowerCase() === city.toLowerCase());
        let _stores = {};
        locations.forEach((item) => {
            return _stores[item._id] = item._id
        })
        setSummaryStores(_stores);
        setOption(locations);
    }


    const setSelectedDayRangeFromCalendarTwo = (obj) => {
        setSelectedDayRangeTwo(obj);
        setDateSelectionTwo("");
    }


    const selectAllStores = (event) => {
        if (event.target.checked) {
            setOption(options);
            let selectedIds = {};
            options.forEach((item) => {
                selectedIds[item._id] = item._id
            })


            setStoresForComparison(selectedIds);
            selectAllStoreFlag(true)
        }
        else {
            setOption([options[0]]);
            let _id = {};
            _id[options[0]._id] = options[0]._id
            setStoresForComparison(_id);
            selectAllStoreFlag(false)


        }
    }


    const selectByclassifyFlagTwo = (key) => {

        let _storesForComparison = { ...storesForComparisonTwo };
        let _comparisonFids2 = { ...comparisonFids2 }
        let storesOfCurrentKey = options.filter((item) => { return captialLetterOfEachWord(item[classifyFlag]) === captialLetterOfEachWord(key) });
        let newStore = {};

        let newFids = {}

        storesOfCurrentKey.forEach((item) => {
            newStore[item._id] = item._id;
            item.floors.forEach((flr) => {
                newFids[flr._id] = flr._id;
            })
        })
        let allStores = { ..._storesForComparison, ...newStore }
        let allFids = { ...newFids, ..._comparisonFids2 };
        setComparisonFids2(allFids);
        setStoresForComparisonTwo(allStores);
        let arrayOfIds = Object.keys(allStores ? allStores : {}).map((key) => { return key })
        let stores = options.filter((item) => arrayOfIds.includes(item._id))
        setStore2(stores);

    }


    const selectByclassifyFlag = (key) => {


        let _storesForComparison = { ...storesForComparison };
        let _comparisonFids1 = { ...comparisonFids1 }
        let storesOfCurrentKey = options.filter((item) => { return captialLetterOfEachWord(item[classifyFlag]) === captialLetterOfEachWord(key) });
        let newStore = {};
        let newFids = {}
        storesOfCurrentKey.forEach((item) => {
            newStore[item._id] = item._id;
            item.floors.forEach((flr) => {
                newFids[flr._id] = flr._id;
            })
        })
        let allStores = { ..._storesForComparison, ...newStore }
        let allFids = { ..._comparisonFids1, ...newFids };
        setComparisonFids1(allFids);
        setStoresForComparison(allStores);
        let arrayOfIds = Object.keys(allStores ? allStores : {}).map((key) => { return key })
        let stores = options.filter((item) => arrayOfIds.includes(item._id))
        setOption(stores);

    }


    const selectBysingleCity = (city) => {

        setIsFloorActive(false)
        setSingleLength(false)
        let _city = capitalizeFirstLetter(city);
        setSingleCity(city)
        let _option = options.filter((store) => { return captialLetterOfEachWord(store[classifyFlag]) === captialLetterOfEachWord(city) })[0];
        if (typeof _option !== undefined && _option) {
            setIsFloorActive(false)
            if (_option.floors.length > 0) {
                setFloor({ _id: _option.floors[0]._id, name: _option.floors[0].floor_label })
            }
            setSelection(_option._id);
            setStoreName(_option.label)
            setOption([_option])
        }

    }


    const selectByStore = (store) => {
        if (props.type === "compareMultiple" || props.type === "brown" || props.type === "visitor") {
            let _stores = (option !== undefined && option) ? option.slice() : [];
            let existObject = _stores.find((item) => item._id === store._id);
            if (existObject !== undefined && existObject) {
                _stores = _stores.filter((item) => item._id !== existObject._id)
                setOption(_stores)

            }
            else {
                _stores.push(store)
                setOption(_stores)
            }

            let selectedIds = {};
            let store1name = {};
            let fids1 = {};

            _stores.forEach((item) => {
                selectedIds[item._id] = item._id
                store1name[item.label] = item.label;
                item.floors.forEach((floor) => {
                    fids1[floor._id] = floor._id
                })
            })

            setComparisonFids1(fids1);
            setStoresForComparison(selectedIds)
            setSingleLength(false)

        }
        else {
            setSingleLength(false)
            {
                setIsFloorActive(false)
                setFloor({ _id: store.floors[0]._id, name: store.floors[0].floor_label })
                // setFloor()
                setSelection(store._id);
                setStoreName(store.label)
                var locations = options.filter(stores => stores._id === store._id);
                setOption(locations)
                if (store.floors.length === 1 || props.pageTitle === "Conversion") {
                    setFloor({ _id: store.floors[0]._id, name: store.floors[0].floor_label })
                }

            }

        }
    }


    const selectByStore2 = (store) => {

        if (props.type === "compareMultiple") {
            let _stores = (typeof store2 !== undefined && store2) ? store2.slice() : [];
            let existObject = _stores.find((item) => item._id === store._id);
            if (existObject !== undefined && existObject) {
                _stores = _stores.filter((item) => item._id !== existObject._id)
                setStore2(_stores)

            }
            else {
                _stores.push(store)
                setStore2(_stores)
            }

            let selectedIds = {};
            let store2Name = {};
            let fids2 = {};

            _stores.forEach((item) => {
                selectedIds[item._id] = item._id
                store2Name[item.label] = item.label
                item.floors.forEach((floor) => {
                    fids2[floor._id] = floor._id
                })
            })


            setComparisonFids2(fids2);
            setStoresForComparisonTwo(selectedIds)
            setSingleLength(false)

        }
        else {
            setSelection2(store._id);
            setStoreName2(store.label)
            var locations = options.filter(stores => stores._id === store._id);
            setStore2(locations)
        }


    }

    const selectByfloor = async (floor) => {
        if (props.type === "compareMultiple" || props.type === "visitor") {
            let _fids = JSON.parse(JSON.stringify(comparisonFids1));
            if (floor._id in _fids) {

                delete _fids[floor._id]
            }
            else {
                _fids[floor._id] = floor._id
            }


            let fidsOfSelectedSids = await getFloorFidAndSids(options, floor._id);
            let fidsArray = await convertItemIntoArray(_fids);

            const isExist = fidsArray.some(r => fidsOfSelectedSids.fids.indexOf(r) >= 0);
            if (!isExist) {
                let _sids = JSON.parse(JSON.stringify(storesForComparison));
                delete _sids[fidsOfSelectedSids.sid];
                setStoresForComparison(_sids);
                let _option = option.slice();
                let newOption = _option.filter((item) => { return item._id !== fidsOfSelectedSids.sid })
                setOption(newOption);
            }

            setComparisonFids1(_fids);

        } else {
            setFloor({ _id: floor._id, name: floor.floor_label })
            setIsFloorActive(true)
        }

    }


    const selectByfloor2 = async (floor) => {
        if (props.type === "compareMultiple") {
            let _fids = JSON.parse(JSON.stringify(comparisonFids2));
            if (floor._id in _fids) {
                delete _fids[floor._id]
            }
            else {
                _fids[floor._id] = floor._id
            }

            let fidsOfSelectedSids = await getFloorFidAndSids(options, floor._id);
            let fidsArray = await convertItemIntoArray(_fids);

            const isExist = fidsArray.some(r => fidsOfSelectedSids.fids.indexOf(r) >= 0);
            if (!isExist) {
                let _sids = JSON.parse(JSON.stringify(storesForComparisonTwo));
                delete _sids[fidsOfSelectedSids.sid];
                setStoresForComparisonTwo(_sids);
                let _option = store2.slice();
                let newOption = _option.filter((item) => { return item._id !== fidsOfSelectedSids.sid })
                setStore2(newOption);
            }
            setComparisonFids2(_fids);

        }
        else {
            setFloor2({ _id: floor._id, name: floor.floor_label })

        }
    }


    const selectByBranchFilter = (e) => {
        setSelection(e._id);
        var locations = options.filter(store => e.filter.map(row => row.value).indexOf(store._id) >= 0);
        setOption(locations);
    }

    const selectByDateFilter = (e) => {
        setDateSelection(e.id);
        setSelectedDayRange({ from: e.filter.from, to: e.filter.to });
    }


    const selectByDateFilterTwo = (e) => {
        setDateSelectionTwo(e.id);
        setSelectedDayRangeTwo({ from: e.filter.from, to: e.filter.to });
    }


    const updateFilter = async (options) => {

        let _option = []
        let sids = [];
        let fids = [];
        let ex_fid = [];
        if (options.length) {

            let storeObject = await getStoresObject(options)

            sids = storeObject.sids;
            fids = storeObject.includedFids;
            ex_fid = storeObject.exludedFids;
        }
        else {

            let storeObject = await getStoresObject(option)
            sids = storeObject.sids;
            fids = storeObject.includedFids;
            ex_fid = storeObject.exludedFids;
        }




        let selected;
        if (props.type != "default") {
            selected = { floorName: floorId?.name, storeName: storeName, from: await getDateString(selectedDayRange?.from, "ll"), to: await getDateString(selectedDayRange?.to, "ll") }
        }
        var start, end;
        start = await getStartDate(selectedDayRange?.from)
        end = await getEndDate(selectedDayRange?.to)
        var filter = { EMP: '0', start1: timeFrom, end1: timeTo, start: start, end: end, sid: sids, type: "d", fid: fids, selected: selected, ex_fid: ex_fid };
        props.updateFilter(filter)
        setShowFilters(false)
    }


    const updateFilterForOnlyCalendar = async () => {

        let _endDate;
        let _startDate;
        let dateDifference = getDifference(selectedDayRange.from, selectedDayRange.to);
        if (props.singleDate) {
            if (dateDifference === 0) {
                _endDate = await getEndDate(selectedDayRange?.to);
                _startDate = await getStartDate(selectedDayRange?.from)
            }
            else {
                _endDate = await getEndDate(defaultDate)
                _startDate = await getStartDate(defaultDate)
                setSelectedDayRange({ from: defaultDate, to: defaultDate })
            }

        }
        else {
            _endDate = await getEndDate(selectedDayRange?.to);
            _startDate = await getStartDate(selectedDayRange?.from)
        }



        var filter = { start: _startDate, end: _endDate, EMP: '0', }
        props.updateFilter(filter);
        setShowFilters(false)


    }


    const updateFilterForGold = async () => {
        var _option = [];
        option?.map((row) => {
            _option.push(row.value);
        })
        var _floorId;

        if (floorId === null) {
            _floorId = { _id: option[0]?.floors[0]?._id, name: option[0]?.floors[0]?.floor_label }
        }
        else {
            _floorId = floorId;
        }
        let selected;

        if (props.type != "default") {
            selected = { floorName: floorId?.name, storeName: storeName, from: await getDateString(selectedDayRange?.from, "ll"), to: await getDateString(selectedDayRange?.to, "ll") }

        }

        let start = await getStartDate(selectedDayRange?.from)
        let end = await getEndDate(selectedDayRange?.to)



        var filter = {
            start: start,
            end: end,
            sid: _option[0],
            type: "d",
            fid: props.pageTitle === "Traffic" ? [_floorId?._id] : _floorId?._id,
            EMP: '0',
        };
        if (props.pageTitle === "Categories") {
            delete filter["type"];
        }
        console.log('heatmap filter is', filter);
        props.updateFilter(filter)
        setShowFilters(false)

    }


    const updateFilterForGallery = async () => {
        var _option = [];
        option?.map((row) => {
            _option.push(row.value);
        })
        var _floorId;

        if (floorId === null) {
            _floorId = { _id: option[0]?.floors[0]?._id, name: option[0]?.floors[0]?.floor_label }
        }
        else {
            _floorId = floorId;
        }
        let selected;

        if (props.type != "default") {
            selected = { floorName: floorId?.name, storeName: storeName, from: await getDateString(selectedDayRange?.from, "ll"), to: await getDateString(selectedDayRange?.to, "ll") }

        }

        let start = await getStartDate(selectedDayRange?.from)
        let end = await getEndDate(selectedDayRange?.to)



        var filter = {
            start: start,
            end: end,
            sid: _option[0],
            type: "d",
            fid: props.pageTitle === "Traffic" ? [_floorId?._id] : _floorId?._id,
            G: genderKeys,
            EMP: staffKeys

        };

        if (props.pageTitle === "Categories") {
            delete filter["type"];
        }
        props.updateFilter(filter)
        setShowFilters(false)

    }

    const updateFilterForComparison = async () => {
        let _sid1;
        let _sid2;
        let store2Name;
        let store1Name;


        if (typeof store2 !== undefined && store2 && store2?.length > 1) {
            _sid2 = await getSids(store2);
            store2Name = store2[0].label + " + " + `${store2.length - 1}`;

        }
        else if (typeof store2 !== undefined && store2 && store2?.length === 1) {
            _sid2 = await getSids(store2);
            store2Name = store2[0].label

        }
        else {
            if (options.length > 1 && options[1] !== undefined && options[1]) {
                _sid2 = [options[1]._id]
                store2Name = options[1].label
            }
            else {
                _sid2 = [options[0]._id]
                store2Name = options[0].label
            }

        }



        if (typeof option === 'object' && option?.length > 1 && optionLength) {
            _sid1 = [options[0]._id]
            store1Name = options[0].label

        }
        else if (typeof option === 'object' && option?.length > 1) {
            _sid1 = await getSids(option);
            store1Name = option[0].label + " + " + `${option.length - 1}`

        }
        else if (option.length === 1) {
            _sid1 = await getSids(option)
            store1Name = option[0].label

        }
        else {
            _sid1 = [options[0]._id]
            store1Name = options[0].label

        }
        setStoreName1(store1Name)
        setStoreNametwo(store2Name)



        var type;
        var selected;
        let _fids1;
        let _fids2;
        if (comparisonFids1 == undefined && !comparisonFids1 && comparisonFids2 == undefined && !comparisonFids2) {
            let fid1;
            let fid2;

            fid1 = getFidsFormat2(options[0]);


            if (options.length > 1 && options[1] !== undefined && options[1]) {
                fid2 = await getFidsFormat2(options[1]);
            }
            else {
                fid2 = await getFidsFormat2(options[0]);
            }

            _fids1 = fid1.array;
            _fids2 = fid2.array;

            setComparisonFids1(fid1.object)
            setComparisonFids2(fid2.object);
        }
        if (comparisonFids1 !== undefined && comparisonFids1 && comparisonFids2 !== undefined && comparisonFids2) {

            _fids1 = Object.keys(comparisonFids1).map((key) => { return key });
            _fids2 = Object.keys(comparisonFids2).map((key) => { return key });

        }



        var startTime = ["00:00", "00:00"]
        var endTime = ["23:59", "23:59"]
        var startDate1 = await getStartDate(selectedDayRange?.from)
        var endDate1 = await getEndDate(selectedDayRange?.to)
        var startDate2 = await getStartDate(selectedDayRangeTwo?.from)
        var endDate2 = await getEndDate(selectedDayRangeTwo?.to)
        selected = {
            store1: store1Name,
            store2: store2Name,
            group1: option,
            group2: store2,
            from1: await getDateString(selectedDayRange?.from, "ll"),
            to1: await getDateString(selectedDayRange?.to, "ll"),
            from2: await getDateString(selectedDayRangeTwo?.from, "ll"),
            to2: await getDateString(selectedDayRangeTwo?.to, "ll")
        }


        var filter = {
            sd: [startDate1, startDate2],
            ed: [endDate1, endDate2],
            st: startTime, et: endTime,
            sid1: _sid1,
            sid2: _sid2,
            fid1: _fids1,
            fid2: _fids2,
            t: "d",
            selected: selected,
            type: (_sid1.length === 1 ? _sid2.length === 1 ? _sid1[0] === _sid2[0] ? "single" : "multiple" : "multiple" : "multiple"),
            EMP: '0',
        };

        props.updateFilter(filter)
        setShowFilters(false)
    }


    const updateFilterForNewInsights = async () => {
        setShowFilters(false)
        var _option = option?.map((row) => {
            return row.value;
        })
        var type = 'daily';
        let _startDate;
        let _endDate;
        var selected;
        if (selectType === 'insightsyearly') {
            if (insightsYearlyDate?.value) {
                _startDate = moment(insightsYearlyDate?.value, "YYYY").startOf("year").startOf("day")
                _endDate = moment(insightsYearlyDate?.value, "YYYY").endOf("year").endOf("day");
                type = "yearly"
                selected = { storeName: storeName, from: await getDateString(_startDate, "ll"), to: await getDateString(_endDate, "ll") }
            }
        }
        else {
            _endDate = await getEndDate(selectedDayRange?.to)
            _startDate = await getStartDate(selectedDayRange?.from)
            selected = { storeName: storeName, from: await getDateString(selectedDayRange?.from, "ll"), to: await getDateString(selectedDayRange?.to, "ll") }
        }
        var sids = [];
        var labels = [];
        if (sidForInsights != undefined && sidForInsights) {

            let selectedSids = await convertItemIntoArray(sidForInsights)
            var selectedStore = options.filter((item) => selectedSids.includes(item.label));

            selectedStore.map(item => {
                sids.push(item._id);
                labels.push({ label: item.label, value: item._id })
            });
        }
        var filter = {
            saveFilter: saveInsightFlag,
            sid: sids,
            labels: labels,
            end: _endDate,
            start: _startDate,
            type: selectType === 'insightsyearly' ? 'yearly' : selectType === 'monthly' ? 'monthly' : selectType === 'weekly' ? 'weekly' : 'daily',
            feature: selectedFeature,
            EMP: '0',
        };
        props.updateFilter(filter);

        setShowFilters(false)
    }


    const updateFilterForPremium = async () => {
        var _option = await getSids(option)
        let _startDate;
        let _endDate;
        let type;
        var selected
        if (selectType === 'insightsyearly') {
            if (insightsYearlyDate?.value) {
                _startDate = moment(insightsYearlyDate?.value, "YYYY").startOf("year").startOf("day")
                _endDate = moment(insightsYearlyDate?.value, "YYYY").endOf("year").endOf("day");
                type = "yearly"
                selected = { storeName: storeName, from: await getDateString(_startDate, "ll"), to: await getDateString(_endDate, "ll") }
            }
        }
        else {
            _endDate = await getEndDate(selectedDayRange?.to)
            _startDate = await getStartDate(selectedDayRange?.from)
            selected = { storeName: storeName, from: await getDateString(selectedDayRange?.from, "ll"), to: await getDateString(selectedDayRange?.to, "ll") }
        }

        var filter;
        if (props.pageTitle === 'Conversion') {
            filter = {
                sid: _option[0],
                end: _endDate,
                start: _startDate,
                fid: [floorId._id],
                storeName: storeName,
                EMP: '0',
            }
        }
        else {
            filter = { sid: _option[0], end: _endDate, start: _startDate, type: type ? type : selectType === "weekly" ? "weekly" : "daily", EMP: '0', };
        }
        props.updateFilter(filter);
        setShowFilters(false)
    }


    const updateFilterForSilver = async (store) => {
        let _option;

        if (store !== undefined && store) {
            _option = await getSids(store)
        }
        else {
            _option = await getSids(option)
        }

        let _endDate;
        let _startDate;
        let dateDifference = await getDifference(selectedDayRange.from, selectedDayRange.to);
        if (dateDifference === 0) {
            _endDate = await getEndDate(selectedDayRange?.to);
            _startDate = await getStartDate(selectedDayRange?.from)
        }
        else {
            _endDate = await getEndDate(defaultDate)
            _startDate = await getStartDate(defaultDate)
            setSelectedDayRange({ from: defaultDate, to: defaultDate })
        }
        let filter = { sid: _option[0], end: _endDate, start: _startDate, EMP: '0', };
        props.updateFilter(filter);
        setShowFilters(false)

    }


    const updateFilterForBrown = async (option) => {
        var _option = await getSids(option);
        let allStoresInfo = await getStoresObject(option);

        let sids = allStoresInfo.sids;
        let fids = allStoresInfo.includedFids;


        let _startDate;
        let _endDate;
        let type;
        var selected
        if (selectType === 'insightsyearly') {
            if (insightsYearlyDate?.value) {
                _startDate = moment(insightsYearlyDate?.value, "YYYY").startOf("year").startOf("day")
                _endDate = moment(insightsYearlyDate?.value, "YYYY").endOf("year").endOf("day");
                type = "yearly"
                selected = { storeName: storeName, from: await getDateString(_startDate, "ll"), to: await getDateString(_endDate, "ll"), range: insightsYearlyDate?.value }
            }
        }
        else {
            _endDate = await getEndDate(selectedDayRange?.to);
            _startDate = await getStartDate(selectedDayRange?.from)
            selected = { storeName: storeName, from: await getDateString(selectedDayRange?.from, "ll"), to: await getDateString(selectedDayRange?.to, "ll"), selectedDayRange: selectedDayRange }
            type = selectType

        }

        var filter = {
            sid: sids,
            end: _endDate,
            start: _startDate,
            fid: fids,
            storeName: storeName,
            start1: timeFrom,
            end1: timeTo,
            type: type,
            range: new Date(_startDate).getFullYear(),
            selected: selected,
            dispatch,
            stores: option,
            EMP: '0',
        }
        props.updateFilter(filter);
        setShowFilters(false)

    }

    const updateFilterForEight = async (paramOptions) => {



        if (paramOptions.length > 0) {

            const req_id = paramOptions.map((item) => {
                const store_id = item._id
                return store_id
            })

            let filter = {
                start: await getStartDate(selectedDayRange.from),
                end: await getEndDate(selectedDayRange.to),
                sid: req_id,
                EMP: '0',
            }
            props.updateFilter(filter)
        }
        else {
            var sids = [];
            var labels = [];
            if (sidForInsights != undefined && sidForInsights) {

                let selectedSids = await convertItemIntoArray(sidForInsights)

                var selectedStore = options.filter((item) => selectedSids.includes(item.label));


                selectedStore.map(item => {
                    sids.push(item._id);
                    labels.push({ label: item.label, value: item._id })
                });
            }

            let filter = {
                start: await getStartDate(selectedDayRange.from),
                end: await getEndDate(selectedDayRange.to),
                sid: sids,
                EMP: '0',
            }
            props.updateFilter(filter)

        }

        setShowFilters(false);


    }

    const updateFilterForVisitor = async (item) => {
        let filter;
        if (item !== undefined && item) {
            filter = {
                start: await getStartDate(selectedDayRange.from),
                end: await getEndDate(selectedDayRange.to),
                sid: await convertItemIntoArray(item.sid),
                fid: await convertItemIntoArray(item.fid),
                option: item.option,
                selected: { from: selectedDayRange.from, to: selectedDayRange.to },
                singleDay: await getDifference(selectedDayRange.from, selectedDayRange.to),
                type: 'd',
                EMP: trafficType,

            }
        }
        else {
            filter = {
                start: await getStartDate(selectedDayRange.from),
                end: await getEndDate(selectedDayRange.to),
                sid: await convertItemIntoArray(storesForComparison),
                fid: await convertItemIntoArray(comparisonFids1),
                selected: { from: selectedDayRange.from, to: selectedDayRange.to },
                singleDay: await getDifference(selectedDayRange.from, selectedDayRange.to),
                option: option,
                type: 'd',
                EMP: trafficType,
            }

        }
        props.updateFilter(filter)
        setShowFilters(false)

    }


    const [years, setYears] = useState()
    const yearlyInsights = (yea) => {
        const year = new Array(12).fill(0).map((val, index) => {
            return { value: new Date().getFullYear() - index - (yearRnage), label: new Date().getFullYear() - index - (yearRnage) }
        });
        setYears(year)
    }


    const createDateFilters = () => {
        var dateFilters = {};
        let today = moment(new Date());
        let yesterday = moment(new Date()).subtract(1, "days");
        let thisWeek = moment(new Date()).startOf("week");
        let lastWeek = moment(new Date()).subtract(1, "week").startOf("week");
        let thisMonth = moment(new Date()).startOf("month");
        let lastMonth = moment(new Date()).subtract(1, "month").startOf("month");
        let thisYear = moment(new Date()).startOf("year");
        let lastYear = moment(new Date()).subtract(1, "year").startOf("year");
        dateFilters["today"] = { type: "day", label: "Today", from: getDateObject(today), to: getDateObject(today) };
        dateFilters["yesterday"] = { type: "day", label: "Yesterday", from: getDateObject(yesterday), to: getDateObject(yesterday) };
        dateFilters["thisWeek"] = { type: "week", label: "Current Week", from: getDateObject(thisWeek), to: getDateObject(today) };
        dateFilters["lastWeek"] = { type: "week", label: "Previous Week", from: getDateObject(lastWeek), to: getDateObject(lastWeek.endOf("week")) };
        dateFilters["thisMonth"] = { type: "month", label: thisMonth.format("MMMM"), from: getDateObject(thisMonth), to: getDateObject(today) };
        dateFilters["lastMonth"] = { type: "month", label: lastMonth.format("MMMM"), from: getDateObject(lastMonth), to: getDateObject(lastMonth.endOf("month")) };
        dateFilters["thisYear"] = { type: "year", label: thisYear.format("YYYY"), from: getDateObject(thisYear), to: getDateObject(today) };
        dateFilters["lastYear"] = { type: "year", label: lastYear.format("YYYY"), from: getDateObject(lastYear), to: getDateObject(lastYear.endOf("year")) };
        return dateFilters;
    }


    const getDateObject = (momentObj) => {
        return {
            year: momentObj.year(),
            month: momentObj.month() + 1,
            day: momentObj.date()
        }
    }


    const selectDateFilter = (key) => {
        setDateSelection(key);
        let date = dates[key];
        setSelectedDayRange({ from: date.from, to: date.to });
    }

    const selectDateFilterTwo = (key) => {
        setDateSelectionTwo(key);
        let date = dates[key];
        setSelectedDayRangeTwo({ from: date.from, to: date.to })
    }


    const defaultSettingOfBranch = () => {
        setCustomSelection(false);
        setIsEditBranch(false);
        setBranchFilter(false);
        setBranchLabel();
        setOption(options);
        setEditDateFilterFlag(false);
    }


    const selectStoreForInsights = (event) => {
        var checkedList = { ...sidForInsights };
        if (event.target.checked) {
            let _sids = {};

            _sids[event.target.name] = true;
            if (sidForInsights == null) {
                checkedList = _sids;

            }
            else {
                checkedList = { ...checkedList, ..._sids }
            }
        }
        else {

            delete checkedList[event.target.name];
        }
        setSidsForInsights(checkedList)
    }


    const selectSingleStoreForInsights = (event) => {

        if (event.target.checked) {
            let _sids = {};
            _sids[event.target.name] = true;
            setSidsForInsights(_sids)
        }
        else {
            setSidsForInsights({})
        }

    }


    const setAllInsightsSidsTrue = (event) => {


        var checkedList = { ...sidForInsights };
        if (event === undefined) {
            let checkedItem = options.map((item) => {
                checkedList[item.label] = true
            })
            setSidsForInsights(checkedList)
        }


        if (event.target.checked) {
            let checkedItem = options.map((item) => {
                checkedList[item.label] = true
            })
            setSidsForInsights(checkedList)
            selectAllStoreFlag(true)

        }
        else {
            setSidsForInsights(false)
            selectAllStoreFlag(false)

        }


    }



    const selectByStoreForSummary = (store) => {
        let _option = [...option];
        let _ids = option.map((item) => { return item._id })
        if (_ids.includes(store._id)) {
            let _optio = _option.filter((item) => item._id != store._id);
            let stores = {}
            _optio.forEach((item) => {
                return stores[item._id] = item._id
            })
            setSummaryStores(stores);
            setOption(_optio)
        }
        else {
            let optio = [..._option, ...[store]]
            let stores = {}

            optio.forEach((item) => {
                return stores[item._id] = item._id
            })
            setSummaryStores(stores);
            setOption(optio)
        }
    }


    const editDateFilter = (item) => {
        if (item.type === "date") {
            if (item.filter.range === "single") {
                setSelectedDayRange(item.filter);
                setDateLabel(item.label);
                setDateFilter(true);
                setCurrentDate(item)
                setEditDateFilterFlag(true)
                setIsEditBranch(false)

            }
        }
        else {
            setBranchFilter(true)
            setOption(item.filter)
            setCurrentDate(item)
            setDeleteFilters(false)
            setEditDateFilterFlag(false)
            setCustomSelection(true)
            setIsEditBranch(true)
            setBranchLabel(item.label)
        }


    }




    useEffect(() => {
        setShowFilters(false);
        setCustomSelection(false)

    }, [showFilterByRedux])

    useEffect(() => {
        if (!loaded) {
            let requestData = {
                dispatch: dispatch
            };
            listFilterRequest(requestData)
        }
    }, [loaded])


    const fetchFirst = async () => {
        if (props.type === 'default') {
            setSelection("all");
            setOption(options)
            setFloor({ _id: options[0]?.floors[0]?._id, name: options[0]?.floors[0]?.floor_label });
            updateFilter(options);
        }
        if (props.type === "brown") {

            let currentStore = options.filter((item) => { return removeSpaceAndCamelCase(item.label) === removeSpaceAndCamelCase(props.updateStoreNameURL) })[0]
            if (currentStore !== undefined && currentStore) {
                setSelection(currentStore._id);
                setStoreName(currentStore.label);
                setOption([currentStore]);
                let obj1 = {};
                obj1[currentStore._id] = currentStore._id;
                setStoresForComparison(obj1)
                updateFilterForBrown([currentStore]);
            }
        }
        if (props.type === 'silver') {
            let currentStore = options[0];
            if (currentStore !== undefined && currentStore) {
                setSelection(currentStore._id);
                setStoreName(currentStore.label);
                setOption([currentStore]);
                updateFilterForSilver([currentStore]);
            }
        }
        if (props.type === 'insights') {
            setOption([options[0]]);
            setSelection(options[0]._id);
            setStoreName(options[0].label);
            setSidsForInsights()
            // updateFilterForNewInsights();
        }
        if (props.type === "calendar") {
            updateFilterForOnlyCalendar()
        }
        if (props.type === 'compareMultiple' || props.type === "visitor") {
            let _optionn = [options[0]];
            setOption(_optionn);
            setStoreName()
            let fid2 = {};
            let obj1 = {};
            let fid1 = {};
            let obj = {}


            if (options.length > 1 && options[1] !== undefined && options[1]) {
                setSelection2(options[1]._id);
                setStoreName2(options[1].label)
                setStore2([options[1]])
                fid2 = await getFidsFormat2(options[1]).object;
                obj[options[1]._id] = options[1]._id

            }
            else {
                setSelection2(options[0]._id);
                setStoreName2(options[0].label)
                setStore2([options[0]])
                obj[options[0]._id] = options[0]._id;
                fid2 = await getFidsFormat2(options[0]).object;
            }


            setStoresForComparisonTwo(obj)
            obj1[options[0]._id] = options[0]._id;
            fid1 = await getFidsFormat2(options[0]).object;

            setComparisonFids1(fid1)
            setComparisonFids2(fid2);
            setStoresForComparison(obj1)
            if (props.type === "compareMultiple") {
                updateFilterForComparison();
            }
            else {
                updateFilterForVisitor({ option: _optionn, fid: fid1, sid: obj1 });
            }

        }

        if (props.type === "premium") {
            updateFilterForPremium();
            setOption([options[0]]);
            setSelection(options[0]._id);
            setStoreName(options[0].label);
        }


        if (props.type === 'gold' || props.type === 'gallery') {
            let currentStore;
            if (selection === null || selection.length < 23) {
                currentStore = options[0];
                setOption([currentStore])
                setFloor({ _id: currentStore?.floors[0]?._id, name: currentStore?.floors[0]?.floor_label });
                setSelection(currentStore._id);
                setStoreName(currentStore.label);

            }
            else {
                currentStore = options.filter((item) => { return item._id === selection })[0];
                setOption([currentStore])
                setStoreName(currentStore.label);
                setFloor({ _id: currentStore?.floors[0]?._id, name: currentStore?.floors[0]?.floor_label })

            }
            setTimeout(() => {
                if (props.type === 'gold') {
                    updateFilterForGold()

                } else {
                    updateFilterForGallery()

                }
            }, 400);
        }

        if (props.type === 'store_activity') {
            updateFilterForEight(options)
            setAllInsightsSidsTrue({ target: { checked: false } })
        }

    }

    useEffect(() => {
        fetchFirst()

    }, [])

    useEffect(() => {
        if (feature) {
            let allOptions = [...feature.footfall, ...feature.sales]
            setUserFeature(allOptions)
        }
    }, [feature])

    useEffect(() => {
        setSelectType('daily')
    }, [props.currentView])


    useEffect(() => {
        yearlyInsights(yearRnage)
    }, [yearRnage])

    useEffect(() => {
        let _currentDate = new Date();
        if (dateSelection === 'today') {
            if (_currentDate.getDate() != selectedDayRange.from.day || _currentDate.getMonth() + 1 != selectedDayRange.from.month || _currentDate.getFullYear() != selectedDayRange.from.year) {
                setDateSelection('')
            }
        }
    }, [selectedDayRange])

    /* usefect whose dependency is id is running only for conversion page */

    useEffect(() => {
        if (props.pageTitle === "Conversion") {
            let currentStore = options?.filter((item) => {
                return item._id === id
            })
            setStoreName(currentStore[0]?.label);
            setSelection(currentStore[0]?._id);
            setSingleCity(currentStore[0][classifyFlag].toLowerCase())
            if (currentStore[0]?.floors.length) {
                setFloor({ _id: currentStore[0]?.floors[0]?._id, name: currentStore[0]?.floors[0]?.floor_label })
            }
            setOption(currentStore);

            let _endDate = moment(new Date(selectedDayRange.to.year, selectedDayRange.to.month - 1, selectedDayRange.to.day)).endOf("day");
            let _startDate = moment(new Date(selectedDayRange.from.year, selectedDayRange.from.month - 1, selectedDayRange.from.day)).startOf("day");

            if (props.pageTitle === 'Conversion') {
                var filter = {
                    sid: currentStore[0]?._id,
                    end: _endDate,
                    start: _startDate,
                    fid: [currentStore[0].floors[0]._id],
                    storeName: currentStore[0]?.label,
                    EMP: '0',
                }
                props.updateFilter(filter)
            }
        }
    }, [id])

    useEffect(() => {
        if (!showFilters) {
            if (isFirstRender.current) {
                isFirstRender.current = false;
                return; // 👈️ return early if first render
            }

            else {
                if (props.type === 'default') {
                    updateFilter([])
                }
                else if (props.type === 'brown') {
                    updateFilterForBrown(option)
                }
                else if (props.type === 'gold') {
                    updateFilterForGold()
                }
                else if (props.type === 'gallery') {
                    updateFilterForGallery()

                }
                else if (props.type === 'store_activity') {
                    updateFilterForEight(sidForInsights)
                }
                else if (props.type === 'silver') {
                    updateFilterForSilver(option)
                }
                else if (props.type === "visitor") {
                    updateFilterForVisitor()

                }
            }
        }

    }, [selectedDayRange])


    useEffect(() => {

        if (props.currentView === "basicTrends") {
            if (Object.keys(sidForInsights ? sidForInsights : {}).length > 1) {
                setSidsForInsights()
            }
        }

    }, [props.currentView])


    useEffect(() => {
        if (!customSelection) {
            setEditDateFilterFlag(false)
            setDateFilter(false)

        }

    }, [customSelection])


    useEffect(() => {
        if (classifyFlag === 'city') {
            setCities(classifyByCity)
        }
        else if (classifyFlag === 'grade') {
            setCities(classifyByClasses)
        }

    }, [classifyFlag])


    useEffect(() => {
        if (props.type === 'compareMultiple') {
            // setOption(_option);
        }

    }, [props.type])




    //  constants consists of list and chunks

    const branchesMenuCustom = branchFilters.map((val) => {
        return (
            <ListGroupItem key={'dates-' + val._id} className={selection == val._id ? 'active' : 'ListGroupItem'} tag="a" href="#" onClick={(e) => { e.detail == 2 ? setCustomSelection(true) : selectByBranchFilter(val) }}>
                {val.label}
            </ListGroupItem>
        )
    })


    const storeForInsights = user.access.map((item, index) => {
        let obj = { label: item.label, sid: item._id };
        let flag = sidForInsights !== undefined && sidForInsights && sidForInsights[captialLetterOfEachWord(obj.label)];

        return (
            <ListGroupItem className='d-flex' key={index}>

                <input type='checkbox' className='m-2' name={item.label} value={item._id} onChange={(event) => props.currentView === "basicTrends" ? selectSingleStoreForInsights(event) : selectStoreForInsights(event)}
                    // defaultChecked={flag}
                    checked={flag === undefined ? false : flag}
                />
                <label className='m-1' > {obj.label}</label>
            </ListGroupItem>
        )

    })


    const onlyStores = Object.keys(cities).map((key) => {
        return (
            <ListGroupItem key={'city-' + key}
                className='white'
            >
                <div onClick={(e) => { selectByclassifyFlag(key); }} className={'cityItem p-2'}
                    style={{ cursor: 'pointer', borderTop: "none", borderLeft: "none", borderRight: 'none' }}  >
                    <span key={'city-' + key} tag="a" href="#" >
                        {cities[key]}
                    </span>
                </div>

                {options.map((stores, index) => {
                    return (
                        stores[classifyFlag] === cities[key] &&
                        <>
                            <div
                                key={key + index}
                                className={"px-3 pb-1 "}
                                style={{ cursor: "pointer", }} >
                                <div className={(storesForComparison != undefined && storesForComparison && storesForComparison[stores._id]) && stores._id === storesForComparison[stores._id] ? 'storeItemActive p-1' : 'storeItem p-1 '} onClick={(e) => { selectByStore(stores) }} >
                                    <span key={stores._id} tag='a' href="#" >
                                        {stores.label}
                                    </span>
                                </div>
                            </div>
                            {singleCity === key && (props.pageTitle != "Comparisons" && props.pageTitle != "Insights" && props.pageTitle != 'Conversion' && props.pageTitle != "Employee_Activity" && props.type !== "brown") && options[index].floors.length > 1 && options[index].floors.map((floor, index1) => {
                                return (
                                    <div className={selection === stores._id ? 'px-5 my-2 ' : ' hide'} style={{ cursor: "pointer", }} >
                                        <div className={floorId?.name === floor.floor_label ? 'storeItemActive p-1' : "p-1 storeItem"} onClick={(e) => { selectByfloor(floor) }} >
                                            {floor.floor_label}
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </>
                    )
                })}
            </ListGroupItem>
        )
    })


    const listOfStores = <ListOfStores
        options={options}
        citiesOrClass={cities}
        setCustomSelection={(e) => setCustomSelection(e)}
        selectByCity={(e) => selectByCity(e)}
        selection={selection}
        classifyFlag={classifyFlag}
        summaryStores={summaryStores}
        selectByStoreForSummary={(e) => selectByStoreForSummary(e)}
    />


    const comparisonStores1 = Object.keys(cities).map((key) => {
        return (
            <ListGroupItem key={'city-' + key} className='white' >
                <div onClick={(e) => { selectByclassifyFlag(key); }} className={'cityItem p-2'}
                    //  className={singleCity === key ? 'active' : 'white'}
                    style={{ cursor: 'pointer', borderTop: "none", borderLeft: "none", borderRight: 'none' }}  >
                    <span key={'city-' + key} tag="a" href="#" >
                        {cities[key]}
                    </span>
                </div>
                {options.map((stores, index) => {
                    return (
                        stores[classifyFlag] === cities[key] &&
                        <>
                            <div
                                className={singleCity === key ? "px-3 pb-1" : 'px-3 pb-1'} style={{ cursor: "pointer", }} >

                                <div
                                    className={((storesForComparison != undefined && storesForComparison && storesForComparison[stores._id]) && stores._id === storesForComparison[stores._id] ? 'storeItemActive p-1' : 'storeItem p-1 ')}

                                    onClick={(e) => { selectByStore(stores) }} >
                                    <span key={stores._id} tag='a' href="#" >
                                        {stores.label}
                                    </span>
                                </div>
                            </div>
                            {options[index].floors.length > 1 && options[index].floors.map((floor, index1) => {

                                return (
                                    <div key={index1} className={(storesForComparison !== undefined && storesForComparison && stores._id in storesForComparison) ? 'fit-content my-1 ' : 'hide'} style={{ cursor: "pointer", }} >
                                        <div
                                            className={((comparisonFids1 != undefined && comparisonFids1 && comparisonFids1[floor._id])
                                                && floor._id === comparisonFids1[floor._id] ? 'storeItemActive p-1' : 'storeItem p-1 ')}

                                            //  className={floorId?.name === floor.floor_label ? 'storeItemActive p-1' : "p-1 storeItem"} 

                                            onClick={(e) => { selectByfloor(floor) }} >
                                            {floor.floor_label}
                                        </div>
                                    </div>
                                )
                            })

                            }
                        </>
                    )
                })}
            </ListGroupItem>
        )
    })


    const comparisonStores2 = Object.keys(cities).map((key) => {
        return (
            <ListGroupItem key={'city-' + key} className='white' >
                <div onClick={(e) => { selectByclassifyFlagTwo(key); }} className={'cityItem p-2'}
                    //  className={singleCity === key ? 'active' : 'white'}
                    style={{ cursor: 'pointer', borderTop: "none", borderLeft: "none", borderRight: 'none' }}  >
                    <span key={'city-' + key} tag="a" href="#" >
                        {cities[key]}
                    </span>
                </div>
                {options.map((stores, index) => {
                    return (
                        stores[classifyFlag] === cities[key] &&
                        <>
                            <div key={index} className={singleCity2 === key ? "px-3 " : 'px-3'} style={{ cursor: "pointer", }} >

                                <div
                                    className={((storesForComparisonTwo != undefined && storesForComparisonTwo && storesForComparisonTwo[stores._id]) && stores._id === storesForComparisonTwo[stores._id] ? 'storeItemActive p-1' : 'storeItem p-1 ')}

                                    onClick={(e) => { selectByStore2(stores) }} >
                                    <span key={stores._id} tag='a' href="#" >
                                        {stores.label}
                                    </span>
                                </div>
                            </div>
                            {options[index].floors.length > 1 && options[index].floors.map((floor, index1) => {

                                return (
                                    <div key={index1} className={(storesForComparisonTwo !== undefined && storesForComparisonTwo && stores._id in storesForComparisonTwo) ? 'fit-content my-1 ' : 'hide'} style={{ cursor: "pointer" }} >
                                        <div
                                            className={((comparisonFids2 != undefined && comparisonFids2 && comparisonFids2[floor._id])
                                                && floor._id === comparisonFids2[floor._id] ? 'storeItemActive p-1' : 'storeItem p-1 ')}

                                            //  className={floorId?.name === floor.floor_label ? 'storeItemActive p-1' : "p-1 storeItem"} 

                                            onClick={(e) => { selectByfloor2(floor) }} >
                                            {floor.floor_label}
                                        </div>
                                    </div>
                                )
                            })

                            }
                        </>
                    )
                })}
            </ListGroupItem>
        )
    })

    const storesWithFloors = Object.keys(cities).map((key) => {
        return (
            <ListGroupItem key={'city-' + key} className='white' >
                {props.type !== "compareMultiple" && <div onClick={(e) => { selectBysingleCity(key); }}
                    className={'cityItem p-2'}
                    style={{ cursor: 'pointer', borderTop: "none", borderLeft: "none", borderRight: 'none' }}  >
                    <span key={'city-' + key} tag="a" href="#" >
                        {cities[key]}
                    </span>
                </div>}
                {options.map((stores, index) => {
                    return (
                        stores[classifyFlag] === cities[key] &&
                        <>
                            <div key={index} className={singleCity === key ? "px-3 pb-1 " : ' pb-1 storeHide '} style={{ cursor: "pointer", }} >

                                <div
                                    className={(storeName !== undefined && storeName) ? (storeName === stores.label ? isFloorActive === false ? '  storeItemActive p-1' : 'storeItem p-1 ' : "storeItem p-1") : ((storesForComparison != undefined && storesForComparison && storesForComparison[stores._id]) && stores._id === storesForComparison[stores._id] ? 'storeItemActive p-1' : 'storeItem p-1 ')}

                                    // className={storeName === stores.label ? isFloorActive === false ? '  storeItemActive p-1' : 'storeItem p-1 ' : "storeItem p-1"} 
                                    onClick={(e) => { selectByStore(stores) }} >
                                    <span key={stores._id} tag='a' href="#" >
                                        {stores.label}
                                    </span>
                                </div>
                            </div>

                            {
                                props.floorToShow && options[index].floors.filter((item) => { return item.showInFilter }).length > 1 && singleCity === key && (props.pageTitle != "Insights" && props.pageTitle != 'Conversion' && props.pageTitle != "Employee_Activity" && props.type != "silver") && options[index].floors.length > 1 && options[index].floors.map((floor, index1) => {
                                    {/* {singleCity === key && ( props.type === "gold" || props.type === "default" || props.type === "compareMultiple" ) && options[index].floors.length > 1 && options[index].floors.map((floor, index1) => { */ }
                                    let flag = "showInFilter" in floor ? floor.showInFilter ? true : false : true;

                                    if (flag) {
                                        return (
                                            <div key={index1} className={selection === stores._id ? 'px-5 my-2 ' : ' hide'} style={{ cursor: "pointer", }} >
                                                <div className={floorId?.name === floor.floor_label ? 'storeItemActive p-1' : "p-1 storeItem"} onClick={(e) => { selectByfloor(floor) }} >
                                                    {floor.floor_label}
                                                </div>
                                            </div>
                                        )
                                    }

                                }
                                )

                            }
                        </>
                    )
                })}
            </ListGroupItem>
        )
    })



    const dates = createDateFilters();
    const datesMenu = Object.keys(dates).map((key) => {
        if (props.singleDate) {
            if (getDifference(dates[key].from, dates[key].to) === 0) {
                return (
                    <ListGroupItem key={'dates-' + key} className={dateSelection == key ? 'active' : ''} tag="a" href="#" onClick={() => selectDateFilter(key)}>
                        {dates[key].label}
                    </ListGroupItem>
                )
            }
        }
        else {
            return (
                <ListGroupItem key={'dates-' + key} className={dateSelection == key ? 'active' : ''} tag="a" href="#" onClick={() => selectDateFilter(key)}>
                    {dates[key].label}
                </ListGroupItem>
            )
        }

    })


    const datesMenuTwo = Object.keys(dates).map((key) => {
        return (
            <ListGroupItem key={'dates-' + key} className={dateSelectionTwo == key ? 'active' : ''} tag="a" href="#" onClick={() => selectDateFilterTwo(key)}>
                {dates[key].label}
            </ListGroupItem>
        )
    })


    const singleRangeDateFilters = dateFilters.filter((val) => {
        return (val.filter.range === "single")
    })



    const datesMenuCustom = singleRangeDateFilters.map((val) => {
        if (props.type === "silver") {
            if (getDifference(val.filter.from, val.filter.to) === 0) {
                return (
                    <ListGroupItem key={'dates-' + val._id} className={dateSelection == val._id ? 'active' : ''} tag="a" href="#" onClick={() => selectByDateFilter(val)}>
                        {val.label}
                    </ListGroupItem>
                )
            }
        }
        else {
            return (
                <ListGroupItem key={'dates-' + val._id} className={dateSelection == val._id ? 'active' : ''} tag="a" href="#" onClick={() => selectByDateFilter(val)}>
                    {val.label}
                </ListGroupItem>
            )
        }


    })



    const datesMenuCustomTwo = singleRangeDateFilters.map((val) => {
        {
            return (
                <ListGroupItem key={'dates-' + val._id} className={dateSelectionTwo == val._id ? 'active' : ''} tag="a" href="#" onClick={() => selectByDateFilterTwo(val)}>
                    {val.label}
                </ListGroupItem>
            )
        }
    })


    var dateTyes = [
        { value: 'daily', label: "Daily", _id: 1 },
        { value: 'weekly', label: "Weekly", _id: 2 },
        { value: "monthly", label: "Monthly", _id: 4 },
        { value: 'insightsyearly', label: "Yearly", _id: 3 },
    ]


    const dateTypeForInsights = dateTyes.map(val => {
        return (
            <ListGroupItem key={'dates-' + val._id} className={selectType == val.value ? 'active' : ''} tag="a" href="#" onClick={() => setSelectType(val.value)}    >
                {val.label}
            </ListGroupItem>
        )
    })

    const dropdownStyle = {
        minWidth: "250px",     // Set a minimum width for the dropdown
        padding: "0.75rem",    // Add some padding to the content
        maxWidth: "100%",      // Ensure the dropdown doesn't exceed the screen width
        width: "100%",         // Set the width to 100% to span the entire screen width
        marginTop: "5px",      // Add some top margin for spacing

        // Media query for larger screens (laptops and desktops)
        '@media (min-width: 768px)': {
            minWidth: "800px",   // Increase the minimum width for larger screens
            maxHeight: "400px",  // Adjust the maximum height for better visibility
        },

        // Media query for extra-large screens (desktops)
        '@media (min-width: 1200px)': {
            minWidth: "1100px",   // Increase the minimum width for extra-large screens
            maxHeight: "500px",  // Adjust the maximum height for better visibility
        },
    }


    const [isOpen, setIsOpen] = useState(false);
    const [isDateRnageOpen, setDateRangeOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const toggleDropdown1 = () => {
        setDateRangeOpen(!isDateRnageOpen);
    };











    return (


        <Row className='mb-4 mt-1'>
            <Col sm={2} style={{ backgroundColor: '' }} className='p-2 mt-2' >
                <h4 className='m-0 p-0 fw-normal'> {props.pageTitle} </h4>
            </Col>
            <Col className='text-end' md={10}>
                <Row>
                    <Col className='p-2 mt-2 text-end' md={10} style={{ overflow: "hidden" }} >
                        {/*****   Selected filter show in this component like selected store , floor and date range  *****/}
                        <FilterDescription
                            setSelectedDayRange={(e) => setSelectedDayRange(e)}
                            selectedDayRange={selectedDayRange}
                            option={option}
                            title={title}
                            updateStoreNameURL={props.updateStoreNameURL}
                            type={props.type}
                            floorId={floorId}
                            storeName={storeName}
                            storeOneName={storeOneName}
                            dateFilterFlag={dateFilterFlag}
                            dateSelection={dateSelection}
                            dates={dates}
                            singleRangeDateFilters={singleRangeDateFilters}
                            storeTwoName={storeTwoName}
                            dateSelectionTwo={dateSelectionTwo}
                            dateFilterFlagTwo={dateFilterFlagTwo}
                            selectedDayRangeTwo={selectedDayRangeTwo}
                            compareType={props.compareType}
                            pageTitle={props.pageTitle}
                            sidForInsights={sidForInsights}
                            floorToShow={props.floorToShow}

                        />
                    </Col>
                    <Col md={2} className='p-2'>
                        <Dropdown id="filter" className='px-1 py-2' style={{ width: "fit-content", float: "right", whiteSpace: 'nowrap' }} isOpen={showFilters} toggle={toggle} direction='down'>
                            <DropdownToggle nav className='text-dark'>
                                <Filter size="16" /> Filters
                            </DropdownToggle>
                            <DropdownMenu id="main"
                                // style={dropdownStyle}
                                className='dropdown-mobile'
                                style={{ width: props.width ? props.width : "1100px", marginTop: '10px', 'marginTop': '10px !important' }}
                            >
                                <div style={{ paddingLeft: "0.75rem", paddingRight: "0.75rem", background: "#ffffff", paddingBottom: "30px" }}>
                                    {
                                        props.type === "calendar" &&
                                        <>
                                            <Row>
                                                <Fragment>

                                                    <Col md={4} className="  pt-3 border-start border-end border-bottom border-1 ">
                                                        <div className="">
                                                            <h4 className='filter-heading'>Date Filters</h4>
                                                            <div className='scrollbar ' id="style-3" style={{ width: "100%" }}>
                                                                <ListGroup flush >
                                                                    {datesMenu}
                                                                    {datesMenuCustom}
                                                                </ListGroup>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col md={8} className='border-bottom border-1'>

                                                        <Calendar
                                                            calendarClassName='pt-0 Calendar'
                                                            value={selectedDayRange}
                                                            onChange={props.singleDate ? setDateForEmployeeActivity : setSelectedDayRangeFromCalendar}
                                                            colorPrimary={colors.primary}
                                                            colorPrimaryLight={colors.highlight}
                                                            maximumDate={getDateObject(moment(new Date()))}
                                                        />
                                                    </Col>
                                                </Fragment>

                                            </Row>
                                            <div className='text-end p-3'>

                                                {
                                                    props.type === "calendar" &&
                                                    <Button color="primary" style={{ width: "120px" }} className='' onClick={() => updateFilterForOnlyCalendar()}>Apply</Button>
                                                }
                                            </div>

                                        </>

                                    }
                                    {
                                        (customSelection || deleteFilters) &&
                                        <>
                                            <Row>
                                                {!customSelection && deleteFilters && <Col md={8} className="pt-3 border-start border-end border-bottom border-1">
                                                    {
                                                        <div>
                                                            <h4 className='filter-heading'> <ArrowLeft style={{ color: colors.primary, cursor: "pointer", marginLeft: "-5px" }} onClick={() => { setDeleteFilters(false); setDateFilter(false); setEditDateFilterFlag(false); props.type != "default" && setCustomSelection(false) }} /> Delete Filters</h4>
                                                            <Row className='mb-3 ' id='wrapper' >
                                                                <Col className="scrollbar" id="style-3">
                                                                    <ListGroup className="force-overflow">
                                                                        {
                                                                            branchFilters.length === 0 && dateFilters.length === 0 &&
                                                                            <ListGroupItem
                                                                                disabled
                                                                                href="#"
                                                                                tag="a"
                                                                            >
                                                                                No Custom Filters Available
                                                                            </ListGroupItem>
                                                                        }
                                                                        <CustomeFilterList
                                                                            list={branchFilters}
                                                                            currentDate={currentDate}
                                                                            editDateFilter={(e) => editDateFilter(e)}
                                                                            name={"Branch"}
                                                                        />

                                                                        <CustomeFilterList
                                                                            list={dateFilters}
                                                                            currentDate={currentDate}
                                                                            editDateFilter={(e) => editDateFilter(e)}
                                                                            name={"Date"}
                                                                        />
                                                                    </ListGroup>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    }
                                                </Col>}
                                                {
                                                    customSelection && !deleteFilters &&
                                                    <Col md={8} className="pt-3 border-bottom border-end border-1" style={{ maxHeight: '408px' }}>
                                                        <div>
                                                            <h4 className='filter-heading'> <ArrowLeft style={{ color: colors.primary, cursor: "pointer", marginLeft: "-5px" }} onClick={defaultSettingOfBranch} /> Customize Selection</h4>
                                                            <BranchFilter
                                                                setOption={(e) => setOption(e)}
                                                                setSelection={(e) => setSelection(e)}
                                                                setBranchLabel={(e) => setBranchLabel(e)}
                                                                option={option}
                                                                branchLabel={branchLabel}
                                                                setBranchFilter={(e) => setBranchFilter(e)}
                                                                isEditBranch={isEditBranch}
                                                                branchFilter={branchFilter}
                                                                currentDate={currentDate}
                                                                setCurrentDate={(e) => setCurrentDate(e)}
                                                                options={options}
                                                            />
                                                        </div>
                                                    </Col>
                                                }


                                                <Col md={4} className="pt-3 border-start border-end border-bottom border-1">
                                                    <div className='pt-2'>
                                                        <CalendarWithCreateDate
                                                            selectedDayRange={selectedDayRange}
                                                            setSelectedDayRange={setSelectedDayRangeFromCalendar}
                                                            maximumDate={getDateObject(moment(new Date()))}
                                                            dateFilters={dateFilters}
                                                            setDeleteFilters={(e) => setDeleteFilters(e)}
                                                            deleteFilters={deleteFilters}
                                                            setCustomSelection={(e) => setCustomSelection(e)}
                                                            isEdit={editDateFilterFlag}
                                                            setEdit={(e) => setEditDateFilterFlag(e)}
                                                            editDateLabel={dateLabel}
                                                            setCurrentDate={(e) => setCurrentDate(e)}
                                                            currentDate={currentDate}
                                                            showFooter={true}

                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                    {
                                        props.type === 'default' && !deleteFilters && !customSelection &&
                                        <>

                                            {windowWidth >= 768 &&

                                                <>
                                                    <Row>

                                                        <Col md={3} className="pt-3 border-bottom border-1 ">
                                                            <ListGroup>
                                                                <ListGroupItem className={selection == 'custom' ? 'active' : ''} tag="a" href="#" onClick={() => setCustomSelection(true)}>
                                                                    Custom Selection
                                                                </ListGroupItem>

                                                                <ListGroupItem className={selection == 'all' ? 'active' : ''} tag="a" href="#" onClick={(e) => { e.detail == 2 ? setCustomSelection(true) : selectAll() }}>
                                                                    All Stores
                                                                </ListGroupItem>
                                                                <div className='scrollbar ' id="style-3" style={{ width: "100%", overflowX: 'hidden' }}>

                                                                    {listOfStores}
                                                                    {branchesMenuCustom}
                                                                </div>

                                                            </ListGroup>

                                                        </Col>
                                                        <Col md={5} className="pt-3 border-start border-end border-bottom border-1">
                                                            <Row>

                                                                <Col md={6}
                                                                    className=" border-start border-end  border-1  ">
                                                                    <TimeRangeSlider
                                                                        heading={'Time Range'}
                                                                        timeTo={timeTo}
                                                                        timeFrom={timeFrom}
                                                                        setTimeFrom={(e) => setTimeFrom(e)}
                                                                        setTimeTo={(e) => setTimeTo(e)}
                                                                    />
                                                                </Col>
                                                                <Col md={6}>
                                                                    <h4 className='filter-heading'>Date Filters</h4>
                                                                    <div className='scrollbar ' id="style-3" style={{ width: "100%", overflowX: 'hidden' }}>
                                                                        <ListGroup>
                                                                            {datesMenu}
                                                                            {datesMenuCustom}
                                                                        </ListGroup>
                                                                    </div>

                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={4} className="pt-3 border-start border-end border-bottom border-1">
                                                            <div className='pt-2'>


                                                                <CalendarWithCreateDate
                                                                    selectedDayRange={selectedDayRange}
                                                                    setSelectedDayRange={setSelectedDayRangeFromCalendar}
                                                                    maximumDate={getDateObject(moment(new Date()))}
                                                                    dateFilters={dateFilters}
                                                                    deleteFilters={deleteFilters}
                                                                    setEdit={(e) => setEditDateFilterFlag(e)}
                                                                    setDeleteFilters={(e) => setDeleteFilters(e)}
                                                                    setCustomSelection={(e) => setCustomSelection(e)}
                                                                    isEdit={editDateFilterFlag}
                                                                    editDateLabel={dateLabel}
                                                                    setCurrentDate={(e) => setCurrentDate(e)}
                                                                    currentDate={currentDate}
                                                                    showFooter={true}

                                                                />

                                                            </div>

                                                        </Col>

                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FilterFooter
                                                                warning={'Please select at least one store'}
                                                                disabled={!option.length}
                                                                onClick={() => updateFilter([])}
                                                                search={"Search"}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </>

                                            }
                                            {windowWidth < 768 &&
                                                <div className='p-3'>
                                                    <FilterDropDown

                                                        firstTitle="Select Stores"
                                                        option={option}
                                                        selection={selection}
                                                        setCustomSelection={(e) => setCustomSelection(e)}
                                                        selectAll={selectAll}
                                                        locationList={listOfStores}
                                                        customList={branchesMenuCustom}
                                                        allLocations={true}


                                                    />

                                                    <DateRangeDropdown
                                                        calendar={<CalendarWithCreateDate
                                                            selectedDayRange={selectedDayRange}
                                                            setSelectedDayRange={setSelectedDayRangeFromCalendar}
                                                            maximumDate={getDateObject(moment(new Date()))}
                                                            dateFilters={dateFilters}
                                                            deleteFilters={deleteFilters}
                                                            setEdit={(e) => setEditDateFilterFlag(e)}
                                                            setDeleteFilters={(e) => setDeleteFilters(e)}
                                                            setCustomSelection={(e) => setCustomSelection(e)}
                                                            isEdit={editDateFilterFlag}
                                                            editDateLabel={dateLabel}
                                                            setCurrentDate={(e) => setCurrentDate(e)}
                                                            currentDate={currentDate}
                                                            showFooter={false}

                                                        />}

                                                    />


                                                    <div className='right-button'>
                                                        <Button style={{ width: "100px" }} color="primary" className='btn-primary btn' title={"Please Select at least one store"} disabled={!option.length} onClick={() => updateFilter([])}>Apply</Button>
                                                    </div>





                                                </div>



                                            }




                                        </>
                                    }
                                    {
                                        props.type === 'brown' && !deleteFilters && !customSelection &&
                                        <>
                                            {
                                                windowWidth >= 768 &&
                                                <>
                                                    <Row>

                                                        <Col md={3} className="pt-3 border-bottom border-1 ">

                                                            <StoresTypeHeader
                                                                setClassifyFlag={(e) => setClassifyFlag(e)}
                                                                classifyFlag={classifyFlag}
                                                                classifyByClasses={classifyByClasses}
                                                                showToggle={true}

                                                            />
                                                            <div className='scrollbar' id="style-3" style={{ overflowX: 'hidden', width: "100%" }}>
                                                                <ListGroup>
                                                                    <>
                                                                        <ListGroupItem
                                                                            className={selectAllStoreFlaf ? 'storeItemActive p-1' : 'storeItem p-1 '} style={{ cursor: 'pointer' }}
                                                                            defaultChecked={selectAllStoreFlaf ? false : true} onClick={(event) => selectAllStores(event)}
                                                                        >
                                                                            <input type="checkbox" id='check' className='m-2' defaultChecked={selectAllStoreFlaf} />
                                                                            <lable className='m-1' >Select All</lable>
                                                                        </ListGroupItem>
                                                                        {onlyStores}
                                                                    </>
                                                                </ListGroup>
                                                            </div>

                                                        </Col>
                                                        <Col md={5} className="pt-3 border-start border-end border-bottom border-1">
                                                            <Row>

                                                                <Col md={6}
                                                                    className=" border-start border-end  border-1  ">
                                                                    <TimeRangeSlider
                                                                        heading={'Time Range'}
                                                                        timeTo={timeTo}
                                                                        timeFrom={timeFrom}
                                                                        setTimeFrom={(e) => setTimeFrom(e)}
                                                                        setTimeTo={(e) => setTimeTo(e)}
                                                                    />
                                                                </Col>
                                                                <Col md={6}>
                                                                    <h4 className='filter-heading'>Date Filters</h4>
                                                                    <div className='scrollbar ' id="style-3" style={{ width: "100%", overflowX: 'hidden' }}>
                                                                        <ListGroup>
                                                                            {datesMenu}
                                                                            {datesMenuCustom}
                                                                        </ListGroup>
                                                                    </div>

                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={4} className="pt-3 border-start border-end border-bottom border-1">
                                                            <div className='pt-2'>


                                                                <CalendarWithCreateDate
                                                                    selectedDayRange={selectedDayRange}
                                                                    setSelectedDayRange={setSelectedDayRangeFromCalendar}
                                                                    maximumDate={getDateObject(moment(new Date()))}
                                                                    dateFilters={dateFilters}
                                                                    deleteFilters={deleteFilters}
                                                                    setEdit={(e) => setEditDateFilterFlag(e)}
                                                                    setDeleteFilters={(e) => setDeleteFilters(e)}
                                                                    setCustomSelection={(e) => setCustomSelection(e)}
                                                                    isEdit={editDateFilterFlag}
                                                                    editDateLabel={dateLabel}
                                                                    setCurrentDate={(e) => setCurrentDate(e)}
                                                                    currentDate={currentDate}
                                                                    showFooter={true}

                                                                />

                                                            </div>

                                                        </Col>

                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FilterFooter
                                                                warning={'Please select at least one store'}
                                                                disabled={!option.length}
                                                                onClick={() => updateFilterForBrown(option)}
                                                                search={"Search"}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </>
                                            }
                                            {
                                                windowWidth < 768 &&
                                                <>
                                                    <div className='p-3'>


                                                        <FilterDropDown

                                                            firstTitle="Select Stores"
                                                            option={option}
                                                            selection={selection}
                                                            setCustomSelection={(e) => setCustomSelection(e)}
                                                            selectAll={() => selectAll()}
                                                            locationList={onlyStores}
                                                            customList={[]}
                                                            filterHeader={<StoresTypeHeader
                                                                setClassifyFlag={(e) => setClassifyFlag(e)}
                                                                classifyFlag={classifyFlag}
                                                                classifyByClasses={classifyByClasses}
                                                                showToggle={true}

                                                            />}
                                                            allLocations={false}
                                                            selectAllStores={(e) => selectAllStores(e)}
                                                            selectAllStoreFlaf={selectAllStoreFlaf}
                                                            inputTypeAll={true}
                                                            header={true}
                                                        />

                                                        <DateRangeDropdown
                                                            calendar={<CalendarWithCreateDate
                                                                selectedDayRange={selectedDayRange}
                                                                setSelectedDayRange={setSelectedDayRangeFromCalendar}
                                                                maximumDate={getDateObject(moment(new Date()))}
                                                                dateFilters={dateFilters}
                                                                deleteFilters={deleteFilters}
                                                                setEdit={(e) => setEditDateFilterFlag(e)}
                                                                setDeleteFilters={(e) => setDeleteFilters(e)}
                                                                setCustomSelection={(e) => setCustomSelection(e)}
                                                                isEdit={editDateFilterFlag}
                                                                editDateLabel={dateLabel}
                                                                setCurrentDate={(e) => setCurrentDate(e)}
                                                                currentDate={currentDate}
                                                                showFooter={true}

                                                            />}
                                                        />
                                                        <div className='right-button'>
                                                            <Button
                                                                style={{ width: "100px" }}
                                                                color="primary"
                                                                className='btn-primary btn'
                                                                title={"Please select at least one store"}
                                                                disabled={!option.length}
                                                                onClick={() => updateFilterForBrown(option)}>Fetch</Button>
                                                        </div>

                                                    </div>

                                                </>
                                            }

                                        </>
                                    }
                                    {
                                        props.type === 'gold' && !deleteFilters && !customSelection &&
                                        <>

                                            {windowWidth >= 768 &&
                                                <>
                                                    <Row>
                                                        <Col md={4} className="pt-3 border-bottom border-1 "  >

                                                            <StoresTypeHeader
                                                                setClassifyFlag={(e) => setClassifyFlag(e)}
                                                                classifyFlag={classifyFlag}
                                                                classifyByClasses={classifyByClasses}
                                                                showToggle={true}

                                                            />

                                                            <div className='scrollbar' id="style-3" style={{ overflowX: 'hidden', width: "100%" }}>
                                                                <ListGroup>
                                                                    {storesWithFloors}
                                                                </ListGroup>
                                                            </div>

                                                        </Col>
                                                        <Col md={4} className="pt-3 border-start border-end border-bottom border-1">
                                                            <h4 className='filter-heading'>Date Filters</h4>
                                                            <div className='scrollbar ' id="style-3" style={{ width: "100%", overflowX: 'hidden' }}>
                                                                <ListGroup>
                                                                    {datesMenu}
                                                                    {datesMenuCustom}
                                                                </ListGroup>
                                                            </div>
                                                        </Col>
                                                        <Col md={4} className="pt-3 border-start border-end border-bottom border-1">
                                                            <div className='pt-2'>
                                                                <CalendarWithCreateDate
                                                                    selectedDayRange={selectedDayRange}
                                                                    setSelectedDayRange={setSelectedDayRangeFromCalendar}
                                                                    maximumDate={getDateObject(moment(new Date()))}
                                                                    dateFilters={dateFilters}
                                                                    deleteFilters={deleteFilters}
                                                                    setEdit={(e) => setEditDateFilterFlag(e)}
                                                                    setDeleteFilters={(e) => setDeleteFilters(e)}
                                                                    setCustomSelection={(e) => setCustomSelection(e)}
                                                                    isEdit={editDateFilterFlag}
                                                                    editDateLabel={dateLabel}
                                                                    setCurrentDate={(e) => setCurrentDate(e)}
                                                                    currentDate={currentDate}
                                                                    showFooter={true}

                                                                />
                                                            </div>

                                                        </Col>

                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FilterFooter
                                                                warning={'Please select at least one store'}
                                                                disabled={!option.length}
                                                                onClick={() => updateFilterForGold()}
                                                                search={"Search"}
                                                            />
                                                        </Col>
                                                    </Row>

                                                </>
                                            }

                                            {
                                                windowWidth < 768 &&


                                                <>
                                                    <div className='p-3 '>


                                                        <FilterDropDown

                                                            firstTitle="Select Stores"
                                                            option={option}
                                                            selection={selection}
                                                            setCustomSelection={(e) => setCustomSelection(e)}
                                                            selectAll={selectAll}
                                                            locationList={storesWithFloors}
                                                            customList={[]}
                                                            filterHeader={<StoresTypeHeader
                                                                setClassifyFlag={(e) => setClassifyFlag(e)}
                                                                classifyFlag={classifyFlag}
                                                                classifyByClasses={classifyByClasses}
                                                                showToggle={true}

                                                            />}
                                                            allLocations={false}
                                                            header={true}
                                                        />

                                                        <DateRangeDropdown

                                                            calendar={<CalendarWithCreateDate
                                                                selectedDayRange={selectedDayRange}
                                                                setSelectedDayRange={setSelectedDayRangeFromCalendar}
                                                                maximumDate={getDateObject(moment(new Date()))}
                                                                dateFilters={dateFilters}
                                                                deleteFilters={deleteFilters}
                                                                setEdit={(e) => setEditDateFilterFlag(e)}
                                                                setDeleteFilters={(e) => setDeleteFilters(e)}
                                                                setCustomSelection={(e) => setCustomSelection(e)}
                                                                isEdit={editDateFilterFlag}
                                                                editDateLabel={dateLabel}
                                                                setCurrentDate={(e) => setCurrentDate(e)}
                                                                currentDate={currentDate}
                                                                showFooter={true}

                                                            />}

                                                        />
                                                        <div className='right-button'>
                                                            <Button style={{ width: "100px" }} color="primary" className='btn-primary btn' title={"Please Select at least one location and floor"} disabled={!option.length} onClick={() => updateFilterForGold([])}>Apply</Button>
                                                        </div>
                                                    </div>
                                                </>



                                            }


                                        </>
                                    }
                                    {
                                        props.type === 'gallery' && !deleteFilters && !customSelection &&
                                        <>

                                            {windowWidth >= 768 &&
                                                <>
                                                    <Row>
                                                        <Col md={4} className="pt-3 border-bottom border-1 "  >

                                                            <StoresTypeHeader
                                                                setClassifyFlag={(e) => setClassifyFlag(e)}
                                                                classifyFlag={classifyFlag}
                                                                classifyByClasses={classifyByClasses}
                                                                showToggle={true}

                                                            />

                                                            <div className='scrollbar' id="style-3" style={{ overflowX: 'hidden', width: "100%" }}>
                                                                <ListGroup>
                                                                    {storesWithFloors}
                                                                </ListGroup>
                                                            </div>

                                                        </Col>
                                                        <Col md={2} className="pt-3 border-start border-end border-bottom border-1">
                                                            <h4 className='filter-heading'>Gender</h4>
                                                            <div className='' id="" style={{ width: "100%", overflowX: 'hidden' }}>
                                                                <ListGroup>
                                                                    <ListGroupItem
                                                                        className={genderKeys === 'all' ? 'storeItemActive p-1' : 'storeItem p-1 '} style={{ cursor: 'pointer' }}
                                                                        onClick={() => setGenderKeys('all')}>Both</ListGroupItem>
                                                                    <ListGroupItem
                                                                        className={genderKeys === '1' ? 'storeItemActive p-1' : 'storeItem p-1 '} style={{ cursor: 'pointer' }}
                                                                        onClick={() => setGenderKeys('1')}>Male</ListGroupItem>
                                                                    <ListGroupItem
                                                                        className={genderKeys === '0' ? 'storeItemActive p-1' : 'storeItem p-1 '} style={{ cursor: 'pointer' }}
                                                                        onClick={() => setGenderKeys('0')}>Female</ListGroupItem>
                                                                </ListGroup>
                                                            </div>
                                                            <h4 className='filter-heading mt-3'>Role</h4>
                                                            <div className='' id="" style={{ width: "100%", overflowX: 'hidden' }}>
                                                                <ListGroup>
                                                                    <ListGroupItem
                                                                        className={staffKeys === 'all' ? 'storeItemActive p-1' : 'storeItem p-1 '} style={{ cursor: 'pointer' }}
                                                                        defaultChecked={staffKeys === 'all' ? true : false}
                                                                        onClick={() => setStaffKeys('all')}
                                                                    >
                                                                        Both
                                                                    </ListGroupItem>
                                                                    <ListGroupItem
                                                                        className={staffKeys === '1' ? 'storeItemActive p-1' : 'storeItem p-1 '} style={{ cursor: 'pointer' }}
                                                                        onClick={() => setStaffKeys('1')}>Employee</ListGroupItem>
                                                                    <ListGroupItem
                                                                        className={staffKeys === '0' ? 'storeItemActive p-1' : 'storeItem p-1 '} style={{ cursor: 'pointer' }}
                                                                        onClick={() => setStaffKeys('0')}>Customer</ListGroupItem>
                                                                </ListGroup>
                                                            </div>
                                                        </Col>
                                                        <Col md={2} className="pt-3 border-start border-end border-bottom border-1">
                                                            <h4 className='filter-heading'>Date Filters</h4>
                                                            <div className='scrollbar ' id="style-3" style={{ width: "100%", overflowX: 'hidden' }}>
                                                                <ListGroup>
                                                                    {datesMenu}
                                                                    {datesMenuCustom}
                                                                </ListGroup>
                                                            </div>
                                                        </Col>
                                                        <Col md={4} className="pt-3 border-start border-end border-bottom border-1">
                                                            <div className='pt-2'>
                                                                <CalendarWithCreateDate
                                                                    selectedDayRange={selectedDayRange}
                                                                    setSelectedDayRange={setSelectedDayRangeFromCalendar}
                                                                    maximumDate={getDateObject(moment(new Date()))}
                                                                    dateFilters={dateFilters}
                                                                    deleteFilters={deleteFilters}
                                                                    setEdit={(e) => setEditDateFilterFlag(e)}
                                                                    setDeleteFilters={(e) => setDeleteFilters(e)}
                                                                    setCustomSelection={(e) => setCustomSelection(e)}
                                                                    isEdit={editDateFilterFlag}
                                                                    editDateLabel={dateLabel}
                                                                    setCurrentDate={(e) => setCurrentDate(e)}
                                                                    currentDate={currentDate}
                                                                    showFooter={true}

                                                                />
                                                            </div>

                                                        </Col>

                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FilterFooter
                                                                warning={'Please select at least one store'}
                                                                disabled={!option.length}
                                                                onClick={() => updateFilterForGallery()}
                                                                search={"Search"}
                                                            />
                                                        </Col>
                                                    </Row>

                                                </>
                                            }

                                            {
                                                windowWidth < 768 &&


                                                <>
                                                    <div className='p-3 '>


                                                        <FilterDropDown

                                                            firstTitle="Select Stores"
                                                            option={option}
                                                            selection={selection}
                                                            setCustomSelection={(e) => setCustomSelection(e)}
                                                            selectAll={selectAll}
                                                            locationList={storesWithFloors}
                                                            customList={[]}
                                                            filterHeader={<StoresTypeHeader
                                                                setClassifyFlag={(e) => setClassifyFlag(e)}
                                                                classifyFlag={classifyFlag}
                                                                classifyByClasses={classifyByClasses}
                                                                showToggle={true}

                                                            />}
                                                            allLocations={false}
                                                            header={true}
                                                        />

                                                        <DateRangeDropdown

                                                            calendar={<CalendarWithCreateDate
                                                                selectedDayRange={selectedDayRange}
                                                                setSelectedDayRange={setSelectedDayRangeFromCalendar}
                                                                maximumDate={getDateObject(moment(new Date()))}
                                                                dateFilters={dateFilters}
                                                                deleteFilters={deleteFilters}
                                                                setEdit={(e) => setEditDateFilterFlag(e)}
                                                                setDeleteFilters={(e) => setDeleteFilters(e)}
                                                                setCustomSelection={(e) => setCustomSelection(e)}
                                                                isEdit={editDateFilterFlag}
                                                                editDateLabel={dateLabel}
                                                                setCurrentDate={(e) => setCurrentDate(e)}
                                                                currentDate={currentDate}
                                                                showFooter={true}

                                                            />}

                                                        />
                                                        <div className='right-button'>
                                                            <Button style={{ width: "100px" }} color="primary" className='btn-primary btn' title={"Please Select at least one location and floor"} disabled={!option.length} onClick={() => updateFilterForGold([])}>Apply</Button>
                                                        </div>
                                                    </div>
                                                </>



                                            }


                                        </>
                                    }
                                    {
                                        props.type === "compareMultiple" && !deleteFilters && !customSelection &&
                                        <>
                                            {
                                                windowWidth >= 768 &&
                                                <>
                                                    <Row>
                                                        <Col md={3} className="pt-3 border-bottom border-1 ">

                                                            <Row >
                                                                <Col  >

                                                                    <div className='d-flex'>
                                                                        {Object.keys(classifyByClasses ? classifyByClasses : {}).length > 0 &&
                                                                            <div className='' style={{ marginLeft: '', marginTop: '', marginBottom: '-2.4rem' }}>
                                                                                <Row className='' style={{ paddingTop: '-3rem' }} >
                                                                                    <Col md={6} >
                                                                                        <Label htmlFor='classes' className=" filter-heading" style={{ marginTop: '-0.6rem', marginLeft: '-0.8rem' }}>Class</Label>
                                                                                    </Col>
                                                                                    <Col md={6} className='mt-1' >
                                                                                        <Label className='switch' style={{ marginTop: '-0.5rem', marginLeft: '-0.7rem' }}>
                                                                                            <input className='classInput' type='checkbox' id='classes' name='classes' defaultChecked={classifyFlag === 'grade' && true} onChange={classifyFlag === 'city' ? () => setClassifyFlag('grade') : () => setClassifyFlag('city')}></input>
                                                                                            <span className='sliderr round'></span>
                                                                                        </Label>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col style={{ marginTop: '2.4rem' }}>
                                                                    <ListGroupItem className='filter-heading'>Stores</ListGroupItem>

                                                                    <div id="style-3" className='scrollbar' style={{ width: "100%", overflowX: 'hidden' }}>
                                                                        {comparisonStores1}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>

                                                        <Col md={3} className="pt-3 border-bottom border-1 ">

                                                            <DateToggle
                                                                name="Custom Date"
                                                                value={dateFilterFlag}
                                                                setValue={(e) => setDateFilterFlag(e)}


                                                            />


                                                            <div className="border-1" style={{ marginTop: '-0.6rem' }}>

                                                                {
                                                                    dateFilterFlag &&

                                                                    <div className='scrollbar ' id="style-3" style={{ width: "100%", overflowX: 'hidden' }}>
                                                                        <ListGroup>
                                                                            {datesMenu}
                                                                            {datesMenuCustom}
                                                                        </ListGroup>
                                                                    </div>


                                                                }
                                                                {!dateFilterFlag &&
                                                                    <>
                                                                        <div className='mt-2'>
                                                                            <CalendarWithCreateDate
                                                                                selectedDayRange={selectedDayRange}
                                                                                setSelectedDayRange={setSelectedDayRangeFromCalendar}
                                                                                maximumDate={getDateObject(moment(new Date()))}
                                                                                dateFilters={dateFilters}
                                                                                deleteFilters={deleteFilters}
                                                                                setEdit={(e) => setEditDateFilterFlag(e)}
                                                                                setDeleteFilters={(e) => setDeleteFilters(e)}
                                                                                setCustomSelection={(e) => setCustomSelection(e)}
                                                                                isEdit={editDateFilterFlag}
                                                                                editDateLabel={dateLabel}
                                                                                setCurrentDate={(e) => setCurrentDate(e)}
                                                                                currentDate={currentDate}
                                                                                showFooter={false}

                                                                            />
                                                                        </div>

                                                                    </>



                                                                }
                                                            </div>
                                                        </Col>
                                                        <Col md={3} className="pt-3 border-bottom border-1 ">
                                                            <ListGroup style={{ maxHeight: "", overflowY: "auto", overflowX: 'hidden', marginTop: '2.4rem' }}>
                                                                <ListGroupItem className='filter-heading'>Stores</ListGroupItem>
                                                                <div id="style-3" className='scrollbar' style={{ width: "100%", overflowX: 'hidden', }}>
                                                                    {comparisonStores2}
                                                                </div>

                                                            </ListGroup>
                                                        </Col>
                                                        <Col md={3} className="pt-3 border-bottom border-1 ">
                                                            <DateToggle
                                                                name="Custom Date"
                                                                value={dateFilterFlagTwo}
                                                                setValue={(e) => setDateFilterFlagTwo(e)}
                                                            />
                                                            <div className="border-1" style={{ marginTop: '-0.6rem' }}>
                                                                {
                                                                    dateFilterFlagTwo &&
                                                                    <div className='scrollbar ' id="style-3" style={{ width: "100%", overflowX: 'hidden' }}>
                                                                        <ListGroup>
                                                                            {datesMenu}
                                                                            {datesMenuCustom}
                                                                        </ListGroup>
                                                                    </div>
                                                                }
                                                                {
                                                                    !dateFilterFlagTwo &&
                                                                    <div className='mt-2'>
                                                                        <CalendarWithCreateDate
                                                                            selectedDayRange={selectedDayRangeTwo}
                                                                            setSelectedDayRange={setSelectedDayRangeFromCalendarTwo}
                                                                            maximumDate={getDateObject(moment(new Date()))}
                                                                            dateFilters={dateFilters}
                                                                            deleteFilters={deleteFilters}
                                                                            setEdit={(e) => setEditDateFilterFlag(e)}
                                                                            setDeleteFilters={(e) => setDeleteFilters(e)}
                                                                            setCustomSelection={(e) => setCustomSelection(e)}
                                                                            isEdit={editDateFilterFlag}
                                                                            editDateLabel={dateLabel}
                                                                            setCurrentDate={(e) => setCurrentDate(e)}
                                                                            currentDate={currentDate}
                                                                            showFooter={false}
                                                                        />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FilterFooter
                                                                warning={((!option?.length || !store2?.length) && "Please select at least one store from each side")
                                                                    ||
                                                                    ((option?.length > 0 && store2?.length > 0 && (!Object.keys(comparisonFids1 ? comparisonFids1 : {}).length)
                                                                        || (!Object.keys(comparisonFids2 ? comparisonFids2 : {}).length)) && "Please select at least one floor from each side")}
                                                                disabled={!option.length || !Object.keys(comparisonFids1 ? comparisonFids1 : {}).length || !Object.keys(comparisonFids2 ? comparisonFids2 : {}).length}
                                                                onClick={() => updateFilterForComparison()}
                                                                search={"Compare"}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </>
                                            }
                                            {
                                                windowWidth < 768 &&
                                                <>
                                                    <div className='p-3 '>


                                                        <FilterDropDown

                                                            firstTitle="List 1 for comparison"
                                                            option={option}
                                                            selection={selection}
                                                            setCustomSelection={(e) => setCustomSelection(e)}
                                                            selectAll={selectAll}
                                                            locationList={comparisonStores1}
                                                            customList={[]}
                                                            filterHeader={<StoresTypeHeader
                                                                setClassifyFlag={(e) => setClassifyFlag(e)}
                                                                classifyFlag={classifyFlag}
                                                                classifyByClasses={classifyByClasses}
                                                                showToggle={true}

                                                            />}
                                                            allLocations={false}
                                                            header={true}
                                                        />

                                                        <DateRangeDropdown

                                                            calendar={<CalendarWithCreateDate
                                                                selectedDayRange={selectedDayRange}
                                                                setSelectedDayRange={setSelectedDayRangeFromCalendar}
                                                                maximumDate={getDateObject(moment(new Date()))}
                                                                dateFilters={dateFilters}
                                                                deleteFilters={deleteFilters}
                                                                setEdit={(e) => setEditDateFilterFlag(e)}
                                                                setDeleteFilters={(e) => setDeleteFilters(e)}
                                                                setCustomSelection={(e) => setCustomSelection(e)}
                                                                isEdit={editDateFilterFlag}
                                                                editDateLabel={dateLabel}
                                                                setCurrentDate={(e) => setCurrentDate(e)}
                                                                currentDate={currentDate}
                                                                showFooter={true}

                                                            />}

                                                        />
                                                        <FilterDropDown

                                                            firstTitle="List 2 for comparison"
                                                            option={store2}
                                                            selection={selection}
                                                            setCustomSelection={(e) => setCustomSelection(e)}
                                                            selectAll={selectAll}
                                                            locationList={comparisonStores2}
                                                            customList={[]}
                                                            filterHeader={<StoresTypeHeader
                                                                setClassifyFlag={(e) => selectByclassifyFlagTwo(e)}
                                                                classifyFlag={classifyFlag}
                                                                classifyByClasses={classifyByClasses}
                                                                showToggle={true}

                                                            />}
                                                            allLocations={false}
                                                            header={false}
                                                        />

                                                        <DateRangeDropdown

                                                            calendar={<CalendarWithCreateDate
                                                                selectedDayRange={selectedDayRangeTwo}
                                                                setSelectedDayRange={setSelectedDayRangeFromCalendarTwo}
                                                                maximumDate={getDateObject(moment(new Date()))}
                                                                dateFilters={dateFilters}
                                                                deleteFilters={deleteFilters}
                                                                setEdit={(e) => setEditDateFilterFlag(e)}
                                                                setDeleteFilters={(e) => setDeleteFilters(e)}
                                                                setCustomSelection={(e) => setCustomSelection(e)}
                                                                isEdit={editDateFilterFlag}
                                                                editDateLabel={dateLabel}
                                                                setCurrentDate={(e) => setCurrentDate(e)}
                                                                currentDate={currentDate}
                                                                showFooter={false}
                                                            />}

                                                        />
                                                        <div className='right-button'>
                                                            <Button
                                                                style={{ width: "100px" }}
                                                                color="primary"
                                                                className='btn-primary btn'
                                                                title={((!option?.length || !store2?.length) && "Please select at least one store from each side")
                                                                    ||
                                                                    ((option?.length > 0 && store2?.length > 0 && (!Object.keys(comparisonFids1 ? comparisonFids1 : {}).length)
                                                                        || (!Object.keys(comparisonFids2 ? comparisonFids2 : {}).length)) && "Please select at least one floor from each side")}

                                                                disabled={!option.length || !Object.keys(comparisonFids1 ? comparisonFids1 : {}).length || !Object.keys(comparisonFids2 ? comparisonFids2 : {}).length}
                                                                onClick={() => updateFilterForComparison()}
                                                            >
                                                                Compare
                                                            </Button>
                                                        </div>
                                                    </div>

                                                </>
                                            }

                                        </>
                                    }

                                    {
                                        props.type === 'silver' && !deleteFilters && !customSelection &&
                                        <>
                                            <Row>


                                                <Col md={4} className="pt-3 border-bottom border-1 "  >

                                                    <StoresTypeHeader
                                                        setClassifyFlag={(e) => setClassifyFlag(e)}
                                                        classifyFlag={classifyFlag}
                                                        classifyByClasses={classifyByClasses}
                                                        showToggle={false}

                                                    />

                                                    <div className='scrollbar' id="style-3" style={{ overflowX: 'hidden', width: "100%" }}>
                                                        <ListGroup>
                                                            {storesWithFloors}
                                                        </ListGroup>
                                                    </div>

                                                </Col>
                                                <Col md={4} className="pt-3 border-start border-end border-bottom border-1">
                                                    <h4 className='filter-heading'>Date Filters</h4>
                                                    <div className='scrollbar ' id="style-3" style={{ width: "100%", overflowX: 'hidden' }}>
                                                        <ListGroup>
                                                            {datesMenu}
                                                            {datesMenuCustom}
                                                        </ListGroup>
                                                    </div>
                                                </Col>
                                                <Col md={4} className="pt-3 border-start border-end border-bottom border-1">
                                                    <div className='pt-2'>
                                                        <CalendarWithCreateDate
                                                            selectedDayRange={selectedDayRange}
                                                            setSelectedDayRange={setDateForEmployeeActivity}
                                                            maximumDate={getDateObject(moment(new Date()))}
                                                            dateFilters={dateFilters}
                                                            deleteFilters={deleteFilters}
                                                            setEdit={(e) => setEditDateFilterFlag(e)}
                                                            setDeleteFilters={(e) => setDeleteFilters(e)}
                                                            setCustomSelection={(e) => setCustomSelection(e)}
                                                            isEdit={editDateFilterFlag}
                                                            editDateLabel={dateLabel}
                                                            setCurrentDate={(e) => setCurrentDate(e)}
                                                            currentDate={currentDate}
                                                            showFooter={true}

                                                        />
                                                    </div>

                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FilterFooter
                                                        warning={'Please select at least one store'}
                                                        disabled={!option.length}
                                                        onClick={() => updateFilterForSilver()}
                                                        search={"Search"}
                                                    />
                                                </Col>
                                            </Row>

                                        </>
                                    }

                                    {
                                        props.type === 'insights' && !deleteFilters && !customSelection &&
                                        <>
                                            {
                                                windowWidth >= 768 &&
                                                <>
                                                    <Row>


                                                        <Col md={4} className="pt-3 border-bottom border-1 "  >

                                                            <StoresTypeHeader
                                                                setClassifyFlag={(e) => setClassifyFlag(e)}
                                                                classifyFlag={classifyFlag}
                                                                classifyByClasses={classifyByClasses}
                                                                showToggle={false}

                                                            />

                                                            <div className='scrollbar' id="style-3" style={{ overflowX: 'hidden', width: "100%" }}>
                                                                <ListGroup>
                                                                    <ListGroupItem>
                                                                        {props.currentView !== "basicTrends" &&
                                                                            <>
                                                                                <input type="checkbox" className='m-2' onChange={(event) => setAllInsightsSidsTrue(event)} />
                                                                                <lable className='m-1'>Select All</lable>
                                                                            </>}
                                                                    </ListGroupItem>
                                                                    {storeForInsights}
                                                                </ListGroup>
                                                            </div>
                                                        </Col>
                                                        <Col md={4} className="pt-3 border-start border-end border-bottom border-1">
                                                            <h4 className='filter-heading'>Features</h4>
                                                            <div className='scrollbar ' id="style-3" style={{ width: "100%", overflowX: 'hidden' }}>
                                                                <ListGroup flush >
                                                                    {props.type === 'insights' &&
                                                                        userFeature.length > 1 && userFeature.map(item => {
                                                                            return <div className='p-2 fz-12' key={item.value}>
                                                                                <Input type='checkbox' value={item.value}
                                                                                    defaultChecked={selectedFeature === item.value ? true : false}
                                                                                    onClick={(e) => setSelectedFeature(e.target.value)} />
                                                                                <Label style={{ fontFamily: "Poppins", fontSize: 'inherit' }} className='mx-2 '>{item.label === 'Total' ? 'Total Traffic' : item.label}</Label>
                                                                            </div>
                                                                        })
                                                                    }
                                                                </ListGroup>
                                                            </div>

                                                        </Col>
                                                        <Col md={4} className="pt-3 border-start border-end border-bottom border-1">
                                                            <div className='pt-2'>
                                                                <CalendarWithCreateDate
                                                                    selectedDayRange={selectedDayRange}
                                                                    setSelectedDayRange={setSelectedDayRangeFromCalendar}
                                                                    maximumDate={getDateObject(moment(new Date()))}
                                                                    dateFilters={dateFilters}
                                                                    deleteFilters={deleteFilters}
                                                                    setEdit={(e) => setEditDateFilterFlag(e)}
                                                                    setDeleteFilters={(e) => setDeleteFilters(e)}
                                                                    setCustomSelection={(e) => setCustomSelection(e)}
                                                                    isEdit={editDateFilterFlag}
                                                                    editDateLabel={dateLabel}
                                                                    setCurrentDate={(e) => setCurrentDate(e)}
                                                                    currentDate={currentDate}
                                                                    showFooter={true}

                                                                />
                                                            </div>

                                                        </Col>

                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FilterFooter
                                                                warning={((!getLengthFlag(sidForInsights) && !selectedFeature) && "Please select one store and one feature") || ((!selectedFeature && getLengthFlag(sidForInsights)) && "Please select at least one feature") || ((!getLengthFlag(sidForInsights) && selectedFeature) && "Please select one store")}
                                                                disabled={!option.length || !Object.keys(sidForInsights ? sidForInsights : {}).length || !selectedFeature}
                                                                onClick={() => updateFilterForNewInsights()}
                                                                search={"Create"}
                                                            />
                                                        </Col>
                                                    </Row>

                                                </>
                                            }
                                            {
                                                windowWidth < 768 &&
                                                <>
                                                    <div className='p-3'>

                                                        <FilterDropDown

                                                            firstTitle="Select Stores"
                                                            option={convertItemIntoArray(sidForInsights)}
                                                            selection={selection}
                                                            setCustomSelection={(e) => setCustomSelection(e)}
                                                            selectAll={selectAll}
                                                            locationList={storeForInsights}
                                                            customList={[]}
                                                            filterHeader={<StoresTypeHeader
                                                                setClassifyFlag={(e) => setClassifyFlag(e)}
                                                                classifyFlag={classifyFlag}
                                                                classifyByClasses={classifyByClasses}
                                                                showToggle={true}

                                                            />}
                                                            allLocations={false}
                                                            selectAllStores={(e) => setAllInsightsSidsTrue(e)}
                                                            selectAllStoreFlaf={selectAllStoreFlaf}
                                                            inputTypeAll={true}
                                                            header={false}
                                                        />
                                                        <div className='p-0 mt-2'>
                                                            <FilterDropDown

                                                                firstTitle={
                                                                    <span>
                                                                        Select Feature (
                                                                        <span className='d-inline font-italic small opacity-30' style={{ fontStyle: 'italic' }}>
                                                                            {selectedFeature}
                                                                        </span>
                                                                        )
                                                                    </span>
                                                                } option={convertItemIntoArray(selectedFeature)}
                                                                selection={selection}
                                                                selectAll={""}
                                                                locationList={
                                                                    <ListGroup flush >
                                                                        {props.type === 'insights' &&
                                                                            userFeature.length > 1 && userFeature.map(item => {
                                                                                return <div className='p-2 fz-12' key={item.value}>
                                                                                    <Input type='checkbox' value={item.value}
                                                                                        defaultChecked={selectedFeature === item.value ? true : false}
                                                                                        onClick={(e) => setSelectedFeature(e.target.value)} />
                                                                                    <Label style={{ fontFamily: "Poppins", fontSize: 'inherit' }} className='mx-2 '>{item.label === 'Total' ? 'Total Traffic' : item.label}</Label>
                                                                                </div>
                                                                            })
                                                                        }
                                                                    </ListGroup>
                                                                }
                                                                customList={[]}
                                                                header={false}
                                                            />


                                                        </div>

                                                        <DateRangeDropdown
                                                            calendar={<CalendarWithCreateDate
                                                                selectedDayRange={selectedDayRange}
                                                                setSelectedDayRange={setSelectedDayRangeFromCalendar}
                                                                maximumDate={getDateObject(moment(new Date()))}
                                                                dateFilters={dateFilters}
                                                                deleteFilters={deleteFilters}
                                                                setEdit={(e) => setEditDateFilterFlag(e)}
                                                                setDeleteFilters={(e) => setDeleteFilters(e)}
                                                                setCustomSelection={(e) => setCustomSelection(e)}
                                                                isEdit={editDateFilterFlag}
                                                                editDateLabel={dateLabel}
                                                                setCurrentDate={(e) => setCurrentDate(e)}
                                                                currentDate={currentDate}
                                                                showFooter={true}

                                                            />}
                                                        />

                                                        <div className='right-button'>
                                                            <Button
                                                                style={{ width: "100px" }}
                                                                color="primary"
                                                                className='btn-primary btn'
                                                                title={((!getLengthFlag(sidForInsights) && !selectedFeature) && "Please select one store and one feature") || ((!selectedFeature && getLengthFlag(sidForInsights)) && "Please select at least one feature") || ((!getLengthFlag(sidForInsights) && selectedFeature) && "Please select one store")}
                                                                disabled={!option.length || !Object.keys(sidForInsights ? sidForInsights : {}).length || !selectedFeature}
                                                                onClick={() => updateFilterForNewInsights()}
                                                            >
                                                                Create
                                                            </Button>
                                                        </div>

                                                    </div>


                                                </>
                                            }

                                        </>
                                    }


                                    {
                                        props.type === 'store_activity' && !deleteFilters && !customSelection &&
                                        <>
                                            <Row>


                                                <Col md={4} className="pt-3 border-bottom border-1 "  >

                                                    <StoresTypeHeader
                                                        setClassifyFlag={(e) => setClassifyFlag(e)}
                                                        classifyFlag={classifyFlag}
                                                        classifyByClasses={classifyByClasses}
                                                        showToggle={false}

                                                    />

                                                    <div className='scrollbar' id="style-3" style={{ overflowX: 'hidden', width: "100%" }}>
                                                        <ListGroup>
                                                            <ListGroupItem>
                                                                {
                                                                    <>
                                                                        <input type="checkbox" className='m-2' defaultChecked={checkall === undefined && true} onChange={(event) => setAllInsightsSidsTrue(event)} />
                                                                        <lable className='m-1'>Select All</lable>
                                                                    </>
                                                                }
                                                            </ListGroupItem>
                                                            {storeForInsights}

                                                        </ListGroup>
                                                    </div>
                                                </Col>
                                                <Col md={4} className="  pt-3 border-start border-end border-bottom border-1 ">
                                                    <div className="">
                                                        <h4 className='filter-heading'>Date Filters</h4>
                                                        <div className='scrollbar ' id="style-3" style={{ width: "100%" }}>
                                                            <ListGroup flush >
                                                                {datesMenu}
                                                                {datesMenuCustom}
                                                            </ListGroup>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={4} className="pt-3 border-start border-end border-bottom border-1">
                                                    <div className='pt-2'>
                                                        <CalendarWithCreateDate
                                                            selectedDayRange={selectedDayRange}
                                                            setSelectedDayRange={setSelectedDayRangeFromCalendar}
                                                            maximumDate={getDateObject(moment(new Date()))}
                                                            dateFilters={dateFilters}
                                                            deleteFilters={deleteFilters}
                                                            setEdit={(e) => setEditDateFilterFlag(e)}
                                                            setDeleteFilters={(e) => setDeleteFilters(e)}
                                                            setCustomSelection={(e) => setCustomSelection(e)}
                                                            isEdit={editDateFilterFlag}
                                                            editDateLabel={dateLabel}
                                                            setCurrentDate={(e) => setCurrentDate(e)}
                                                            currentDate={currentDate}
                                                            showFooter={true}

                                                        />
                                                    </div>

                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FilterFooter
                                                        warning={!sidForInsights && "please select at least one store"}
                                                        disabled={!sidForInsights}
                                                        onClick={() => updateFilterForEight([])}
                                                        search={"Search"}
                                                    />
                                                </Col>
                                            </Row>
                                        </>
                                    }



                                    {
                                        props.type === "visitor" &&
                                        <>


                                            {
                                                windowWidth >= 768 &&
                                                <>
                                                    <Row>
                                                        <Col md={4} className="pt-3 border-bottom border-1 "  >


                                                            <StoresTypeHeader
                                                                setClassifyFlag={(e) => setClassifyFlag(e)}
                                                                classifyFlag={classifyFlag}
                                                                classifyByClasses={classifyByClasses}
                                                                showToggle={true}
                                                                setTrafficType={setTrafficType}
                                                                trafficType={trafficType}
                                                                pageTitle={props.pageTitle}

                                                            />

                                                            <div className='scrollbar' id="style-3" style={{ overflowX: 'hidden', width: "100%" }}>
                                                                <ListGroup>
                                                                    {comparisonStores1}
                                                                </ListGroup>
                                                            </div>

                                                        </Col>
                                                        <Col md={4} className="pt-3 border-start border-end border-bottom border-1">
                                                            <h4 className='filter-heading'>Date Filters</h4>
                                                            <div className='scrollbar ' id="style-3" style={{ width: "100%", overflowX: 'hidden' }}>
                                                                <ListGroup>
                                                                    {datesMenu}
                                                                    {datesMenuCustom}
                                                                </ListGroup>
                                                            </div>
                                                        </Col>
                                                        <Col md={4} className="pt-3 border-start border-end border-bottom border-1">
                                                            <div className='pt-2'>
                                                                <CalendarWithCreateDate
                                                                    selectedDayRange={selectedDayRange}
                                                                    setSelectedDayRange={setSelectedDayRangeFromCalendar}
                                                                    maximumDate={getDateObject(moment(new Date()))}
                                                                    dateFilters={dateFilters}
                                                                    deleteFilters={deleteFilters}
                                                                    setEdit={(e) => setEditDateFilterFlag(e)}
                                                                    setDeleteFilters={(e) => setDeleteFilters(e)}
                                                                    setCustomSelection={(e) => setCustomSelection(e)}
                                                                    isEdit={editDateFilterFlag}
                                                                    editDateLabel={dateLabel}
                                                                    setCurrentDate={(e) => setCurrentDate(e)}
                                                                    currentDate={currentDate}
                                                                    showFooter={true}

                                                                />
                                                            </div>

                                                        </Col>

                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FilterFooter
                                                                warning={((!option?.length) && "Please select at least one store")
                                                                    ||
                                                                    ((option?.length > 0 && (!Object.keys(comparisonFids1 ? comparisonFids1 : {}).length)
                                                                    ) && "Please select at least one floor")}

                                                                disabled={!option.length || !Object.keys(comparisonFids1 ? comparisonFids1 : {}).length}
                                                                onClick={() => updateFilterForVisitor()}
                                                                search={"Search"}
                                                            />
                                                        </Col>
                                                    </Row>

                                                </>


                                            }

                                            {
                                                windowWidth < 768 &&
                                                <>
                                                    <div className='p-3'>
                                                        {
                                                            windowWidth < 768 &&
                                                            <>
                                                                <div className='p-3 '>


                                                                    <FilterDropDown

                                                                        firstTitle="Select Locations"
                                                                        option={option}
                                                                        selection={selection}
                                                                        setCustomSelection={(e) => setCustomSelection(e)}
                                                                        selectAll={selectAll}
                                                                        locationList={comparisonStores1}
                                                                        customList={[]}
                                                                        filterHeader={<StoresTypeHeader
                                                                            setClassifyFlag={(e) => setClassifyFlag(e)}
                                                                            classifyFlag={classifyFlag}
                                                                            classifyByClasses={classifyByClasses}
                                                                            showToggle={true}

                                                                        />}
                                                                        allLocations={false}
                                                                        header={true}
                                                                    />

                                                                    <DateRangeDropdown

                                                                        calendar={<CalendarWithCreateDate
                                                                            selectedDayRange={selectedDayRange}
                                                                            setSelectedDayRange={setSelectedDayRangeFromCalendar}
                                                                            maximumDate={getDateObject(moment(new Date()))}
                                                                            dateFilters={dateFilters}
                                                                            deleteFilters={deleteFilters}
                                                                            setEdit={(e) => setEditDateFilterFlag(e)}
                                                                            setDeleteFilters={(e) => setDeleteFilters(e)}
                                                                            setCustomSelection={(e) => setCustomSelection(e)}
                                                                            isEdit={editDateFilterFlag}
                                                                            editDateLabel={dateLabel}
                                                                            setCurrentDate={(e) => setCurrentDate(e)}
                                                                            currentDate={currentDate}
                                                                            showFooter={true}

                                                                        />}

                                                                    />
                                                                    <div className='right-button'>
                                                                        <Button style={{ width: "100px" }} color="primary" className='btn-primary btn' title={"Please Select at least one location and floor"} disabled={!option.length || !Object.keys(comparisonFids1 ? comparisonFids1 : {}).length} onClick={() => updateFilterForVisitor([])}>Apply</Button>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }

                                                    </div>

                                                </>
                                            }


                                        </>

                                    }
                                </div>
                            </DropdownMenu>
                        </Dropdown>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default MainFilter;