import React, { Fragment, useEffect, useState } from "react";
import { BarChart, Hash, Layout, PieChart, Star, Search } from "react-feather";
import { Button, ButtonGroup, Col, Input, Row, UncontrolledTooltip } from "reactstrap";
import MainFilter from "../components/filters/MainFilter";
import * as echarts from "echarts/core";
import { GridComponent, TooltipComponent, TitleComponent } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { ScatterChart } from "echarts/charts";
import Spider from "../components/charts/Spider";
import Scatter from "../components/charts/Scatter";
import { summaryRequest, storesActivityRequest, user, liveAccupancyRequest, employeeSummaryRequest, getAvgDwellTimeRequest } from "../helpers/requests";
import { useDispatch, useSelector } from "react-redux";
import SummaryTable from "../components/tables/SummaryTable";
import LeaderBoard from "../components/LeaderBoard";
import Properties from "../components/modals/Properties";
import SummaryCardCompact from "../components/cards/SummaryCardCompact";
import BarGraph from "../components/tables/BarGraph";
import { convertIntoMillion, convertMsToSeMiHo } from "../helpers/utils";
import TableSearch from "../components/TableSearch";
import ChatgptResponse from "../components/ChatgptResponse";
import { todayDate } from "../components/filters/components/FilterCommon";
import moment from "moment";


echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    ScatterChart,
    CanvasRenderer,
]);

const Home_chatgpt = () => {
    let tooltip;
    const [currentView, setCurrentView] = useState("table");
    const [filter, setFilter] = useState();
    const [selectedStores, setSelectedStores] = useState([]);
    const [categories, setCategories] = useState([])
    const [excludedCategories, setExcludedCategories] = useState(categories)
    const dispatch = useDispatch();
    const summary = useSelector((state) => state.summary);
    const features = useSelector((state) => state.auth.features);

    const [occupancy, setOccupancy] = useState({ total: 0, stores: {}, time: '' })
    const [avgDwellTime, setAvgDwellTime] = useState({ totalAvg: 0, stores: {} })
    const payload = {
        start: '',
        start1: '',
        end: '',
        fid: [],
        sid: [],
        type: 'd',
        ex_fid: [],
        excluded: [],
        included: [],
        end1: "",
    }

    const updateFilter = async (e) => {

        console.log('eeeeeeeeeeeeeeeeeeeee', e);


        let _selectedStores = user.access.filter((item) => e.sid.includes(item._id));
        setSelectedStores(_selectedStores);
        let _categories = [];
        _selectedStores.forEach((store) => {
            store.floors.forEach((floor) => {
                floor.sections.forEach((section) => {
                    _categories.push({ label: section.label, value: section._id })
                })
            })
        })
        let staticCategories = [
            { label: "M Kids", value: "M Kids" },
            { label: "Un-Stitched", value: "Un-Stitched" },
            { label: "Stitched", value: "Stitched" },
            { label: "Jewelry", value: "Jewelry" },
            { label: "M Basic", value: "M Basic" },
            { label: "Perfume", value: "Perfume" },
            { label: "M Couture", value: "M Couture" },
            { label: "M Luxe", value: "M Luxe" },
            { label: "Left-Over", value: "Left-Over" },
        ]



        // setCategories(_categories);
        setCategories(staticCategories);

        e['excluded'] = excludedCategories;
        e["included"] = [];

        let _payload = {};
        Object.keys((payload ? payload : {})).forEach((key) => {
            if (key !== undefined && key && key in e) {
                _payload[key] = e[key]
            }
        })

        console.log('_payload', _payload, payload, e);
        var requestData = {
            params: _payload,
            dispatch,
        };
        setFilter(e)

        storesActivityRequest({ client_ids: [user.client_id[0]._id], dispatch })
        summaryRequest(requestData);
        employeeSummaryRequest(requestData)

        let currentDate = todayDate();

        let payloadForOccupancy = {
            params: { ..._payload, start: currentDate.start, end: currentDate.end },
            dispatch
        }

        try {
            const occupancy = await liveAccupancyRequest(payloadForOccupancy);
            console.log('occupancy i get', occupancy);

            if (occupancy.status === 200) {
                let data = {};
                let total = 0;
                let time = null;

                occupancy.data.forEach((item) => {
                    let count = item.data[item.data.length - 1].count;
                    data[item._id] = count;
                    total = total + count;
                    let _time = new Date(item.AT);
                    console.log('_time==', _time);
                    if (time === null) {
                        time = _time
                    } else {
                        if (time !== undefined && time) {
                            if (_time > time) {
                                time = _time
                            }
                        }
                    }


                })
                let _occupancy = { total: total, stores: data, time: moment(time).utcOffset(0).format('LTS') }
                console.log('_occupancy in state', _occupancy);
                setOccupancy(_occupancy)
            }


            const avgDt = await getAvgDwellTimeRequest(requestData);
            let avgData = {}
            let totalAvg = 0
            if (avgDt.status === 200) {
                avgDt.data.forEach((item) => {
                    avgData[item.sid] = convertMsToSeMiHo(item.avgDt * 1000);
                    totalAvg = totalAvg + item.avgDt;
                })
            }

            let _avgDwellTime = { totalAvg: convertMsToSeMiHo((totalAvg * 1000) / avgDt.data.length), stores: avgData }
            console.log('_avgDwellTime', _avgDwellTime);
            setAvgDwellTime(_avgDwellTime)
            console.log('avgDt===', avgDt);
        }
        catch (err) {
            console.log('err while fetching liveAccupancyRequest', err);
            setOccupancy({ total: 0, stores: {}, time: '' })
        }

    };


    const [option, setOption] = useState();
    const [options, setOptions] = useState();
    const [footfallFeatures, setFootfallFeatures] = useState([]);
    const [properties, setProperties] = useState([]);
    const raw = useSelector(state => state.summary.current);
    const chatgptResponse = useSelector((state) => state.summary.chatgptResponse);

    const summaryMenu = [
        ["table", <Layout size={16} />, "Tabular View"],
        ["radar", <PieChart size={16} />, "Radar Chart"],
        ["scatter", <Hash size={16} />, "Scatter Plot"],
        ["bar", <BarChart size={16} />, "Stacked Chart"],
        ["leader", <Star size={16} />, "Leader Board"],
        ["search", <Search size={16} />, "Table Search"],

    ];





    useEffect(() => {
        setFootfallFeatures(features.footfall);
        let allOptions = [...features.footfall, ...features.sales];
        setOption(allOptions);
        setOptions(allOptions);
    }, [features]);

    useEffect(() => {
        var findMedianSortedArrays = function (nums1, nums2) {
            let concatArray = [...nums1, ...nums2];
            let sorted = concatArray.sort(function (a, b) {
                return a - b;
            });
            let lengthOfArray = concatArray.length;
            if (lengthOfArray % 2 !== 0) {
                let index = Math.floor(lengthOfArray / 2);
                return sorted[index];
            } else {
                let index1 = Math.floor(lengthOfArray / 2);
                let median = (sorted[index1] + sorted[index1 + 1]) / 2;
                return median;
            }
        };
        findMedianSortedArrays([1, 3], [2, 2]);
    });


    useEffect(() => {
        setProperties(option == null ? [] : typeof option !== undefined && option && option.map((opt) => opt.value));
    }, [option]);

    useEffect(() => {
        if (excludedCategories) {

            if (filter !== undefined && filter) {
                let _excluded = excludedCategories.map((item) => { return item.label })

                filter['excluded'] = _excluded;
                filter['included'] = [];
                let requestData = {
                    params: filter,
                    dispatch
                }

                // storesActivityRequest({ client_ids: [user.client_id[0]._id], dispatch })
                summaryRequest(requestData);
            }
        }

    }, [excludedCategories])


    return (
        <Fragment>
            <MainFilter
                pageTitle="Summary"
                singleDate={false}
                updateFilter={(e) => updateFilter(e)}
                type="default"
            />


            <h5 className="p-0 mb-3 fw-normal">Traffic</h5>
            <Row className="mb-4 g-2">
                {summary &&
                    summary.totalCurrent.length > 0 &&
                    footfallFeatures &&
                    footfallFeatures.map((item, index) => {
                        let check = isFinite(summary.totalCurrent[0][item.value]);
                        let current = item.value === "occupancy" ? occupancy.total : item.value === "avgdt" ? avgDwellTime.totalAvg : check ? (summary.totalCurrent[0][item.value]) : 0;
                        let previous = item.value === "occupancy" ? 0 : item.value === "avgdt" ? 0 : check ? (summary.totalPrevious[0][item.value]) : 0;
                        let insteadOfprevious = item.value === "occupancy" ? occupancy.time : ''

                        let timeIs = occupancy.time;
                        console.log('timeIs', timeIs, "and insteadOfprevious", insteadOfprevious, item.value == 'occupancy');


                        console.log('item.value', item.value, current, previous);
                        return (
                            <Col md={2} className="" key={"traffic-card-" + index}>
                                <SummaryCardCompact
                                    title={item?.label.toUpperCase()}
                                    current={current}
                                    previous={previous}
                                    imputeFlag={false}
                                    showPercentage={item.value === "occupancy" ? false : true}
                                    showPrevious={item.value === "occupancy" ? false : true}


                                />
                            </Col>
                        );
                    })
                }


                {
                    features.insight.map((item, index) => {

                        let current = item.value === "occupancy" ? occupancy.total : item.value === "avgdt" ? avgDwellTime.totalAvg : 0;
                        let previous = item.value === "occupancy" ? 0 : item.value === "avgdt" ? 0 : 0;

                        return (
                            <Col md={2} className="" key={"sales-card-" + index}>
                                <SummaryCardCompact
                                    title={item?.label.toUpperCase()}
                                    current={current}
                                    previous={previous}
                                    imputeFlag={false}
                                    showPercentage={item.value === "occupancy" ? false : true}
                                    showPrevious={item.value === "occupancy" ? false : true}
                                    showTime={false}
                                    occupancy={occupancy.time}




                                />
                            </Col>
                        );
                    })
                }
                {!footfallFeatures.length && (
                    <span className="d-block small opacity-50">
                        <i>You dont have any feature please contact admin</i>
                    </span>
                )}
            </Row>
            {<h5 className="p-0 mb-3 fw-normal">Sales</h5>}
            <Row className="mb-4 g-2">
                {summary &&
                    summary.totalCurrent.length > 0 &&
                    features &&
                    features.sales &&
                    features.sales.map((item, index) => {

                        let _current = summary.totalCurrent[0][item.value];
                        let _previous = summary.totalPrevious[0][item.value]

                        return (
                            <Col md={2} className="" key={"sales-card-" + index}>
                                <SummaryCardCompact
                                    title={item.label}
                                    current={(_current)}
                                    previous={(_previous)}
                                    imputeFlag={false}
                                    showPercentage={item.value === "occupancy" ? false : true}
                                    showPrevious={item.value === "occupancy" ? false : true}
                                    showTime={false}
                                    occupancy={""}


                                />
                            </Col>
                        );
                    })}
                {/* {!features?.sales.length > 0 && (
          <span className='className="d-block small opacity-50"'>
            <i>You dont have any feature please contact admin</i>
          </span>
        )} */}
            </Row>

            {(footfallFeatures.length || features?.sales) && (
                <div className="mb-4 d-flex">

                    <div className="text-start">
                        {option && options &&
                            <Properties
                                option={option}
                                options={options}
                                setOption={(e) => setOption(e)}
                                flag={'feature'}

                            />}
                    </div>

                    {/* this categories feature is just for mariab for now  */}
                    {/* <div className="mx-3">
                        {
                            categories &&

                            <Properties
                                option={excludedCategories}
                                options={categories}
                                setOption={(e) => setExcludedCategories(e)}
                                flag={'category'}
                            />
                        }
                    </div> */}




                    {currentView === "search" && <div className="d-flex">

                        <TableSearch
                            dataOptions={option}
                            summary={summary}
                            features={features}

                        />



                    </div>}


                    <Col className="text-end">
                        <ButtonGroup>
                            {summaryMenu.map((val, index) => {
                                return (
                                    <Button
                                        href={"#toggle-tab-" + val[0]}
                                        color={
                                            currentView === val[0]
                                                ? "outline-primary"
                                                : "outline-light"
                                        }
                                        className="section-tab"
                                        size="sm"
                                        onClick={function noRefCheck() {
                                            setCurrentView(val[0]);
                                        }}
                                        active={currentView === val[0] ? true : null}
                                        key={"compare-tab-" + index}
                                        id={"toggle-tooltip-" + val[0]}
                                    >
                                        {val[1]}
                                    </Button>
                                );
                            })}
                        </ButtonGroup>
                        {summaryMenu.map((val, index) => {
                            return (
                                <UncontrolledTooltip
                                    flip
                                    isOpen={val[0] === tooltip}
                                    autohide={false}
                                    placement="top"
                                    target={"toggle-tooltip-" + val[0]}
                                    key={"compare-tab-tooltip-" + index}
                                >
                                    {val[2]}
                                </UncontrolledTooltip>
                            );
                        })}
                    </Col>
                </div>
            )}


            {
                features?.employee.length > 0 &&
                <h5 className="p-0 mb-3 fw-normal">Employees</h5>
            }
            <Row className="mb-4 g-2">
                {summary &&
                    summary.empTotalCurrent.length > 0 &&
                    features &&
                    features.employee &&
                    features.employee.map((item, index) => {

                        let _current = summary.empTotalCurrent[0][item.value];
                        let _previous = summary.empTotalPrevious[0][item.value]

                        return (
                            <Col md={2} className="" key={"sales-card-" + index}>
                                <SummaryCardCompact
                                    title={item.label}
                                    current={(_current)}
                                    previous={(_previous)}
                                    imputeFlag={false}

                                />
                            </Col>
                        );
                    })}

            </Row>
            <div style={{ minHeight: "500px" }}>

                {currentView === "search" && <ChatgptResponse
                    response={chatgptResponse.data}
                    filters={filter}

                />}




                {currentView === "table" && option && (
                    <Row className="mb-4" id={"#toggle-tab-" + currentView}>
                        <Col>
                            <SummaryTable
                                dataOptions={option}
                                summary={summary}
                            />
                        </Col>
                    </Row>
                )}
                {currentView === "radar" && option && typeof summary !== undefined && summary && (
                    <Row className="mb-4" id={"#toggle-tab-" + currentView}>
                        <Col>
                            <Spider dataOptions={option}
                                summary={summary}
                            />
                        </Col>
                    </Row>
                )}
                {currentView === "scatter" && option && typeof summary !== undefined && summary && (
                    <Row className="mb-4" id={"#toggle-tab-" + currentView}>
                        <Col>
                            <Scatter dataOptions={option}
                                summary={summary}
                            />
                        </Col>
                    </Row>
                )}

                {currentView === "bar" && option && typeof summary !== undefined && summary && (

                    <Row className="mb-4" id={"#toggle-tab-" + currentView}>
                        <Col>
                            {/* <Line type="bar" stack dataOptions={option} /> */}
                            <BarGraph
                                dataOptions={option}
                                title={options}
                                payload={raw}
                                properties={properties}
                                sortFlag={true}
                            />

                        </Col>
                    </Row>
                )}
                {currentView === "leader" && option && (
                    <Row className="mb-4" id={"#toggle-tab-" + currentView}>
                        <Col>
                            <LeaderBoard dataOptions={option} />
                        </Col>
                    </Row>
                )}
            </div>
        </Fragment>
    );
};

export default Home_chatgpt;
