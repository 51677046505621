import { Card, CardBody, CardTitle, CardFooter, CardHeader, Button } from 'reactstrap'
import { passwordForgetRequest } from '../../helpers/requests'
import { useDispatch, useSelector } from 'react-redux'
import React, { Fragment, useEffect, useState } from 'react'
import { setForgotEmailStatus } from '../../redux/actions/auth'

const FindAccount = (props) => {

    const status = useSelector((state) => state.emailStatus.forgotEmailStatus)
    const [validationError, setError] = useState('')

    const [localStatus, setLocalStatus] = useState()
    const [email, setEmail] = useState();
    const highlights = useSelector(state => state.highlights);
    const dispatch = useDispatch();
    const searchEmail = () => {
        if (emailValidation()) {
            passwordForgetRequest({ email: email, dispatch })
        }

    }


    const cancelResetPassowrd = () => {
        props.setForgetFlag(false);
        setLocalStatus('')
        dispatch(setForgotEmailStatus())
    }

    const clear = () => {
        setEmail('');
        setLocalStatus()
    }
    const emailValidation = () => {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!email || regex.test(email) === false) {
            setError("Email is not valid")
            return false;
        }
        else {
            setError('')
            return true;
        }
    }


    useEffect(() => {
        if (status?.status === false) {
            setLocalStatus(false)
        }
        if (status?.status === 200) {
            setLocalStatus(true)
        }
    }, [status])


    useEffect(() => {
        setLocalStatus();
        setEmail();
        setError('')
    }, [1])


    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#e9ebee', }}>
            <Card style={{ maxWidth: '500px', borderRadius: '8px' }} >
                <CardHeader>
                    <h4 className='mt-2 '>Find Your Account</h4>
                </CardHeader>
                <CardBody>
                    {localStatus === false &&
                        <div style={{ backgroundColor: '#ffebe8', border: '1px solid #dd3c10' }} className='p-2'>
                            <h5>No Results Match</h5>
                            <span>Your search did not return any results. Please try again with other information.</span>
                        </div>}
                    {localStatus === true &&
                        <div style={{ backgroundColor: '', border: '1px solid green' }} className='p-2'>
                            <span>Reset link is sent to your email. Please open your email and click the link</span>
                        </div>}
                    {!localStatus &&
                        <>
                            <span className='d-block small opacity-50 m-1'>
                                Please enter your email address to search for your account.
                            </span>
                            <div className='my-2'>
                                <input type='text' placeholder='Email address' className='block p-2'
                                    value={email}
                                    style={{ border: '1px solid #dfe1e4', borderRadius: '5px', width: '300px' }}
                                    onChange={(event) => setEmail(event.target.value)}
                                ></input>
                                {<span className='d-block small opacity-50 m-1'>
                                    {validationError}
                                </span>}

                            </div>
                        </>}
                </CardBody>
                <CardFooter className='d-flex justify-content-end' >
                    <Button className='m-2' onClick={cancelResetPassowrd}>{localStatus === true ? "Close" : "Cancel"}</Button>
                    {!localStatus && <Button color="primary" className='m-2' onClick={searchEmail}>{highlights.loading ? '...Searching' : 'Search'}</Button>}
                    {localStatus && <Button color="primary" className='m-2' onClick={() => clear()}>Search again</Button>}

                </CardFooter>

            </Card>

        </div>



    )
}
export default FindAccount