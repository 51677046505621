import { actionTypes } from "../constants/action-types";
import moment from "moment";
const initialState = {
    loader: false,
    success: "",
    error: "",
    data: [],
    dailyData: [],
    total: {
        row1: {
            units: {
                value: 0,
                last: 0,
                change: 0,
            },
            sale: {
                value: 0,
                last: 0,
                change: 0,
            },
            traffic: {
                value: 0,
                last: 0,
                change: 0,
            },
            transaction: {
                value: 0,
                last: 0,
                change: 0,
            }
        },
        row2:{
            conversion: {
                value: 0,
                last: 0,
                change: 0,
            },
            ipt: {
                value: 0,
                last: 0,
                change: 0,
            },
            atv: {
                value: 0,
                last: 0,
                change: 0,
            },
            asp: {
                value: 0,
                last: 0,
                change: 0,
            }
        }
    },
    filter: {
        duration: "today",
        type: "today",
        store: null,
        stores: [],
        floor: null,
        floors: [],
        start: moment(new Date()).startOf("day"),
        end: moment(new Date()).endOf("day")
    }
}

export const conversionReducer = (state = initialState, {type, payload={}}) => {
    switch(type){
        case actionTypes.SET_CONVERSION_DATA:
            var data = payload.map((row)=>{
                return {...row, label: row._id.label, id: row._id.space_id, sale_updated: row.sale_updated?moment(row.sale_updated).format('lll'): "NA", traffic_updated: row.traffic_updated?moment(row.traffic_updated).format('lll'): "NA"}
            });
            return { ...state, data: data, loader: false };
        case actionTypes.SET_CONVERSION_DAILY_DATA:
            // data = payload.map((row)=>{
            //     return {...row, label: row._id.date, id: row._id.date, sale_updated: row.sale_updated?moment(row.sale_updated).format('lll'): "NA", traffic_updated: row.traffic_updated?moment(row.traffic_updated).format('lll'): "NA"}
            // });


            // data = data.sort((a, b) => {
            //     return new Date(b.label) - new Date(a.label);
            // })
            return { ...state, dailyData: payload, loader: false };
        case actionTypes.SET_CONVERSION_TOTAL:
            var total = state.total;
            Object.keys(total.row1).forEach((key, index) => {
                total.row1[key].value = payload[key].this_week; 
                total.row1[key].last = payload[key].last_week; 
                total.row1[key].change = (((payload[key].this_week-payload[key].last_week)/(payload[key].last_week?payload[key].last_week:1))*100).toFixed(1); 
                return 1;
            });
            Object.keys(total.row2).forEach((key, index) => {
                total.row2[key].value = payload[key].this_week; 
                total.row2[key].last = payload[key].last_week; 
                total.row2[key].change = (((payload[key].this_week-payload[key].last_week)/(payload[key].last_week?payload[key].last_week:1))*100).toFixed(1); 
                return 1;
            })
            return { ...state, total: total, loader: false };
        case actionTypes.SET_CONVERSION_FILTER:
            return { ...state, filter: payload };
        case actionTypes.SET_CONVERSION_LOADER:
            return { ...state, loader: payload };
        case actionTypes.SET_CONVERSION_SUCCESS:
            return { ...state, success: payload };
        case actionTypes.SET_CONVERSION_ERROR:
            return { ...state, error: payload };
        default:
            return state;
    }
}