import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainFilter from "../components/filters/MainFilter";
import heatmapImage from "../assets/img/heatmap.png";
import heatmapRange from "../assets/img/HeatmapRanges1.jpg";
import { getCameraByLinkedSection, getCameraHeatmap, heatmapRequest, user } from "../helpers/requests";
import Heatmap from "./Templates/Heatmap";
import { setHeatmapFilter, setHeatmapLoader, setHeatmap } from "../redux/actions/heatmapActions";
import { singleFloorFetch, } from "../helpers/requests";
import { Card, CardBody, Row, Col } from "reactstrap";
import { getDifference } from "../helpers/utils";
import { isInside } from "../helpers/isInside";
import SectionWithScale from "./Templates/SectionWithScale";
import CameraHeatmap from "../components/modals/CameraHeatmap";
import Heatmap2 from "./Templates/Heatmap2";
import { setError } from "../redux/actions/highlights";
import { ToastContainer, toast } from 'react-toastify';


const Heatmaps = () => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState();
  const [linkedSection, setLinkedSection] = useState();
  const [modal, setModal] = useState(false);
  console.log('modal==', modal);
  const heatmap = useSelector((state) => state?.heatmapReducer?.data);
  var filter = useSelector((state) => state?.heatmapReducer?.filter);
  const currentFloor = useSelector((state) => state.floorReducer.floor);
  const [activeView, setActiveView] = useState(3)
  console.log('activeView===', activeView);
  console.log('currentFloor==', currentFloor);
  const [normalizeHeatmap, setNormalizeHeatmap] = useState([]);
  console.log('normalizeHeatmap=', normalizeHeatmap);
  const [floor, setFloor] = useState({})
  console.log('floor of range', floor);

  const [genderWiseHM, setGenderWiseHM] = useState([]);
  console.log('genderWiseHM=', genderWiseHM);
  const [section, setSection] = useState();
  console.log('section===', section);
  const [selectedSection, setSelectedSection] = useState();

  const normalize = (_heatmap) => {
    let heatmap = _heatmap.slice();


    heatmap = heatmap.sort((a, b) => {
      return (b.v) - (a.v);
    })


    // let arrayLength = heatmap.length;
    // let topFive = arrayLength*0.20;
    // let nintyFour = parseInt(arrayLength-topFive);
    // let _data = heatmap.splice(0,nintyFour)


    let max = heatmap[0].v;
    let min = heatmap[heatmap.length - 1].v;
    let mean = Math.floor(heatmap.reduce((total, a) => total += a.v, 0) / heatmap.length);

    // let sum = 0;
    // for (let i = 0; i < heatmap.length; i++) {
    //   sum += Math.pow((heatmap[i].v - mean), 2);
    // }
    // let standarDevi = Math.sqrt(sum / heatmap.length);




    // const _data = heatmap.map((item) => {
    //   item.v =((item.v - mean) / (standarDevi))
    //   return item
    // })

    // recommend

    let _data = heatmap.map((item) => {
      item.v = ((item.v - mean) / (max - min)) / 2
      return item
    })




    // const _data = [];

    // heatmap.forEach((item, index) => {
    //   if(index > nintyFour){
    //     heatmap[index].v = heatmap[nintyFour].v;
    //     let x = heatmap[index].v;

    //   }
    // })

    //  const _data = heatmap.filter((item, index, array) => {

    //     if(index > nintyFour)
    //     {
    //       item.v = replaceValue ;
    //     }
    //     return item
    //   } )




    // const _data = heatmap.filter((item) => {
    //   if(item.v <= mean + 4 * (standarDevi)){
    //     return item
    //   }

    // })





    //   const _data = heatmap.map((item) => {
    //     item.v = min + ((item.v-min)*(max-min))/(max-min)
    //   return item
    // })

    return _data
  }
  const median = arr => {
    const { length } = arr;
    arr.sort((a, b) => a - b);
    if (length % 2 === 0) {
      return (arr[length / 2 - 1] + arr[length / 2]) / 2;
    }
    return arr[(length - 1) / 2];
  };

  const abstructHeatmap = (_heatmap) => {
    if (filters !== undefined && filters) {
      let dateDifference = getDifference(filters.start, filters.end);

      let heatmap = _heatmap.slice();

      if (dateDifference > 12) {
        let array = [...new Set(_heatmap.map(e => e.v))].sort((a, b) => b - a);
        let sortedArray = array.slice();
        let media = median(sortedArray);
        // let flag = (media * 5000 )/100;
        let percent = (Math.max(...array) * 100) / media
        let flag = percent / 2.5;
        let arr = heatmap.filter((item) => { return item.v < flag });


        return arr
      }
      else {
        return heatmap
      }
    }
  }





  const setVValue = (_heatmap) => {


    let heatmap = _heatmap.slice();

    let riyadPoints = [
      { x: 562, y: 94 },
      { x: 531, y: 96 },
      { x: 523, y: 123 },
      { x: 551, y: 119 },

    ]


    let jaddahPoints = [
      { x: 501, y: 269 },
      { x: 453, y: 262 },
      { x: 455, y: 235 },
      { x: 498, y: 240 },

    ];

    let jaddahPointstwo = [
      { x: 237, y: 271 },
      { x: 193, y: 261 },
      { x: 196, y: 229 },
      { x: 230, y: 242 },

    ]
    let jaddahPointst3 = [
      { x: 69, y: 164 },
      { x: 59, y: 183 },
      { x: 88, y: 182 },
      { x: 90, y: 163 },

    ]

    let dyeSection = currentFloor.heatmap_sections[0]?.positions;

    let jaddahHeatmap = [];
    let insidePoints = []
    for (let i = 0; i < heatmap.length; i++) {
      let point = { x: heatmap[i].x, y: heatmap[i].y, v: heatmap[i].v };

      if (isInside(dyeSection, dyeSection.length, point)) {



        if (isInside(jaddahPoints, jaddahPoints.length, point)
          //  || isInside(polygon2, polygon2.length, point) || isInside(polygon3, polygon3.length, point) || isInside(polygon4, polygon4.length, point) || isInside(polygon5, polygon5.length, point)
        ) {
          let _point = { x: heatmap[i].x, y: heatmap[i].y, v: 1 }
          jaddahHeatmap.push(_point);
          insidePoints.push(point);
        }
        else if (isInside(jaddahPointstwo, jaddahPointstwo.length, point)) {
          let _point = { x: heatmap[i].x, y: heatmap[i].y, v: 1 }
          jaddahHeatmap.push(_point);
          insidePoints.push(point);
        }
        else if (isInside(jaddahPointst3, jaddahPointst3.length, point)) {
          let _point = { x: heatmap[i].x, y: heatmap[i].y, v: 1 }
          jaddahHeatmap.push(_point);
          insidePoints.push(point);
        }
        else {
          jaddahHeatmap.push(point)
        }
      }
    }

    console.log('jaddahHeatmap==', jaddahHeatmap);
    console.log('jaddahHeatmap insidePoints==', insidePoints);


    return jaddahHeatmap
  }

  const setVValueForRiyadh = (_heatmap) => {


    let heatmap = _heatmap.slice();

    let riyadPoints = [
      { x: 418 + 80, y: 37 + 20 },
      { x: 426 + 80, y: 109 + 20 },
      { x: 572 + 80, y: 125 + 20 },
      { x: 592 + 80, y: 46 + 20 },

    ]





    let jaddahHeatmap = [];
    let insidePoints = []
    for (let i = 0; i < heatmap.length; i++) {
      let point = { x: heatmap[i].x, y: heatmap[i].y, v: heatmap[i].v };

      if (isInside(riyadPoints, riyadPoints.length, point)
        //  || isInside(polygon2, polygon2.length, point) || isInside(polygon3, polygon3.length, point) || isInside(polygon4, polygon4.length, point) || isInside(polygon5, polygon5.length, point)
      ) {
        let _point = { x: heatmap[i].x, y: heatmap[i].y, v: 1 }
        jaddahHeatmap.push(_point);
        insidePoints.push(point);
      }

      else {
        jaddahHeatmap.push(point)
      }
    }

    console.log('jaddahHeatmap==', jaddahHeatmap);
    console.log('jaddahHeatmap insidePoints==', insidePoints);


    return jaddahHeatmap
  }


  const excludeDummy = (_heatmap) => {
    let heatmap = _heatmap.slice();

    let dynastyPolygon = [
      { x: 723, y: 94 },
      { x: 735, y: 93 },
      { x: 740, y: 93 },
      { x: 738, y: 119 },
      { x: 720, y: 116 },

    ]

    let polygon = [
      { x: 473, y: 246 },
      { x: 483, y: 256 },
      { x: 484, y: 288 },
      { x: 497, y: 291 },
      { x: 510, y: 280 },
      { x: 507, y: 252 },
      { x: 499, y: 245 },
      { x: 475, y: 244 },
    ];

    let polygon2 = [
      { x: 102, y: 192 },
      { x: 128, y: 192 },
      { x: 127, y: 207 },
      { x: 101, y: 207 }
    ]

    let polygon3 = [
      { x: 346, y: 170 },
      { x: 361, y: 170 },
      { x: 363, y: 188 },
      { x: 343, y: 189 },
      { x: 347, y: 170 }
    ]

    let polygon4 = [
      { x: 341, y: 357 },
      { x: 384, y: 375 },
      { x: 340, y: 376 },
      { x: 341, y: 357 }
    ]

    let polygon5 = [
      { x: 485, y: 272 },
      { x: 509, y: 277 },
      { x: 502, y: 294 },
      { x: 478, y: 284 }
    ]

    let excludedPoints = [];
    let insidePoints = [];


    for (let i = 0; i < heatmap.length; i++) {
      let point = { x: heatmap[i].x, y: heatmap[i].y, v: heatmap[i].v };

      if (isInside(dynastyPolygon, dynastyPolygon.length, point)
        //  || isInside(polygon2, polygon2.length, point) || isInside(polygon3, polygon3.length, point) || isInside(polygon4, polygon4.length, point) || isInside(polygon5, polygon5.length, point)
      ) {
        insidePoints.push(point)
      }
      else {
        excludedPoints.push(point)
      }
    }

    const _data = heatmap.filter((item) => { return item.v < 500 })

    return excludedPoints

  }


  const loop = (_heatmap) => {
    let obj = {};

    _heatmap.map(e => e.v).forEach(element => { obj[element] = (obj[element] || 0) + 1 });

    return _heatmap

  }


  const divideAndConquer = (_heatmap) => {

    if (typeof filters !== undefined && filters) {



      let dateDifference = getDifference(filters.start, filters.end);
      let heatmap = _heatmap.slice();
      let totalLength = heatmap.length;
      let sortedOriginal = [...new Set(heatmap.map(e => e.v))].sort((a, b) => b - a);
      // let sortedOriginal = heatmap.sort((a, b) => a.v - b.v);

      let max = sortedOriginal[0];
      let hundredIndex;
      if (sortedOriginal.length <= 100) {
        if (sortedOriginal.length < 28) {
          hundredIndex = sortedOriginal[6];
          console.log('hundredIndex', sortedOriginal[Math.floor(sortedOriginal.length - 1 / 2)]);
        }
        else {
          hundredIndex = sortedOriginal[28];

        }
      }

      else {
        hundredIndex = sortedOriginal[100];
      }

      let percentage = parseInt(max / hundredIndex) * 100;

      let cutFlag = 0;

      if (dateDifference > 26 && dateDifference < 35) {
        if (totalLength > 100000 && totalLength < 200000) {
          if (percentage < 600) {
            // unze london packages mall ground floor
            if (filters !== undefined && filters && filters.fid === "62861c1219914a97b5ee488c") {
              cutFlag = 33;

            }
            else {
              cutFlag = 500;
            }

          }
          else if (percentage > 2000 && percentage < 3000) {
            cutFlag = 400

            // only for unze london
            // cutFlag = 1700
          }
          else if (percentage > 3000) {
            cutFlag = 700;
          }
          else if (percentage > 1500 && percentage < 2000) {
            // sapphire
            if (user?.client_id && user?.client_id.length > 1 && user?.client_id[0]?._id === "60c9ac8562a70e11372a524b") {
              if (filters !== undefined && filters && filters.fid === "62b441535e55e70ede9789e2") {
                cutFlag = 3000
              }
              else {
                cutFlag = 6000;

              }


            }
            else {
              cutFlag = 500;
            }



          }
          else if (percentage > 600 && percentage < 1500) {
            // sapphhire
            if (user?.client_id && user?.client_id.length > 1 && user?.client_id[0]?._id === "60c9ac8562a70e11372a524b") {
              cutFlag = 1500;

            }
            else {
              cutFlag = 1000;

            }

            // onlu for unze london
            // cutFlag = 250
          }
          else cutFlag = 500
        }
        else if (totalLength > 200000 && totalLength < 300000) {
          if (percentage > 3000) {
            cutFlag = 600;
          }
          else {
            console.log('user', user);
            // outfitter
            if (user?.client_id && user?.client_id.length > 1 && user?.client_id[0]?._id === "6315b2e069475af41be766ef") {
              cutFlag = 8000
            }
            // sapphirre
            else if (user?.client_id && user?.client_id.length > 1 && user?.client_id[0]?._id === "60c9ac8562a70e11372a524b") {
              cutFlag = 16000;

            } else {
              // this if is for hushpuppies (reports)
              if (user.client_id[0]._id === "64745b52241da796cbadf199") {

                cutFlag = 2000
              }
              else cutFlag = 1000
            }

          }

        }
        else if (totalLength < 100000) {
          if (percentage > 2000 && percentage < 3000) {

            // cutFlag = 600

            // for dynasty only
            cutFlag = 1050;
          }
          else if (percentage < 2000) {
            // only for demo account 
            if (user?.client_id && user?.client_id.length > 0 && user?.client_id[0]?._id == "6193b1ba38b03450ee4b9889") {

              cutFlag = 7500;

            }
            else {
              cutFlag = 1000
            }

            // only for unze london
            // cutFlag = 2000
          }
          // only for unze london
          else if (percentage > 3000) {
            // sapphire
            if (user?.client_id && user?.client_id.length > 0 && user?.client_id[0]?._id === "60c9ac8562a70e11372a524b") {
              if (filters !== undefined && filters && filters.fid === "62b441815e55e70ede978a6e") {
                cutFlag = 100;

              }
              else {
                cutFlag = 500
              }

            }
            else if (filters !== undefined && filters && filters.fid === "62861c1219914a97b5ee488c") {
              cutFlag = 1400;
            }
            else {
              cutFlag = 500;
            }
          }
        }

        else cutFlag = 1200;

      }
      else if (dateDifference > 35 && dateDifference < 62) {
        if (totalLength > 100000 && totalLength < 200000) {
          if (percentage > 1000) {
            // sapphhire
            if (user?.client_id && user?.client_id.length > 1 && user?.client_id[0]?._id === "60c9ac8562a70e11372a524b") {
              if (filters !== undefined && filters && filters.fid === "62b441445e55e70ede9789b5") {
                cutFlag = 6000
              }
              else {
                cutFlag = 3000;

              }


            } else {
              cutFlag = 1000;

            }

          }
          else if (percentage < 1000) {
            cutFlag = 700;
          }
          else cutFlag = 500;
        }
        else if (totalLength < 100000) {
          if (percentage < 1000) {
            cutFlag = 1000;
          }
          else if (percentage > 3000) {
            //  cutFlag = 600

            // only for dynasty
            cutFlag = 20;
          }
          else if (percentage < 2000 && percentage > 1000) {
            // only for demo account 
            if (user?.client_id && user?.client_id.length > 0 && user?.client_id[0]?._id == "6193b1ba38b03450ee4b9889") {
              cutFlag = 13000;

            }
            else {
              cutFlag = 500;

            }

          }

          // else cutFlag = 700;

          // ***** For dynasty only
          else cutFlag = 1500;
        }
        else if (totalLength > 200000 && totalLength < 300000) {
          if (percentage > 600) {
            // sapphhire
            if (filters !== undefined && filters && filters.fid === "62b441445e55e70ede9789b5") {
              cutFlag = 5000
            }
            else {
              cutFlag = 700;
            }
          }
          else if (percentage < 600) {
            cutFlag = 500
          }
          else cutFlag = 800;
        }
        else cutFlag = 500
      }
      // only for unze london
      else if (dateDifference > 10 && dateDifference < 15) {
        if (user?.client_id && user?.client_id.length > 0 && user?.client_id[0]?._id == "6193b1ba38b03450ee4b9889") {
          cutFlag = 5000
        }
        else {

          if (percentage <= 200) {
            cutFlag = 30

          } else {
            cutFlag = 160;

          }


        }

      }
      else if (dateDifference > 15 && dateDifference < 25) {
        // outfitter
        if (user?.client_id && user?.client_id.length > 1 && user?.client_id[0]?._id === "6315b2e069475af41be766ef") {
          cutFlag = 10000;

        }
        // sapphirre
        else if (user?.client_id && user?.client_id.length > 1 && user?.client_id[0]?._id === "60c9ac8562a70e11372a524b") {
          console.log('filters', filters);
          if (filters !== undefined && filters && filters.fid === "62b441535e55e70ede9789e2") {
            cutFlag = 900
          }
          else if (filters !== undefined && filters && filters.fid === "62b441815e55e70ede978a6e") {
            cutFlag = 300
          }
          else {
            cutFlag = 1800;

          }

        }
        // unze london emporium mall ground floor
        else if (filters !== undefined && filters && filters.fid === "628619e119914a97b5ee46d0") {
          cutFlag = 50;

        }
        else if (filters !== undefined && filters && filters.fid === "62861c1219914a97b5ee488c") {
          cutFlag = 500
        }
        else if (user.client_id[0]._id === "65bb3fab14aa23f66d44fbaf") {

          if (percentage <= 100) {
            cutFlag = 30

          }
          else cutFlag = 200
        }
        else {
          cutFlag = 200
        }
      }
      else if (dateDifference > 65) {
        if (totalLength > 200000) {
          // sapphirre
          if (user?.client_id && user?.client_id.length > 1 && user?.client_id[0]?._id === "60c9ac8562a70e11372a524b") {
            cutFlag = 8000;
          }
          else if (user?.client_id && user?.client_id.length > 1 && user?.client_id[0]?._id === "6315b2e069475af41be766ef") {
            cutFlag = 9000;

          }
          else {
            cutFlag = 2500
          }


        }
        else if (totalLength < 200000) {
          // sapphhire
          if (user?.client_id && user?.client_id.length > 1 && user?.client_id[0]?._id === "60c9ac8562a70e11372a524b") {
            if (filters !== undefined && filters && filters.fid === "62b441815e55e70ede978a6e") {
              cutFlag = 800
            }
            else {
              cutFlag = 3000;
            }
          }
          else {
            cutFlag = 2500;
          }

        }
        else {
          cutFlag = 2500;

        }
      }
      // only for hushpuppies loucky one mall
      else if (user.client_id[0]._id === "64745b52241da796cbadf199" || user.client_id[0]._id === "65bb3fab14aa23f66d44fbaf") {

        if (dateDifference === 1) {

          if (totalLength > 60000) {
            if (percentage > 350) {
              cutFlag = 9
            }
            else if (percentage > 250) {
              cutFlag = 85

            }
            else cutFlag = 200
          }
          else if (totalLength > 50000) {
            if (percentage > 190) {
              cutFlag = 120
            }
            else cutFlag = 100
          }

          else if (totalLength > 40000) {
            if (percentage > 190) {
              cutFlag = 60
            }

            else cutFlag = 100
          }
          else if (totalLength > 15000) {
            if (percentage > 190) {
              cutFlag = 30
            }
            else cutFlag = 200
          }
          else cutFlag = 80

        }

        else {


          if (totalLength > 100000) {


            if (percentage >= 200) {
              cutFlag = 300

            }
            else cutFlag = 500
          }
          else if (totalLength > 90000) {
            if (percentage <= 100 || percentage < 200) {
              cutFlag = 30
            }

            else if (percentage >= 200 || percentage <= 300) {
              if (sortedOriginal.length < 100) {
                cutFlag = Math.floor(hundredIndex * 0.7)
              }
              else {
                cutFlag = 300

              }

            }

            else cutFlag = 200

            // if(percentage >=200){
            // }
          }
          else if (totalLength > 80000) {

            if (percentage <= 220) {
              cutFlag = 200

            }
            else if (percentage >= 300 || percentage <= 400) {
              if (sortedOriginal.length < 100) {
                cutFlag = 17
              }

              else {
                cutFlag = 100
              }

            }
            else {
              cutFlag = 300

            }

          }

          else if (totalLength > 70000) {
            cutFlag = 150
          }

          else if (totalLength > 50000) {
            cutFlag = 80
          }
          else cutFlag = 140
          // else if (totalLength > )

        }



      }
      else {
        cutFlag = 2500;
      }


      if (sortedOriginal.length < 28) {
        if (user.client_id[0]._id === '65bb3fab14aa23f66d44fbaf') {
          cutFlag = 15
        }
        else {
          cutFlag = 8;

        }

      }

      console.log('cutFlag', filters, cutFlag);

      let indexPerSlot = parseInt(max / hundredIndex);
      console.log('indexPerSlot', indexPerSlot);

      let belowCut = [];
      let aboveCut = [];
      heatmap.forEach((item) => {
        if (item.v <= cutFlag) {
          belowCut.push(item)
        }
        else {

          aboveCut.push(item)
        }
      })

      let _aboveCut = aboveCut.slice();
      const totalSlot = Math.ceil(aboveCut.length / indexPerSlot);


      if (indexPerSlot < _aboveCut.length) {
        let iteration = 0;
        for (let i = 0; i < _aboveCut.length; i = i + indexPerSlot) {

          for (let j = 0; j < indexPerSlot; j++) {
            if (j + (iteration * indexPerSlot) < _aboveCut.length) {
              _aboveCut[j + (iteration * indexPerSlot)].v = cutFlag + iteration;
            }

          }
          iteration = iteration + 1;
        }
      }
      else {
        for (let i = 0; i < _aboveCut.length; i = i + 1) {

          _aboveCut[i].v = cutFlag + i;

        }
      }
      console.log('sortedOriginal', sortedOriginal, "hundredIndex", hundredIndex, "totalLength", totalLength, 'belowCutLength', belowCut.length, "cutted length", totalLength - belowCut.length, "percentage", percentage, "dateDifference", dateDifference, "cutFlag", cutFlag);


      if (user.client_id[0]._id === "64745b52241da796cbadf199") {
        // this is for hushpuppies (donot add _aboveCut length)
        return belowCut
      }
      else {
        let forwardHeatmap = [...belowCut.slice(), ..._aboveCut.slice()].slice()
        return forwardHeatmap

      }


    }
  }

  const divideAndConquerForCamera = (_heatmap) => {

    if (typeof filters !== undefined && filters) {



      let dateDifference = getDifference(filters.start, filters.end);
      let heatmap = _heatmap.slice();
      let totalLength = heatmap.length;
      let sortedOriginal = [...new Set(heatmap.map(e => e.v))].sort((a, b) => b - a);
      // let sortedOriginal = heatmap.sort((a, b) => a.v - b.v);
      console.log('sortedOriginal is', sortedOriginal);

      let max = sortedOriginal[0];
      let hundredIndex;

      if (sortedOriginal.length <= 100) {
        if (sortedOriginal.length < 50) {
          if (sortedOriginal.length < 19) {

          } else {

          }
          // hundredIndex = sortedOriginal[6];
          let indexx = Math.floor((sortedOriginal.length - 1) / 2);
          hundredIndex = sortedOriginal[indexx]
          console.log('hundredIndex', indexx);
        }
        else {
          hundredIndex = sortedOriginal[50];

        }
      }

      else {
        hundredIndex = sortedOriginal[100];
      }

      let percentage = parseInt(max / hundredIndex) * 100;

      let cutFlag = 0;

      if (dateDifference > 26 && dateDifference < 35) {
        if (totalLength > 100000 && totalLength < 200000) {
          if (percentage < 600) {
            // unze london packages mall ground floor
            if (filters !== undefined && filters && filters.fid === "62861c1219914a97b5ee488c") {
              cutFlag = 33;

            }
            else {
              cutFlag = 500;
            }

          }
          else if (percentage > 2000 && percentage < 3000) {
            cutFlag = 400

            // only for unze london
            // cutFlag = 1700
          }
          else if (percentage > 3000) {
            cutFlag = 700;
          }
          else if (percentage > 1500 && percentage < 2000) {
            // sapphire
            if (user?.client_id && user?.client_id.length > 1 && user?.client_id[0]?._id === "60c9ac8562a70e11372a524b") {
              if (filters !== undefined && filters && filters.fid === "62b441535e55e70ede9789e2") {
                cutFlag = 3000
              }
              else {
                cutFlag = 6000;

              }


            }
            else {
              cutFlag = 500;
            }



          }
          else if (percentage > 600 && percentage < 1500) {
            // sapphhire
            if (user?.client_id && user?.client_id.length > 1 && user?.client_id[0]?._id === "60c9ac8562a70e11372a524b") {
              cutFlag = 1500;

            }
            else {
              cutFlag = 1000;

            }

            // onlu for unze london
            // cutFlag = 250
          }
          else cutFlag = 500
        }
        else if (totalLength > 200000 && totalLength < 300000) {
          if (percentage > 3000) {
            cutFlag = 600;
          }
          else {
            console.log('user', user);
            // outfitter
            if (user?.client_id && user?.client_id.length > 1 && user?.client_id[0]?._id === "6315b2e069475af41be766ef") {
              cutFlag = 8000
            }
            // sapphirre
            else if (user?.client_id && user?.client_id.length > 1 && user?.client_id[0]?._id === "60c9ac8562a70e11372a524b") {
              cutFlag = 16000;

            } else {
              cutFlag = 1000
            }

          }

        }
        else if (totalLength < 100000) {
          if (percentage > 2000 && percentage < 3000) {

            // cutFlag = 600

            // for dynasty only
            cutFlag = 1050;
          }
          else if (percentage < 2000) {
            // only for demo account 
            if (user?.client_id && user?.client_id.length > 0 && user?.client_id[0]?._id == "6193b1ba38b03450ee4b9889") {

              cutFlag = 7500;

            }
            else {
              cutFlag = 1000
            }

            // only for unze london
            // cutFlag = 2000
          }
          // only for unze london
          else if (percentage > 3000) {
            // sapphire
            if (user?.client_id && user?.client_id.length > 0 && user?.client_id[0]?._id === "60c9ac8562a70e11372a524b") {
              if (filters !== undefined && filters && filters.fid === "62b441815e55e70ede978a6e") {
                cutFlag = 100;

              }
              else {
                cutFlag = 500
              }

            }
            else if (filters !== undefined && filters && filters.fid === "62861c1219914a97b5ee488c") {
              cutFlag = 1400;
            }
            else {
              cutFlag = 500;
            }
          }
        }

        else cutFlag = 1200;

      }
      else if (dateDifference > 35 && dateDifference < 62) {
        if (totalLength > 100000 && totalLength < 200000) {
          if (percentage > 1000) {
            // sapphhire
            if (user?.client_id && user?.client_id.length > 1 && user?.client_id[0]?._id === "60c9ac8562a70e11372a524b") {
              if (filters !== undefined && filters && filters.fid === "62b441445e55e70ede9789b5") {
                cutFlag = 6000
              }
              else {
                cutFlag = 3000;

              }


            } else {
              cutFlag = 1000;

            }

          }
          else if (percentage < 1000) {
            cutFlag = 700;
          }
          else cutFlag = 500;
        }
        else if (totalLength < 100000) {
          if (percentage < 1000) {
            cutFlag = 1000;
          }
          else if (percentage > 3000) {
            //  cutFlag = 600

            // only for dynasty
            cutFlag = 20;
          }
          else if (percentage < 2000 && percentage > 1000) {
            // only for demo account 
            if (user?.client_id && user?.client_id.length > 0 && user?.client_id[0]?._id == "6193b1ba38b03450ee4b9889") {
              cutFlag = 13000;

            }
            else {
              cutFlag = 500;

            }

          }

          // else cutFlag = 700;

          // ***** For dynasty only
          else cutFlag = 1500;
        }
        else if (totalLength > 200000 && totalLength < 300000) {
          if (percentage > 600) {
            // sapphhire
            if (filters !== undefined && filters && filters.fid === "62b441445e55e70ede9789b5") {
              cutFlag = 5000
            }
            else {
              cutFlag = 700;
            }
          }
          else if (percentage < 600) {
            cutFlag = 500
          }
          else cutFlag = 800;
        }
        else cutFlag = 500
      }
      // only for unze london
      else if (dateDifference > 10 && dateDifference < 15) {
        if (user?.client_id && user?.client_id.length > 0 && user?.client_id[0]?._id == "6193b1ba38b03450ee4b9889") {
          cutFlag = 5000
        }
        else {

          cutFlag = 160;


        }

      }
      else if (dateDifference > 15 && dateDifference < 25) {
        // outfitter
        if (user?.client_id && user?.client_id.length > 1 && user?.client_id[0]?._id === "6315b2e069475af41be766ef") {
          cutFlag = 10000;

        }
        // sapphirre
        else if (user?.client_id && user?.client_id.length > 1 && user?.client_id[0]?._id === "60c9ac8562a70e11372a524b") {
          console.log('filters', filters);
          if (filters !== undefined && filters && filters.fid === "62b441535e55e70ede9789e2") {
            cutFlag = 900
          }
          else if (filters !== undefined && filters && filters.fid === "62b441815e55e70ede978a6e") {
            cutFlag = 300
          }
          else {
            cutFlag = 1800;

          }

        }
        // unze london emporium mall ground floor
        else if (filters !== undefined && filters && filters.fid === "628619e119914a97b5ee46d0") {
          cutFlag = 50;

        }
        else if (filters !== undefined && filters && filters.fid === "62861c1219914a97b5ee488c") {
          cutFlag = 500
        }
        else {
          console.log('section is', section);
          if (typeof section !== undefined && section && '_id' in section && section._id === "65a8e2a282605b1099228a60") {
            cutFlag = 25

          }
          else if (typeof section !== undefined && section && '_id' in section && section._id === "65a8e22382605b10992288f7") {
            cutFlag = 60

          }
          else if (typeof section !== undefined && section && '_id' in section && section._id === "65a8df9a0e07935c672cc744") {
            cutFlag = 12

          }
          else cutFlag = 200


        }
      }
      else if (dateDifference > 65) {
        if (totalLength > 200000) {
          // sapphirre
          if (user?.client_id && user?.client_id.length > 1 && user?.client_id[0]?._id === "60c9ac8562a70e11372a524b") {
            cutFlag = 8000;
          }
          else if (user?.client_id && user?.client_id.length > 1 && user?.client_id[0]?._id === "6315b2e069475af41be766ef") {
            cutFlag = 9000;

          }
          else {
            cutFlag = 2500
          }


        }
        else if (totalLength < 200000) {
          // sapphhire
          if (user?.client_id && user?.client_id.length > 1 && user?.client_id[0]?._id === "60c9ac8562a70e11372a524b") {
            if (filters !== undefined && filters && filters.fid === "62b441815e55e70ede978a6e") {
              cutFlag = 800
            }
            else {
              cutFlag = 3000;
            }
          }
          else {
            cutFlag = 2500;
          }

        }
        else {
          cutFlag = 2500;

        }
      }
      else {
        cutFlag = 2500;
      }

      console.log('sortedOriginalInCamera', sortedOriginal, "hundredIndex", hundredIndex, "totalLength", totalLength, percentage, dateDifference, cutFlag);


      console.log('cutFlag', filters, cutFlag);

      let indexPerSlot = parseInt(max / hundredIndex);

      let belowCut = [];
      let aboveCut = [];
      heatmap.forEach((item) => {
        if (item.v <= cutFlag) {
          belowCut.push(item)
        }
        else {
          aboveCut.push(item)
        }
      })

      let _aboveCut = aboveCut.slice();
      const totalSlot = Math.ceil(aboveCut.length / indexPerSlot);


      if (indexPerSlot < _aboveCut.length) {
        let iteration = 0;
        for (let i = 0; i < _aboveCut.length; i = i + indexPerSlot) {

          for (let j = 0; j < indexPerSlot; j++) {
            if (j + (iteration * indexPerSlot) < _aboveCut.length) {
              _aboveCut[j + (iteration * indexPerSlot)].v = cutFlag + iteration;
            }

          }
          iteration = iteration + 1;
        }
      }
      else {
        for (let i = 0; i < _aboveCut.length; i = i + 1) {

          _aboveCut[i].v = cutFlag + i;

        }
      }

      let forwardHeatmap = [...belowCut.slice(), ..._aboveCut.slice()].slice()
      return forwardHeatmap
    }
  }


  const set1 = async (heatmap) => {

    if (heatmap.length > 0) {

      let orignalHeatmap = JSON.parse(JSON.stringify(heatmap));
      orignalHeatmap = orignalHeatmap.map(e => {
        if (e.length === 4) {
          return { x: e[0], y: e[1], g: e[2], v: e[3] }
        }
        else if (e.length === 3) {
          return { x: e[0], y: e[1], v: e[2] }

        }
      })

      let _heatmap = JSON.parse(JSON.stringify(heatmap));
      let formatHeatmap = [];
      _heatmap.forEach(e => {
        if (e.length === 4 && e.length !== 1) {
          let obj = { x: e[0], y: e[1], g: e[2], v: e[3] }
          formatHeatmap.push(obj)
        }
        else if (e.length === 3 && e.length !== 1) {
          let obj = { x: e[0], y: e[1], v: e[2] }
          formatHeatmap.push(obj)
        }
      })

      let heatmapAfterExcludePoints;
      if (filters !== undefined && filters && filters.fid === "631eef0a2998eee221567a84") {
        heatmapAfterExcludePoints = await excludeDummy(formatHeatmap);
      }
      // this is for jeddah
      else if (filters !== undefined && filters && filters.fid === "650ec04693dffd41dbab0e1d") {
        heatmapAfterExcludePoints = await setVValue(formatHeatmap);
      }
      // this is for riyadh
      else if (filters !== undefined && filters && filters.fid === "650ec07a93dffd41dbab0e7d") {
        heatmapAfterExcludePoints = await setVValueForRiyadh(formatHeatmap);
      }


      else {
        heatmapAfterExcludePoints = formatHeatmap
      }




      let dateDifference = getDifference(filters?.start, filters?.end);
      let normalizeData = [];
      if (dateDifference >= 26) {
        normalizeData = divideAndConquer(heatmapAfterExcludePoints)
      }
      // only for unze london
      else if (dateDifference > 10 && dateDifference < 15) {
        normalizeData = divideAndConquer(heatmapAfterExcludePoints)
      }

      else if (dateDifference > 15 && dateDifference < 25) {
        normalizeData = divideAndConquer(heatmapAfterExcludePoints)
      }

      // only for hushpuppies lucky one mall and sjs 
      else if (user.client_id[0]._id === "64745b52241da796cbadf199" || user.client_id[0]._id === '65bb3fab14aa23f66d44fbaf') {
        normalizeData = divideAndConquer(heatmapAfterExcludePoints)
      }

      else {
        normalizeData = heatmapAfterExcludePoints.slice();
      }

      /******** For original *********/

      // if (orignalHeatmap.length > 0) {
      //   console.log('orignalHeatmap', orignalHeatmap.sort((a, b) => b.v - a.v));

      //   setNormalizeHeatmap(orignalHeatmap);
      // }


      /******** for HeatmapLogic *********/
      console.log('dateDifference', dateDifference);
      if (normalizeData && normalizeData.length) {
        console.log('normalizeData', normalizeData.sort((a, b) => b.v - a.v));
        setNormalizeHeatmap(normalizeData)
      }

    }
    else {
      setNormalizeHeatmap([])
    }
  }



  useEffect(() => {

    console.log("heatmap in set1",heatmap);
    set1(heatmap)

  }, [heatmap])

  const [currentCam, setCurrentCam] = useState();
  const [cameraHeatmap, setCameraHeatmap] = useState([])
  console.log('cameraHeatmap===', cameraHeatmap);

  const getHeatmapByCamera = async (heatmap) => {

    if (heatmap.length > 0) {

      let orignalHeatmap = JSON.parse(JSON.stringify(heatmap));
      orignalHeatmap = orignalHeatmap.map(e => {
        if (e.length === 4) {

          return { x: e[0], y: e[1], g: e[2], v: e[3] }
        }
        else if (e.length === 3) {
          return { x: e[0], y: e[1], v: e[2] }

        }
      })

      let _heatmap = JSON.parse(JSON.stringify(heatmap));
      let formatHeatmap = [];
      _heatmap.forEach(e => {
        if (e.length === 4 && e.length !== 1) {
          let obj = { x: e[0], y: e[1], g: e[2], v: e[3] }
          formatHeatmap.push(obj)
        }
        else if (e.length === 3 && e.length !== 1) {
          let obj = { x: e[0], y: e[1], v: e[2] }
          formatHeatmap.push(obj)
        }
      })



      let heatmapAfterExcludePoints;
      if (filters !== undefined && filters && filters.fid === "631eef0a2998eee221567a84") {
        heatmapAfterExcludePoints = await excludeDummy(formatHeatmap);
      }
      // this is for jeddah
      else if (filters !== undefined && filters && filters.fid === "650ec04693dffd41dbab0e1d") {
        heatmapAfterExcludePoints = await setVValue(formatHeatmap);
      }
      // this is for riyadh
      else if (filters !== undefined && filters && filters.fid === "650ec07a93dffd41dbab0e7d") {
        heatmapAfterExcludePoints = await setVValueForRiyadh(formatHeatmap);
      }


      else {
        heatmapAfterExcludePoints = formatHeatmap
      }




      let dateDifference = getDifference(filters?.start, filters?.end);
      let normalizeData;
      if (dateDifference >= 26) {
        normalizeData = divideAndConquerForCamera(heatmapAfterExcludePoints)
      }
      // only for unze london
      else if (dateDifference > 10 && dateDifference < 15) {
        normalizeData = divideAndConquerForCamera(heatmapAfterExcludePoints)
      }

      else if (dateDifference > 15 && dateDifference < 25) {
        normalizeData = divideAndConquerForCamera(heatmapAfterExcludePoints)
      }



      else {
        normalizeData = heatmapAfterExcludePoints.slice();
      }

      normalizeData =

        /******** For original *********/

        // if (orignalHeatmap.length > 0) {
        //   console.log('orignalHeatmap', orignalHeatmap.sort((a, b) => b.v - a.v));
        //   return orignalHeatmap

        //   setNormalizeHeatmap(orignalHeatmap);
        // }


        /******** for HeatmapLogic *********/
        console.log('dateDifference', dateDifference);
      if (normalizeData.length) {
        console.log('normalizeData', normalizeData.sort((a, b) => b.v - a.v));
        return normalizeData
        // setCameraHeatmap(normalizeData)
      }

    }
    else {
      return []
    }

  }

  const set2 = async (section, modal) => {

    if (typeof section !== undefined && section && modal) {

      let obj = {
        linkedsectionid: section.linked_section,
        dispatch
      }
      const camera = await getCameraByLinkedSection(obj);
      if (camera.data.status && camera.data.data.length > 0) {
        let _currentCam = camera.data.data[0]
        setCurrentCam(_currentCam)

        let object = {
          params: { ...filters, cid: _currentCam._id },
          dispatch
        }

        const _heatmap = await getCameraHeatmap(object);
        console.log('_heatmap==', _heatmap);

        /*******for Original heatmap******/

        // setCameraHeatmap(_heatmap)

        /*******for Original heatmap******/



        /*******for pipeline heatmap******/



        if (_heatmap) {
          const _cameraHeatmap = await getHeatmapByCamera(_heatmap);
          console.log('_cameraHeatmap==', _cameraHeatmap);
          if (activeView === 3) {
            const hmp = _cameraHeatmap.filter((item) => { return (item.g === 1 || item.g === 0) })
            console.log('hmp', hmp);
            setCameraHeatmap(hmp)
          }
          else {
            const hmp = _cameraHeatmap.filter((item) => { return item.g === activeView })
            console.log('hmp', hmp);

            setCameraHeatmap(hmp)

          }
        } else {
          setCameraHeatmap([])

        }


        /*******for pipeline heatmap******/





      }
      console.log('camera===', camera);
    }

  }

  useEffect(() => {

    set2(section, modal)
  }, [modal, section])

  useEffect(() => {
    try {
      if (normalizeHeatmap.length > 0) {
        console.log('inside heatmap', activeView);

        const filteredHeatmap = normalizeHeatmap.filter(item => {

          return activeView === 3 ? item : item.g === activeView;
        });

        setGenderWiseHM(filteredHeatmap);
      }
      else {
        setGenderWiseHM([]);

      }
    } catch (error) {
      console.error("Error in useEffect:", error);
      setGenderWiseHM([]);

      // You can handle or log the error further if needed
    }
  }, [normalizeHeatmap, activeView]);

  useEffect(() => {

    if (typeof currentFloor !== undefined && currentFloor && filters) {
      if (currentFloor?.layout?.length > 0) {
        if (currentFloor?.layout?.length === 1) {
          setFloor(currentFloor[0])
        }
        else {
          let _startdate = new Date(filters.start);
          let _endDate = new Date(filters.end);
          let filteredItems = []
          let filteredLayouts = currentFloor.layout.filter((layout) => {
            let start = new Date(layout.startDate);
            let end = new Date(layout.endDate);

            // Check if the date range intersects with the layout's start and end dates
            return (_startdate >= start && _startdate <= end) || (_endDate >= start && _endDate <= end);
          });

          let selectedLayout = null;
          let message = '';

          if (filteredLayouts.length === 1) {
            selectedLayout = filteredLayouts[0];
          } else if (filteredLayouts.length > 1) {
            message = 'Multiple layouts match the selected date range. Please select a different range.';
          } else {
            message = 'No layout matches the selected date range. Please select a different range.';
          }

          if (selectedLayout) {
            setFloor(filteredLayouts[0])
          }

          if (message) {
            // alert(message)
            toast.error(message, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: false,
            })
            // dispatch(setError(message));
            setFloor({})
          }




        }


      }
      else {
        setFloor({ dye_image: currentFloor?.dye_image, image: currentFloor.image })
      }

    }

  }, [filters, currentFloor && currentFloor])


  const updateFilter = (e) => {
    console.log('heatmap filter is', e);
    let obj = { ...e, }
    setFilters(e)
    let requestData = {
      params: obj,
      dispatch,
    };

    let floorRequestdata = {
      _id: e.fid,
      dispatch,
    };
    let _filter = {
      _startDate: e.start,
      _endDate: e.end,
      _fid: e.fid,
      _sid: e.sid,
    };

    dispatch(setHeatmapFilter(_filter));
    singleFloorFetch(floorRequestdata);
    dispatch(setHeatmap([]));
    setTimeout(() => {
      heatmapRequest(requestData)
    }, 200);
  };




console.log("sdfiaoifw",currentFloor);


  const genderOptions = [
    { id: 3, label: "Both" },
    { id: 1, label: "Male" },
    { id: 0, label: "Female" },
  ];

  return (
    <Fragment>
      <MainFilter
        pageTitle="Heatmaps"
        noRange={true}
        updateFilter={(e) => updateFilter(e)}
        type="gold"
        floorToShow={true}

      ></MainFilter>

      {
        (user?.client_id[0]?._id === "64745b52241da796cbadf199" || user?.client_id[0]?._id === "659bb1c60e02c806ff27475e") &&
        <div className="btn-group mb-3" role="group" aria-label="Basic example">
          {genderOptions.map((option) => (
            <button
              key={option.id}
              type="button"
              className={activeView === option.id ? "px-3 btn btn-sm btn-primary" : "px-3 btn btn-sm btn-outline-primary"}
              onClick={() => setActiveView(option.id)}
            >
              {option.label}
            </button>
          ))}
        </div>}

      <Row className="mb-3">
        <Col>
          <Card>
            <CardBody>
              <Row className="align-items-center justify-content-evenly">
                <Col className="col-8 col-md-8">
                  {filter.store !== null && currentFloor && (
                    <>


                      <Heatmap
                        dye={floor ? floor.dye_image : ""}
                        map={floor ? floor.image : ""}
                        heatmapData={genderWiseHM ? genderWiseHM : []}
                        key={"my_map"}
                        sections={currentFloor.sections ? currentFloor.sections : []}
                        setSection={(section) => setSection(section)}
                        section={section ? section : ""}
                        dispatch={dispatch}
                        setModal={(e) => setModal(e)}
                        floorid ={currentFloor}

                      />

                    </>
                  )}
                </Col>
                <Col className="col-3 col-md-3">
                  <img src={heatmapRange} className="rounded" />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* {
        typeof currentCam !== undefined && currentCam &&
        <div style={{ height: "1080px", width: "960px", maxHeight: "1080px" }} className="p-0 m-0">
          <Heatmap2
            dye={currentCam.screenshot_dye ? currentCam.screenshot_dye : ""}
            map={currentCam.screenshot ? currentCam.screenshot : ""}
            heatmapData={cameraHeatmap.length > 0 ? cameraHeatmap : []}
            key={"my_map"}
            sections={[]}
            setSection={(section) => (section)}
            section={section ? section : ""}
            dispatch={dispatch}
            setModal={(e) => setModal(e)}

          />
        </div>
      } */}


      {
        modal && typeof currentCam !== undefined && currentCam &&
        <CameraHeatmap
          currentCam={currentCam}
          heatmap={cameraHeatmap}
          setCameraHeatmap={(e) => setCameraHeatmap(e)}
          section={section}
          modal={modal}
          setModal={(e) => setModal(e)}
          filters={filters}
        />
      }
      {false && (
        <>
          <Card>
            <CardBody>
              <Row>
                <Col className="h-100 valign-middle">
                  <h5 className="font-italic">
                    "A picture is worth a thousand words"
                  </h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  <img src={heatmapImage} className="rounded w-100" />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </>
      )}
    </Fragment>
  );
};

export default Heatmaps;
