import React, { useEffect, useState, Fragment } from 'react'
import * as echarts from 'echarts'
import { Card, CardBody, Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import WeeklyHeatmapCalendar from './heatmapCalendar/HeatmapCalendar';
import AnuallyHeatmapCalendar from './heatmapCalendar/AnuallyHeatmapCalendar';
import DoubleAnuallyData from './heatmapCalendar/DoubleAnuallyData';
import moment from 'moment';

export default function CalendarHeatmap(props) {


  const timeFormat = (time) => {


    return moment(new Date(time)).format('YYYY-MM-DD')
  }
  const getMonthlyData = async ({ range, sortBy, dateLabels }) => {

    var date = +echarts.number.parseDate(range + '-01-01');
    var end = +echarts.number.parseDate(+range + 1 + '-01-01');
    var dayTime = 3600 * 24 * 1000;

    let monthlyData = [];
    let monthlyObject = {};
    let month = 1;
    let totalOfMonth = 0;
    let conversion = 0;
    let transaction = 0;
    let traffic = 0;
    let sales = 0;
    let units = 0;


    for (var time = date; time <= end; time += dayTime) {

      let currentDate = await timeFormat(time);
      let lastDate = await timeFormat(end - dayTime)
      let currentMonth = new Date(currentDate).getMonth();
      let newCurrentDate = new Date(currentDate);
      let flag;

      if (lastDate == currentDate) {
        flag = true;
      }
      else {
        flag = false
      }

      if (currentDate in dateLabels) {



        let obj
        if (currentMonth < month) {

          obj = { end: currentDate, };
          obj[currentDate] = (dateLabels[currentDate].value);
          obj["value"] = newCurrentDate.toLocaleString('default', { month: 'long' })
          monthlyObject = { ...monthlyObject, ...obj };

          if (sortBy === "conversion") {

            transaction = transaction + dateLabels[currentDate].transaction;
            traffic = traffic + dateLabels[currentDate].total;
          }
          else if (sortBy === "atv") {
            sales = sales + dateLabels[currentDate].sale;
            transaction = transaction + dateLabels[currentDate].transaction;

          }
          else if (sortBy === "asp") {
            sales = sales + dateLabels[currentDate].sale;
            units = units + dateLabels[currentDate].units;

          }
          else if (sortBy === "ipt") {
            units = units + dateLabels[currentDate].units;
            transaction = transaction + dateLabels[currentDate].transaction;

          }

          else {
            totalOfMonth = totalOfMonth + dateLabels[currentDate].value;
          }
        }

        if (currentMonth === month || flag) {

          if (sortBy === "conversion") {
            let _allTotal = (((transaction / traffic) * 100));
            monthlyObject["allTotal"] = (!isFinite(_allTotal) ? 0 : isNaN(_allTotal) ? 0 : parseFloat(_allTotal))
          }
          else if (sortBy === "atv") {

            let _allTotal = (((sales / transaction)));
            monthlyObject["allTotal"] = (!isFinite(_allTotal) ? 0 : isNaN(_allTotal) ? 0 : parseFloat(_allTotal))

          }
          else if (sortBy === "asp") {
            let _allTotal = (((sales / units)));
            monthlyObject["allTotal"] = (!isFinite(_allTotal) ? 0 : isNaN(_allTotal) ? 0 : parseFloat(_allTotal))
          }
          else if (sortBy === "ipt") {

            let _allTotal = (((units / transaction)));
            monthlyObject["allTotal"] = (!isFinite(_allTotal) ? 0 : isNaN(_allTotal) ? 0 : parseFloat(_allTotal))

          }
          else {
            monthlyObject["allTotal"] = totalOfMonth;

          }
          monthlyData.push(monthlyObject);

          monthlyObject = {};
          totalOfMonth = 0;
          month = month + 1;
          conversion = 0;
          sales = 0;
          units = 0;
          traffic = 0;
          transaction = 0;

          if (time !== end - dayTime) {
            time = time - dayTime;
          }
        }

      }
      else {
        let obj = { end: currentDate };


        if (currentMonth < month) {
          obj = { end: currentDate };
          obj[currentDate] = 0;
          obj["value"] = newCurrentDate.toLocaleString('default', { month: 'long' })
          monthlyObject = { ...monthlyObject, ...obj }
          if (sortBy === "conversion") {
            transaction = transaction + 0;
            traffic = traffic + 0;
          }
          else if (sortBy === "atv") {
            transaction = transaction + 0;

            sales = sales + 0;
          }
          else if (sortBy === "asp") {
            sales = sales + 0;
            units = units + 0;
          }
          else if (sortBy === "ipt") {

            transaction = transaction + 0;
            units = units + 0;

          }

          else {
            totalOfMonth = totalOfMonth + 0;
          }
        }

        if (currentMonth === month || flag) {
          if (sortBy === "conversion") {

            let _allTotal = (((transaction / traffic) * 100));
            monthlyObject["allTotal"] = (!isFinite(_allTotal) ? 0 : isNaN(_allTotal) ? 0 : parseFloat(_allTotal))

          }
          else if (sortBy === "atv") {

            let _allTotal = (((sales / transaction)));
            monthlyObject["allTotal"] = (!isFinite(_allTotal) ? 0 : isNaN(_allTotal) ? 0 : parseFloat(_allTotal))

          }
          else if (sortBy === "asp") {

            let _allTotal = (((sales / units)));
            monthlyObject["allTotal"] = (!isFinite(_allTotal) ? 0 : isNaN(_allTotal) ? 0 : parseFloat(_allTotal))

          }
          else if (sortBy === "ipt") {

            let _allTotal = (((units / transaction)));
            monthlyObject["allTotal"] = (!isFinite(_allTotal) ? 0 : isNaN(_allTotal) ? 0 : parseFloat(_allTotal))

          }

          else {
            monthlyObject["allTotal"] = totalOfMonth

          }


          monthlyData.push(monthlyObject);
          monthlyObject = {};
          transaction = 0;
          traffic = 0;
          sales = 0;
          totalOfMonth = 0;
          month = month + 1

          if (time !== end - dayTime) {
            time = time - dayTime;
          }
        }
      }
    }
    return monthlyData
  }

  const getWeeklyData = async ({ range, seriesData, sortBy, dateLabels }) => {


    var date = +echarts.number.parseDate(range + '-01-01');
    var end = +echarts.number.parseDate(+range + 1 + '-01-01');
    var dayTime = 3600 * 24 * 1000;

    let weeks = [];
    let weekObj = {};
    let totalOfWeek = 0;
    let i = 1;
    let conversion = 0;
    let transaction = 0;
    let units = 0;
    let sales = 0;
    let traffic = 0;


    for (var time = date; time <= end; time += dayTime) {
      let currentDay = await timeFormat(time);
      if (currentDay in dateLabels) {
        let obj = { end: currentDay }
        obj[new Date(time).toLocaleString('en-us', { weekday: 'long' })] = (dateLabels[currentDay].value);
        weekObj = { ...weekObj, ...obj }
        if (dateLabels[currentDay].objectName === "conversion") {
          transaction = transaction + dateLabels[currentDay].transaction;
          traffic = traffic + dateLabels[currentDay].total;
        }
        else if (sortBy === "atv") {
          sales = sales + dateLabels[currentDay].sale;
          transaction = transaction + dateLabels[currentDay].transaction;

        }
        else if (sortBy === "asp") {
          sales = sales + dateLabels[currentDay].sale;
          units = units + dateLabels[currentDay].units
        }
        else if (sortBy === "ipt") {
          transaction = transaction + dateLabels[currentDay].transaction;
          units = units + dateLabels[currentDay].units
        }



        else {
          totalOfWeek = totalOfWeek + dateLabels[currentDay].value;
        }
        if (new Date(time).toLocaleString('en-us', { weekday: 'long' }) === 'Saturday') {
          weekObj["start"] = await timeFormat(time - (3600 * 24 * 1000 * 6))
          if (dateLabels[currentDay].objectName === "conversion") {
            let _allTotal = (((transaction / traffic) * 100));
            weekObj["allTotal"] = (!isFinite(_allTotal) ? 0 : isNaN(_allTotal) ? 0 : parseFloat(_allTotal))
          }
          else if (sortBy === "atv") {
            let _allTotal = (((sales / transaction)))
            weekObj["allTotal"] = (!isFinite(_allTotal) ? 0 : isNaN(_allTotal) ? 0 : parseFloat(_allTotal))
          }
          else if (sortBy === "asp") {
            let _allTotal = (((sales / units)))
            weekObj["allTotal"] = (!isFinite(_allTotal) ? 0 : isNaN(_allTotal) ? 0 : parseFloat(_allTotal))
          }
          else if (sortBy === "ipt") {
            let _allTotal = (((units / transaction)).toFixed(2));
            weekObj["allTotal"] = (!isFinite(_allTotal) ? 0 : isNaN(_allTotal) ? 0 : parseFloat(_allTotal))
          }
          else {
            weekObj["allTotal"] = totalOfWeek
          }
          weekObj["value"] = "week " + i;

          let obj1 = {};
          obj1["week" + i] = weekObj
          weeks.push(weekObj);
          weekObj = {};
          i = i + 1
          totalOfWeek = 0;
          conversion = 0;
          traffic = 0;
          transaction = 0;
          units = 0;
          sales = 0;

        }
      }
      else {
        let obj = { end: currentDay }
        obj[new Date(time).toLocaleString('en-us', { weekday: 'long' })] = 0;
        weekObj = { ...weekObj, ...obj }

        if (sortBy === "conversion") {
          transaction = transaction + 0;
          traffic = traffic + 0;
        }
        else if (sortBy === "atv") {
          sales = sales + 0;
          transaction = transaction + 0;

        }
        else if (sortBy === "asp") {
          sales = sales + 0;
          units = units + 0
        }
        else if (sortBy === "ipt") {
          transaction = transaction + 0;
          units = units + 0
        }


        if (new Date(time).toLocaleString('en-us', { weekday: 'long' }) === 'Saturday') {
          weekObj["start"] = await timeFormat(time - (3600 * 24 * 1000 * 6))
          if (sortBy === "conversion") {

            let _allTotal = (((transaction / traffic) * 100));
            weekObj["allTotal"] = (!isFinite(_allTotal) ? 0 : isNaN(_allTotal) ? 0 : parseFloat(_allTotal))

          }
          else if (sortBy === "atv") {
            let _allTotal = (((sales / transaction)))
            weekObj["allTotal"] = (!isFinite(_allTotal) ? 0 : isNaN(_allTotal) ? 0 : parseFloat(_allTotal))
          }
          else if (sortBy === "asp") {
            let _allTotal = (((sales / units)))
            weekObj["allTotal"] = (!isFinite(_allTotal) ? 0 : isNaN(_allTotal) ? 0 : parseFloat(_allTotal))

          }
          else if (sortBy === "ipt") {
            let _allTotal = (((units / transaction)).toFixed(2));
            weekObj["allTotal"] = (!isFinite(_allTotal) ? 0 : isNaN(_allTotal) ? 0 : parseFloat(_allTotal))

          }
          else {
            weekObj["allTotal"] = totalOfWeek
          }


          weekObj["value"] = "week " + i;
          let obj1 = {};
          obj1["week" + i] = weekObj
          weeks.push(weekObj);
          weekObj = {};
          i = i + 1;
          conversion = 0;
          traffic = 0;
          transaction = 0;
          units = 0;
          sales = 0;
          totalOfWeek = 0;

        }
      }
    }

    return weeks;


  }

  const getVirtulData = async ({ range, seriesData, sortBy, dateLabels }) => {
    console.log('range, seriesData, sortBy, dateLabels ', range, seriesData, sortBy, dateLabels);
    var end;
    var time;
    var date, end;

    if (range.length !== undefined && range.length && range.length > 0) {

      date = +echarts.number.parseDate(range[0]);
      end = +echarts.number.parseDate(range[1]);

    }
    else {

      date = +echarts.number.parseDate(range + '-01-01');
      end = +echarts.number.parseDate(+range + 1 + '-01-01');
    }

    var dayTime = 3600 * 24 * 1000;

    var data = [];
    for (var time = date; time <= end; time += dayTime) {
      let _time = await timeFormat(time);
      console.log('_time_time');
      console.log('_time_time', _time, dateLabels);
      if (_time in dateLabels) {
        data.push([
          _time,
          dateLabels[_time].value
        ]);
      }
      else {
        data.push([
          _time,
          0
        ]);
      }
    }

    console.log('datadata', data);
    return data;
  }


  const [sortBy, setSortBy] = useState('total');
  const [dataPoints, setDatapoints] = useState()
  const [max, setMax] = useState();
  const [min, setMin] = useState();
  const [max2, setMax2] = useState();
  const [min2, setMin2] = useState();


  const [weeklyData, setWeeklyData] = useState([]);
  const [monthLydata, setMonthlyData] = useState([]);
  const [anuallyData, setAnuallyData] = useState([]);
  const [anuallyData2, setAnuallyData2] = useState([]);

  const [sortedData, setSortedData] = useState([]);


  useEffect(() => {
    const _dataPoints = {};
    props.dataOptions.forEach(val => {
      _dataPoints[val.value === 'unis' ? 'units' : val.value] = val.label;
    });
    setDatapoints(_dataPoints);
    setSortBy(Object.keys(_dataPoints)[0])

  }, [props.dataOptions]);

  useEffect(() => {
    const _dataPoints = {};
    props.dataOptions.forEach(val => {
      _dataPoints[val.value === 'unis' ? 'units' : val.value] = val.label;
    });
    setDatapoints(_dataPoints);
    setSortBy(Object.keys(_dataPoints)[0])

  }, [props.dataOptions]);

  const set = async (_data, min) => {

    console.log('min==',min);

    if (_data.length > 0 && typeof min !== undefined && min) {

      let _max = _data[0][sortBy];
      setMax(min[_data.length - 1][sortBy]);

      var _min = Math.min(..._data.filter(e => e[sortBy] > 0).map(e => e[sortBy]));

      setMin(_min);

      let dateLabels = {};
      _data.forEach((item) => {
        let obj = {
          ...item,
          label: item.label,
          value: item[sortBy],
          objectName: sortBy,
        }
        dateLabels[item.date] = obj;
      });

      const _anuallyData = await getVirtulData({ range: 'range1' in props.filters ? props?.filters?.range1 : props.filters.range, seriesData: _data, sortBy: sortBy, dateLabels: dateLabels });
      setAnuallyData(_anuallyData)
      console.log('_anuallyData', _anuallyData);
      const _weeklyData = await getWeeklyData({ range: props?.filters?.range, seriesData: _data, sortBy: sortBy, dateLabels: dateLabels });
      setWeeklyData(_weeklyData);

      const _monthlyData = await getMonthlyData({ range: props?.filters?.range, seriesData: _data, sortBy: sortBy, dateLabels: dateLabels });
      setMonthlyData(_monthlyData);

    }

  }

  useEffect(() => {

    let _data = props.data.slice();

    _data = _data.sort((a, b) => {
      return new Date(b[sortBy]) - new Date(a[sortBy]);
    })
    let min = _data = _data.sort((a, b) => {
      if (a === null || isNaN(a) || a === 0) {
        if (b === null || isNaN(b) || b === 0) {
          return (a[sortBy]) - (b[sortBy]);
        }
      }
    })



    setSortedData(_data);
    console.log('min===',min);

    set(_data, min)

  }, [sortBy, props])

  console.log('min===',min);

  useEffect(() => {
    if (props.view === 'double') {

      let _data = props.data2;

      _data = _data.sort((a, b) => {
        return (b[sortBy]) - (a[sortBy]);
      })
      let min = _data = _data.sort((a, b) => {
        if (a === null || isNaN(a) || a === 0) {
          if (b === null || isNaN(b) || b === 0) {
            return (a[sortBy]) - (b[sortBy]);
          }
        }
      })

      let max = _data = _data.sort((a, b) => {
        if (a === null || isNaN(a) || a === 0) {
          if (b === null || isNaN(b) || b === 0) {
            return (b[sortBy]) - (a[sortBy]);
          }
        }
      })


      setSortedData(_data);

      if (_data.length > 0) {

        let _max = max[0][sortBy];
        let _min = _data[_data.length - 1][sortBy];
        setMax2(_max);
        setMin2(min[0][sortBy]);

        let dateLabels = {};
        _data.forEach((item) => {
          let obj = {
            ...item,
            label: item.label,
            value: item[sortBy],
            objectName: sortBy,
          }
          dateLabels[item.label] = obj;
        });
        const anuallyData = getVirtulData({ range: props?.filters?.range2, seriesData: _data, sortBy: sortBy, dateLabels: dateLabels });
        setAnuallyData2(anuallyData)




      }
    }

  }, [sortBy, props.data2])




  return (
    <Fragment>

      <Row>
        <Col>
          {
            <>
              <Card className="my-2" style={{ minHeight: '', maxheight: "", borderRadius: 'none', }}>
                <CardBody>
                  <Row>
                    <Col sm={2} md={2} className='border-right-1'>
                      <h4 className='filter-heading'>Feature</h4>
                      <ListGroup flush>
                        {
                          sortBy !== undefined && sortBy && dataPoints !== undefined && dataPoints && Object.keys(dataPoints).map((key, index) => {
                            return (
                              <ListGroupItem tag="li" onClick={() => setSortBy(key)} key={"selected-data-point-" + index} className={'w-100 data-point-card pointer ' + (sortBy == key ? 'active' : '')}>
                                {dataPoints[key]}
                              </ListGroupItem>
                            )
                          })
                        }
                      </ListGroup>
                    </Col>
                    <Col md={10} sm={12} style={{ height: '' }}>

                      <Row>
                        <Col className='px-5'>

                          {anuallyData.length > 0 && max !== undefined && min !== undefined && props?.filters?.range && props.anually &&

                            <>

                              {props.view === "double" && anuallyData2.length > 0 &&
                                <>
                                  <Row >
                                    <Col md={2} className='text-center' >
                                      <div className='p-2' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {props?.storeToBeCompare1}
                                      </div>
                                    </Col>
                                    <Col md={2} className='text-center p-2' >
                                      {"Vs"}
                                    </Col>
                                    <Col md={2}>
                                      <div className='p-2' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {props?.storeToBeCompare2}
                                      </div>
                                    </Col>
                                    <Col md={1} className='text-center' >
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={12} style={{ height: "100%" }}>
                                      <DoubleAnuallyData
                                        seriesData={anuallyData}
                                        seriesData2={anuallyData2}
                                        max={max > max2 ? max : max2}
                                        min={min < min2 ? min : min2}
                                        range1={props?.filters.range1}
                                        range2={props?.filters.range2}
                                        heatmapView={props.heatmapView} />
                                    </Col>
                                  </Row>
                                </>
                              }
                              {props.view !== "double" &&
                                <AnuallyHeatmapCalendar
                                  seriesData={anuallyData}
                                  max={max}
                                  min={min}
                                  range={props?.filters?.range}
                                  heatmapView={props.heatmapView}
                                />
                              }
                            </>
                          }

                        </Col>
                      </Row>

                      <Row>
                        <Col className='my-3'>
                          {
                            weeklyData !== undefined && weeklyData && props.weekly &&
                            <WeeklyHeatmapCalendar
                              data={weeklyData}
                              range={props?.filters?.range}
                              sortBy={sortBy}
                              min={min}
                              max={max}
                              xAxis={"week"}
                              yAxis={"Weeks"}
                            />
                          }
                        </Col>
                      </Row>

                      <Row>
                        <Col className='my-3'>
                          {
                            monthLydata !== undefined && monthLydata && props.monthly &&
                            <WeeklyHeatmapCalendar
                              data={monthLydata}
                              range={props?.filters?.range}
                              sortBy={sortBy}
                              min={min}
                              max={max}
                              xAxis={"Month"}
                              yAxis={"Months"}
                            />
                          }
                        </Col>
                      </Row>

                    </Col>
                  </Row>
                </CardBody>
              </Card>

            </>
          }
        </Col>

      </Row >

    </Fragment >
  )
}