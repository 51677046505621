import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { numberFormater } from '../../helpers/common';
import trophy1 from '../../assets/img/trophy-1.png';
import trophy2 from '../../assets/img/trophy-2.png';
import trophy3 from '../../assets/img/trophy-3.png';
import { colors } from '../../helpers/meta';
const circleStyle = {
    marginTop: "-41px", 
    width: "50px", 
    height: "50px", 
    background: colors.primary, 
    color: colors.white, 
    fontSize: "25px", 
    lineHeight: "50px", 
    borderRadius: "50%"
}

const LeaderBoardCard = ({data, sortBy, position, sortByLabel}) => {
    const trophy = [trophy1, trophy2, trophy3];
    const [percentage, setPercentage] = useState(0);
    useEffect(()=>{
        if(data.previous == undefined ||  data.previous[sortBy] == 0 || data.previous[sortBy] == null){
            setPercentage(0);
        }else{
            setPercentage((((data[sortBy] - data.previous[sortBy])/data.previous[sortBy])*100).toFixed(1))
        }
    }, [data])
    return (
        <Card className="w-100" style={{display: "inline-block", float: "none", marginRight: "30px", minWidth: "150px"}}>
            <CardHeader className="bg-white" style={{ borderBottom: "1px solid "+colors.silver }}>
                <h6 className='text-end my-2'>{data.label}</h6>
            </CardHeader>
            <CardBody className='text-center'>
                <div style={{...circleStyle, background: position == 1 ? colors.primaryDark : position == 2 ? colors.primary : position == 3 ? colors.primaryLight : colors.silver}}>
                    {position}
                </div>
                <span className='secondary mt-4'>{sortByLabel}</span>
                <h2 className='m-0 p-0 mb-4' style={{fontSize: "30px", fontWeight: "500"}}>{numberFormater(data[sortBy])}</h2>
                <hr/>
                <p className={percentage < 0 ? "m-0 p-0 danger" : percentage == 0 ? "m-0 p-0 light" : "m-0 p-0 dark"} style={{fontSize: "14px", textAlign: "left"}}>
                        {
                            percentage < 0 && 
                            <ChevronDown style={{marginTop: "-3px"}} className="danger" size={18}/>
                        }
                        {
                            percentage > 0 && 
                            <ChevronUp style={{marginTop: "-3px"}} className="success" size={18}/>
                        }
                        <span>{ data.previous? data.previous[sortBy] == 0 ? "0%" : Math.abs(percentage)+"%" :"0%" }</span>
                    <span className='primary' style={{float: "right"}}><span className="secondary" style={{marginRight: "8px"}}>Previous</span> {numberFormater(data.previous?data.previous[sortBy]:0)}</span>
                </p>
            </CardBody>
        </Card>
    )
}

export default LeaderBoardCard;