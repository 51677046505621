import React, { useState } from 'react'
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export default function PictureView(props) {
    const [zoomLevel, setZoomLevel] = useState(1);
    const handleZoomIn = () => {
        setZoomLevel(zoomLevel + 0.1); // Increase the zoom level
    };

    const handleZoomOut = () => {
        if (zoomLevel > 1) {
            setZoomLevel(zoomLevel - 0.1); // Decrease the zoom level, but keep it above 1
        }
    };
    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} centered backdrop="static" keyboard={false} size="lg">
            {/* <ModalHeader >Password Reset</ModalHeader> */}
            <ModalBody className=''>
                <Row>
                    <Col>

                        {/* <TransformWrapper>
                            <TransformComponent>
                                <img src={props.image} alt="test"  width="100%" height={"100%"}/>
                            </TransformComponent>
                        </TransformWrapper> */}

                        <TransformWrapper
                            initialScale={1}
                            initialPositionX={1}
                            initialPositionY={1}
                            
                        >
                            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                <React.Fragment>
                                    <div className="tools">
                                        <button onClick={() => zoomIn()}>+</button>
                                        <button onClick={() => zoomOut()}>-</button>
                                        <button onClick={() => resetTransform()}>x</button>
                                    </div>
                                    <TransformComponent>
                                        <img src={props.image} alt="test" width="100%" height={"100%"} />
                                    </TransformComponent>
                                </React.Fragment>
                            )}
                        </TransformWrapper>





                        {/* <img src={props.image} width="100%" height={"100%"} /> */}

                    </Col>
                </Row>


            </ModalBody>
            <ModalFooter>
                <div className='d-flex' style={{ width: "100%", position: '' }}>
                    <Button className='btn btn-primary' style={{ marginLeft: "auto" }} onClick={props.toggle} >Close</Button>
                </div>
            </ModalFooter>
        </Modal>
    )
}
