import { actionTypes } from "../constants/action-types";
const initialState = {
    systemFeatures : [],
    clientFeature : [],
}

export const systemFeaturesReducer = (state = initialState, {type, payload = {}}) => {
    switch(type) {
        case actionTypes.GET_SYSTEM_FEATURE :
            return {systemFeatures : payload}
        default : 
        return state
    }
}