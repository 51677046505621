import {actionTypes} from '../constants/action-types'

export const setConversionData = (payload) => {
    return {
        type: actionTypes.SET_CONVERSION_DATA,
        payload: payload
    }
}

export const setConversionDailyData = (payload) => {
    return {
        type: actionTypes.SET_CONVERSION_DAILY_DATA,
        payload: payload
    }
}

export const setConversionTotal = (payload) => {
    return {
        type: actionTypes.SET_CONVERSION_TOTAL,
        payload: payload
    }
}

export const setConversionFilter = (payload) => {
    return {
        type: actionTypes.SET_CONVERSION_FILTER,
        payload: payload
    }
}

export const setConversionLoader = (payload) => {
    return {
        type: actionTypes.SET_CONVERSION_LOADER,
        payload: payload
    }
}

export const setConversionSuccess = (payload) => {
    return {
        type: actionTypes.SET_CONVERSION_SUCCESS,
        payload: payload
    }
}

export const setConversionError = (payload) => {
    return {
        type: actionTypes.SET_CONVERSION_ERROR,
        payload: payload
    }
}