import {actionTypes} from '../constants/action-types'



export const setCategory = (payload) => {
    return {
        type: actionTypes.SET_CATEGORY,
        payload: payload
    }
}

export const setCategoryGraph = (payload) => {
    return {
        type: actionTypes.SET_CATEGORY_GRAPH,
        payload: payload
    }
}

export const setCategoryFilter = (payload) => {
    return {
        type: actionTypes.SET_CATEGORY_FILTER,
        payload: payload
    }
}

export const setCategoryLoader = (payload) => {
    return {
        type: actionTypes.SET_CATEGORY_LOADER,
        payload: payload
    }
}

export const setEmployeeSaleUnit = (payload) => {
    return {
        type: actionTypes.SET_EMPLOYEE_SALE_UNITS,
        payload: payload
    }
}

export const setJourney = (payload) => {
    return {
        type : actionTypes.SET_JOURNEY,
        payload : payload 
    }
}

export const setSaleItem = (payload) => {
    return {
        type : actionTypes.SET_SALE_ITEM,
        payload : payload 
    }
}






