import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Table, Input, Button } from 'reactstrap';
import { Plus } from 'react-feather';

export default function DwelltimeAdd(props) {
    const [modal, setModal] = useState(false);
    const [data, setData] = useState([
        { time: '', dt: '', tid: 1, seconds: '', minutes: '', hour: '' }
    ]);



    useEffect(() => {
        setModal(props.modal);
    }, [props]);

    const toggle = () => {
        setModal(!modal);
        props.setModal(!modal);
    };

    const addMore = () => {
        let _data = [...data];
        _data.push({ time: '', dt: '', tid: _data.length+1 });
        setData(_data);
    };

    const handleInputChange = (index, name, value) => {
        let _data = [...data];
        _data[index][name] = value;
        setData(_data);
    };

    const handleAdd = () => {
        // Handle adding data here, you can access the updated data state
        // in the "data" variable.
        // Add your logic to handle the addition of data to your application state.
        // For example:
        props.addData(data);
        toggle(); // Close the modal after adding data.
    };

    return (
        <div>
            <Modal isOpen={modal} toggle={toggle} centered size='lg'>
                <ModalHeader>
                    <h4>Add Dwelltime</h4>
                </ModalHeader>
                <ModalBody className='text-center'>
                    <div className='d-flex' style={{ justifyContent: 'end' }}>
                        <Plus
                            size='20'
                            color='#fd7e14'
                            onClick={() => addMore()}
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                    <Table>
                        <thead>
                            <tr>
                                <th style={{ textAlign: 'left' }}>Hour</th>
                                <th style={{ textAlign: 'left' }}>Minute</th>
                                <th style={{ textAlign: 'left' }}>Seconds</th>
                                <th style={{ textAlign: 'left' }}>Dt (min)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className='m-2'>
                                            <Input
                                                type='number'
                                                name='hour'
                                                placeholder='hour'
                                                style={{ width: '100px' }}
                                                value={item.hour}
                                                onChange={(e) =>
                                                    handleInputChange(index, 'hour', e.target.value)
                                                }
                                            />
                                        </td>
                                        <td className='m-2'>
                                            <Input
                                                type='number'
                                                name='minutes'
                                                placeholder='minutes'
                                                style={{ width: '100px' }}
                                                value={item.minutes}
                                                onChange={(e) =>
                                                    handleInputChange(index, 'minutes', e.target.value)
                                                }
                                            />
                                        </td>
                                        <td className='m-2'>
                                            <Input
                                                type='number'
                                                name='seconds'
                                                placeholder='seconds'
                                                style={{ width: '100px' }}
                                                value={item.seconds}
                                                onChange={(e) =>
                                                    handleInputChange(index, 'seconds', e.target.value)
                                                }
                                            />
                                        </td>
                                        <td className='m-2'>
                                            <Input
                                                type='number'
                                                name='dt'
                                                placeholder='dt'
                                                style={{ width: '100px' }}
                                                value={item.dt}
                                                onChange={(e) =>
                                                    handleInputChange(index, 'dt', e.target.value)
                                                }
                                            />
                                        </td>
                                        {/* ... other input fields ... */}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <div className='d-flex' style={{ width: '100%', position: '' }}>
                        <Button className='btn btn-secondary' style={{ marginLeft: '' }} onClick={toggle}>
                            Close
                        </Button>
                        <Button className='btn btn-primary' style={{ marginLeft: 'auto' }} onClick={handleAdd}>
                            Add
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    );
}
