import React, {useState, useEffect} from 'react';

export default function Avatar(props) {


    const [letters, setLetters] = useState();


    useEffect(() => {
        if(props) {
            var name = props.name?.split(" ");
            var _first = name [0]?.charAt(0);
            var _second = name[1]?.charAt(0);
            if(name.length > 1) {
                let _letters = _first + _second;
                setLetters(_letters);
            }
            if(name.length === 1) {
                setLetters(_first);

            }
        }
    }, [props])


  return <div className='profileContainer'>
      <div className='letterProfile' id='name'>  
            {letters}
      </div>

  </div>;
}
