import React from 'react'
import "../MainFilter.css"
import "../../../App.css"
import { Label } from 'reactstrap'

export default function DateToggle({ name, value, setValue }) {
    return (
        <div className='d-flex'>

            <Label htmlFor='classes' className="form-check-label  custom-date-toggle">{name}</Label>
            <Label className='switch mx-3' style={{ marginTop: "0.2rem" }}>
                <input className='classInput' type='checkbox' id='classes' name='classes' defaultChecked={value} onChange={() => setValue(!value)}></input>
                <span className='sliderr round'></span>
            </Label>
        </div>
    )
}
