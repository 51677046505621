import React from 'react'
import { ListGroupItem } from 'reactstrap';
import { captialLetterOfEachWord } from '../../../helpers/utils';
import "../MainFilter.css"
import "../../../App.css"



export default function ListOfStores({options, citiesOrClass,setCustomSelection,selectByCity,selection,classifyFlag,summaryStores,selectByStoreForSummary}) {
    return (
        <div>
            {Object.keys(citiesOrClass).map((key) => {
        return (
            <ListGroupItem key={'city-' + key} tag="a" href="#" className={'ListGroupItem'}>
                <div onClick={(e) => { e.detail == 2 ? setCustomSelection(true) : selectByCity(key); }}>
                    <span key={'city-' + key} tag="a" href="#"  >
                        {citiesOrClass[key]}
                    </span>


                </div>
                {options.map((stores, index) => {
                    if (typeof selection != undefined && selection && stores[classifyFlag] === captialLetterOfEachWord(selection)) {
                        return (
                            <>
                                <div className={selection === key ? "px-3 pb-1" : 'pb-1 storeHide '} style={{ cursor: "pointer", }} >
                                    <div className={(summaryStores != undefined && summaryStores && summaryStores[stores._id]) && stores._id === summaryStores[stores._id] ? 'storeItemActive p-1' : 'storeItem p-1 '} onClick={(e) => { selectByStoreForSummary(stores) }} >
                                        {stores.label}
                                    </div>
                                </div>

                            </>
                        )
                    }

                })

                }
            </ListGroupItem>
            )
    })}
        </div>

    )
}
