import { actionTypes } from "../constants/action-types"
const initialState = {
    users: [],
    user: null,
    userActivities : []
}

export const userReducer = (state = initialState, { type, payload = {} }) => {
    switch (type) {
        case actionTypes.GET_USER:
            return {...state, users: payload };
        case actionTypes.GET_NEW_USER:
            return {...state, user: payload }
        case actionTypes.GET_USER_ACTIVITY :
            return {...state, userActivities : payload}
        default:
            return state;
    }
}







