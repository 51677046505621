import React, { useEffect, useState, Fragment } from 'react'
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts'
import { Card, CardBody, Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { formatter, numberFormater } from '../../../helpers/common';

export default function WeeklyHeatmapCalendar({ data, range, sortBy, min, max, yAxis, xAxis, value }) {
    const [option, setOption] = useState();


    useEffect(() => {

        if (data.length > 0) {

            let _data = [];
            let xAxisData = [];
            let total = [];

            data.forEach((item, index) => {
                let array = [0];
                array.push(index);
                array.push(item.allTotal);
                _data.push(array);
                total.push(item.allTotal)
                let week = { value: item.value, start: item.start, end: item.end };
                xAxisData.push(week)
            })
            const dataOption = _data.map((item) => {
                return [item[1], item[0], formatter(item[2]) || '-'];
            })
       
            var minValue = Math.min.apply(null, total.filter(Boolean));
            var maxValue = Math.max.apply(null, total.filter(Boolean))


            total = total.sort((a, b) => {
                return b - a;
            })


            let maxNumber = total[0];
            let minNumber = total[total.length - 1]

            let option = {
                tooltip: {
                    position: 'top',

                    formatter: yAxis === "Months" ? null: function (p) {
                        var weekly = `<div style="padding : ">
                                    <div>
                                         <p style="font-weight:">  ${echarts.format.formatTime('dd-MM-yy', data[p.value[0]].start)} <span style="font-weight:bold">to</span> ${echarts.format.formatTime('dd-MM-yy', data[p.value[0]].end)}</p> 
                                          <p> <span style="font-weight:">${p.name}</span>  : <span style="font-weight:bold">${p.data[2]}</p>  
                                    </div>
                                    
                                </div>`
                        var monthly = `<div style="padding : 1rem ">
                                            <p><span style="color:${p.color}; margin-right:10px;">\u2B24</span> <span style="font-weight:">${p.name}</span>  : <span style="font-weight:bold">${p.data[2]}</p>  
                                        </div>`
                        return  yAxis === 'Months' ?  monthly : weekly;
                    }
                },
                grid: {
                    height: '40%',
                    // top: '10%'
                    left: "6%"
                },

                xAxis: {
                    type: 'category',
                    data: xAxisData,
                    splitArea: {
                        show: true
                    },
                },
                yAxis: {
                    type: 'category',
                    data: [yAxis],
                    splitArea: {
                        show: true
                    },
                    minInterval: 5,

                },
                visualMap: {
                    min: !isFinite(minValue) ? 0 : minValue,
                    max: !isFinite(maxValue) ? 0 : maxValue,
                    calculable: true,
                    orient: 'horizontal',
                    left: 'center',
                    top: '80%'
                },
                series: [
                    {
                        name: '',
                        type: 'heatmap',
                        data: dataOption,
                        label: {
                            rotate: 90,
                            show: true
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };
            setOption(option);
        }



    }, [data, sortBy, max, min])

    return (
        <Fragment>
            {option != undefined &&
                <ReactECharts
                    option={option}
                    style={{ maxHeight: '15rem' }}
                />
            }
        </Fragment>
    )
}
