import React from 'react'
import "../MainFilter.css"
import "../../../App.css"
import { Button, Col, Input, InputGroup, Row } from 'reactstrap'
import Select from 'react-select'
import { colourStyles } from '../../../helpers/common'
import { useState } from 'react'
import { createFilterRequest, updateFilterRequest } from '../../../helpers/requests'
import { Bookmark, Plus, XCircle } from 'react-feather'
import { useDispatch } from 'react-redux'
import { useRef } from 'react'
import { setError } from '../../../redux/actions/highlights'


export default function BranchFilter(
    { setOption,
        setSelection,
        setBranchLabel,
        option,
        branchLabel,
        setBranchFilter,
        isEditBranch,
        branchFilter,
        currentDate,
        setCurrentDate,
        options
    }) {


    const [branchLoader, setBranchLoader] = useState(false)
    const branchRef = useRef(null);
    const dispatch = useDispatch();


    const createBranchFilter = async () => {
        if (!branchLabel || branchLabel.trim() == 0) {
            dispatch(setError("Label for branch filter cannot be empty"));
            return;
        }
        setBranchLoader(true);
        let requestData = {
            params: {
                filter: option.map(row => { return { value: row.value, label: row.label, country: row.country, state: row.state, city: row.city } }),
                label: branchLabel,
                type: "branch"
            },
            dispatch
        };
        await createFilterRequest(requestData);
        setBranchLabel("");
        setBranchFilter(false);
        setBranchLoader(false);
    }


    const updateBranchFilter = async () => {
        if (!branchLabel || branchLabel.trim() == 0) {
            dispatch(setError("Label for branch filter cannot be empty"));
            return;
        }
        setBranchLoader(true);
        let requestData = {
            params: {
                ...currentDate,
                filter: option.map(row => { return { value: row.value, label: row.label, country: row.country, state: row.state, city: row.city } }),
                label: branchLabel,
                type: "branch"
            },
            dispatch
        };
        await updateFilterRequest(requestData);
        setBranchLabel("");
        setBranchFilter(false);
        setBranchLoader(false);
        setCurrentDate({ _id: "" })
    }


    const setOptionByDefault = (e) => {
        setOption(e);
        setSelection("custom")
    }

    return (
        <div>
            <Row className='mb-3' >
                {
                    branchFilter &&
                    <Col>
                        <InputGroup>
                            <Input ref={branchRef} size="sm" type='text' value={branchLabel} onChange={(e) => setBranchLabel(e.target.value)} placeholder='Filter name. For example, Top Branches' />
                            <Button size="sm" color="outline-danger" onClick={() => setBranchFilter(false)} disabled={branchLoader}><XCircle size={16} /></Button>
                            <Button size="sm" color="outline-primary" onClick={() => isEditBranch ? updateBranchFilter() : createBranchFilter()} disabled={branchLoader}><Bookmark size={16} /> {isEditBranch ? "Update Filter" : "Save Filter"} </Button>
                        </InputGroup>
                    </Col>
                }
                {
                    !branchFilter &&
                    <Col className='text-end'>
                        <Button size="sm" color="outline-primary" onClick={() => setBranchFilter(true)}><Plus size={16} /> Create Branch Filter</Button>
                    </Col>
                }
            </Row>
            <div className='mb-3'>
                <Select options={options} value={option} onChange={setOptionByDefault} isMulti styles={colourStyles} />
            </div>

        </div>
    )
}
