import { actionTypes } from "../constants/action-types"
import { user } from '../../helpers/requests'

const dummyDefault = [
    {
        created_at: "2022-03-25T07:54:55.235Z",
        id: "623d754f9d6ad7d251b81544",
        identity: 5015,
        image: "https://devapi.adlytic.ai/uploads/client/164821198608110.JPG",
        name: "Bilal Arif",
        sid: "6234c4d7115aa9163039ad5a",
        updated_at: "2022-03-25T12:39:55.985Z",
        __v: 0,
        _id: "623d754f9d6ad7d251b81544"
    },
    {
        created_at: "2022-03-25T07:57:45.370Z",
        id: "623d75f99d6ad7d251b81564",
        identity: 3190,
        image: "https://devapi.adlytic.ai/uploads/client/164821198608110.JPG",
        name: "Ghazala Manzoor",
        sid: "6234c4d7115aa9163039ad5a",
        updated_at: "2022-03-25T12:55:00.857Z",
        __v: 0,
        _id: "623d75f99d6ad7d251b81564"
    },
    {
        created_at: "2022-03-25T07:57:46.145Z",
        id: "623d75fa9d6ad7d251b81565",
        identity: 2801,
        image: "https://devapi.adlytic.ai/uploads/client/164821198608110.JPG",
        name: "Gohar Sharoon",
        sid: "6234c4d7115aa9163039ad5a",
        updated_at: "2022-03-25T12:55:12.638Z",
        __v: 0,
        _id: "623d75fa9d6ad7d251b81565",
    },
    {
        created_at: "2022-03-25T07:57:48.561Z",
        id: "623d75fc9d6ad7d251b81566",
        identity: 4789,
        image: "https://devapi.adlytic.ai/uploads/client/164821221125418.png",
        name: "Hassan Abdullah",
        sid: "6234c4d7115aa9163039ad5a",
        updated_at: "2022-03-25T12:43:34.789Z",
        __v: 0,
        _id: "623d75fc9d6ad7d251b81566"
    }, {
        asp: 1330.3571,
        atv: 1330.3571,
        conversion: 780,
        emp_id: "623d75f99d6ad7d251b81564",
        ipt: 15,
        minutes: 40,
        net_sale: 1330.3571,
        sec_id: "62387570f63c815bab764500",
        traffic: 4,
        transaction: 1,
        units: 1,
    }
]


const initialState = {
    floors: [],
    floor: null,
    employee: []

}


export const floorReducer = (state = initialState, { type, payload = {} }) => {
    switch (type) {
        case actionTypes.SET_FLOORS:
            return {
                ...state, floors: payload
            }
        case actionTypes.SET_FLOOR:
            return {
                floor: payload
            }
        case actionTypes.SET_EMPLOYEE:
            if ( user.client_id[0]._id ==="60c9ac8562a70e11372a524b" || user.client_id[0]._id === "6234c413115aa9163039ac20") {
                return {
                    ...state, employee: payload
                }
            }

            else {
                return {
                    ...state, employee: dummyDefault

                }
            }

        default:
            return state
    }
}