import { actionTypes } from "../constants/action-types"
import { user } from '../../helpers/requests'
import moment from 'moment';
import { lineSeries } from "./categoryReducer";

import { colors } from "../../helpers/meta";
import { graphColors } from "../../helpers/utils";

const dummySections = () => {

    let dummyData = [{
        asp: 5119.6581,
        atv: 10239.3162,
        conversion: 1,
        emp_id: "623d754f9d6ad7d251b81544",
        ipt: 20,
        minutes: 47.45,
        sale: 10239.3162,
        sec_id: "6238746bf63c815bab7642fb",
        traffic: 121,
        transaction: 1,
        units: 2
    },

        , {
        asp: 3644.9175846153844,
        atv: 4738.39286,
        conversion: 0,
        emp_id: "623d75f99d6ad7d251b81564",
        ipt: 1.3,
        minutes: 500,
        sale: 47383.9286,
        sec_id: "6238732af63c815bab7640b3",
        traffic: 58,
        transaction: 10,
        units: 13
    }, {
        asp: 4068.452388888889,
        atv: 5230.867357142857,
        conversion: 230,
        emp_id: "623d75fa9d6ad7d251b81565",
        ipt: 1.2857142857142858,
        minutes: 40,
        sale: 36616.0715,
        sec_id: "623b073f6043c1508cec1cd8",
        traffic: 20,
        transaction: 7,
        units: 9,
    }, {
        asp: 2708.1807000000003,
        atv: 2708.1807000000003,
        conversion: 850,
        emp_id: "623d75fc9d6ad7d251b81566",
        ipt: 1,
        minutes: 560,
        sale: 8124.542100000001,
        sec_id: "6238751df63c815bab764437",
        traffic: 59,
        transaction: 3,
        units: 3
    }
    ]

    return dummyData

}

const getLabelsPerMinutes = (startDate, endDate) => {
    var labels = [];
    var start = moment(startDate).add(10, "hour");
    var end = moment(endDate);
    if (end.diff(start, "day") <= 1) {
        while (end.diff(start, "hour") > 0) {
            labels.push(start.format("hh:mm:A"));
            start = start.add(1, "minutes");
        }
    } else {
        while (end.diff(start, "day") >= 0) {
            labels.push(start.format("MMM D"));
            start = start.add(1, "minutes");
        }
    }
    return labels;

};

const getLabelsPerHour = (startDate, endDate) => {
    var labels = [];
    var start = moment(startDate).add(10, "hour");
    var end = moment(endDate);
    if (end.diff(start, "day") <= 1) {
        while (end.diff(start, "hour") > 0) {
            labels.push(start.format("hh:mm:A"));
            start = start.add(1, "hour");
        }
    } else {
        while (end.diff(start, "day") >= 0) {
            labels.push(start.format("MMM D"));
            start = start.add(1, "hour");
        }
    }
    return labels;

};

const compileLineData = (startDate, endDate, _rawdata, entity, title, color, type, smooth, areaStyle) => {


    let rawdata = _rawdata.sort((a, b) => { return new Date(a.time) - new Date(b.time) });
    console.log('rawdata', rawdata);


    rawdata = rawdata.map((item) => {
        let obj = {
            _id: moment(item.time).subtract(5, "hours").format("hh:mm:A"),
            [entity]: item[entity]
        }
        return obj
    })
    console.log('rawdata', rawdata);

    var data = [];
    var index = 0;
    let start = moment(startDate).add(10, "hour");
    let end = moment(endDate);
    if (end.diff(start, "day") <= 1) {
        while (end.diff(start, "hour") > 0) {
            if (rawdata.length && index < rawdata.length) {
                if (start.format("hh:mm:A") === rawdata[index]._id) {
                    let _data = parseFloat(rawdata[index][entity]);
                    console.log('_data_data', _data);
                    data.push(_data);
                    // if (_data >= 5) {
                    //     data.push(2)
                    // }
                    // else {
                    //     data.push(_data);
                    // }
                    index++;
                } else {
                    data.push(0);
                }
            } else {
                data.push(0);
            }
            start = start.add(1, "hour");
        }
    } else {
        rawdata = rawdata.sort((a, b) => { return new Date(a._id) - new Date(b._id) });
        while (end.diff(start, "day") >= 0) {
            if (rawdata.length && index < rawdata.length) {
                if (start.format("YYYY-MM-DD") === rawdata[index]._id) {
                    data.push(parseFloat(rawdata[index][entity] / 60).toFixed(1));
                    index++;
                } else {
                    data.push(0);
                }
            } else {
                data.push(0);
            }
            start = start.add(1, "day");
        }
    }
    return lineSeries(data, [entity].indexOf(entity) >= 0 ? color : graphColors.secondary, title, type, smooth, areaStyle);
}




const initialState = {
    sectionOfEmploye: [],
    floor: null,
    employee: [],
    graphOfEmploye: [],
    graphLoader: false,
    timelineLoader: false,
    timelineSection: [],
    employeFrequency: {},
    customerTimeline: [],
    timeSpent: {
        customer: 0,
        employee: 0,
        missed: 0,

    },
    timeSpentChart: {
        allData: 0,
        childLabel: [{ label: "Customer", key: "Customer" }, { label: "Employee", key: "Employee" }, { label: "Missed", key: "Missed" },]
    },
    empFrqChart: {
        allData: 0,
        childLabel: []
    }


}

export const employeActivity = (state = initialState, { type, payload = {} }) => {
    switch (type) {
        case actionTypes.GET_SECTIONS_EMPLOYEE:
            if (user.client_id[0]._id === "60c9ac8562a70e11372a524b" || user.client_id[0]._id === "6234c413115aa9163039ac20") {
                return {
                    ...state, sectionOfEmploye: payload
                }
            }
            if (!payload.length) {
                if (user.client_id[0]._id !== "60c9ac8562a70e11372a524b" || user.client_id[0]._id !== "6234c413115aa9163039ac20") {
                    return {
                        ...state, sectionOfEmploye: dummySections()
                    }
                }
            }
            else {
                return {
                    ...state, sectionOfEmploye: dummySections()
                }
            }
        case actionTypes.GET_GRAPH_OF_EMPLOYEE_ACTIVITY:
            return { ...state, graphOfEmploye: payload }

        case actionTypes.SET_FLOOR:
            return {
                floor: payload
            }
        case actionTypes.SET_EMPLOYEE:
            if (payload.length) {
                return {
                    ...state, employee: payload
                }
            }
        case actionTypes.EMP_ACT_GRAPH_LOADER:
            return { ...state, graphLoader: payload }
        case actionTypes.TIMELINE_LOADER:
            return { ...state, timelineLoader: payload }




        case actionTypes.EMP_ACT_TIMELINE:
            if (payload.data) {

                let _labelsPerMinutes = getLabelsPerHour(payload.requestData.start, payload.requestData.end,);
                let empty = new Array(12).fill(0)
                let _graph;
                let customer;
                let missed;
                let topFive;
                let customerSpent = 0;
                let employeeSpent = 0;
                if (payload.data.sections[0]?.data.length > 0) {
                    _graph = compileLineData(payload.requestData.start, payload.requestData.end, payload.data.sections[0]?.data, 'count', 'Employee', graphColors.secondary, 'bar');
                    payload.data.sections[0]?.data.forEach((item) => {
                        employeeSpent = item.timeSpent + employeeSpent;
                    })

                } else {
                    _graph = lineSeries(empty, graphColors.secondary, "Employee", "bar");

                }
                if (payload.data.customers.length) {
                    customer = compileLineData(payload.requestData.start, payload.requestData.end, payload.data.customers, 'count', 'Customer', graphColors.primary, 'line', true);
                    payload.data.customers.forEach((item) => {
                        customerSpent = customerSpent + parseInt(item.timeSpent);
                    })
                }
                else {
                    customer = lineSeries(empty, graphColors.primary, "Customer", "line", {});


                }

                if (payload.data.missed.length > 0) {
                    let _areaStyle = {
                        color: graphColors.primary,
                        shadowColor: graphColors.secondary,
                        opacity: 0.7
                    }
                    missed = compileLineData(payload.requestData.start, payload.requestData.end, payload.data.missed.map((item) => { return { ...item, ratio: Math.ceil(item.ratio) } }), 'ratio', 'Customer/Employee Ratio', colors.lightDark, 'line', true, null);
                    if (payload.data.missed.length > 5) {
                        let _topFive = payload.data.missed.sort((a, b) => { return b.ratio - a.ratio }).slice(0, 5).map((item, index) => {

                            return { ...item, ratio: Math.ceil(item.ratio) }

                        });
                        if (typeof _topFive !== undefined && _topFive && _topFive.length > 0) {
                            let _topFiveItem = []
                            _topFive.forEach((item) => {
                                if (item.ratio === 100) {
                                    let obj = {
                                        ...item, ratio: _topFive[1].ratio
                                    }
                                    _topFiveItem.push(obj)
                                }
                                else {
                                    _topFiveItem.push(item)
                                }

                            })

                            if (typeof _topFiveItem !== undefined && _topFiveItem && _topFiveItem.length > 0) {
                                topFive = compileLineData(payload.requestData.start, payload.requestData.end, _topFiveItem, 'ratio', 'Missed Opportunity', graphColors.primary, 'line', true, _areaStyle);
                            }
                            else {
                                topFive = lineSeries(empty, graphColors.primary, "Missed Opportunity", "line");

                            }
                        }
                        else {
                            topFive = lineSeries(empty, graphColors.primary, "Missed Opportunity", "line");
                        }
                    }
                    else {
                        topFive = lineSeries(empty, graphColors.primary, "Missed Opportunity", "line");
                    }
                }
                else {
                    topFive = lineSeries(empty, graphColors.primary, "Missed Opportunity", "line");
                    missed = lineSeries(empty, colors.lightDark, "Customer/Employee Ratio", "bar");
                }





                // let _series = [...[_graph], ...[customer], ...[missed]];
                let _series = [...[topFive], ...[missed]];
                console.log('topFive missed', topFive, missed);

                let _employeFrequency;
                if (typeof _graph !== undefined && _graph && 'data' in _graph && _graph.data.length > 0) {

                    _employeFrequency = _graph.data.reduce(function (obj, b) {
                        obj[b] = ++obj[b] || 1;
                        return obj;
                    }, {});

                }



                // let customerSpent = customer.data.filter((item) => { return item > 0 });
                // let employeeSpent = _graph.data.filter((item) => { return item > 0 });
                let missedSpent = missed.data.filter((item) => { return item > 0 });
                let childrenLabel = [{ label: "Customer", key: "Customer" }, { label: "Employee", key: "Employee" }, { label: "Missed", key: "Missed" },];
                let allData = { Customer: customerSpent, Employee: employeeSpent, Missed: missedSpent.length }
                let _spentTime = {
                    childLabel: childrenLabel,
                    allData: allData
                }

                let spentTime = {
                    customer: customerSpent.length,
                    employee: employeeSpent.length,
                    missed: missedSpent.length
                }

                let empFreqChild = Object.keys(_employeFrequency ? _employeFrequency : {}).map((item) => {
                    let obj = {
                        label: item,
                        key: item
                    }
                    return obj;
                })

                let employeFrequencyChart = {
                    childLabel: empFreqChild,
                    allData: _employeFrequency
                }

                console.log('employeFrequencyChart', employeFrequencyChart);






                let obj = {
                    labels: _labelsPerMinutes,
                    data: _series
                }

                console.log('objobjobjobj', obj);

                return { ...state, timelineSection: obj, employeFrequency: _employeFrequency, timeSpent: spentTime, timeSpentChart: _spentTime, empFrqChart: employeFrequencyChart }

            }
            else {
                return {
                    ...state, timelineSection: { data: [], labels: [] }, employeFrequency: {}, timeSpent: {
                        customer: 0,
                        employee: 0,
                        missed: 0,

                    },
                }
            }


        default:
            return state
    }
}
