import React, { Component, Fragment } from "react";
import { Popover, PopoverBody } from "reactstrap";
export class Sections extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isImageLoaded: false,
      selectedArea: this.props.sections[0]._id,
      scale: 1,
      popoverOpen: [],
      svgWidth: 25,
      svgHeight: 25,
      scaleWidth: null,
      scaleHeight: null,
      getSelectedSection: this.props.sections[0].label,
    };
  }
  componentDidMount() {
    this.props.selectedSection(this.state.getSelectedSection);
    this.imageElement = document.createElement("img");
    this.imageElement.src = this.props.map;
    this.imageElement.addEventListener("load", () => {
      
      console.log(
        this.imageElement.width,
        this.imageElement.height,
        "width height"
      );
      this.setState({ isImageLoaded: true });
    });
    this.imageElement.onload = () => {
      var drawingWidth = document.getElementById("live-heatmap").offsetWidth;
      var drawingHeight = document.getElementById("live-heatmap").offsetHeight;
      let scale = drawingWidth / this.imageElement.width;
      let _scaleWidth = this.imageElement.width * scale;
      let _scaleHeight = this.imageElement.height * scale;
      this.setState({ scaleWidth: _scaleWidth, scaleHeight: _scaleHeight })
      this.setState({ scale: scale });
    };
  }

  componentWillUpdate() {
    console.log("Will update", this.props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.sections[0]._id !== prevProps.sections[0]._id) {
      this.setState({ selectedArea: this.props.sections[0]._id });
    }
  }

  toggle = (index, status = -1) => {
    var _popoverOpen = this.state.popoverOpen.slice();
    if (status === -1) {
      _popoverOpen[index] = !_popoverOpen[index];
      this.setState({ popoverOpen: _popoverOpen });
    } else {
      _popoverOpen[index] = status > 0 ? true : false;
      this.setState({ popoverOpen: _popoverOpen });
    }
  };
  clickArea = (section) => {
    this.props.setSection(section); // this handles onclick updated request
    this.setState({ selectedArea: section._id });
    this.props.selectedSection(section.label);
  };

  render() {
    return (
      <Fragment>
        {this.state.isImageLoaded && (
          <div>
            {this.props.dye != null && this.props.dye.length > 0 && (
              <img
                width={this.state.scaleWidth}
                height={this.scale.scaleHeight}
                src={this.props.dye}
                style={{ position: "absolute", zIndex: 2 }}
                alt={""}
              />
            )}
            <div
              id="live-heatmap"
              style={{
                width: "100%",
                height: "100%",
                overflow: "auto",
                margin: "0px",
                padding: "0px",
              }}
            >
              <svg
                height={this.scale.scaleHeight}
                width={this.scale.scaleHeight}
                style={{ position: "absolute", zIndex: -1 }}
              >
                {this.props.sections.map((section, ind) => {
                  var points = "";
                  var xCords = [];
                  var yCords = [];
                  section.positions.forEach((point, index) => {
                    if (index > 0) {
                      points +=
                        " " +
                        // point.x * this.state.scale +
                        point.x * this.state.scale +

                        "," +
                        point.y * this.state.scale;
                    } else {
                      points +=
                        point.x * this.state.scale +
                        "," +
                        point.y * this.state.scale;
                    }
                    xCords.push(point.x);
                    yCords.push(point.y);
                  });
                  var axisRatio = {
                    x: Math.floor(
                      (Math.max(...xCords) + Math.min(...xCords)) / 2
                    ),
                    y: Math.floor(
                      (Math.max(...yCords) + Math.min(...yCords)) / 2
                    ),
                  };

                  return (
                    <Fragment key={"section_" + ind}>
                      <svg
                        width={this.state.svgWidth * this.state.scale}
                        height={this.state.svgHeight * this.state.scale}
                        id={"Popover-" + section._id}
                        x={axisRatio.x - 12}
                        y={axisRatio.y  - 12}
                        stroke="rgba(0,0,0,0.1)"
                        strokeWidth="1"
                        fill={
                          this.state.selectedArea === section._id
                            ? "rgba(232,129,49,1)"
                            : ""
                        }
                        viewBox="0 0 368 368"
                        onMouseEnter={() => this.toggle(ind, 1)}
                        onMouseLeave={() => this.toggle(ind, 0)}
                        style={{ zIndex: 100 }}
                      >
                        <g>
                          <g>
                            <g>
                              <path
                                d="M184.333,0C102.01,0,35.036,66.974,35.036,149.297c0,33.969,11.132,65.96,32.193,92.515
                                                                        c27.27,34.383,106.572,116.021,109.934,119.479l7.169,7.375l7.17-7.374c3.364-3.46,82.69-85.116,109.964-119.51
                                                                        c21.042-26.534,32.164-58.514,32.164-92.485C333.63,66.974,266.656,0,184.333,0z M285.795,229.355
                                                                        c-21.956,27.687-80.92,89.278-101.462,110.581c-20.54-21.302-79.483-82.875-101.434-110.552
                                                                        c-18.228-22.984-27.863-50.677-27.863-80.087C55.036,78.002,113.038,20,184.333,20c71.294,0,129.297,58.002,129.296,129.297
                                                                        C313.629,178.709,304.004,206.393,285.795,229.355z"
                              />
                              <path
                                d="M184.333,59.265c-48.73,0-88.374,39.644-88.374,88.374c0,48.73,39.645,88.374,88.374,88.374s88.374-39.645,88.374-88.374
                                                                        S233.063,59.265,184.333,59.265z M184.333,216.013c-37.702,0-68.374-30.673-68.374-68.374c0-37.702,30.673-68.374,68.374-68.374
                                                                        s68.373,30.673,68.374,68.374C252.707,185.341,222.035,216.013,184.333,216.013z"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                      {this.state.popoverOpen[ind] && (
                        <polygon
                          onClick={() => this.clickArea(section)}
                          style={{
                            fill:
                              this.state.selectedArea === section._id
                                ? "rgba(0,0,0,0.1)"
                                : "rgba(0,0,0,0.2)",
                          }}
                          className="it"
                          onMouseEnter={() => this.toggle(ind, 1)}
                          onMouseLeave={() => this.toggle(ind, 0)}
                          points={points}
                        />
                      )}
                      {this.state.popoverOpen[ind] && (
                        <Popover
                          placement="top"
                          isOpen={this.state.popoverOpen[ind]}
                          target={"Popover-" + section._id}
                          toggle={() => this.toggle(ind)}
                          key={"Popover-" + section._id}
                        >
                          <PopoverBody>{section.label}</PopoverBody>
                        </Popover>
                      )}
                    </Fragment>
                  );
                })}
              </svg>
              <img
                width={this.scale.scaleWidth}
                height={this.scale.scaleHeight}
                src={this.props.map}
                alt=""
              />
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default Sections;
