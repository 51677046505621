import React, { Fragment, useEffect, useState } from "react";
import { ArrowDown, ArrowUp } from "react-feather";
import { useSelector } from "react-redux";
import { Button, ButtonGroup, Card, Col, Row, CardBody, Progress, ListGroup, ListGroupItem, Table } from "reactstrap";
import { numberFormater } from "../../../helpers/common";
import { user } from "../../../helpers/requests";
import { adlyticFormatter, capitalize, numberFixed } from "../../../helpers/utils";


export default function Performance(props) {

    const features = useSelector((state) => state.auth.features);
    const [dataPoints, setDatapoints] = useState()
    const graphLoader = useSelector((state) => state.employeActivity.graphLoader);
    const mainLoader = useSelector((state) => state.highlights.loading)
    const [data, setData] = useState();


    const [sortBy, setSortBy] = useState("units");
    let stores = user.access.map((item) => { return item.label })

    useEffect(() => {
        let allOptions = [...features.footfall, ...features.sales];
        const _dataPoints = {};
        allOptions.forEach(val => {
            _dataPoints[val.value === 'unis' ? 'units' : val.value] = val.label;
        });
        setDatapoints(_dataPoints);
        setSortBy(Object.keys(_dataPoints)[0])

    }, [features, props.currentView]);

    useEffect(() => {
        if (props.data) {
            let _data = {};
            let object = props.data;
            Object.defineProperty(object, "type", { writable: false, enumerable: false });
            Object.keys(object ? object : {}).forEach((key) => {
                let obj = {};
                props.data[key].forEach((item, index) => {
                    obj[item.label] = item;
                })
                _data[key] = obj
            })
            _data["type"] = props.data.type;
            setData(_data)


        }

    }, [props.data])


    return (
        <Fragment>
            <div className="btn-group" role="group" aria-label="Basic example">
                <button type="button" className={props.activeView === "store" ? "btn btn-primary" : "px-3 btn btn-sm btn-outline-primary"} onClick={() => props.setActiveView("store")} >Stores</button>
                <button type="button" className={props.activeView === "city" ? "btn btn-primary" : "px-3 btn btn-sm btn-outline-primary "} onClick={() => props.setActiveView("city")}>Cities</button>
            </div>

            <Row>
                <Col>
                    {
                        <>
                            <Card className="my-2" style={{ minHeight: '6.75rem', maxheight: "20rem", borderRadius: 'none', }}>
                                <CardBody>
                                    <Row>
                                        <Col sm={2} md={2} className='border-right-1'>
                                            <h4 className='filter-heading'>Feature</h4>
                                            <ListGroup flush>
                                                {
                                                    sortBy !== undefined && sortBy && - dataPoints !== undefined && dataPoints && Object.keys(dataPoints).map((key, index) => {
                                                        return (
                                                            <ListGroupItem tag="li" onClick={() => setSortBy(key)} key={"selected-data-point-" + index} className={'w-100 data-point-card pointer ' + (sortBy == key ? 'active' : '')}>
                                                                {dataPoints[key]}
                                                            </ListGroupItem>
                                                        )
                                                    })
                                                }
                                            </ListGroup>
                                        </Col>
                                        <Col md={10} sm={12}>
                                            {
                                                !graphLoader &&
                                                <div className="container h-100">
                                                    <div className="row align-items-center h-100">
                                                        <div className="col-6 mx-auto">
                                                            <div className="jumbotron">
                                                                {<span className="d-block small opacity-30 text-center align-middle "><i>Data Fetching...</i></span>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}
                                            {data !== undefined && data && "type" in data
                                                && graphLoader
                                                &&
                                                <Row>
                                                    <Table responsive className=" table-hover">
                                                        <thead >
                                                            <tr>
                                                                <th>{props.activeView === "store" ? "Stores" : "Cities"}</th>
                                                                <th>Prev Interval</th>
                                                                <th style={{ paddingLeft: '8.3rem' }}>Current</th>
                                                                {/* <th>{capitalize(sortBy)}</th> */}
                                                                <th>Prev Year</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody >
                                                            {
                                                                data !== undefined && data && "type" in data && data.type && data.type.length > 0 &&
                                                                <>
                                                                    {data.type.map((item, index) => {
                                                                        let label = item;
                                                                        let _current = (item in data.current && sortBy in data.current[item]) ? (data.current[item][sortBy]) : 0;
                                                                        let _previous = (item in data.prev && sortBy in data.prev[item]) ? (data.prev[item][sortBy]) : 0;
                                                                        let _prev_year = (item in data.prev_year && sortBy in data.prev_year[item]) ? data.prev_year[item][sortBy] : 0;
                                                                        let perWRTprev = (((_current - _previous) / _previous) * 100).toFixed(1);
                                                                        let perWRTprev_year = (((_current - _prev_year) / _prev_year) * 100).toFixed(1);

                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{label}</td>
                                                                                <td>{adlyticFormatter(_previous)}</td>
                                                                                <td>

                                                                                    <div className="d-flex">
                                                                                       


                                                                                            <p style={{ width: '4rem', textAlign: 'end', verticalAlign: 'middle' }}>
                                                                                                {_current === _previous ? "-" :
                                                                                                    <>

                                                                                                        <span>{perWRTprev == 0 ? "0%" : isFinite(perWRTprev) ? Math.abs(perWRTprev) + "%" : 0 + "%"}</span>
                                                                                                        {perWRTprev > 0 ?
                                                                                                            <ArrowUp style={{ marginTop: "" }} className="success" size={16} />
                                                                                                            :
                                                                                                            <ArrowDown style={{ marginTop: "" }} className="danger" size={16} />
                                                                                                        }
                                                                                                    </>
                                                                                                }


                                                                                            </p>
                                                                                            <p className="mx-2 force-overflow-horizontal" style={{ fontWeight: "bold", width: '4rem', textAlign: 'center' }}>{  adlyticFormatter(_current)}</p>
                                                                                            <p style={{ width: '4rem' }}>
                                                                                                {_current === _prev_year ? "-" :
                                                                                                    <>
                                                                                                        {perWRTprev_year > 0 ?
                                                                                                            <ArrowUp style={{ marginTop: "" }} className="success" size={16} />
                                                                                                            :
                                                                                                            <ArrowDown style={{ marginTop: "" }} className="danger" size={16} />
                                                                                                        }
                                                                                                        <span>{_prev_year === 0 ? '100%' : perWRTprev_year == 0 ? "0%" : isFinite(perWRTprev_year) ? Math.abs(perWRTprev_year) + "%" : 0 + "%"}</span>
                                                                                                    </>}

                                                                                            </p>
                                                                                        
                                                                                    </div>
                                                                                </td>
                                                                                <td style={{ overflow: 'hidden' }}>{adlyticFormatter(_prev_year)}</td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </>

                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Row>}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                        </>
                    }
                </Col>

            </Row>

        </Fragment>
    )
}
