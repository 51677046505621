import { colors, graphColors } from "./meta";
import { encryptStorage } from './encryptStorage'

export const storage = {
    set: (key, value) => {
        if (typeof value === "object") {
            value = JSON.stringify(value);
        }
        encryptStorage.setItem(key, value);
    },
    get: (key, _default = null) => {
        var value = encryptStorage.getItem(key);
        if (value == undefined || value == null) {
            value = _default;
        }
        return value;
    },
    clear: () => {
        encryptStorage.clear();
    },
    getParsed: (key, _default = null) => {
        var value = encryptStorage.getItem(key);
        if (value == undefined || value == null) {
            value = _default;
        }
        return value;
    }
}

export const enums = {
    USER: "user",
    ROLE_ADMIN: "admin",
    ROLE_SUPER_ADMIN: "superAdmin",
    ENABLED_FEATURES: "enabledFeatures"
}

export const sortTypes = {
    DESC: "DESC",
    ASC: "ASC"
}

export const shortNumber = (number, fixed = 2) => {
    // Nine Zeroes for Billions
    return Math.abs(Number(number)) >= 1.0e9
        ? (Math.abs(Number(number)) / 1.0e9).toFixed(fixed) + 'B'
        : // Six Zeroes for Millions
        Math.abs(Number(number)) >= 1.0e6
            ? (Math.abs(Number(number)) / 1.0e6).toFixed(fixed) + 'M'
            : // Three Zeroes for Thousands
            Math.abs(Number(number)) >= 1.0e3
                ? (Math.abs(Number(number)) / 1.0e3).toFixed(fixed) + 'K'
                : Math.abs(Number(number)).toFixed(fixed);
};

export const numberFormater = (x) => {
    if (x == null) {
        return "0";
    }
    return x.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}



export const formatter = (x) => {
    if (x == null || x == 0) {
        return 0
    }
    else {
        // return x.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        let _x = parseFloat(x.toFixed(2));
        return _x
    }
}

function isInt(n) {
    return Number(n) === n && n % 1 === 0;
}

function isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
}



export const idToLabel = (id, arr) => {
    var label = "";
    arr.map(row => {
        if (row._id == id) {
            label = row.label;
        }
    });
    return label;
}

export const colourStyles = {
    control: (styles, { isFocused, isSelected }) => ({
        ...styles,
        backgroundColor: 'white',
        boxShadow: "none",
        fontSize : "12px",
        borderColor: isSelected ? colors.primary : isFocused ? colors.primary : colors.highlight,
        ':hover': {
            ...styles[':active'],
            borderColor: colors.primary
        },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? data.color
                    : isFocused
                        ? colors.highlight
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? colors.primary
                    : colors.dark,
            cursor: isDisabled ? 'not-allowed' : 'default',
            fontSize : "12px",

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? data.color
                        : colors.primary
                    : undefined,
            },
        };
    },
    multiValue: (styles, { data }) => {
        return {
            ...styles,
            backgroundColor: colors.highlight,
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.color,
        fontSize : '12px'
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: data.color,
        ':hover': {
            backgroundColor: colors.primary,
            color: 'white',
        },
    }),
};

export const hexToRgb = hexValue => {
    let hex;
    hexValue.indexOf('#') === 0 ? (hex = hexValue.substring(1)) : (hex = hexValue);
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
        hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)
    );
    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
};
export const rgbColor = (color = graphColors[0]) => `rgb(${hexToRgb(color)})`;
export const rgbaColor = (color = graphColors[0], alpha = 0.5) => `rgba(${hexToRgb(color)},${alpha})`;
