import { Row, Col, Card, CardBody, CardHeader, CardFooter, ButtonGroup, Button, Progress, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { CustomInput, Form, FormGroup, Input, Label, } from 'reactstrap';
import { resetPasswordRequest } from '../../helpers/requests';
import { Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom"

export default function ResetPassowrd() {


    const updated = useSelector((state) => state.highlights.isSuccess);
    const error = useSelector((state) => state.highlights.isError)
    const highlights = useSelector(state => state.highlights);
    const [confirmPassword, setConfirmPassword] = useState();
    const [errorMessage, setErrorMessage] = useState()
    const [matchFlag, setMatchFlag] = useState(false);
    const [newPassword, setNewPassword] = useState();
    const dispatch = useDispatch();
    const location = useLocation()
    let { token } = useParams();

    const resetPassword = (matchFlag) => {
        if (matchFlag === true) {
            resetPasswordRequest({ dispatch, token: token, newPassword: newPassword })
        }
        else if (matchFlag == 'notValidate') {
            setErrorMessage('Password should be greater than 7 digits')
        }
        else if (matchFlag == 'notMatch') {
            setErrorMessage('Password is not matched')
        }
        else {
            setErrorMessage('Fields are empty')
        }
    }

    useEffect(() => {
        if (newPassword && confirmPassword) {
            if (newPassword === confirmPassword) {
                if (newPassword.length > 7 && confirmPassword.length > 7) {
                    setMatchFlag(true)
                }
                else {
                    setMatchFlag('notValidate')
                }
            }
            else {
                setMatchFlag('notMatch')
            }
        }
    }, [newPassword, confirmPassword])


    useEffect(() => {
        if(error) {
            setErrorMessage('Please try again')
        }
    }, [error])


    return (

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#e9ebee', }}>

            {updated &&
                <Navigate to="/login" state={{ from: location }} replace />

            }
            <Card style={{ maxWidth: '500px', borderRadius: '8px' }} >
                <CardHeader>
                    <h4 className='mt-2 '>Choose a new password</h4>
                </CardHeader>
                <CardBody>
                    <span className='d-block small opacity-50 m-1'>
                        Create a new password that is at least 8 characters long. A strong password has a combination of letters,
                        digits and punctuation marks.  </span>
                    <div className='my-2'>
                        <input type='text' placeholder='New Password' className='block p-2 m-1'
                            style={{ border: '1px solid #dfe1e4', borderRadius: '5px', width: '300px' }}
                            onChange={(event) => setNewPassword(event.target.value)}
                        />
                        <input type='text' placeholder='Confirm New Password' className='block p-2 m-1'
                            style={{ border: '1px solid #dfe1e4', borderRadius: '5px', width: '300px' }}
                            onChange={(event) => setConfirmPassword(event.target.value)}

                        />
                    </div>
                    
                    {errorMessage && <span className='d-block small opacity-50 m-1' style={{ color: 'red' }}>
                        {errorMessage}
                    </span>}

                </CardBody>
                <CardFooter className='d-flex justify-content-end' >
                    <Button className='m-2' >Cancel</Button>
                    <Button color="primary" className='m-2' onClick={() => resetPassword(matchFlag)}>{highlights.loading ? "..updating" : "Save"}</Button>
                </CardFooter>

            </Card>

        </div>

    )
}
