import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Card, CardBody, Col, ListGroup, ListGroupItem, Row, Table } from 'reactstrap';
import { numberFormater } from '../../../helpers/common';
import { adlyticFormatter, capitalize, capitalizeFirstLetter, numberFixed } from "../../../helpers/utils";
import { ArrowDown, ArrowUp } from "react-feather";

export default function SalesPerformance(props) {
  const [sortBy, setSortBy] = useState();
  const [data, setData] = useState();
  const mainLoader = useSelector((state) => state.highlights.loading)

  const features = useSelector((state) => state.auth.features);
  const [dataPoints, setDatapoints] = useState();
  useEffect(() => {
    let allOptions =  [...features.sales, ...features.footfall];
    const _dataPoints = {};
    allOptions.forEach(val => {
      _dataPoints[val.value === 'unis' ? 'units' : val.value] = val.label;
    });
    setDatapoints(_dataPoints);
    setSortBy(Object.keys(_dataPoints)[0])

  }, [features]);


  useEffect(() => {

    if (props.data !== undefined && props.data && 'type' in props.data && typeof props.data.type !== undefined &&  props.data.type  &&   props.data.type.length) {
      setSortBy(props.data.type[0])
    }



  }, [props])

  useEffect(() => {
    if (sortBy !== undefined && sortBy && props.data !== undefined && props.data) {
      let _data = {};

      Object.keys(props.data ? props.data : {}).forEach((key) => {
        if (key in props.data && props.data[key] !== undefined && props.data[key] && props.data[key].length > 0) {
          if (key === 'type') {
            _data[key] = props.data[key].find(item => item === sortBy)
          }
          else {
            _data[key] = props.data[key]?.find((item) => item.label === sortBy)
          }
        }
        else {
          if (key === 'type') {
            _data[key] = props.data[key].find(item => item === sortBy)
          } else {
            _data[key] = {}
          }

        }
      })
      setData(_data);
    }

  }, [sortBy])


  return (
    <Fragment>
      <div className="btn-group" role="group" aria-label="Basic example">
        <button type="button" className={props.activeView === "store" ? "btn btn-primary" : "px-3 btn btn-sm btn-outline-primary"} onClick={() => props.setActiveView("store")} >Stores</button>
        <button type="button" className={props.activeView === "city" ? "btn btn-primary" : "px-3 btn btn-sm btn-outline-primary "} onClick={() => props.setActiveView("city")}>Cities</button>
      </div>

      <Card className="my-2" style={{ minHeight: '6.75rem', maxheight: "20rem", borderRadius: 'none', }}>
        <CardBody>
          <Row>
            <Col sm={2} md={2} xm={2} className='border-right-1'>
              <h4 className='filter-heading'>{capitalize(props.activeView)}</h4>
              <ListGroup flush>
                {
                  props.data !== undefined && props.data && 'type' in props.data && props.data.type?.length > 0 && sortBy !== undefined && sortBy &&
                  props.data.type.map((item, index) => {
                    return (
                      <ListGroupItem tag="li" key={"selected-data-point-" + index} onClick={() => setSortBy(item)} className={'data-point-card pointer ' + (sortBy == item ? 'active' : '')}>
                        {item}
                      </ListGroupItem>
                    )

                  })
                }

              </ListGroup>

            </Col>
            <Col md={10} sm={9} xm={9} >
              {
                mainLoader &&
                <div className="container h-100">
                  <div className="row align-items-center h-100">
                    <div className="col-6 mx-auto">
                      <div className="jumbotron">
                        {<span className="d-block small opacity-30 text-center align-middle "><i>Data Fetching...</i></span>}
                      </div>
                    </div>
                  </div>
                </div>}
              {data !== undefined && !mainLoader

                &&
                <Row>
                  <Table responsive className=" table-hover">
                    <thead >
                      <tr>
                        <th>Feaures</th>
                        <th>Prev Interval</th>
                        <th style={{ paddingLeft: '8.3rem' }}>Current</th>
                        <th>Prev Year</th>
                      </tr>
                    </thead>
                    <tbody >
                      {
                        data !== undefined && data && dataPoints !== undefined && dataPoints &&
                        <>
                          {dataPoints !== undefined && dataPoints &&
                            Object.keys(dataPoints ? dataPoints : {}).map((item) => {

                              let _current = (data.current !== undefined && data.current) && item in data.current ? (data.current[item] !== undefined && data.current[item]) ? data.current[item] : 0 : 0;
                              let _previous = (data.prev !== undefined && data.prev) && item in data.prev ? (data.prev[item] !== undefined && data.prev[item]) ? data.prev[item] : 0 : 0;
                              let _prev_year = (data.prev_year !== undefined && data.prev_year) && item in data.prev_year ? (data.prev_year[item] !== undefined && data.prev_year[item]) ? data.prev_year[item] : 0 : 0;
                              let perWRTprev = (((_current - _previous) / _previous) * 100).toFixed(1);
                              let perWRTprev_year = (((_current - _prev_year) / _prev_year) * 100).toFixed(1);
                              return (
                                <tr key={item}>
                                  <td>{capitalize(item)}</td>
                                  <td>{adlyticFormatter(_previous)}</td>
                                  <td>

                                    <div className="d-flex">
                                      <p style={{ width: '4rem', textAlign: 'end' }}>
                                        {_current === _previous ? "-" :
                                          <>
                                            <span>{perWRTprev == 0 ? "0%" : isFinite(perWRTprev) ? Math.abs(perWRTprev) + "%" : 0 + "%"}</span>
                                            {perWRTprev > 0 ?
                                              <ArrowUp style={{ marginTop: "" }} className="success" size={16} />
                                              :
                                              <ArrowDown style={{ marginTop: "" }} className="danger" size={16} />
                                            }
                                          </>
                                        }


                                      </p>
                                      <p className="mx-2 force-overflow-horizontal" style={{ fontWeight: "bold", width: '4rem', textAlign: 'center' }}>{adlyticFormatter(_current)}</p>
                                      <p style={{ width: '4rem' }}>
                                        {_current === _prev_year ? "-" :
                                          <>
                                            {perWRTprev_year > 0 ?
                                              <ArrowUp style={{ marginTop: "" }} className="success" size={16} />
                                              :
                                              <ArrowDown style={{ marginTop: "" }} className="danger" size={16} />
                                            }
                                            <span>{_prev_year === 0 ? '100%' : perWRTprev_year == 0 ? "0%" : isFinite(perWRTprev_year) ? Math.abs(perWRTprev_year) + "%" : 0 + "%"}</span>
                                          </>}

                                      </p>
                                    </div>
                                  </td>
                                  <td style={{ overflow: 'hidden' }}>{adlyticFormatter(_prev_year)}</td>
                                </tr>
                              )
                            })
                          }
                        </>

                      }
                    </tbody>
                  </Table>
                </Row>}

            </Col>
          </Row>

        </CardBody>
      </Card>



    </Fragment>
  )
}
