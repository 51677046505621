import React, { Fragment, useEffect, useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { useSelector } from 'react-redux';
import { Table } from 'reactstrap';
import { numberFormater, sortTypes } from '../../helpers/common';
import { colors } from '../../helpers/meta';
import ColorDot from '../ColorDot';
import { Link } from 'react-router-dom';
import { adlyticFormatter, convertIntoMillion, getTimeDifference, getTimeDifferenceByMinutes, numberFixed } from '../../helpers/utils';
import { user } from '../../helpers/requests';
import "../../App.css"

const SummaryTable = ({ dataOptions, summary, occupancy, avgDwellTime }) => {
    const dataPoints = { floorLabel: 'Floor' };

    console.log('dataOptions', dataOptions);



    dataOptions.forEach(val => {
        dataPoints[val.value] = val.label;
    });
    dataPoints["Last-Visitor"] = "Last-Visitor"


    const features = useSelector((state) => state.auth.features);
    const storesActivity = useSelector((state) => state.summary.storesActivity);
    const _footfall = { floorLabel: 'Floor' };

    const _sales = {};
    const _insight = {};
    features.sales.forEach(val => {
        _sales[val.value] = val.label;
    });
    features.insight.forEach(val => {
        _insight[val.value] = val.label;
    });
    features.footfall.forEach(val => {
        _footfall[val.value] = val.label;
        if (val.value === 'total') {
            _footfall['totalimpt'] = "totalimpt";

        }
    });

    const [footfall, setFootfall] = useState(_footfall)
    const [sales, setSales] = useState(_sales);
    const [insight, setInsight] = useState(_insight);
    console.log('footfall', footfall);





    const [sortBy, setSortBy] = useState(Object.keys(dataPoints)[1]);
    const [sortDir, setSortDir] = useState(sortTypes.DESC);
    const [sorted, setSorted] = useState([]);
    const [data, setAllData] = useState([]);


    const setSelection = (key) => {
        if (key !== "floorLabel") {
            if (key != sortBy) {
                setSortBy(key);
                setSortDir(sortTypes.DESC);
            } else {
                setSortDir(sortDir == sortTypes.DESC ? sortTypes.ASC : sortTypes.DESC);
            }
        }

    }

    useEffect(() => {
        if (summary.current && summary.current.length) {
            try {
                var _current = summary.current.slice();
                var _previous = summary.previous.slice();


                _current = _current.map((val, index) => {
                    return {
                        ...val, previous: (_previous.filter((a) => a._id == val._id))[0],
                    };
                });

                var _sorted = [];
                if (sortDir == "DESC") {
                    _sorted = _current.sort((a, b) => b[sortBy] - a[sortBy]);
                } else {
                    _sorted = _current.sort((a, b) => a[sortBy] - b[sortBy]);
                }
                setSorted(_sorted);
            } catch (e) {
                console.log(e)
            }
        }
    }, [summary, sortBy, sortDir])

    console.log('sorted', sorted);
    useEffect(() => {
        if (sorted) {
            let mainObj = [];
            let matchItem = [];

            for (let i = 0; i < sorted.length; i++) {
                let _item = sorted[i];
                for (let j = i + 1; j < sorted.length; j++) {
                    let floor = [_item]
                    let total = [];
                    if (_item._id.sid === sorted[j]._id.sid) {
                        let item = { ...sorted[j], fid: sorted[j]._id.fid }

                        floor.push(item);
                        let totalOfFloor = floor.slice();

                        let total = {};
                        let keys = Object.keys(totalOfFloor[0]).forEach((key) => {
                            let totalOfKey = totalOfFloor[0][key];
                            for (let i = 1; i < totalOfFloor.length; i++) {

                                totalOfKey = totalOfKey + totalOfFloor[i][key]

                            }
                            total[key] = totalOfKey;
                        })


                        //   floor.map((floor, index) => {
                        //     return (

                        //     )
                        //   })
                        let obj = {
                            label: _item.label,
                            sid: _item._id.sid,
                            floors: floor,
                            total: total
                        }
                        matchItem.push(obj)
                    }
                    else {
                        mainObj.push(_item)
                    }
                }

            }

            let matchSids = matchItem.map((item) => { return item.sid });
            let data = sorted.filter((item) => (!matchSids.includes(item._id.sid)))
            let allData = [...matchItem, ...data]
            setAllData(allData);

        }

    }, [sorted, dataOptions])

    useEffect(() => {

        if (dataOptions) {
            if (_footfall) {
                let _footfallFeature = { floorLabel: 'Floor' }
                Object.keys(_footfall ? _footfall : {}).forEach((feature) => {
                    if (feature in dataPoints) {
                        _footfallFeature[feature] = _footfall[feature]
                    }
                })

                setFootfall(_footfallFeature);

            }
            if (_sales) {
                let _salesFeature = {}
                Object.keys(_sales ? _sales : {}).forEach((feature) => {
                    if (feature in dataPoints) {
                        _salesFeature[feature] = _sales[feature]
                    }
                })
                setSales(_salesFeature);

            }

            if (_insight) {
                let _insightFeature = {}
                Object.keys(_insight ? _insight : {}).forEach((feature) => {
                    if (feature in dataPoints) {
                        _insightFeature[feature] = _insight[feature]
                    }
                })
                setInsight(_insightFeature);

            }
        }

    }, [dataOptions])

    return (
        <Fragment>
            <Table responsive bordered style={{ fontSize: "", textAlign: "center", overflowX: 'scroll', width: '' }}>
                <thead>
                    <tr>
                        <th scope="row" className='pointer select-none' style={{ width: "", paddingLeft: "", position: 'sticky' }}>#</th>
                        <th scope="row" className='pointer ' style={{ textAlign: "left", position: 'sticky' }}>Store Name</th>
                        {
                            Object.keys(footfall).map((key, index) => {
                                console.log('key=== dataPoints', key, dataPoints[key]);
                                <th scope="row" className='pointer ' style={{ textAlign: "", position: '' }}>Live Occupancy</th>
                                if (key !== "occupancy" && key !== "avgdt") {
                                    return <th scope="row" className='pointer select-none' style={{ display: "", width: '', whiteSpace: 'nowrap', paddingLeft: "1rem" }} onClick={() => setSelection(key)} key={"main-table-th-" + index}>{key !== 'floorLabel' && sortBy == key && (sortDir == sortTypes.DESC ? <ChevronDown size={16} style={{ position: "absolute", marginTop: "2px", marginLeft: "-15px", color: colors.primary }} /> : <ChevronUp size={16} style={{ position: "absolute", marginTop: "2px", marginLeft: "-15px", color: colors.primary }} />)} {(user?.client_id[0]?._id === "6315b2e069475af41be766ef" && key === "floorLabel") ? "Entrance" : dataPoints[key]}</th>
                                }
                            })
                        }
                        {
                            Object.keys(sales).map((key, index) => {
                                return <th scope="row" className='pointer select-none' style={{ display: "", width: '', whiteSpace: 'nowrap', paddingLeft: "1rem" }} onClick={() => setSelection(key)} key={"main-table-th-" + index}>{key !== 'floorLabel' && sortBy == key && (sortDir == sortTypes.DESC ? <ChevronDown size={16} style={{ position: "absolute", marginTop: "2px", marginLeft: "-15px", color: colors.primary }} /> : <ChevronUp size={16} style={{ position: "absolute", marginTop: "2px", marginLeft: "-15px", color: colors.primary }} />)} {dataPoints[key]}</th>
                            })
                        }
                        {
                            Object.keys(insight).map((key, index) => {
                                return <th scope="row" className='pointer select-none' style={{ display: "", width: '', whiteSpace: 'nowrap', paddingLeft: "1rem" }} onClick={() => setSelection(key)} key={"main-table-th-" + index}>{key !== 'floorLabel' && sortBy == key && (sortDir == sortTypes.DESC ? <ChevronDown size={16} style={{ position: "absolute", marginTop: "2px", marginLeft: "-15px", color: colors.primary }} /> : <ChevronUp size={16} style={{ position: "absolute", marginTop: "2px", marginLeft: "-15px", color: colors.primary }} />)} {dataPoints[key]}</th>
                            })
                        }

                        <th scope="row" className='pointer ' style={{ textAlign: "", position: '' }}>Last-Visitor</th>


                    </tr>
                </thead>
                <tbody style={{ borderTop: "none", height: "100%" }}>
                    {sorted && sorted.length > 0 &&
                        sorted.map((parent, parentIndex) => {
                            let activity = parent._id in storesActivity && storesActivity[parent._id];
                            let time = getTimeDifference(parent._id in storesActivity && storesActivity[parent._id].last_activity);
                            let statusTime = getTimeDifferenceByMinutes(parent._id in storesActivity && storesActivity[parent._id].updatedAt);
                            console.log("parent of table", parent);

                            if ("floors" in parent) {

                                return (
                                    <>

                                        <tr key={parentIndex}>
                                            <td rowSpan={parent?.floors?.length}>{parentIndex + 1}</td>
                                            <td rowSpan={parent?.floors?.length} className='' style={{ textAlign: "left", width: '18%', whiteSpace: 'nowrap' }}>

                                                <ColorDot position='relative' marginBottom={'-0.125rem'} color={statusTime < 11 ? colors.success : colors.silver} display={'inline-block'} /><span style={{ marginLeft: "20px" }}><Link to={"/conversions/" + parent.sid + "/" + parent.label}>{parent.label}</Link></span>



                                            </td>
                                            {

                                                Object.keys(footfall).map((key, index) => {
                                                    let number = (key in parent.floors[0] && parent.floors[0][key]) ? key === 'totalimpt' ? (parent.floors[0][key] / parent.floors[0]['total']) * 100 : parent.floors[0][key] : '-';
                                                    console.log('keykey', key);
                                                    if (key !== "occupancy" && key !== "avgdt") {
                                                        return <td
                                                            style={{ width: `${key === "floorLabel" && '14%'}`, whiteSpace: 'nowrap' }}
                                                            key={"main-table-td-" + index}>
                                                            {key === "floorLabel" ? key in parent.floors[0] ? parent.floors[0][key] : "-" :
                                                                key === "totalimpt" ?
                                                                    adlyticFormatter(((parent.floors[0][key] / parent.floors[0]['total']) * 100)) + "%" : adlyticFormatter(parent.floors[0][key])}
                                                        </td>
                                                    }

                                                })
                                            }

                                            {
                                                Object.keys(sales).map((key, index) => {
                                                    return <td rowSpan={parent?.floors?.length}

                                                        className=' justify-content-center'
                                                        key={"main-table-td-" + index}
                                                        style={{ whiteSpace: 'nowrap' }}
                                                    >{adlyticFormatter(parent[key])}</td>

                                                })
                                            }
                                            {
                                                Object.keys(insight).map((key, index) => {
                                                    console.log('key of insight is', key, "and occupancy is", occupancy, "and dwelltime is", avgDwellTime, parent.sid, avgDwellTime.stores[parent.sid]);
                                                    let value = key === 'occupancy' ? adlyticFormatter(occupancy.stores[parent.sid]) : key === "avgdt" ? avgDwellTime.stores[parent.sid] : key in parent ? adlyticFormatter(parent[key]) : "-";
                                                    return <td rowSpan={parent?.floors?.length}

                                                        className=' justify-content-center'
                                                        key={"main-table-td-" + index}
                                                        style={{ whiteSpace: 'nowrap' }}
                                                    >{value}</td>

                                                })
                                            }


                                            {

                                                <td rowSpan={parent?.floors?.length} style={{ whiteSpace: 'nowrap' }}>
                                                    {time.response}
                                                </td>
                                            }

                                        </tr>

                                        {
                                            parent.floors.map((floor, index1) => {
                                                if (parent.floors[index1 + 1] !== undefined) {

                                                    return <tr key={index1}>
                                                        {
                                                            Object.keys(footfall).map((key, index) => {
                                                                return <td
                                                                    style={{ width: `${key === "floorLabel" && '14%'}`, whiteSpace: 'nowrap' }}

                                                                    key={"main-table-td-" + index}>

                                                                    {
                                                                        key === "floorLabel" ? key in parent.floors[index1 + 1] ? parent.floors[index1 + 1][key] : "-" :
                                                                            key === "totalimpt" ?
                                                                                adlyticFormatter((parent.floors[index1 + 1][key] / parent.floors[index1 + 1]['total']) * 100) + "%" : adlyticFormatter(parent.floors[index1 + 1][key])}
                                                                </td>
                                                            })
                                                        }
                                                    </tr>

                                                }

                                            })
                                        }

                                    </>
                                )
                            }
                            else {
                                return <tr key={"main-table-" + parentIndex}>

                                    <td>{parentIndex + 1}</td>

                                    <td style={{ textAlign: "left", width: '18%', whiteSpace: 'nowrap' }}><ColorDot position='relative' marginBottom={'-0.825rem'} color={activity.isActive ? colors.success : colors.silver} /><span style={{ marginLeft: "20px" }}><Link to={"/conversions/" + parent._id.sid + "/" + parent.label}>{parent.label}</Link></span></td>

                                    {
                                        Object.keys(dataPoints).map((key, index) => {
                                            let value = key === 'occupancy' ? adlyticFormatter(occupancy.stores[parent.sid]) : key === "avgdt " ? avgDwellTime.stores[parent.sid] : key == "floorLabel" ? key in parent ? parent[key] : "-" : adlyticFormatter(parent[key]);

                                            if (key !== "Last-Visitor")
                                                return <td
                                                    style={{ width: `${key === "floorLabel" && '18%'}`, whiteSpace: 'nowrap' }}

                                                    key={"main-table-td-" + index}>{value}</td>
                                        })
                                    }


                                    <td style={{ whiteSpace: 'nowrap' }}>
                                        {time.response}
                                    </td>
                                </tr>
                            }
                        })}
                </tbody>
            </Table>
        </Fragment>
    )
}

export default SummaryTable;