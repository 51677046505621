import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { BarChart2, Hash, Layout, PieChart, Star, TrendingUp } from "react-feather";
import { Button, ButtonGroup, Card, Col, Row, CardBody, Progress } from "reactstrap";
import MainFilter from "../components/filters/MainFilter";
import { insightRequest, user, getUserCustomQuestion, getBasicInsightsRequest, deleteCustomQuestionRequest, insightsBreakDownRequest, getInsightTrendsRequest, getAllPrimaryQuestionRequest, addCustomQuestionRequest, salesTrafficPerformance } from "../helpers/requests";
import moment from "moment";
import { themeColors, graphColors, getDifference, getDateString } from "../helpers/utils";
import { useLocalStorage } from "../components/Hooks/useLocalStorage";
import GraphCard from "../components/cards/GraphCard";
import InsightSummary from "./Templates/InsightSummary";
import InsightsTrends from "./Templates/InsightsTrends";
import BasicInsightQuestion from "./Templates/BasicInsightQuestion";
import '../App.css'
import InsightPerformance from "./Templates/InsightView/InsightPerformance";

const Insights = () => {
  const [filter, setFilter] = useState();
  const [filters, setFilters] = useState();
  const [performanceFilter, setPerformanceFilter] = useState();

  const primaryQuestions = useSelector((state) => state.insights.primaryQuestion);
  const userCustomQuestion = useSelector((state) => state.insights.customQuestion);


  const [defaultQuestion, setDefaultQuestion] = useState([]);
  const [defaultBreakDownQuestion, setDefaultBreakDownQuestion] = useState([]);
  const [defaultTrends, setDefaultTrends] = useState([]);
  

  const [customQuestion, setCustomQuestion] = useLocalStorage(
    "customQuestion",
    []
  );

  const [customTrends, setCustomTrends] = useLocalStorage("customTrends", []);
  const basicTrends = useSelector((state) => state.insights.basicTrends);
  const [granularity, setGranularity] = useState("daily");
  const [currentView, setCurrentView] = useState("question");


  const dispatch = useDispatch();
  const updateFilter = (e) => {
    setFilters(e);
    createCustomFilter(e)
    // setFilter(e);
  };
  const updateFilterForInsightsSummary = (e) => {
    var requestData = {
      params: e,
      dispatch,
    };
    insightRequest(requestData);
  };

  const updateFilterForPerformance = (e) => {
    setPerformanceFilter(e)
  }




  const getPrimaryQuestion = () => {
    if (user !== undefined && user) {
      if ("access" in user) {
        let sids = user.access.map((item) => {
          return item._id
        })

        let obj = {
          sid: sids,
          dispatch
        }
        getAllPrimaryQuestionRequest(obj)
      }
    }
  }

  const getLabels = (item) => {
    let _labels = [];
    item.answers.forEach((label) => {
      let obj = {
        label: label._id,
        key: label._id
      }
      _labels.push(obj)
    });
    return _labels


  }

  const getValues = (item) => {
    let _values = {};
    item.answers.forEach((value) => {
      _values[value._id] = value.value
    })
    return _values
  }

  const getCustomeQuestion = () => {
    let obj = {
      user_id: user._id,
      dispatch
    }
    getUserCustomQuestion(obj)
  }

  useEffect(() => {
    getCustomeQuestion();
  }, [1])

  useEffect(() => {
    if (currentView === "question") {
      getPrimaryQuestion()
    }
  }, [currentView])

  useEffect(() => {
    if ((primaryQuestions !== undefined && primaryQuestions)) {
      if ("getBasicInsights" in primaryQuestions) {
        setDefaultQuestion(primaryQuestions.getBasicInsights)
      }
      if ("getBasicInsightsBreakdown" in primaryQuestions) {
        setDefaultBreakDownQuestion(primaryQuestions.getBasicInsightsBreakdown)
      }
      if ("getInsightsTrendsBreakdown" in primaryQuestions) {
        setDefaultTrends(primaryQuestions.getInsightsTrendsBreakdown)
      }
    }


  }, [primaryQuestions])



  const createCustomFilter = (item) => {

    if (item !== undefined && item) {

      let dateRange = getDifference(item.start, item.end) == 0 ? true : false;
      let title = `How many ${item?.feature} did I have in ${item?.labels[0]?.label
        }  ${item?.labels?.length > 1
          ? `and ${item?.labels?.length - 1}` + "+"
          : ""
        } ${dateRange === true
          ? "for " + getDateString(item.start, "ll")
          : "from " +
          getDateString(item.start, "ll") +
          " to " +
          getDateString(item.end, "ll")
        }`

      let obj = {
        dispatch,
        question: title,
        type: "custom",
        user_id: user._id,
        target: currentView === "question" ? "getBasicInsights" : "getInsightsTrendsBreakdown",
        payload: {
          feature: item?.feature,
          start: item.start,
          end: item.end,
          sid: item.sid,
          dateRange: getDifference(item.start, item.end),
          type: currentView === "basicTrends" ? item.type : ''
        }
      }

      if (item.saveFilter) {
        addCustomQuestionRequest(obj)
      }

    }
  }

  useEffect(() => {
    if (filter && filter.sid != undefined && filter.sid.length > 0) {
      let object = {
        dispatch,
        sid: filter.sid,
        end: filter.end,
        start: filter.start,
        feature: filter.feature,
        labels: filter.labels,
      };
      if (currentView === "question") {
        let object = {
          dispatch,
          sid: filter.sid,
          end: filter.end,
          start: filter.start,
          feature: filter.feature,
          // labels: filter.labels,
        };
        getBasicInsightsRequest(object);
        let _question = [...customQuestion, ...[object]].reverse();
        setCustomQuestion(_question);
      }
      if (currentView === "basicTrends") {
        object["type"] = filter.type;
        getInsightTrendsRequest(object);
        setGranularity(object.type);
        let _question = [...customTrends, ...[object]].reverse();
        setCustomTrends(_question);
      }
    }
  }, [filter]);

  useEffect(() => {
    if (basicTrends) {
      let _data = basicTrends.map((item) => item.value);
      let _labels;
      if (granularity == "weekly") {
        _labels = basicTrends?.map((val, index) => {
          return moment(val._id).format("MMM Do YY");
        });
      } else if (granularity == "monthly") {
        _labels = basicTrends?.map((val, index) => {
          return moment(val._id).format("MMM YY");
        });
      } else if (granularity == "daily") {
        _labels = basicTrends?.map((val, index) => {
          return moment(val._id).format("Do MMM YY");
        });
      }
    }
  }, [basicTrends]);



  useEffect(() => {
    if (filter) {
      let obj = { ...filter, dispatch };
      insightRequest(obj);
    }
  }, [filter]);



  const tabStyle = (type, selected) => {
    return {
      fontSize: "16px",
      border: "0px",
      color: selected === type ? themeColors.dark : "inherit",
      background: "white",
      padding: "20px 20px 17px 20px",
      borderBottom:
        selected === type
          ? "3px solid " + graphColors.primary
          : "3px solid transparent",
    };
  };


  const deleteCustom = (question_id) => {
    deleteCustomQuestionRequest({
      _id: question_id, dispatch
    })
  }


  return (
    <Fragment >
      {(currentView === "question" || currentView === "basicTrends") && (
        <MainFilter
          pageTitle=""
          updateFilter={(e) => updateFilter(e)}
          type="insights"
          currentView={currentView}

        ></MainFilter>
      )}
      {currentView === "summary" && (
        <MainFilter
          pageTitle=""
          updateFilter={(e) => updateFilterForInsightsSummary(e)}
          type="premium"
          currentView={currentView}
        ></MainFilter>
      )}
      {(currentView === "trafficPerformance" || currentView === "salesPerformance") && (
        <MainFilter
          pageTitle=""
          updateFilter={(e) => updateFilterForPerformance(e)}
          type="calendar"
          width="550px"
          currentView={currentView}
        ></MainFilter>
      )}

      <Card className="my-4" style={{ backgroundColor: "" }}>
        <Row>
          <Col
            className="h-100 align-middle px-4 text-right"
            style={{ height: "64px" }}
          >
            <ButtonGroup size="sm" style={{ borderRadius: "0px" }}>
             
              <Button
                style={tabStyle("question", currentView)}
                onClick={() => setCurrentView("question")}
              >
                Intelligent Question
              </Button>
              <Button
                style={tabStyle("basicTrends", currentView)}
                onClick={() => setCurrentView("basicTrends")}
              >
                Basic Trends
              </Button>
              <Button
                style={tabStyle("trafficPerformance", currentView)}
                onClick={() => setCurrentView("trafficPerformance")}
              >
                Overall Performance
              </Button>
              <Button
                style={tabStyle("salesPerformance", currentView)}
                onClick={() => setCurrentView("salesPerformance")}
              >
                Stores Performance
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </Card>

      {
        currentView === "question" &&
        <>
          {
            (defaultQuestion.length > 0 || ("getBasicInsights" in userCustomQuestion && userCustomQuestion.getBasicInsights.length > 0 )) &&
            <BasicInsightQuestion
              defaultQuestion={defaultQuestion}
              customQuestion={userCustomQuestion.getBasicInsights}
              deleteCustom={(output) => deleteCustom(output)}
            />
          }

          <Row className="mb-4 g-2">
            {defaultBreakDownQuestion.length > 0 && defaultBreakDownQuestion.map((item, index) => {
              return (
                <>
                  <Col md={4} className="" key={"traffic-card-" + index}>
                    <div className='justify-center' style={{ width: "100%", overflow: 'auto', overflowX : "" }}
                    >
                      <GraphCard
                        parent={{ label: item.question.question }}
                        childrenLabel={getLabels(item)}
                        allData={getValues(item)}
                        overFlowFlag={true}
                      />
                    </div>
                  </Col>
                </>
              )
            })}

          </Row>
        </>
      }

      {
        currentView === "basicTrends" && (
          <InsightsTrends
            trends={defaultTrends}
            customTrends={"getInsightsTrendsBreakdown" in userCustomQuestion ? userCustomQuestion.getInsightsTrendsBreakdown : []}
            deleteCustom={(output) => deleteCustom(output)}
            filters={filters}
          />
        )
      }

      {
        (currentView === "trafficPerformance" || currentView === "salesPerformance") && performanceFilter !== undefined && performanceFilter && (
          <InsightPerformance
            filters={performanceFilter}
            currentView={currentView}
          />
        )

      }

    </Fragment >
  );
};

export default Insights;
